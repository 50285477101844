import React  from 'react';
import { Modal, ModalBody, ModalHeader, Row, Col, Button } from 'reactstrap';
import { useHistory } from "react-router-dom";
import { isMobile } from 'react-device-detect';
import { IoIosArrowForward } from "react-icons/io";
import {colors} from '../../../../../../config/constants';
import useModal from '../../../../../../SharedComponents/Modals/useModal';
import moment from 'moment';


const OrderViewDetailsModal = (props) => {
    const { id, title, menu, fullMenu, selectedOrder } = props;
    const modalProps = useModal(id, menu, fullMenu);
    const history = useHistory();

    const showDetails = () => {
        history.push(`/order-history/inventory/${selectedOrder.id}`);
        modalProps.toggle();
    };

    return (
        <Modal {...modalProps}>
            <ModalHeader toggle={modalProps.toggle}>{title}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col className='text-center text-main'>
                       Inventory
                    </Col>
                </Row>
                <Row>
                    <Col className='text-gray py-2'>
                        Last Inventory Date
                    </Col>
                    <Col className='text-right'>
                        {moment(selectedOrder.lastInventoryDate).format("MM/DD/YY")}
                    </Col>
                </Row>
                <Row>
                    <Col className='text-gray py-2'>
                        On Hand Total
                    </Col>
                    <Col className='text-right'>
                        {selectedOrder.onHandTotal}
                    </Col>
                </Row>
                <Row>
                    <Col className='text-gray py-2'>
                    </Col>
                      { isMobile ?  
                        <Col className='text-right' onClick={showDetails}>
                            <IoIosArrowForward size={22} color={colors.lightBlueText} />  
                        </Col> 
                        :
                        <Col className='text-right' onClick={showDetails}>
                            <Button color='green' >See Details</Button> 
                        </Col>
                      }
                </Row>
                <Row>
                    <Col className='text-center text-main'>
                       Order
                    </Col>
                </Row>
                <Row>
                    <Col className='text-gray py-2'>
                        Line Items
                    </Col>
                    <Col className='text-right'>
                        {selectedOrder.lineItems}
                    </Col>
                </Row>
                <Row>
                    <Col xs={7} className='text-gray py-2'>
                        {selectedOrder.distributor} SubTotal
                        
                    </Col>
                    <Col xs={5} className='text-right'>
                        ${selectedOrder.subtotal}
                    </Col>
                </Row>
                <Row>
                    <Col className='text-gray py-2'>
                        Applied Credit
                    </Col>
                    <Col className='text-right'>
                        ${selectedOrder.usedCredit}
                    </Col>
                </Row>
                <Row>
                    <Col className='text-gray py-2'>
                        Rebates Accrued
                    </Col>
                    <Col className='text-right'>
                        ${selectedOrder.totalRebates}
                    </Col>
                </Row>
                <Row>
                    <Col className='text-gray py-2'>
                        Invoice Savings
                    </Col>
                    <Col className='text-right'>
                        ${selectedOrder.totalSavings}
                    </Col>
                </Row>
                <Row>
                    <Col className='text-gray py-2'>
                        Delivery Fee
                    </Col>
                    <Col className='text-right'>
                        ${selectedOrder.deliveryFee}
                    </Col>
                </Row>
                <Row>
                    <Col className='text-gray py-2'>
                        Total
                    </Col>
                    <Col className='text-right'>
                        ${selectedOrder.total}
                    </Col>
                </Row>
                <Row>
                    <Col className='text-center text-main'>
                        Delivery Details
                    </Col>
                </Row>
                <Row>
                    <Col className='text-gray py-2'>
                        Date
                    </Col>
                    <Col className='text-right'>
                        {moment(selectedOrder.requestedDeliveryDate).format('dddd')}, {moment(selectedOrder.requestedDeliveryDate).format("MM/DD/YY")}
                    </Col>
                </Row>
                <Row>
                    <Col className='text-gray py-2'>
                        Time
                    </Col>
                    <Col className='text-right'>
                        {selectedOrder.requestedDeliveryTime}
                    </Col>
                </Row>
                <Row>
                    <Col className='text-gray py-2'>
                        Delivery Notes
                    </Col>
                    <Col className='text-right'>
                        {selectedOrder.notes}
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    )

}

export default OrderViewDetailsModal;