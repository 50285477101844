import React from "react";
import numeral from "numeral";
import { MdKeyboardArrowRight } from "react-icons/md";
import useRecipeCostingMenu from "./useRecipeCostingMenu";
import "./RecipeCostingTableRow.scss";
import { InputCheckboxControlled } from "../../../../../SharedComponents/InputCheckbox/InputCheckbox";

const styles = {
  itemName: {
    fontWeight: 400,
  },
  details: {
    verticalAlign: "middle",
    textAlign: "center",
    fontWeight: 400,
    color: "#006eba",
  },
  detailsIcon: {
    fontSize: "2.2rem",
    position: "relative",
    left: "-0.5rem",
  },
};

const RecipeCostingTableRow = (props) => {
  const {
    selected,
    selectRecipe,
    id,
    name,
    price,
    totalCost,
    foodCostPercent,
    posQuantitySold,
    posTotalSold,
  } = props;
  const { goToRecipe } = useRecipeCostingMenu.useRow(props);

  return (
    <tr className="text-dark-blue recipe-costing-table-row">
      <th>
        <InputCheckboxControlled active={selected} value={id} onClick={selectRecipe} />
      </th>
      <th scope="row" className="py-4" style={styles.itemName}>
        {name}
      </th>
      <td className="py-4">
        {numeral(parseFloat(price)).format("$0,0.00").replace("NaN", "0.00")}
      </td>
      <td className="py-4">{numeral(totalCost).format("$0,0.00")}</td>
      <td className="py-4">{numeral(foodCostPercent).format("0,0.00%")}</td>
      <td className="py-4">
        {null === posQuantitySold || "undefined" === typeof posQuantitySold
          ? "-"
          : numeral(posQuantitySold).format("0,0") +
            " " +
            (posQuantitySold === 1 ? "item" : "items")}
      </td>
      <td className="py-4">
        {null === posTotalSold || "undefined" === typeof posTotalSold
          ? "-"
          : numeral(posTotalSold).format("$0,0.00")}
      </td>
      <td className="clickable" onClick={goToRecipe} style={styles.details}>
        Details <MdKeyboardArrowRight style={styles.detailsIcon} />
      </td>
    </tr>
  );
};

export default RecipeCostingTableRow;
