import { useDispatch } from "react-redux";
import { setSize } from "../../reducers/userInterface/userInterfaceActions";
import { useEffect } from "react";
let innerHeight = require('ios-inner-height');

const useResizeListener = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const updateDimensions = () => {
      dispatch(setSize(window.screen.width, innerHeight()));
    };
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => {
      window.removeEventListener('resize', updateDimensions);
    }
  }, []);
};

export default useResizeListener;