import React, { useState, useEffect } from 'react';
import locationsApi from '../../api/locationsApi';
import { useDispatch } from 'react-redux';
import { localStorageConst } from '../../config/constants';
import { Row, Col } from 'reactstrap';
import { showModal } from "../../reducers/modals/modalsActions";
import AlertModal from '../Modals/AlertModal/AlertModal';

const useApiBroadcast = () => {
  const dispatch = useDispatch();
  const activeLocation = JSON.parse(localStorage.getItem(localStorageConst.keys.activeLocation));

  const getMessages = () => {
    let data=[], params=[];
    if(!activeLocation){ 
      return;
    }
    locationsApi.getMessage(activeLocation.id).then(res => {
      data = res.data;
      if(data.length===0){ 
        return; 
      }
      data.forEach(msg => { params.push({id:msg.id}) });      
      const map = data.map((msg) => ( <Row key={`msg-${msg.id}`}><Col className='text-center'>{msg.message}</Col></Row> ));      
      const props = {message:map, onOk: readMessages(params)};
      dispatch(showModal(AlertModal, props));
    })
    .catch(error => {
      console.log(error);
    });
  };

  const readMessages = (params) => {
    if(params.length===0){ return; }
    locationsApi.readMessage(params).then(res => {
      return res.data;
    })
    .catch(error => console.log(error));    
  };

  const getBroadcastMessages = () => {
    let data=[], params=[];
    if(!activeLocation){ 
      return;
    }
    locationsApi.getBroadcastMessage(activeLocation.id).then(res => {
      data = res.data[0];
      if(data.broadcast_message===null){ 
        return;
      }
      params={id:activeLocation.id};
      const map = <Row key={`bmsg`}><Col className='text-center'>{data.broadcast_message}</Col></Row>;      
      const props = {message:map, onOk: readBroadcastMessages(params)};
      dispatch(showModal(AlertModal, props));
    })
    .catch(error => {
      console.log(error);
    });
  };

  const readBroadcastMessages = (params) => {
    if(params.length===0){ return; }
    locationsApi.readBroadcastMessage(params).then(res => {
      return res.data;
    })
    .catch(error => console.log(error));    
  };

  return { getMessages, getBroadcastMessages };
};


export default {
  useApiBroadcast
};