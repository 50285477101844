export default function reducer(state = {
    values: []
  }, action) {
    switch (action.type) {
      case 'SET_AMOUNT_ITEM_GROUP':
        return {
          ...state,
          values: action.payload
        }
      default:
        return state;
    }
  };