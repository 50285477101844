import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import Header from '../../../../SharedComponents/Header/Header';
import SubHeaderPlaceHolder from '../../../../SharedComponents/SubHeader/SubHeaderPlaceOrder/SubHeaderPlaceOrder';
import PlaceOrderContent from './PlaceOrderContent';
import useMessages from '../../../../SharedComponents/Messages/useMessages';


const PlaceOrder = (props) => {
  const { match, history } = props;
  const { view } = match.params;

  const { getMessages, getBroadcastMessages } = useMessages.useApiBroadcast();

  const headerProps = {
    view, showSearchbar: true, history,
    showSearchFilters: view === 'list'
  };

  useEffect(() => {
    getMessages();
    getBroadcastMessages();
  }, []);

  return (
    <Container fluid={true} className='m-0 p-0'>
      <Header {...headerProps}>
        <SubHeaderPlaceHolder view={view} history={history} />
      </Header>
      <PlaceOrderContent />
    </Container>
  );
};

export default PlaceOrder;