import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';
import {showModal} from '../../../../../reducers/modals/modalsActions';
import { isMobile } from 'react-device-detect';
import ordersApi from '../../../../../api/ordersApi';
import OrderInvetoryRow from './OrderInventoryRow';
import OrderViewDetailsModal from '../Modals/OrderView/OrderViewDetailsModal';
import OrderInventoryDetailsModal from '../Modals/OrderInventory/OrderInventoryDetailsModal';

const useApi = (props) => {
    const params = useParams();
    const dispatch = useDispatch();
    const id = parseInt(params.id);
    const [orderInventoryList, setOrderInventoryList] = useState([]);

    const inputProps = {
        title: '',
        placeholder: '',
        //menu: isMobile,
        fullMenu: true
    };

    const getOrdersByInventory = () => {
        ordersApi.getOrdersByInventory(id).then((res) => {
            setOrderInventoryList(res.data);
        }).catch(error => {
        });
    };
    useEffect(() => {
        getOrdersByInventory();
    }, []);

  

    const showDetails = () => {
        dispatch(showModal(OrderViewDetailsModal, inputProps));
    }

    const orderInventoryMap = orderInventoryList.map((c, i) =>
        <OrderInvetoryRow key={`oi-list-${i}`} data={c}  />
    )

    return { orderInventoryList, orderInventoryMap, showDetails}

}

const useRow = (data) => {
    
    const dispatch = useDispatch();
    const [hover, setHover] = useState(false);

    const showDetailsInventory = () => {
        const inputPropsInventory = {
            title: '',
            menu: isMobile ? true : false,
            fullMenu: true,
            data
        };
        dispatch(showModal(OrderInventoryDetailsModal, inputPropsInventory));
    }

  

    return { setHover, hover, showDetailsInventory }
}




export default {
    useApi,
    useRow
};
