import validate from 'validate.js';

validate.validators.fraction = function(value, options) {
  const num = value ? value.toString() : '';
  if (options.strict) {
    if (num.match(/^[.][0-9]+$/)) {
      return null;
    } else if (num.match(/[0-9]+$/)) {
      return null;
    } else if (num.match(/^[0-9]+[.][0-9]+$/)) {
      return null;
    } else if (num.match(/[0-9]+[/][0-9]+$/)) {
      return null;
    }
  } else {
    // While typing
    if (num === '') {
      return null;
    } else if (num.match(/^[0-9]+$/)) {
      return null;
    } else if (num.match(/^[0-9]+[.]$/)) {
      return null;
    } else if (num.match(/^[0-9]+[.][0-9]+$/)) {
      return null;
    } else if (num.match(/^[0-9]+[/]$/)) {
      return null;
    } else if (num.match(/^[0-9]+[/][0-9]+$/)) {
      return null;
    } else if (num.match(/^[.]+$/)) {
      return null;
    } else if (num.match(/^[.][0-9]+$/)) {
      return null;
    }
  }
  return "is not a fraction or number";
};

validate.validators.array = function(value, options, keyName) {
  const result = [];
  const { length, key, ...rest } = options;
  if (value.length < length) {
    return `needs at least ${length} active`;
  }
  for (let i = 0; i < value.length; i++) {
    const v = value[i];
    const r = validate(v, rest, {format: "flat"});
    if (r) {
      if (key) {
        result.push(r.map(e => e + ' for ' + v[key]));
      } else {
        result.push(r);
      }
    }
  };

  return result.length === 0 ? null : result
};