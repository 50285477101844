import { toggleSidebar, spinner } from '../../reducers/userInterface/userInterfaceActions';
import useInput from '../SharedEffects/useInput';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { changeTourMoral } from '../../reducers/modals/modalsActions';
import { useRef, useState, useEffect } from 'react';
import qs from 'query-string'
import { setListSearch, setListSortByOptions, setListSortBy, setInventorySearch, setInventorySortBy, setInventoryOptionsRearrangeDisabled } from '../../reducers/items/itemsActions';
import { sortOptions, localStorageConst } from '../../config/constants';
import { saveHeaderIndexRevOrder } from '../../reducers/header/headerActions';
import * as $ from 'jquery';

const useActions = () => {
  const { set: setSearch, value: search, clearValue, setValue: setSearchValue } = useInput.useText(null);
  const storeView = useSelector(({items}) => items.view);
  const [view, setView] = useState(storeView);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();


  const clickMenu = () => {
    dispatch(toggleSidebar());
  };

  const clickBack = () => {
    history.goBack();
  };

  const clearSearch = () => {

    const { pathname } = history.location;
    clearValue();
    const search='';

    $("HTML, BODY").animate({
      scrollTop: 0
    });

    switch (view) {
      case 'list':
        dispatch(setListSearch(search));
        dispatch(setListSortByOptions(sortOptions.listView));
        dispatch(setListSortBy(sortOptions.listView.filter(lw => lw.default === 1)[0]));
        break;
      case 'inventory':
        dispatch(setInventorySearch(search));
        const defaultSort = localStorage.getItem(localStorageConst.keys.inventorySortBySelected) ? JSON.parse(localStorage.getItem(localStorageConst.keys.inventorySortBySelected)) : sortOptions.inventoryView.filter(s => s.default)[0];
        dispatch(setInventorySortBy(defaultSort));
        dispatch(setInventoryOptionsRearrangeDisabled(defaultSort));
        
        break;
      default:
        break;
    }

    history.push({
      pathname: pathname,
      search: search
    });
  };

  useEffect(() => {
    const { pathname } = history.location;
    const parts  = pathname.split('/');
    const pathView  = parts[parts.length-1];
    setView(pathView);
    const urlObj = qs.parseUrl(location.search);
    if (urlObj) {
      if (urlObj.query.query !== search) {
        setSearchValue(urlObj.query.query);
      }
    }
  }, [location.search]);

  useEffect(() => {
    if(view === 'list'){
      dispatch(setListSearch(''));
      dispatch(setListSortByOptions(sortOptions.listView));
      dispatch(setListSortBy(sortOptions.listView.filter(lw => lw.default === 1)[0]));
    }else{
      dispatch(setInventorySearch(''));
      const defaultSort = localStorage.getItem(localStorageConst.keys.inventorySortBySelected) ? JSON.parse(localStorage.getItem(localStorageConst.keys.inventorySortBySelected)) : sortOptions.inventoryView.filter(iv => iv.default === 1)[0];
      dispatch(setInventorySortBy(defaultSort));
      dispatch(setInventoryOptionsRearrangeDisabled(defaultSort));
    }
  }, [view]);

  const enterSearch = () => {
    const { pathname } = history.location;

    $("HTML, BODY").animate({
      scrollTop: 0
    });

    switch (view) {
      case 'list':
        dispatch(setListSearch(search));
        if (search) {
          dispatch(setListSortByOptions(sortOptions.listViewSearch));
          dispatch(setListSortBy(sortOptions.listViewSearch.filter(lws => lws.default === 1)[0]));
        } else {
          dispatch(setListSortByOptions(sortOptions.listView));
          dispatch(setListSortBy(sortOptions.listView.filter(lw => lw.default === 1)[0]));
        }
        break;
      case 'inventory':
        dispatch(setInventorySearch(search));
        const defaultSort = localStorage.getItem(localStorageConst.keys.inventorySortBySelected) ? JSON.parse(localStorage.getItem(localStorageConst.keys.inventorySortBySelected)) : sortOptions.inventoryView.filter(iv => iv.default === 1)[0];
        if (search) {
          dispatch(setInventorySortBy(sortOptions.inventoryView[0]));          
          dispatch(setInventoryOptionsRearrangeDisabled(sortOptions.inventoryView[0]));          
        } else {
          dispatch(setInventorySortBy(defaultSort));          
          dispatch(setInventoryOptionsRearrangeDisabled(defaultSort));
        }
        break;
      default:
        break;
    }

    history.push({
      pathname: pathname,
      search: search ? `?query=${search}` : ''
    });
  };
  const [openModalWC, setOpenModalWC] = useState(false);

  const goToNextTourModal = (value) => {
    setOpenModalWC(!openModalWC);
    value = value == 5 ? 1 : value;
    dispatch(changeTourMoral(value));
  }

  const tourElementSearch = useRef();
  const tourElementFilters = useRef();

  const startTour = () => {
    setOpenModalWC(!openModalWC);
  };
  const skipDemo = () => {
    setOpenModalWC(false);
    dispatch(changeTourMoral(1));

  }

  const goToCart = () => {
    history.push('/checkout-ordering/savings');
    dispatch(saveHeaderIndexRevOrder(2));
    // history.push('/checkout-ordering/review-order');

  }

  return {
    clickMenu,
    enterSearch,
    clearSearch,
    setSearch,
    search,
    clickBack,
    openModalWC,
    tourElementSearch,
    tourElementFilters,
    startTour,
    goToNextTourModal,
    skipDemo,
    goToCart
  };
};

export default {
  useActions
};
