import React from 'react';
import { MdAdd } from 'react-icons/md';
import { colors } from '../../config/constants';
import { Button } from 'reactstrap';
import useTouch from '../SharedEffects/useTouch';
import { isMobile } from 'react-device-detect';

const PlusButton = ({large, isActive, onClick, isBlue, square, disabled, ...props}) => {
  // const {preventDoubleTapZoom} = useTouch.useTap(onClick);

  return (
    <Button {...props} 
      onClick={onClick} 
      size={(large ? 'lg-' : '') + (square ? 'square' : 'circle')} 
      color={isActive ? ( isBlue ? 'dark-blue' : 'green') : 'gray'}
      disabled={(disabled === undefined) ? false : disabled}
      // onTouchStart={preventDoubleTapZoom}
    >
      <MdAdd color={isActive ? 'white' : colors.lightBlueText} size={large ? 30 : 24} />
    </Button>
  );
};

export default PlusButton;