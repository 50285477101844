import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Button  } from 'reactstrap';
import useModal from '../../Modals/useModal';
import { isMobile } from "react-device-detect";
import useShoppingCart from '../../ShoppingCart/useShoppingCart';
import useGoTo from '../../GoTo/useGoTo'

const CartSyncModal = (props) => {
    const { id, header, message, sameModifiedItems, lastModified } = props;
    const modalprops = useModal(id, true);
    const {syncBatch} = useShoppingCart.useBatchUpdateCart();
    const { getCartItems } = useShoppingCart.useCartItems();
    const { reviewOrder } = useGoTo.useActions();

    const discard = () => {
      getCartItems();
      reviewOrder();
      modalprops.toggle();
    };

    const apply = () => {
      syncBatch(sameModifiedItems, lastModified);
      modalprops.toggle();
    };

    return (
        <Modal {...modalprops} backdrop='static' keyboard={false} className={isMobile ? 'syncModal mobile' : 'syncModal'} >
            <ModalHeader>
                {header}
            </ModalHeader>
            <ModalBody className='text-center'>
              <Row className='my-2'>
                <Col> {message} </Col>
              </Row>
              <Row className='m-1 py-2 align-items-center'>
                <Col xs={8} className='p-0 text-gray'>Name</Col>
                <Col xs={2} className='p-0 text-gray'>Current</Col>
                <Col xs={2} className='p-0 text-gray'>Modify to</Col>
              </Row>
              {sameModifiedItems.map((item, i) =>
                <Row className='m-0 border-top py-2 w-100 align-items-center' key={`mod_${i}`}>
                  <Col xs={8} className='p-0 text-left text-capitalize'>{item.description}</Col>
                  <Col xs={2} className='p-0'>{item.currentQuantity}</Col>
                  <Col xs={2} className='p-0'>{item.newQuantity}</Col>
                </Row>
              )}
            </ModalBody>
            <ModalFooter>
              <Row className='my-2'>
                <Col><Button color='blue' onClick={() => discard()}>Discard</Button></Col>
                <Col><Button color='green' onClick={() => apply()}>Apply</Button></Col>
              </Row>
            </ModalFooter>
        </Modal>
    );
};

export default CartSyncModal;

