import React, { Fragment, useState, useEffect } from 'react';
import './Savings.scss'
import { useDispatch } from 'react-redux';
import { saveHeaderIndexRevOrder } from '../../../../../reducers/header/headerActions';
import { useHistory } from 'react-router-dom';
import useShoppingCart from '../../../../../SharedComponents/ShoppingCart/useShoppingCart';
import useSavings from './useSavings';
import SavingsRow from './SavingsRow';
import { Button, Row, Col } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';


const Savings = () => {

    const [listSavings, setListSavings] = useState([]);
    const { transformItemsSavings, swapAcceptApi, swapRejectApi } = useSavings.useSavings();

    const dispatch = useDispatch();
    const history = useHistory();

    const useCartSavings = useShoppingCart.useCartSavings();

    async function getCartMatches() {
        const res = await useCartSavings.getCartMatches();
        console.log(res)
        const newRes = transformItemsSavings(res);
        console.log(newRes, 'newRes');
        if (newRes.length > 0) {
            setListSavings(newRes);
        } else {
            dispatch(saveHeaderIndexRevOrder(3))
            history.push('/checkout-ordering/review-order');
        }
    };

    useEffect(() => {
        getCartMatches();
    }, []);


    const changeSwap = (swapValue, itemDistributorId, idItemSwap) => {
        const newListSavings = listSavings.map(res => {
            if (res.itemDistributorId == itemDistributorId) {
                res.swap = swapValue;
                res.idItemSwapReject = idItemSwap;
            }
            return res;
        });
        setListSavings(newListSavings);
    };

    const changeReject = (rejectValue, optionValue, itemDistributorId, idItemReject) => {
        const newListSavings = listSavings.map(res => {
            if (res.itemDistributorId == itemDistributorId) {
                res.reject = rejectValue;
                res.idItemSwapReject = idItemReject;
                res.optionReject = optionValue;
            }
            return res;
        });
        setListSavings(newListSavings);
    };


    const submitSwapAccept = async () => {

        const listSubmit = listSavings.reduce((acc, el) => {
            if (el.swap || el.reject) acc.push({
                old_item_dist_id: el.itemDistributorId,
                new_item_dist_id: el.matches
                    .find(item => item.itemDistributorId == el.idItemSwapReject).itemDistributorId,
                type: el.swap ? 'swap' : 'reject',
                reason_name: el.swap ? '' : el.optionReject
            });
            return acc;
        }, []);

        if (listSubmit.length === 0) {
            dispatch(saveHeaderIndexRevOrder(3))
            history.push('/checkout-ordering/review-order');
        } else {

            const params = { swaps: listSubmit };
            swapAcceptApi(params).then(res => {
                dispatch(saveHeaderIndexRevOrder(3))
                history.push('/checkout-ordering/review-order');
            });
        }


    }

    const contentSaving = listSavings.map(item => {
        return (
            <SavingsRow itemSavings={item}
                key={`rowSaving-${item.itemDistributorId}`}
                changeSwap={changeSwap}
                changeReject={changeReject} />
        )
    });

    return (
        <Fragment>
            <div className={isMobile ? 'passingSaving mobile' : 'passingSaving'}>
                <ShowIf show={isMobile}>
                    <Row className='textMobile'>
                        <Col>In your cart</Col>
                        <Col>Recommended</Col>
                    </Row>
                    <hr></hr>
                </ShowIf>
                {contentSaving}
                <ShowIf show={!isMobile}>
                    <hr></hr>
                    <Button color='green' className='btnSubmit' onClick={submitSwapAccept} >Done - Review Cart</Button>
                </ShowIf>
            </div>
            <ShowIf show={isMobile}>
                <div className='footerButton'>
                    <Button color='green' className='btnSubmit' onClick={submitSwapAccept} >Done - Review Cart</Button>
                </div>
            </ShowIf>
        </Fragment>


    )
}

export default Savings
