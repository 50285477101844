import React from 'react';
import useModal from '../../../../../../SharedComponents/Modals/useModal';
import { Modal, ModalHeader, ModalBody, CustomInput, Row, Col, ModalFooter, Button } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import AddIngredientModalRow from './AddIngredientModalRow';
import useInput from '../../../../../../SharedComponents/SharedEffects/useInput';

const AddIngredientModal = (props) => {
  const {
    id, openFoodbam, openSubrecipe, openInventory
  } = props;

  const modalProps = useModal(id, isMobile, true);
  const { itemRad, inventoryRad, subrecipeRad } = useInput.useRadioGroup(['itemRad', 'inventoryRad', 'subrecipeRad']);

  const clickAdd = () => {
    if (itemRad.value) {
      openFoodbam();
    } else if (inventoryRad.value) {
      openInventory();
    } else if (subrecipeRad.value) {
      openSubrecipe();
    }
    modalProps.toggle();
  };

  return (
    <Modal {...modalProps}>
      <ModalHeader toggle={modalProps.toggle}>I want to add...</ModalHeader>
      <ModalBody>
        <AddIngredientModalRow {...itemRad} id='item' label='Item' description='Items I use FoodBAM to order' />
        <AddIngredientModalRow {...inventoryRad} id='inventory_item' label='Inventory Item' description='Item groups from my inventory view' />
        <AddIngredientModalRow {...subrecipeRad} id='subrecipe' label='Subrecipe' description='Items that I prepare OR make in house, for example "Chiplote Aioli" for your burger' />
      </ModalBody>
      <ModalFooter>
        <Button onClick={clickAdd} className='px-5' color='green'>Add</Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddIngredientModal;