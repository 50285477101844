import React from 'react';
import { Row, Col } from 'reactstrap';
import { packSizeUomsConversion } from '../../../../../../../config/constants';
import Select from 'react-select/creatable';

const ItemDetailsUnitsCol = (props) => {
  const {
    label, set, value, disabled
  } = props;

  const options = packSizeUomsConversion.map(u => { return { label: u.label, value: u.id} });

  const formatCreateLabel = (input) => {
    return `Custom: ${input}`
  };

  return (
    <Col>
      <Row>
        <Col className='text-label mb-1'>{label}</Col>
      </Row>
      <Row className='align-items-center'>
        <Col>
          <Select menuPlacement='auto' formatCreateLabel={formatCreateLabel}
            className='fb-select bg-white' classNamePrefix='select' options={options}
            onChange={set} value={value} isDisabled={disabled}
          />
        </Col>
      </Row>
    </Col>
  );
};

export default ItemDetailsUnitsCol;