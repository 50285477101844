import React, { useState } from 'react';
import { Modal, ModalBody, Button, Row } from 'reactstrap';
import useModal from '../useModal';
import { isMobile } from 'react-device-detect';
import './StartInventoryModal.scss'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

const StartInventoryModal = (props) => {
    const { id, header, message, onOk } = props;
    const modalprops = useModal(id);
    const [startDate, setStartDate] = useState(new Date());

    const onClickOk = () => {
        if (onOk) {
            onOk(moment(startDate).format('YYYY-MM-DD'));
        };
        modalprops.toggle();
    };

    return (
        <Modal {...modalprops} backdrop='static' keyboard={false} className={isMobile ? 'startInventoryModal mobile' : 'startInventoryModal'} >

            <ModalBody className='text-center'>

                <Row>
                    <h1>{header}</h1>
                </Row>
                <Row>
                    <p>
                        {message}
                    </p>
                    <span className="text-label text-truncate">
                        Start Inventory Date :
                    </span>
                </Row>
                <Row>
                    <DatePicker id='inventoryDateInput'
                        className={isMobile ? 'mb-3' : ''}
                        selected={startDate}
                        dateFormat={"MMMM dd, yyyy"}
                        onChange={date => setStartDate(date)} />
                </Row>
                <Row className='contentButton'>
                    <Button color='green' className='cancel' onClick={() => modalprops.toggle()}>Cancel</Button>
                    <Button color='green' onClick={onClickOk}>Start Inventory</Button>

                </Row>

            </ModalBody>
        </Modal>
    );
};

export default StartInventoryModal;
