import React  from 'react';
import { isMobile } from "react-device-detect";
import { Container, Card, Row, Col } from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroller';
import useOrderHistoryList from './useOrderHistoryList';


const OrderHistoryList = (props) => {
  const { orders, ordersMap, page, isLoading, loadMore, getOrders } = useOrderHistoryList.useApi();

  if (page > 1 && orders.length === 0) {
    return (
      <Container fluid={true} className='order-list mt-2 rounded'>        
        <Card className='order-item'>
          <Row>
            <Col xs={12}>
              No results found.
            </Col>
          </Row>
        </Card>
      </Container>
    );
  }

  return (
    <Container fluid={true} className={`order-list mt-2 rounded `+ (isMobile ? `is-mobile` : ``)}>      
      <InfiniteScroll
        initialLoad = {false}
        pageStart={page}
        loadMore={getOrders}
        hasMore={loadMore && !isLoading}
        useWindow={true}
      >
        {ordersMap}
      </InfiniteScroll>      
    </Container>
  );
};

export default OrderHistoryList;
