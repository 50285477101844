import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from "react-device-detect";
import { Col, Input } from 'reactstrap';
import useShoppingCartItemInterface from './useShoppingCartItemInterface';
import useShoppingCartItem from './useShoppingCartItem';
import useShoppingCartItemOffline from './useShoppingCartItemOffline';
import { itemCart, placeOrderViews } from '../../config/constants';
import MinusButton from '../MinusButton/MinusButton';
import PlusButton from '../PlusButton/PlusButton';
import Circle from '../Icons/Circle/Circle';
import { IoIosArrowForward } from "react-icons/io";
import { localStorageConst } from '../../config/constants';
import { useSelector } from 'react-redux';
import ShowIf from '../ShowIf/ShowIf';
import useConnection from '../OfflineMode/useConnection';


const ShoppingCartItem = (props) => {
  const { item, useInitialLeft, useLastRight, showAll, overlayProps, parentItem, groupTotalProps, useShoppingCartItemProps } = props;
  const { caseQuantity, setCaseQuantity } = useShoppingCartItemProps || useShoppingCartItem.useCartItemMerge(item);
  let { overlay, setOverlay } = useShoppingCartItem.useOverlay();
  const cartAmount = caseQuantity || 0;
  const setCartAmount = setCaseQuantity;
  const [section, setSection] = useState('');
  const isStartInventory = useSelector(({ user }) => user.activeLocation.activeInventory);


  // const { updateCartAmount } = useShoppingCartItem.useCartUpdate(setCartAmount, groupTotalProps);
  const { updateCart } = useShoppingCartItemInterface.useCartUpdate(setCartAmount, groupTotalProps);

  const { valid } = useShoppingCartItem.useCartValidate();
  const view = localStorage.getItem(localStorageConst.keys.view);
  const { disabled } = useShoppingCartItemInterface.useInputConnection();

  if (overlayProps) {
    overlay = overlayProps.overlay;
    setOverlay = overlayProps.setOverlay;
  }

  const handleKeypress = (e) => {
    if (e.charCode === 13) {
      updateCart(cartAmount, item, setCartAmount, cartAmount, parentItem);
    }
  };

  const cartAmountHandler = (e) => {
    if (!valid(e.target.value)) { return; }
    updateCart(e.target.value, item, setCartAmount, cartAmount, parentItem);
  };

  const cartAmountClick = (newCartAmount) => {
    if (!valid(newCartAmount)) { return; }
    updateCart(newCartAmount, item, setCartAmount, cartAmount, parentItem);
  };

  const setButtons = () => {
    let newSection = '';
    const minusImg = <MinusButton onClick={() => { cartAmountClick(cartAmount - 1) }} isBlue isActive={(cartAmount >= itemCart.min)} disabled={disabled} />;
    const newMinusButton = (useInitialLeft === false && cartAmount < itemCart.min) ? '' : minusImg;

    const plusImg = <PlusButton onClick={() => { cartAmountClick(cartAmount + 1) }} isBlue isActive={(cartAmount >= itemCart.min && cartAmount <= itemCart.max)} disabled={disabled} />;
    const newPlusButton = (useLastRight === false && cartAmount > itemCart.max) ? '' : plusImg;

    const icon = <IoIosArrowForward color='white' size={28} />
    const circle = <Circle content={cartAmount} overlay={icon} isBlue large isActive={(disabled ? false : (cartAmount >= itemCart.min))} onClick={() => { setOverlay(false) }} id={`popoverCartOverlay-` + `${item.id}`} disabled={disabled} />;

    if (!isMobile || (isMobile && showAll) || (isMobile && !showAll && overlay)) {
      newSection = <Fragment>
        {(isMobile && overlay) ?
          <Col className={'pl-0 justify-content-start'}>
            {circle}
          </Col> : ''
        }
        <Col className={'w-fc pl-0 justify-content-end' + (isMobile ? ' pr-1' : ' ')}>
          {newMinusButton}
        </Col>
        <Col className={'p-0 justify-content-center' + (view !== placeOrderViews.inventory ? ' w-no-squared-input' : (isMobile ? '' : ' w-squared-input'))}>
          <Input type='text' pattern="[0-9]*" className={isMobile ? 'input p-0 text-center' : 'input'} value={cartAmount} onChange={cartAmountHandler} onKeyPress={handleKeypress} placeholder='0' disabled={disabled ? 'disabled' : ''} />
        </Col>
        <ShowIf show={!isStartInventory}>
          <Col className={'col w-fc justify-content-left' + (isMobile ? ' px-1' : '')}>
            {item.unitMeasure}
          </Col>
        </ShowIf>

        <Col className={'w-fc p-0 justify-content-start' + (isStartInventory ? " pl-3" : "")}>
          {newPlusButton}
        </Col>
      </Fragment>;
    }
    if (isMobile && !showAll && !overlay) {
      newSection = <Fragment>
        <Col className='col-12 p-0 justify-content-end text-right'>
          <Circle content={cartAmount} isBlue large isActive={(disabled ? false : (cartAmount >= itemCart.min))} onClick={() => { setOverlay(true); }} id={`popoverCart-` + `${item.id}`} disabled={disabled} />
        </Col>
      </Fragment>;
    }

    setSection(newSection);

  };

  useEffect(() => {
    setButtons();
  }, []);

  useEffect(() => {
    setButtons();
  }, [cartAmount, disabled]);

  return (
    <Fragment>
      {section}
    </Fragment>
  );
};

ShoppingCartItem.propTypes = {
  useInitialLeft: PropTypes.bool,
  useLastRight: PropTypes.bool,
  showAll: PropTypes.bool,
  square: PropTypes.bool
};

ShoppingCartItem.defaultProps = {
  useInitialLeft: true,
  useLastRight: true,
  square: true
};

export default ShoppingCartItem;