import React, { Fragment } from 'react';
import { Col } from 'reactstrap';

const RecipeInfoTextarea = (props) => {
  const {
    label, set, value, className
  } = props;
  
  return (
    <Fragment>
      <Col className='text-label font-weight-bold mt-3 mb-1'>{label}</Col>
      <Col className={className}>
        <textarea className='w-100' placeholder='Your message' onChange={set} value={value}></textarea>
      </Col>
    </Fragment>
  );
}

export default RecipeInfoTextarea;