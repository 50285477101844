import React from 'react';
import useModal from '../useModal';
import { Modal, ModalBody, ModalHeader, Col, Input, ModalFooter, Button } from 'reactstrap';
import useInput from '../../SharedEffects/useInput';

const OneInputModal = (props) => {
  const { id, label, title, onSave, initValue, menu, placeholder, fullMenu } = props;
  const modalProps = useModal(id, menu, fullMenu);
  const { value, set } = useInput.useText(initValue);

  const clickSave = () => {
    if (onSave) onSave(value);
    modalProps.toggle();
  };

  return (
    <Modal {...modalProps}>
      <ModalHeader toggle={modalProps.toggle}>{title}</ModalHeader>
      <ModalBody className='px-0'>
        <Col className='text-gray'>{label}</Col>
        <Col className='mt-2 mb-4'>
          <Input value={value} onChange={set} placeholder={placeholder} />
        </Col>
      </ModalBody>
      <ModalFooter>
        <Button color='green' onClick={clickSave}>Save</Button>
      </ModalFooter>
    </Modal>
  );
};

export default OneInputModal;