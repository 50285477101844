import React, { Fragment, useEffect } from 'react';
import { Row, Col, Input } from 'reactstrap';
import numeral from 'numeral';
import { FaChevronRight, FaExclamationCircle } from 'react-icons/fa';
import { colors } from '../../../../../../config/constants';
import useInput from '../../../../../../SharedComponents/SharedEffects/useInput';
import validate from 'validate.js';
import ShowIf from '../../../../../../SharedComponents/ShowIf/ShowIf';

const RecipeIngredientsItemRowDesktop = (props) => {
  const {
    image, name, brand, amount, uom, price, updateAmount, data, openDetails
  } = props;

  const amountEdit = useInput.useText(amount, { validate: { fraction: { strict: false } } });

  const onBlur = () => {
    if (!validate.single(amountEdit.value, { fraction: { strict: true } })) {
      updateAmount(data.recipe_id, data.id, data.ingredientType, data.ingredientId, amountEdit.value);
    } else {
      amountEdit.setValue(amount);
    }
  };

  const styleInactive = {
    justifyContent: 'flex-end',
    height: 13,
    fontSize: 11,
    display: 'flex',
    letterSpacing: '0.1em',
    color : colors.blue
  };

  useEffect(() => {
    amountEdit.setValue(amount ? amount : '');
  }, [amount]);

  return (
    <Fragment>

      <ShowIf show={data.item.isPunchedOut}>
        <div style={styleInactive} className="mt-3 mr-4">
          <FaExclamationCircle size={18} color={colors.orange} />
          <p className="ml-2">
            INACTIVE ITEM
      </p>
        </div>
      </ShowIf>
      <Row className={'m-0 mh-90px s-cb border-bottom align-items-center py-1 '} style={{
        opacity: data.item.isPunchedOut ? '0.4' : ''
      }}>


        <Col className='w-image'>
          <img src={image} className='no-image' />
        </Col>
        <Col className='p-0 text-truncate'>
          <Col className='p-0 text-truncate text-main text-capitalize'>{name}</Col>
          <Col className='p-0 text-truncate text-gray'>{brand}</Col>
        </Col>
        <Col className='w-squared-input'>
          <Input type='text' value={amountEdit.value} onChange={amountEdit.set} onBlur={onBlur} />
        </Col>
        <Col className='p-0 w-80px text-truncate'>{uom}</Col>
        <Col className='pr-0 w-80px text-truncate'>
          {numeral(price).format('$0,0.00')}
        </Col>
        <Col className='text-gray w-fc clickable' onClick={openDetails}>
          Details <FaChevronRight size={18} color={colors.lightBlueText} />
        </Col>

      </Row>
    </Fragment>

  );
};

export default RecipeIngredientsItemRowDesktop;