import React, {useState} from 'react';
import { Card, Row, Col, Button } from 'reactstrap';
import { MdDelete } from 'react-icons/md';
import { useHistory, Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import numeral from 'numeral';
import { colors } from '../../../../../../config/constants';
import ShowIf from '../../../../../../SharedComponents/ShowIf/ShowIf';
import ShoppingCartItem from '../../../../../../SharedComponents/ShoppingCartItem/ShoppingCartItem';
import noImage from '../../../../../../assets/images/no-image.png';
import smallCircle from '../../../../../../assets/images/small-circle.png';
import useShoppingCartItem from '../../../../../../SharedComponents/ShoppingCartItem/useShoppingCartItem';

const ListItemRow = (props) => {
  const item = props.children;
  item.name = item.name.trim().toLowerCase();
  item.description = item.description.trim().toLowerCase();
  item.distributor = item.distributor.trim();
  const imageUrl = item.imageUrl ? item.imageUrl : noImage;

  const useShoppingCartItemProps = useShoppingCartItem.useCartItemMerge(item);
  const {overlay, setOverlay} = useShoppingCartItem.useOverlay();

  const deleteShoppingListItem = () => {
    if (props.onDelete) {
      props.onDelete(item.itemDistributorId);
    }
  };

  if (isMobile) {
    return (
      <Card className={'list-item p-0 position-relative' + (item.isPunchedOut ? ' dim' : '')}>
        <Row noGutters={true} className='align-items-center '>
          <Col xs={'auto'} style={{padding: '5px 3px', flex: '0 0 10%', maxWidth: '10%'}}>
            <MdDelete size={22} color={colors.darkBlueText} className='clickable mx-0' onClick={deleteShoppingListItem} />
          </Col>
          <Col className='w-image-sm'>
            <img src={imageUrl} alt={item.name} className='no-image img-fluid' style={{maxWidth: '100%'}} />
          </Col>
          <Col xs={'auto'} style={{padding: '5px 3px', flex: '0 0 50%', maxWidth: '50%'}}>
            <Row noGutters={true}>
              <Col xs={12}>
                <h4 className='item-name text-capitalize text-truncate' title={item.name}>{item.name}</h4>
              </Col>
            </Row>
            <ShowIf show={!!item.foodbamCustomDescription}>
              <Row>
                <Col xs={12}>
                  <div className='item-name text-capitalize text-truncate' title={item.foodbamCustomDescription}>{item.foodbamCustomDescription}</div>
                </Col>
              </Row>
            </ShowIf>
            <Row noGutters={true} className='additional-info brand-distributor'>
              <Col xs={6}>
                <div className='text-truncate'>{item.brand || 'N/A'}</div>
              </Col>
              <ShowIf show={!!item.brand && !!item.distributor}><Col xs={1} className='text-center'><img src={smallCircle} /></Col></ShowIf>
              <Col xs={5}>
                <div className='text-truncate'>{item.distributor || 'N/A'}</div>
              </Col>
            </Row>
            <Row noGutters={true} className='additional-info price'>
              <Col xs={6}>
                <div className='text-truncate'>{numeral(item.price).format('$0,0.00')} {item.unitMeasure}</div>
              </Col>
              <ShowIf show={!!item.price && !!item.packSize}><Col xs={1} className='text-center'><img src={smallCircle} /></Col></ShowIf>
              <Col xs={5}>
                <div className='text-truncate'>{item.packSize}</div>
              </Col>
            </Row>
          </Col>
          <Col className='p-0' >
            <ShoppingCartItem useShoppingCartItemProps={useShoppingCartItemProps} item={item} useInitialLeft={false} overlayProps={{overlay: false, setOverlay}} square={false}/>
          </Col>
        </Row>
        <Row className='m-0 position-absolute w-100 h-100 bg-white animate-left' style={{top: 0, left: overlay ? '0%' : '105%' }}>
          <Col className='col d-flex align-items-center justify-content-center'>
            <ShoppingCartItem useShoppingCartItemProps={useShoppingCartItemProps} item={item} useInitialLeft={false} overlayProps={{overlay: true, setOverlay}} square={false} />
          </Col>
        </Row>
      </Card>
    );
  }

  return (
    <Card className={'list-item' + (item.isPunchedOut ? ' dim' : '')}>
      <Row>
        <Col xs={1} className='w-image'>
          <img src={imageUrl} alt='item' className='no-image' />
        </Col>
        <Col xs={8}>
          <Row>
            <Col xs={10}>
              <ShowIf show={!item.description}><h4 className='item-name text-capitalize' title={item.name}>{item.name}</h4></ShowIf>
              <ShowIf show={item.description}><h4 className='item-name text-capitalize' title={item.description}>{item.description}</h4></ShowIf>
            </Col>
          </Row>
          <ShowIf show={!!item.foodbamCustomDescription}>
            <Row>
              <Col xs={10}>
                <div className='item-name text-capitalize' title={item.foodbamCustomDescription}>{item.foodbamCustomDescription}</div>
              </Col>
            </Row>
          </ShowIf>
          <Row className='additional-info brand-distributor'>
            <Col xs={4}>
              <div>{item.brand || 'N/A'}</div>
            </Col>
            <Col xs={6}>
              <div>{item.distributor || 'N/A'}</div>
            </Col>
          </Row>
          <Row className='additional-info price'>
            <Col xs={4}>
              {numeral(item.price).format('$0,0.00')} {item.unitMeasure}
            </Col>
            <Col xs={6}>
              {item.packSize}
            </Col>
          </Row>
        </Col>
        <Col xs={3} className='d-flex align-items-center justify-content-center'>
          <ShoppingCartItem useShoppingCartItemProps={useShoppingCartItemProps} item={item} useInitialLeft={false} overlayProps={{overlay: false, setOverlay}} square={false} />
        </Col>
      </Row>
    </Card>
  );
};

export default ListItemRow;
