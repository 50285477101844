import React, { Fragment, useState, useEffect } from 'react';
import { Card, Row, Col, Button } from 'reactstrap';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import { isMobile } from "react-device-detect";
import numeral from 'numeral';
import Moment from 'react-moment';
import useOrderHistoryList from './useOrderHistoryList';

const OrderHistoryListRow = (props) => {
  const order = props.children;
  const { setHover, statusOptions } = useOrderHistoryList.useRow(order);

  return (
    <Card className='order-item'>
      <ShowIf show={!isMobile}>
        <Row className='d-flex align-items-center' onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
          <Col xs={1}>{order.id}</Col>
          <Col xs={1}>{order.customInvoiceId}</Col>
          <Col xs={1}>{order.createTimeDisplay}</Col>
          <Col xs={1}>
            {order.requestedDeliveryDate ?
              <Moment format="MM/DD">{order.requestedDeliveryDate}</Moment>
              : ""
            }
          </Col>
          <Col xs={2} className='text-truncate'>{order.distributor}</Col>
          <Col xs={1}>{order.lineItems}</Col>
          <Col xs={1} className='text-right'>{numeral(order.total).format('$0,0.00')}</Col>
          <Col xs={1}>{order.poStatus === '' ? 'Not Sent' : order.poStatus}
            <ShowIf show={order.poStatusDateTime}>
              <Row className='p-0 m-0 text-gray size-14'><Moment format="MM/DD/YYYY">{new Date(order.poStatusDateTime)}</Moment></Row>
              <Row className='p-0 m-0 text-gray size-14'><Moment format="hh:mm A">{new Date(order.poStatusDateTime)}</Moment></Row>
            </ShowIf>
          </Col>
          <Col xs={3} className='text-capitalize p-0 m-0'>
            {statusOptions}
          </Col>
        </Row>
      </ShowIf>
      <ShowIf show={isMobile}>
        <Row className='d-flex align-items-center'>
          <Col xs={3} className='mx-0 px-0'>{(order.customInvoiceId) ? order.customInvoiceId : order.id}</Col>
          <Col xs={2} className='mx-0 px-1'>{order.createTimeDisplay}</Col>
          <Col xs={5} className='mx-0 pl-0 text-truncate'>{order.distributor}</Col>
          <Col xs={2} className='mx-0 px-0 text-capitalize'>
            {statusOptions}
          </Col>
        </Row>
      </ShowIf>
    </Card>
  );
};

export default OrderHistoryListRow;
