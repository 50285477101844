import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PlaceOrderList from './PlaceOrderList/PlaceOrderList';
import PlaceOrderInventory from './PlaceOrderInventory/PlaceOrderInventory';
import { useSelector } from 'react-redux';

const PlaceOrderContent = () => {  
  const activeLocation = useSelector(({user}) => user.activeLocation);

  return (
    <Switch>
      <Route exact path='/place-order/list' component={PlaceOrderList} />
      {
        activeLocation.display_comparison_view ?
          <Route path='/place-order/inventory' component={PlaceOrderInventory} />
        : null
      }
      <Route>
        <Redirect to={ activeLocation.display_comparison_view ? '/place-order/inventory' : '/place-order/list'} />
      </Route>
    </Switch>
  );
};

export default PlaceOrderContent;