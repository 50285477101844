import React from 'react';
import { Col, Row } from 'reactstrap';
import RecipeIngredientsInfoRow from './RecipeIngredientsInfoRow';
import ShowIf from '../../../../../../SharedComponents/ShowIf/ShowIf';
import useRecipeCostingRecipe from '../useRecipeCostingRecipe';
import meal from '../../../../../../assets/images/meal.svg';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

const RecipeIngredients = (props) => {
  const spinner = useSelector(({userInterface}) => userInterface.spinner);
  const {
    ingredientsList, isSubrecipe, getRecipeData, id,
    totalCost, menuPrice, marginGrossProfit, foodCostPercent
  } = props;

  const details = useRecipeCostingRecipe.useIngredientsDetails(getRecipeData);
  const { ingredientsMap } = useRecipeCostingRecipe.useIngredients(ingredientsList, getRecipeData, details);

  const init = { totalCost: totalCost, menuPrice: menuPrice, marginGrossProfit: marginGrossProfit, foodCostPercent: foodCostPercent };
  const { totalCostTotal, menuPriceTotal, marginGrossProfitTotal, foodCostPercentTotal } = useRecipeCostingRecipe.useUpdateTotals(init, id);
  
  return (
    <Col className='px-0 d-flex flex-column' style={isMobile ? {paddingBottom: '227px'} : null}>
      { ingredientsMap }
      <ShowIf show={ingredientsList.length === 0 && !spinner}>
        <Row className={'mx-0 text-center mt-auto' + (isMobile ? ' pt-5' : '')}>
          <Col className='pb-3'>
            <img src={meal} alt='meal' />
          </Col>
        </Row>
        <Row className='mx-0 text-center mb-auto'>
          <Col>
            {"No ingredients have been added."}
            <br/>
            {"Click " + ( isMobile ? "'+'" : "'Add Ingredients'") + " above to begin building your recipe."}
          </Col>
        </Row>
      </ShowIf>
      <ShowIf show={!isMobile}>
        <RecipeIngredientsInfoRow first hideEdit last={isSubrecipe.value} label='Total Cost of Recipe' name='total_cost' {...totalCostTotal} />
        <ShowIf show={!isSubrecipe.value}>
          <RecipeIngredientsInfoRow label='Menu Price' name='menu_price' {...menuPriceTotal} />
          <RecipeIngredientsInfoRow label='Margin/Gross Profit' name='margin_profit' {...marginGrossProfitTotal} />
          <RecipeIngredientsInfoRow last isPerc label='Food Cost %' name='food_cost' {...foodCostPercentTotal} />
        </ShowIf>
      </ShowIf>
      <ShowIf show={isMobile}>
        <Col className='p-0 fixed-down'>
          <RecipeIngredientsInfoRow hideEdit last={isSubrecipe.value} label='Total Cost of Recipe' name='total_cost' {...totalCostTotal} />
          <ShowIf show={!isSubrecipe.value}>
            <RecipeIngredientsInfoRow label='Menu Price' name='menu_price' {...menuPriceTotal} />
            <RecipeIngredientsInfoRow label='Margin/Gross Profit' name='margin_profit' {...marginGrossProfitTotal} />
            <RecipeIngredientsInfoRow last isPerc label='Food Cost %' name='food_cost' {...foodCostPercentTotal} />
          </ShowIf>
        </Col>
      </ShowIf>
    </Col>
  );
};

export default RecipeIngredients;