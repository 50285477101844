import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, FormGroup, CustomInput, Input, Button } from 'reactstrap';
import { CardElement, injectStripe, Elements } from 'react-stripe-elements';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import validate from 'validate.js';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { isMobile } from 'react-device-detect';
import { mobileClassName } from '../../../../../config/constants';
import { showModal } from '../../../../../reducers/modals/modalsActions';
import AlertModal from '../../../../../SharedComponents/Modals/AlertModal/AlertModal';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import useInput from '../../../../../SharedComponents/SharedEffects/useInput';
import cardspApi from '../../../../../api/cardspApi';
import { cardIconHandler } from '../cardIconHandler';
import './CardForm.scss';

const CardForm = (props) => {
  const { cardId, successSaveCard } = props;
  return (
    <Container fluid={true} className='pos-list my-5 rounded'>
      <Elements><StripeForm cardId={cardId} successSaveCard={successSaveCard} /></Elements>
    </Container>
  );

};

const _StripeForm = (props) => {

  const { cardId, successSaveCard } = props;
  const [step, setStep] = useState(1);
  const [errorMsg, setErrorMsg] = useState('');
  const [validCard, setValidCard] = useState(false);
  const [card, setCard] = useState(null);

  const params = useParams();


  const history = useHistory();
  const dispatch = useDispatch();

  const validateForm = () => {
    const data = {
      address_line1: addressLine1.value,
      address_city: addressCity.value,
      address_state: addressState.value,
      address_zip: addressZip.value
    };
    const constrains = {
      address_line1: {
        presence: true,
        length: {
          minimum: 1
        }
      },
      address_city: {
        presence: true,
        length: {
          minimum: 1
        }
      },
      address_state: {
        presence: true,
        format: {
          pattern: '[A-Za-z]+'
        },
        length: {
          minimum: 1,
          maximum: 2
        }
      },
      address_zip: {
        presence: true,
        format: {
          pattern: '[0-9]+'
        },
        length: {
          is: 5
        }
      }
    };

    const result = validate(data, constrains);
    return !!!result;
  };

  const nickname = useInput.useText('');
  const addressLine1 = useInput.useText('');
  const addressLine2 = useInput.useText('');
  const addressCity = useInput.useText('');
  const addressState = useInput.useText('');
  const addressZip = useInput.useText('');
  const isDefault = useInput.useCheckbox(false);

  useEffect(() => {
    if (params.id || cardId) {

      let token = cardId ? cardId : params.id;

      cardspApi.getCardById(token).then(({ data }) => {
      
        data.nickname = (data.nickname + '') == 'null' ? '' : (data.nickname + '');
        data.address1 = (data.address1 + '') == 'null' ? '' : (data.address1 + '');
        data.address2 = (data.address2 + '') == 'null' ? '' : (data.address2 + '');
        data.city = data.city + '';
        data.state = data.state + '';
        data.zip = data.zip + '';
        setCard(data);
        nickname.set(data.nickname);
        isDefault.set(data.isDefault);
        setValidCard(true);
        addressLine1.set(data.address1);
        addressLine2.set(data.address2);
        addressCity.set(data.city);
        addressState.set(data.state);
        addressZip.set(data.zip);
      }).catch(err => {
        if (err.code === 404 && history.location.pathname.startsWith('/manage-cards/update-card')) {
          dispatch(showModal(AlertModal, {
            message: 'Credit Card Not Found',
            onOk: () => { history.replace('/manage-cards') }
          }));
        }
        console.error('getCardById get Error:', err);
      });
    }
  }, []);

  const goToStep1 = () => {
    setStep(1);
  }

  const goToStep2 = () => {
    setStep(2);
  }

  const handleCardChange = (result) => {
    setValidCard(!!!result.error);
    setErrorMsg(result && result.error && result.error.message || '');
  }

  const handleSubmitCardForm = () => {
    if (card) {
      const payload = {
        nickname: nickname.value,
        address_line1: addressLine1.value,
        address_line2: addressLine2.value,
        address_city: addressCity.value,
        address_state: addressState.value,
        address_zip: addressZip.value,
        default: isDefault.value
      };
      cardspApi.updateCard(card.token, payload)
        .then(() => {
          if (successSaveCard) {
            successSaveCard();
            return;
          }
          history.push('/manage-cards')
        })
        .catch(response => setErrorMsg(response.data.data.message));
    } else {
      const tokenData = {
        type: 'card',
        address_line1: addressLine1.value,
        address_line2: addressLine2.value,
        address_city: addressCity.value,
        address_state: addressState.value,
        address_zip: addressZip.value
      };

      if (props.stripe) {
        props.stripe.createToken(tokenData).then(payload => {
          if (!payload.error) {
            cardspApi.addCard({
              token: payload.token.id,
              nickname: nickname.value,
              default: isDefault.value,
              cardId: payload.token.card.id
            })
              .then(() => {
                if (successSaveCard) {
                  successSaveCard();
                  return;
                }
                history.push('/manage-cards')
              })
              .catch(error => console.log(error));
          }
        });
      }
    }

  }

  const cardElementOptions = {
    style: {
      base: {
        color: '#202020',
        lineHeight: '17px',
        fontSmoothing: 'antialiased',
        fontSize: '15px',
        '::placeholder': {
          color: '#76777b'
        }
      },
      invalid: {
        color: '#dc3545',
        iconColor: '#dc3545'
      }
    }
  };

  return (
    <Form noValidate='novalidate'>
      <ShowIf show={errorMsg}>
        <div className='alert alert-danger' role='alert'>
          <i className='ion ion-alert-circled'></i>
          {errorMsg}
        </div>
      </ShowIf>

      <fieldset style={step != 1 ? { display: 'none' } : null}>
        <legend>Step 1 of 2 &nbsp;&nbsp;Add Card Details</legend>
        <ShowIf show={params.id || cardId}>
          <FormGroup>
            <Row>
              <Col xs={1}>
                <img src={cardIconHandler(card)} />
              </Col>
              <Col xs={9}>
                <div className='mt-1'>{card && card.last4}</div>
              </Col>
            </Row>
          </FormGroup>
        </ShowIf>
        <ShowIf show={!params.id && !cardId}>
          <FormGroup>
            <Row>
              <Col xs={12}>
                <CardElement
                  onChange={handleCardChange}
                  hidePostalCode={true}
                  {...cardElementOptions}
                />
              </Col>
            </Row>
          </FormGroup>
        </ShowIf>
        <FormGroup>
          <Row>
            <Col xs={12}>
              <Input type='text' value={nickname.value} onChange={nickname.set} placeholder="Card nickname e.g. head chef's visa" />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row>
            <Col xs={12}>
              <CustomInput id='checkboxDefault' type='checkbox' checked={isDefault.value} onChange={isDefault.toggle} label='Set as default card' />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row>
            <Col xs={(isMobile ? { size: 12, offset: 0 } : { size: 6, offset: 6 })}>
              <Button type='button' className='btn btn-lg btn-block btn-green' onClick={goToStep2} disabled={!validCard}>Next {isMobile ? <FaArrowRight className='float-right' /> : null}</Button>
            </Col>
            <div className='clear'></div>
          </Row>
        </FormGroup>
      </fieldset>

      <fieldset style={step != 2 ? { display: 'none' } : null}>
        <legend >Step 2 of 2 &nbsp;&nbsp;Confirm Billing Info</legend>
        <p className='mt-3 mb-0'>BILLING ADDRESS</p>
        <FormGroup>
          <Row>
            <Col md={12}>
              <Input type='text' value={addressLine1.value} onChange={addressLine1.set} placeholder='Address line 1' />
              <Input type='text' value={addressLine2.value} onChange={addressLine2.set} placeholder='Address line 2' />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row>
            <Col md={6}>
              <span className='card-det-text'>City</span>
              <Input type='text' value={addressCity.value} onChange={addressCity.set} placeholder='City' />
            </Col>
            <Col md={6}>
              <span className='card-det-text'>State</span>
              <Input type='text' value={addressState.value} onChange={addressState.set} placeholder='State' maxLength={2} />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row>
            <Col md={12}>
              <Input type='text' value={addressZip.value} onChange={addressZip.set} placeholder='Zip' maxLength={5} />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row>
            <Col xs={(isMobile ? { size: 12 } : { size: 6 })}>
              <Button type='button' color='green' size='lg' block onClick={goToStep1} className='mb-2'>{isMobile ? <FaArrowLeft className='float-left' /> : null} Back</Button>
            </Col>
            <Col xs={(isMobile ? { size: 12 } : { size: 6 })}>
              <Button type='button' color='green' size='lg' block onClick={handleSubmitCardForm} disabled={!validateForm()}>
                {card ? 'Update' : 'Add Card'} {isMobile ? <FaArrowRight className='float-right' /> : null}
              </Button>
            </Col>
          </Row>
        </FormGroup>
      </fieldset>

    </Form>
  );
};

const StripeForm = injectStripe(_StripeForm);

export default CardForm;
