import axios from 'axios';

const getOrderGuideByDistributor = (params) => {
  return axios.get('/order-guides/get-by-distributor', {params});
};

const getOrderGuideDetail = (id, params) => {
  return axios.get(`/order-guides/${id}/items`, {params});
};

const downloadOrderGuide = (id) => {
  return axios.get(`/order-guides/${id}/download`);
};

const frontUpload = (params) => {
  const formData = new FormData();
  formData.append('file', params.file);
  formData.append('merchantIds', JSON.stringify(params.merchantIds));
  formData.append('distributor_id', params.distributor_id);
  formData.append('fastResponse', params.fastResponse);
  formData.append('start_date', params.start_date);
  formData.append('end_date', params.end_date);
  return axios.post('order-guides/front-upload', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
}

const getMatchingTemplates = (distributorId, file) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('distributor_id', distributorId);
  return axios.post('distributor-order-guides/check-for-template', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
}

const uploadTemplate = (distributorId, file, merchantIds) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('merchantIds', JSON.stringify(merchantIds));
  return axios.post(`distributor-order-guides/upload-template/${distributorId}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
}


export default {
    getOrderGuideByDistributor,
    getOrderGuideDetail,
    downloadOrderGuide,
    frontUpload,
    getMatchingTemplates,
    uploadTemplate
};