import React, { Fragment } from 'react';
import { Col } from 'reactstrap';
import Select from 'react-select';

const AdminLoginFormDropdown = (props) => {
  const { label, placeholder,
    setSearch, set, options, isDisabled }
  = props;

  return (
    <Fragment>
      <Col className='mt-3'>
        <h5>{label}</h5>
      </Col>
      <Col>
        <Select className='fb-select' classNamePrefix='select'
          options={options} placeholder={placeholder} onInputChange={setSearch}
          onChange={set} filterOption={null} isDisabled={isDisabled}
        />
      </Col>
    </Fragment>
  );
};

export default AdminLoginFormDropdown;