import React, { useState, useEffect } from 'react';
import { Col, Row, Button } from 'reactstrap';
import SignUpHeader from './Subcomponents/SignUpHeader';
import SignUpSteps from './Subcomponents/SignUpSteps';
import SignUpStep1 from './Steps/SignUpStep1';
import SignUpStepDistributors from './Steps/SignUpStepDistributors';
import useSignUp from './useSignUp';
import ShowIf from '../../SharedComponents/ShowIf/ShowIf';
import SignUpStepLegal from './Steps/SignUpStepLegal';
import SingUpPayment from './Sections/SingUpPayment';
import { isMobile } from 'react-device-detect';

const SignUpContent = (props) => {
  const {
    contactInformation, locations, step, goTo,
    showLocation, showDistributor, distributors,
    legalInformation
  } = props;

  const {
    handleBack, handleNext, nextTitle,
    backTitle, showSkip, handleSkip,
    editDistributor, errors, setDataCard, validateDisabldDone
  } = useSignUp.useContent(props);

  return (
    <Col className={isMobile ? 'px-4' : 'px-5'}>
      <SignUpHeader title='Hello, New Friend' />
      <SignUpSteps step={step} maxStep={4} />
      <SignUpStep1 step={step} contactInformation={contactInformation}
        setLocationData={locations.setLocationData} showLocation={showLocation}
        locationsData={locations.locationsData}
      />
      <ShowIf show={step === 2}>
        <SignUpStepDistributors step={step} goTo={goTo} showDistributor={showDistributor}
          locationsData={locations.locationsData}
          distributors={distributors.distributorsData}
          setDistributors={distributors.setDistributorData}
          editDistributor={editDistributor}
        />
      </ShowIf>

      <SignUpStepLegal {...legalInformation} step={step} />

      <ShowIf show={step === 4}>
        <SingUpPayment setDataCard={setDataCard} contactInformation={contactInformation} />
      </ShowIf>

      <Row className='mt-3 mb-3'>
        <Col xs={6} lg={4}>
          <Button size='lg' block outline color='dark-blue' onClick={handleBack}>{backTitle}</Button>
        </Col>
        <Col xs={6} lg={4}>
          <Button size='lg' block color='dark-blue' disabled={validateDisabldDone()} onClick={handleNext}>{nextTitle}</Button>
        </Col>
        
        <ShowIf show={showSkip}>
          <Col xs={6} lg={4}>
            <Button size='lg' block color='dark-blue' onClick={handleSkip}>Skip</Button>
          </Col>
        </ShowIf>
      </Row>
      <Row className='mb-5'>
        <Col className='text-danger'>{errors ? (Array.isArray(errors[0]) ? errors[0][0] : errors[0]) : ''}</Col>
      </Row>
    </Col>
  );
};

export default SignUpContent;