import React, { useState } from 'react';
import { Row, Col, Input } from 'reactstrap';
import numeral from 'numeral';
import { MdEdit } from 'react-icons/md';
import { colors } from '../../../../../../config/constants';
import ShowIf from '../../../../../../SharedComponents/ShowIf/ShowIf';
import { isMobile } from 'react-device-detect';
import * as $ from 'jquery';

const RecipeIngredientsInfoRow = ({label, name, isPerc, first, last, hideEdit, set, value}) => {
  const [isEdited, setIsEdited] = useState(false);

  const handleOnBlur = () => {
    setIsEdited(false);
  };

  const handleKeypress = (e) => {
    if (e.charCode === 13) {
      setIsEdited(false);
    }
  };

  const handleOnFocus = () => {
    if (isEdited) {
      $('#sub_'+name+'_input').focus();
    }    
  };
  

  return (
    <Row className={'m-0 align-items-center bg-light-blue' + (last ? '' : ' border-bottom ') + (first ? ' mt-auto' : '')}>
      <Col className={'text-truncate' + (isMobile ? ' py-3 col-8' : ' py-4')}>{label}</Col>
      <Col className='p-0 w-100px d-flex justify-content-end'>
        {isEdited ? 
          <Input type='number' className='w-100' id={`sub_${name}_input`} onChange={set} value={value} onBlur={handleOnBlur} onKeyPress={handleKeypress} onFocus={handleOnFocus}/>
          : numeral(isPerc ? value/100 : value).format(isPerc ?'%0.00' : '$0,0.00')
        }
      </Col>
      <Col className={'p-0 d-flex justify-content-end' + (isMobile ? '' : ' w-100px')}>
        <ShowIf show={!hideEdit || isEdited}>
          <Col className='w-fc pl-0 clickable text-light-blue' onClick={() => setIsEdited(true)}>
            <span className='pr-1'><MdEdit size={22} color={colors.lightBlueText} /></span> {isMobile ? '' : 'Edit'}
          </Col>
        </ShowIf>
      </Col>
    </Row>
  );
};

export default RecipeIngredientsInfoRow;