import React from 'react';
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import distributorsApi from '../../../../api/distributorsApi';
import distributorApi from '../../../../api/distributorsApi';


const useApi = (props) => {
  const [distributors, setDistributors] = useState([]);

  const getDistributors = () => {
    distributorApi.getExpanded().then((res) => {

      setDistributors(
        res.data.map(item => {
          item.dsrs.sort((a, b) => parseFloat(b.phone || 0) - parseFloat(a.phone || 0));
          return item;
        }));
    }).catch(error => {
    });
  };

  useEffect(() => {
    getDistributors();
  }, []);


  return { distributors, getDistributors };
}

const useRow = (props) => {
  const { id } = props;
  const history = useHistory();

  const viewLastOrderGuide = (data) => {
    const { lastOrderGuide } = data;
    if(!lastOrderGuide){
      history.push({
        pathname: `/distributor/${id}/order-guide/`,
        state: { distributor: data }
      });
      return;
    }
    history.push({
      pathname: `/distributor/${id}/order-guide/${lastOrderGuide.id}`,
      state: { distributor: data }
    });

  }

  const editDist = (data) => {
    history.push({
      pathname: `/distributor/${id}`,
      state: { distributor: data }
    });
  };

  const updateDist = (params, dsrld) => {
    distributorApi.updateDistributor(id, params, dsrld)
      .then(res => console.log(res))
      .catch(err => console.log(err));
    // update
  };

  const sendInvite = (params, dsrld) => {
    distributorApi.sendInvite(id, params, dsrld)
      .then(res => console.log(res))
      .catch(err => console.log(err));
    // update
  };



  return {
    viewLastOrderGuide, editDist, updateDist, sendInvite
  }

}

export default {
  useApi, useRow
};