import axios from 'axios';

const getCards = () => {
  //return axios.get('payment-methods?key=L242p3IQ7RSZ3V1aoGqijb186hI3900o');
  return axios.get('payment-methods');
};

const getCardById = (cardToken) => {
  return axios.get(`payment-methods/${cardToken}`);
};

const addCard = (card) => {
  return axios.post('payment-methods', card);
};

const updateCard = (cardToken, card) => {
  return axios.patch(`payment-methods/${cardToken}`, card);
};

const deleteCard = (cardToken) => {
  return axios.delete(`payment-methods/${cardToken}`);
};

export default{
  getCards,
  getCardById,
  addCard,
  updateCard,
  deleteCard
};
