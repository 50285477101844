import { useState, useEffect } from 'react';
import distributorsApi from '../../../api/distributorsApi';
import { localStorageConst } from '../../../config/constants';

const useDataForEmail = (item) => {
    const [email, setEmail] = useState('');

    const getData = () => {
      if(!!item.distributorId){
        distributorsApi.getById(item.distributorId)
        .then(res => {
          setEmail(res.data.dsrEmail);
        })
        .catch(error => console.log(error));
      }      
    };
    
    return {
      email
    };
};


const useDataOnEmail = () => {
  const [user, setUser] = useState(localStorage.getItem(localStorageConst.keys.userName));
  const [location, setLocation] = useState(JSON.parse(localStorage.getItem(localStorageConst.keys.activeLocation)));
  
  const sendEmail = (email) => {
    const to = email;
    const subject = 'Outdated Prices';
    const emailBody = 'Can you send over an updated order guide with correct pricing? I\'m putting together an order right now, unfortunately the items seem to be last week\'s price. Please send updated pricing each week to "orderguidepricing@gmail.com", thank you!' + '\r\n' + '\r\n' + 'From, \r\n' + user + '\r\n' + location.nickname;
    const mailTo = "mailto:" + to + "?subject=" + subject + "&body=" + encodeURIComponent(emailBody);
    window.location.href = mailTo;
  };

  return {
    sendEmail
  };
};

export default {
  useDataForEmail, useDataOnEmail
};