import React from 'react';
import { Modal, ModalBody, ModalFooter, Row, Col, Button } from 'reactstrap';
import notAvailable from '../../../assets/images/not-available.png';
import useModal from '../useModal';
import './PunchedOutModal.scss';
import useGoTo from '../../GoTo/useGoTo';
import { MdClose } from 'react-icons/md';
import { colors } from '../../../config/constants';
import { useDispatch } from 'react-redux';
import { showModal } from "../../../reducers/modals/modalsActions";
import SpecialRequestModal from '../SpecialRequestModal/SpecialRequestModal';
import { isMobile } from 'react-device-detect';

const ItemUnavailableModal = (props) => {
  const { id, item } = props;
  const modalprops = useModal(id);
  const {addSpecialRequest} = useGoTo.useActions();
  const dispatch = useDispatch();

  const onClickOk = () => {
    // addSpecialRequest();
    const mprops = {
      item: item ? item : null,
      menu: isMobile ? true : false,
      fullMenu: isMobile ? true : false      
    }
    dispatch(showModal(SpecialRequestModal, mprops));
    modalprops.toggle();
  };

  return (
    <Modal {...modalprops} backdrop='static' keyboard={false}>
      <ModalBody className='text-center'>
        <Row className='m-0'>
          <Col className='d-flex justify-content-end px-1 clickable' onClick={() => {modalprops.toggle()}}>
            <MdClose className='clickable' size={20} color={colors.lightBlueText} />
          </Col>
        </Row>        
        <Row className='my-2'>
          <Col className='text-center'><img src={notAvailable} alt='punchedout item' className='img-cont'/></Col>
        </Row>
        <Row>
          <Col className='text-center title'> Sorry!</Col>
        </Row>
        <Row>
          <Col className='text-center'>Your distributor has removed an item in your order from your active order guide. You can find the item, as it will appear "grayed out". You cannot place your order with the grayed out item, but you can remove the grayed out item and request this item as a "special request".</Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color='green' block onClick={onClickOk}>Add Special Request</Button>
      </ModalFooter>
    </Modal>
  );
};

export default ItemUnavailableModal;