import React, { Fragment, useState } from 'react';
import { Card, Row, Col, NavLink } from 'reactstrap';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import { isMobile } from 'react-device-detect';
import { colors } from '../../../../../config/constants';
import IconBadge from '../../../../../SharedComponents/IconBadge/IconBadge';
import { FaExclamation, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import numeral from 'numeral';
import noImage from '../../../../../assets/images/no-image.png';
import reject from '../../../../../assets/images/reject.png';



const SavingsSecondCard = (props) => {


    const { itemComparable, swapActive, rejectActive, goToChangeReject, effectChange } = props;
    const price = `${numeral(itemComparable.price).format('$0,0.00')} ${itemComparable.unitMeasure}`;
    const newImage = itemComparable.imageUrl ? itemComparable.imageUrl : (itemComparable.brandImageUrl ? itemComparable.brandImageUrl : noImage);
    const classSwap = `cardSecond ${swapActive ? 'swapActive' : ''}`;
    const classTextSwap = `${swapActive ? 'textCardSwap active' : 'textCardSwap'}`;

    return (
        <Fragment>
            <h1 className={classTextSwap}>Nice! You have grabbed up some serious savings here! We have moved this item to your order guide.</h1>
            <Card className={classSwap + effectChange}>
                <Row className='contentSavings'>
                    <ShowIf show={!isMobile}>
                        <Col xs='4' className='clickable content-align-center'>
                            <div>
                                <img src={newImage}></img>
                            </div><br></br>
                            <div>
                                <ShowIf show={rejectActive}>
                                    <img className='rejectImg' src={reject}></img>
                                </ShowIf>
                            </div>
                        </Col>
                    </ShowIf>

                    <ShowIf show={!isMobile && !rejectActive}>
                        <Col xs='8' className='details clickable'>
                            <Row>
                                <h3>{itemComparable.description}</h3>
                            </Row>
                            <Row>
                                <p>{itemComparable.din}</p>
                            </Row>
                            <Row>
                                <Col>
                                    <p>{itemComparable.brand}</p>
                                </Col>
                                <Col>
                                    <p>{itemComparable.distributor}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='form-inline pop'>
                                    <h3>{price}</h3>
                                    <IconBadge idPop='SAVINGPop1' text='This price is outdated' FaIcon={FaExclamation} size={8} color={colors.white} />

                                </Col>
                                <Col>
                                    <h3>{itemComparable.packSize}</h3>
                                </Col>
                            </Row>
                        </Col>
                    </ShowIf>

                    <ShowIf show={isMobile && !rejectActive}>
                        <div className='m-auto pb-4'>
                            <img src={newImage}></img>
                        </div>
                        <div className='details'>
                            <Row className='mb-4'>
                                <h3 >{itemComparable.description}</h3>
                            </Row>
                            <Row>
                                <Col xs='6'>
                                    <p>{itemComparable.din}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs='6'>
                                    <p>{itemComparable.brand}</p>
                                </Col>
                                <Col xs='6'>
                                    <p>{itemComparable.distributor}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs='6' className='form-inline pop'>
                                    <h3>{price}</h3>
                                </Col >
                                <Col xs='6'>
                                    <h3>{itemComparable.packSize}</h3>
                                </Col>
                            </Row>
                        </div>

                    </ShowIf>
                    <ShowIf show={rejectActive}>
                        <Col xs='8' className='details clickable'>
                            <ShowIf show={!isMobile}>
                                <Row>
                                    <Col>
                                        <h4>{itemComparable.description} Rejected ! We will get back to you with better suggestions next time</h4>
                                    </Col>
                                </Row>
                                <NavLink href="#" onClick={goToChangeReject}>Cancel</NavLink>

                            </ShowIf>
                        </Col>

                        <ShowIf show={isMobile}>
                            <div className='details'>
                                <h4>Rejected! Thanks for your help, we will
                                    get back to you with better suggestions next time.</h4>
                                <NavLink href="#" onClick={goToChangeReject}>Cancel</NavLink>
                            </div>
                        </ShowIf>
                    </ShowIf>

                    <ShowIf show={!rejectActive}>
                        <div className='orderGuideIcon'>
                            <span className={isMobile ? 'iconText iconTextM  right' : 'iconText right'} >ORDER GUIDE</span>
                        </div>
                    </ShowIf>

                </Row>
            </Card >
        </Fragment>

    )
};


export default SavingsSecondCard;

