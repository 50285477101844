import React, {Fragment, useState, useEffect } from 'react';
import { isMobile } from "react-device-detect";
import { Row, Col, Button } from 'reactstrap';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import useOrderHistoryList from './useOrderHistoryList';
import {colors} from '../../../../../config/constants'
import { IoMdCalendar, IoMdCloudDownload } from 'react-icons/io';
import { FiPlus } from "react-icons/fi";


const SubHeaderOrderHistoryList = (props) => {
  
  const { modalOptions, downloadExcel, openDownloadModal } = useOrderHistoryList.useDownload();

  const download = () => {
    if(modalOptions.length>1){
      openDownloadModal();
    }else{
      downloadExcel();
    }    
  };

  return (
  <Fragment>
    <ShowIf show={!isMobile}>
      <Row className='header m-0 px-3 align-items-center'>
        <Col xs={1}> Order No</Col>
        <Col xs={1}>Invoice Number</Col>
        <Col xs={1}>Order Date</Col>
        <Col xs={1}>Delivery Date</Col>
        <Col xs={2}>Distributor</Col>
        <Col xs={1}>Line Items</Col>
        <Col xs={1}>Total Amount</Col>
        <Col xs={1}>PO Status</Col>
        <Col xs={1} className='px-0'>Status</Col>
        <Col xs={2} className='py-2 px-0'>
          <Row className='mx-0 p-0'>
            {/* <Col xs={2}>
              <Button color='white' onClick={getOrdersInCalendar()}>
                <IoMdCalendar color={colors.lightBlueText}/>
              </Button>
            </Col> */}
            <Col className='pr-0'>
              <Button color='white' onClick={  () => { download() } } block>
                <IoMdCloudDownload color={colors.lightBlueText} /> Download
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </ShowIf>
    
    <ShowIf show={isMobile}>
      <Row className={`header m-0 px-3 align-items-center `+ (isMobile ? `is-mobile` : ``)}>
        <Col xs={3} className='py-2 pl-1'>No</Col>
        <Col xs={2} className='py-2 px-1'>Date</Col>
        <Col xs={5} className='py-2 px-1'>Distributor</Col>
        <Col xs={2} className='py-2 px-0'>
          <Row className='mx-0 p-0'>
            <Col className='mx-0 px-0 text-right'>
              {/* <Button color='white' onClick={getOrdersInCalendar()}>
                <IoMdCalendar color={colors.lightBlueText}/>
              </Button> */}
              <span>&nbsp;</span>
              <Button color='white' onClick={ () => { download() } }>
                <IoMdCloudDownload color={colors.lightBlueText}/>
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </ShowIf>
  </Fragment>
  );

};

export default SubHeaderOrderHistoryList;