import React, { useState, useEffect } from 'react'
import { Button, Col } from 'reactstrap'
import './InputCounter.scss';
import useShoppingCartItem from '../ShoppingCartItem/useShoppingCartItem';
import PlusButton from '../PlusButton/PlusButton';
import MinusButton from '../MinusButton/MinusButton';
import { FaTrash } from 'react-icons/fa';
import { colors } from '../../config/constants';
import { useSelector, useDispatch } from 'react-redux';
import { showModal } from '../../reducers/modals/modalsActions';
import QuestionModal from '../Modals/QuestionModal/QuestionModal';
import { isMobile } from 'react-device-detect';

const InputCounter = (props) => {
    const { item, value, unitM, changeValue, indexCart } = props;
    const [cant, setCant] = useState(value);
    const { updateCartAmount } = useShoppingCartItem.useCartUpdate();
    const dispatch = useDispatch();

    const addCant = () => {
        let newCant = cant + 1;
        // setCant(newCant);
        updateCart(newCant);
    }

    const removeCant = () => {
        if (cant > 1) {
            let newCant = cant - 1;
            // setCant(newCant)
            updateCart(newCant);

        }
    }

    const resetCant = () => {
        const qProps = {
            message: `Are you sure you want to remove this item?`,
            menu: isMobile ? true : false,
            leftButton: {
                title: 'Delete',
                color: 'orange',
                onClick: () => updateCart(0)
            },
            rightButton: {
                title: 'Cancel',
                color: 'green',
                // onClick: () => goBack()
            }
        };
        dispatch(showModal(QuestionModal, qProps));

    }

    const updateCart = (newCaseQuantity) => {
        if (item.itemDistributorId !== null) {
            const nitem = {
                quantity: newCaseQuantity,
                status: item.status,
                isPunchedOut: item.isPunchedOut,
                isUnpurchasable: item.isUnpurchasable,
                description: item.name ? item.name : item.description,
                itemDistributorId: item.itemDistributorId,
                shoppingCartId: item.shoppingCartId
            };
            updateCartAmount(newCaseQuantity, nitem, setCant, cant);
        }
    };

    useEffect(() => {
        // setTimeout(() => {
        changeValue(cant, indexCart);
        // }, 1000);
    }, [cant])

    return (

        <Col className='inputCounter'>
            <FaTrash className='buttonDelete clickable' color={colors.lightBlueText} onClick={resetCant} />
            <MinusButton isActive='true' onClick={removeCant} isBlue />
            <p>{cant} {unitM}</p>
            <PlusButton isActive='true' onClick={addCant} isBlue />

        </Col>

    )
}

export default InputCounter;
