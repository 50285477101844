import React, { Fragment } from 'react';
import { Row, Col, CustomInput } from 'reactstrap';
import ShowIf from '../../ShowIf/ShowIf';

const SearchFilterModalCheckbox = (props) => {
  const { col, value, set, label, id, rowClassName, colClassName } = props;

  return (
    <Fragment>
      <ShowIf show={col}>
        <Col className={colClassName}>
          <CustomInput type='checkbox' id={id} checked={value} onChange={set} label={label} />
        </Col>
      </ShowIf>
      <ShowIf show={!col}>
        <Row className={rowClassName}>
          <Col xs='12' col={colClassName}>
            <CustomInput type='checkbox' id={id} checked={value} onChange={set} label={label} />
          </Col>
        </Row>
      </ShowIf>
    </Fragment>
  )
};

export default SearchFilterModalCheckbox;