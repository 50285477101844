import React, {useState, useEffect, Fragment} from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Button, CustomInput } from 'reactstrap';
import useModal from '../../../../../SharedComponents/Modals/useModal';
import '../Distributor.scss';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { OGExpirationDays,colors } from '../../../../../config/constants';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import { MdCloudUpload, MdDelete } from 'react-icons/md';
import * as $ from 'jquery';
import excelIcon from '../../../../../assets/images/excel-icon.jpg';

const UploadOgModal = (props) => {
  const { id, title, distributor, onOk, btnSize, color, allMerchants } = props;
  const modalprops = useModal(id);
  
  const [merchants, setMerchants] = useState(allMerchants);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(moment(new Date()).add(OGExpirationDays, 'days').toDate());
  const [showSelectedFile, setShowSelectedFile] = useState(false);
  const [file, setFile] = useState(null);

  const updateMerchantSelected = (merchantId) => {
    const updatedMerchants = merchants.map((m, index) => {
                                if (m.id !== merchantId) return m;
                                return {
                                  ...m,
                                  value: !m.value
                                };
                            });
    setMerchants(updatedMerchants);
};

  const allMerchantsMap =  merchants.map((m, index) => 
                            <Col xs={12} key={`m_${m.id}`} className='text-left py-1'>
                                <CustomInput className='squared' type='checkbox' id={m.id} checked={m.value} onChange={() => updateMerchantSelected(m.id)} label={m.nickname} />
                            </Col>
                        );
  
  const onCancel = () => {
      modalprops.toggle();
      };

  const onClickOk = () => {
      const selectedMerchants = merchants.filter(m => (m.value));
      onOk(startDate, endDate, file, selectedMerchants);
      modalprops.toggle();
  };

  const openFileDialog = () => {
    $('#file-upload').click();
  };

  const select = () => {
      const newFile = $('#file-upload').prop('files')[0];
      if(newFile){
          setFile(newFile);
          setShowSelectedFile(true);
      }else{
          setFile(null);
          setShowSelectedFile(false);
      }        
  };

  const deleteFile = () => {
      setFile(null);
      setShowSelectedFile(false);     
  };

  useEffect(() => {
    const newEndDate = moment(startDate).add(OGExpirationDays, 'days');
    setEndDate(moment(newEndDate).toDate());
}, [startDate]);

  return (
    <Modal {...modalprops} backdrop='static' keyboard={false}>
        <ModalHeader className='bg-white' toggle={modalprops.toggle}>
        <Row>
          <Col>{title}</Col>
        </Row>
      </ModalHeader>
        <ModalBody className='text-center'>
            <Row className='py-1 px-3'>
                <Col className='text-center font-weight-bold'> Select Locations </Col>
            </Row>
            <Row className='py-1 px-3'>  
                <Col xs={12} className='text-left pt-1' style={{fontSize:'14px'}}>
                    <p className="font-weight-normal m-0">
                        Please select the locations you would like to upload an order guide for.
                    </p>
                    <p className="font-weight-normal m-0">
                        If you would like to load individual files for each location, please unselect the locations that do not apply to the file you are about to upload.
                    </p>
                </Col>
                <Col xs={12} className='text-center pb-2' style={{fontSize:'14px'}}>Accepted files: .xlsx, .xls, .csv</Col>
                <Col className='text-left'> {allMerchantsMap} </Col>
            </Row>
            <Row className='py-1 px-3'>
                <Col className='text-center text-gray px-0'> Distributor </Col>
            </Row>
            <Row className='py-1 px-3'>
                <Col className='text-center font-weight-bold'> {distributor.nickname} </Col>
            </Row>
            <Row className='py-2 px-3'>
                <Col xs={4} className='text-right text-gray px-0'> Order Guide Date </Col>
                <Col>
                    <DatePicker id='startDateInput'
                        className={`selectInput`}
                        selected={startDate}
                        dateFormat={"MMMM dd, yyyy"}
                        onChange={date => setStartDate(date)} 
                        minDate={moment().toDate()}/>
                </Col>
            </Row>
            <Row className='py-2 px-3'>
                <Col xs={4} className='text-right text-gray px-0'> Next Price Change </Col>
                <Col>
                    <DatePicker id='endDateInput'
                        className={`selectInput`}
                        selected={endDate}
                        dateFormat={"MMMM dd, yyyy"}
                        onChange={date => setEndDate(date)} 
                        minDate={moment(startDate).toDate()}/>
                </Col>
            </Row>
            <Row className='py-2 px-3'>
                <Col xs={4} className='text-right text-gray px-0'> Attach File </Col>
                <ShowIf show={!showSelectedFile}>
                    <Col className='text-center'>
                        <Row className='px-3 d-flex flex-direction-column align-items-center justify-content-center' style={{margin: '0px', padding: '10px', color: '#557a99', border: 'dashed 1px #557a99', cursor: 'pointer'}} onClick={openFileDialog}>
                            <Col>
                                <MdCloudUpload  color={colors.lightBlueText} style={{fontSize:'30px'}}/><br/>
                                Click here
                            </Col>
                        </Row>
                        <input className='d-none' onChange={() => select()} type='file' id='file-upload' accept=".xls,.xlsx,.csv"/>
                    </Col>
                </ShowIf>
                <ShowIf show={showSelectedFile}>
                    <Col xs={1} className='text-left'>
                        <img src={excelIcon} style={{widh: '2em', height: '2em', paddingRight: '1em'}} />
                    </Col>
                    <Col className='text-left'>
                        {file ? file.name : ''}
                    </Col>
                    <Col xs={1} className='text-left'>
                        <MdDelete  color={colors.lightBlueText} onClick={() => deleteFile()} style={{fontSize:'20px'}}/>
                    </Col>
                </ShowIf>
            </Row>            
        </ModalBody>
        <ModalFooter>
            <Row className='w-100'>
                <Col className='w-100 text-left'>
                    <Button color={color} size={btnSize} onClick={onCancel} className='btn-outlined'>Cancel</Button>
                </Col>
                <Col className='w-100 text-right'>
                    <Button color={color} size={btnSize} onClick={onClickOk}>Upload</Button>
                </Col>
            </Row>            
        </ModalFooter>
    </Modal>
  );
};

UploadOgModal.defaultProps = {
  footerClass: '',
  btnSize: 'sm',
  color: 'green'
};

export default UploadOgModal;
