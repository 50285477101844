import { useDispatch, useSelector } from "react-redux"
import { showModal } from "../../../../../../reducers/modals/modalsActions";
import { isMobile } from "react-device-detect";
import CountModal from "../../../../../../SharedComponents/Modals/CountModal/CountModal";
import comparisonViewsApi from "../../../../../../api/comparisonViewsApi";
import { editOneInventoryGroup } from "../../../../../../reducers/items/itemsActions";

const useParLevel = ({parLevel, groupId, comparisonViewId}) => {
  const dispatch = useDispatch();
  const canEditComparisonView = useSelector(({user}) => user.editComparisonView) ? true : false;

  const openParLevel = () => {
    if (canEditComparisonView) {
      const modalProps = {
        defaultValue: parLevel,
        menu: isMobile,
        title: 'Edit Par Level',
        onSave: (newParLevel) => {
          const params = {
            parLevel: newParLevel
          };
          comparisonViewsApi.patchGroup(comparisonViewId, groupId, params).then(({data}) => {
            dispatch(editOneInventoryGroup(groupId, params));
          }).catch(err => console.error(err));
        }
      };

      dispatch(showModal(CountModal, modalProps));
    }
  }

  return {
    openParLevel
  };
};

export default {
  useParLevel
};