export const saveOpenSync = (open) => {
  return {
    type: 'SET_OPEN_SYNC',
    payload: open
  }
};

export const saveModalSync = (modal) => {
  return {
    type: 'SET_MODAL_SYNC',
    payload: modal
  }
};

export const saveSyncInventory = (sync) => {
  return {
    type: 'SET_MODAL_SYNC',
    payload: sync
  }
};