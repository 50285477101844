import React from 'react';
import ShowIf from '../ShowIf/ShowIf';
import { useSelector } from 'react-redux';
import './Spinner.scss';
import { FaSpinner } from 'react-icons/fa';
import { colors } from '../../config/constants';

const SpinnerOffline = () => {
  const show = useSelector(state => state.userInterface.spinner);

  return (
    <ShowIf show={show}>
      <div className='mask'>
        <div className='spinner'>
          <FaSpinner className='icon-spin' size={20} color={colors.lightBlueText} />
        </div>
      </div>
    </ShowIf>
  );
};

export default SpinnerOffline;
