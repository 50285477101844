import comparisonViewsApi from '../../api/comparisonViewsApi';
import { saveAllItems, deleteAllItems, saveBaseItems, deleteBaseItems } from '../../local/ItemsLocal';
import {
  deleteAllInventoryLists, saveAllInventoryLists, deleteBaseGroups, saveBaseGroups,
  deleteAllGroups, saveAllGroups, findAllGroups
} from '../../local/ComparisonViewLocal';
import { inventoryItemTypes } from '../../config/constants';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { saveOpenSync } from '../../reducers/sync/syncActions';
import { editInventoryList, selectInventoryList } from '../../reducers/items/itemsActions';
import { localStorageConst } from '../../config/constants';
import useConnection from '../OfflineMode/useConnection';
import { spinner } from '../../reducers/userInterface/userInterfaceActions';
import AlertModal from '../Modals/AlertModal/AlertModal';
import { showModal } from '../../reducers/modals/modalsActions';

const useOptions = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { connectionChange } = useConnection.useActions();

  const syncListToLocal = (items, sortByOptions, sortBy, product, search, filters) => {
    deleteBaseItems()
      .then(() => { saveBaseItems(sortByOptions, sortBy, product, search, filters); })
      .then(() => { deleteAllItems(); })
      .then(() => { saveAllItems(items); })
      .catch(error => { console.log(`Error on saving List ${error}`); });
  };

  const syncInventoryToLocal = (lists, groups, iListSelected, iSortBy, iSearch, iOnHandTotal) => {
    deleteAllInventoryLists()
    .then(() => { saveAllInventoryLists(lists); })
    .then(() => { deleteBaseGroups(); })
    .then(() => { saveBaseGroups(iListSelected, iSortBy, iSearch, iOnHandTotal); })
    .then(() => { deleteAllGroups(); })
    .then(() => { saveAllGroups(groups); })
    .then(() => { dispatch(spinner(false)); return comparisonViewsApi.takeInventory(iListSelected.id, 1); })
    .then(({data}) => {
      dispatch(spinner(true));
      dispatch(editInventoryList(iListSelected.id, {onInventory:data.onInventory}));
      localStorage.setItem(localStorageConst.keys.inventorySync, true);
      localStorage.setItem(localStorageConst.keys.inventoryList, iListSelected.id);
      dispatch(saveOpenSync(false));      
      const props = {
        message: 'You are now in offline mode',
        footerClass: 'justify-content-center',
        btnLabel: 'Ok',
        btnSize: 'lg',
        color: 'green'
      };
      dispatch(showModal(AlertModal, props));
      connectionChange(false, true);
    })
    .catch(error => { console.log(`Error on saving Inventory ${error}`); });

  };

  const checkItemsToSync = () => {
    findAllGroups()
      .then((groups) => {
        localStorage.setItem(localStorageConst.keys.inventoryItemsToSync, false);
        groups.forEach((group) => {
          group.items.forEach(item => {
            if (!item.toUpdate || item.toUpdate === 0) { return; }
            localStorage.setItem(localStorageConst.keys.inventoryItemsToSync, true);
            return;
          });
        });
        return;
      })
      .then(() => {
        const toUpdate = JSON.parse(localStorage.getItem(localStorageConst.keys.inventoryItemsToSync));
        if (toUpdate) { return; }
        const listSelected = localStorage.getItem(localStorageConst.keys.inventoryList);
        return comparisonViewsApi.takeInventory(listSelected, 0);
      })
      .then((res) => {
        if (!res) { return; }
        if (!res.data.onInventory) {
          localStorage.removeItem(localStorageConst.keys.inventoryList);
          history.push('/');
        }
      })
      .catch(error => { console.log(`Error on syncInventoryItemsToServer ${error}`); });

  };

  const syncInventoryItemsToServer = (listSelected) => {
    findAllGroups()
      .then((groups) => {
        if (groups.length === 0) { return; }
        let params = [];
        groups.forEach((group) => {
          if (group.items.length === 0) { return; }
          group.items.forEach(item => {
            if (!item.toUpdate || item.toUpdate === 0) { return; }
            let type = item.isCustomItem ? inventoryItemTypes.custom : (item.isSubRecipe ? inventoryItemTypes.subrecipe : inventoryItemTypes.generic);
            const param = {
              groupId: item.genericGroupId,
              type: type,
              genericCustomItemId: item.genericCustomItemId,
              subRecipeId: item.subRecipeId,
              itemDistributorId: item.itemDistributorId,
              onHand: item.onHand !== null ? item.onHand : 0,
              onHandUom: item.onHandUom !== null ? item.onHandUom : 0,
              onHandPack: item.onHandPack !== null ? item.onHandPack : 0,
              totalOnHand: item.totalOnHand !== null ? item.totalOnHand : 0,
              totalOnHandAmount: item.totalOnHandAmount !== null ? item.totalOnHandAmount : 0,
            };
            params.push(param);
          });
        });
        if (params.length === 0) { return; }
        return comparisonViewsApi.patchGroupItemBatch(params);
      })
      .then(() => { deleteAllGroups(); })
      .then(() => { return comparisonViewsApi.takeInventory(listSelected.id, 0); })
      .then(() => {
        localStorage.removeItem(localStorageConst.keys.inventorySync);
        localStorage.setItem(localStorageConst.keys.inventoryItemsToSync, false);
        localStorage.removeItem(localStorageConst.keys.inventoryList);
        history.push('/');
        // const props = {
        //   message: 'You are now in online mode',
        //   btnLabel: 'Ok',
        //   btnSize: 'lg',
        //   color: 'green'
        // };
        // dispatch(showModal(AlertModal, props));
        connectionChange(true, true);
      })
      .catch(error => { console.log(`Error on syncInventoryItemsToServer ${error}`); });

  };

  return {
    syncListToLocal,
    syncInventoryToLocal,
    syncInventoryItemsToServer,
    checkItemsToSync
  };
};


export default {
  useOptions
};
