import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from '../../../../../reducers/modals/modalsActions';
import merchantApi from '../../../../../api/merchantApi';
import orderGuideApi from '../../../../../api/orderGuideApi';
import OrderGuideDetailRow from './OrderGuideDetailRow';
import UploadOgModal from './UploadOgModal';
import NoTemplateModal from './NoTemplateModal';
import AlertModal from "../../../../../SharedComponents/Modals/AlertModal/AlertModal";
import moment from 'moment';
import { OGExpirationDays,colors } from '../../../../../config/constants';
import { useHistory } from "react-router-dom";
import { debounce } from 'lodash';

const useApi = (props) => {
    const {ogId, distributor, merchant} = props;
    const id = ogId;
    const dispatch = useDispatch();
    const history = useHistory();  
    const [orderGuideDetailList, setOrderGuideDetailList] = useState([]);    
    const [hasMore, setHasMore] = useState(true);
    const [pageCount, setPageCount] = useState(1);
    const [isLoading, setIsLoading] = useState(false);

    const getOrderGuideDetail = () => {
      if(!id){ return; }

      setIsLoading(true);
      const params = {
        page : pageCount,
        limit : 20
      };
      orderGuideApi.getOrderGuideDetail(id, params)
        .then((res) => {
          setOrderGuideDetailList(orderGuideDetailList.concat(res.data));
          if (res._meta.currentPage < res._meta.pageCount ) {              
            setHasMore(true);
            setPageCount(pageCount + 1);
          } else {
            setHasMore(false);
          }
          setIsLoading(false);
        })
        .catch(error => {
        });
      
    };

    const downloadOG = () => {
      if(!id){
        const alertProps = {
          title: 'Alert',
          message: 'No order guide detected',
        };
        dispatch(showModal(AlertModal, alertProps));
        return;
      }

      orderGuideApi.downloadOrderGuide(id).then((res) => {
        if(res.data) {
          const excelElement = document.createElement('a');
          excelElement.href = res.data;
          excelElement.click();
        }
      }).catch(error => {
      });      
        
    }

    const showUploadModal = () => {
      merchantApi.getAllowedChildren(distributor.id).then((res) => {
          const queriedMerchants = res.data;
          const allMerchants = queriedMerchants.map(m => {
            return {                
                id: m.id,
                nickname: m.nickname, 
                value: true,
            }
          });
          
          const modalProps = {
              title: 'UPLOAD ORDER GUIDE',
              distributor: distributor,
              merchant: merchant,
              onOk: preupload,
              allMerchants: allMerchants,
          };
          dispatch(showModal(UploadOgModal, modalProps));
      })
      .catch(error => {
      });
      
        
    };

    const preupload = (startDate, endDate, file, merchants) => {

      orderGuideApi.getMatchingTemplates(distributor.id, file).then((res) => {
          const template = res.data;
          if(!!template){
              if(template.length===0){
                  dispatch(showModal(NoTemplateModal, { onOk: () => requestTemplate(file, merchants) }));
              }else{                  
                delayedClickUpload(startDate, endDate, file, merchants);
              }                
          }else{
              dispatch(showModal(NoTemplateModal, { onOk: () => requestTemplate(file, merchants) }));
          }
      })
      .catch(error => {
      });
    };

    const requestTemplate = (file, merchants) => {
        const merchantIds = merchants.map((m) => {return m.id });

        orderGuideApi.uploadTemplate(distributor.id, file, merchantIds).then((res) => {
            const template = res.data;
            const alertProps = {
                title: 'Confirmation',
                message: 'Successful request',
                onOk : resetUpload(),
            };
            dispatch(showModal(AlertModal, alertProps));
        })
        .catch((error) => {
            const res = error;
            const alertProps = {
                title: 'Error!',
                message: (!!res.data) ? res.data.message : 'There was an error while submiting your template'
            };
            dispatch(showModal(AlertModal, alertProps));
        });
    };

    const uploadOG = (startDate, endDate, file, merchants) => {
      const merchantIds = merchants.map((m) => {return m.id });
      const params = {
          file : file,
          fastResponse : 1,
          merchantIds : merchantIds,
          distributor_id : distributor.id,
          start_date : moment(startDate).format('YYYY-MM-DD'),
          end_date : moment(endDate).format('YYYY-MM-DD'),
      };
      
      orderGuideApi.frontUpload(params).then((res) => {
          const result = res.data;
          const alertProps = {
              title: 'Confirmation',
              onOk : resetUpload(),
              message: result.message
          };
          dispatch(showModal(AlertModal, alertProps));
      })
      .catch((error) => {
        const res = error;
            const alertProps = {
                title: 'Error!',
                message: (!!res.data) ? res.data.message : 'There was an error while uploading your file'
            };
            dispatch(showModal(AlertModal, alertProps));
      });
    }

    const delayedClickUpload = debounce(
                                  (startDate, endDate, file, merchants) => {
                                    uploadOG(startDate, endDate, file, merchants)
                                  }                                   
                                , 1500);

    const resetUpload = () => {
      setTimeout(() => {
          history.push('/distributor');
      }, 2000);
  };
    
    useEffect(() => {
      getOrderGuideDetail();
    }, []);
    
    const orderGuideDetailMap = orderGuideDetailList.map((c, i) => 
        <OrderGuideDetailRow   key={`og-detail-${i}`} data={c} />
    )
    
    
    return { orderGuideDetailList, orderGuideDetailMap, getOrderGuideDetail, downloadOG, uploadOG, preupload, hasMore, pageCount, isLoading, showUploadModal};

}


export default {
    useApi
};