import { delay as baseDelay} from '../../config/constants';
import { useRef } from 'react';

const useTap = (onClick) => {
  // Ensure touches occur rapidly
  const delay = baseDelay.tap;
  // Sequential touches must be in close vicinity
  // const minZoomTouchDelta = baseDelay.touchDelta;

  // Track state of the last touch
  const lastTapAt = useRef(0);
  // let lastClientX = 0
  // let lastClientY = 0

  const preventDoubleTapZoom = (event) => {
    event.preventDefault();
    // Exit early if this involves more than one finger (e.g. pinch to zoom)
    if (event.touches.length > 1) {
      return
    }

    const tapAt = new Date().getTime();
    const timeDiff = tapAt - lastTapAt.current;
    // const { clientX, clientY } = event.touches[0]
    // const xDiff = Math.abs(lastClientX - clientX)
    // const yDiff = Math.abs(lastClientY - clientY)
    if ( timeDiff < delay || timeDiff > 5000) {
      // Trigger a fake click for the tap we just prevented
      // event.target.click()
      onClick();
    }
    // lastClientX = clientX
    // lastClientY = clientY
    lastTapAt.current = tapAt;
  };

  return {preventDoubleTapZoom}
};

const useMove = () => {  

  window.addEventListener('touchmove', function(event) {
      event = event.originalEvent || event;
      if(event.scale > 1) {
          event.preventDefault();
      }
  }, {passive: false});

  
};

export default {
  useTap, useMove
};