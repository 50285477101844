import React, { useEffect, Fragment, useState, useMemo } from 'react';
import timestampedInventoryApi from '../../../../../api/timestampedInventoryApi';
import { ListGroupItem, Row, Col } from 'reactstrap';
import moment from 'moment';
import numeral from 'numeral';
import './Inventories.scss';
import { MdRemoveRedEye, MdCloudDownload, MdKeyboardArrowRight } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import { isMobile } from 'react-device-detect';
import InfiniteScroll from 'react-infinite-scroller';
import { setActiveInventoryEdit } from '../../../../../reducers/user/userAction';
import { useDispatch } from 'react-redux';

const Inventories = (props) => {


    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setActiveInventoryEdit(false));
    }, [])
    const [listInventories, setListInventories] = useState([]);
    const history = useHistory();
    const [page, setPage] = useState(1);
    const [loadMore, setLoadMore] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const completeInventory = (oPage) => {
        setIsLoading(true);
        timestampedInventoryApi.getInventoriesComplete(oPage)
            .then(res => {
                setIsLoading(false);
                const loadMoreNew = res._meta.pageCount > res._meta.currentPage;
                const pageNew = loadMoreNew ? page + 1 : 1;
                setListInventories(listInventories.concat(res.data));
                setPage(pageNew);
                if (res._meta.pageCount - 1 == res._meta.currentPage) {
                    setLoadMore(false);
                };
            })
            .catch(err => console.log(err));

    };

    const getItemsInventory = (itemInventory) => {
        localStorage.setItem('itemInventory', JSON.stringify(itemInventory))
        history.push(`/inventory-view/inventories-detail`);
    };

    const downloadSpreadsheet = (item) => {
        var aElement = document.createElement('a');
        aElement.href = item.file_url + '?t=' + (new Date()).getTime();
        aElement.click();
    };

    const listInventoriesGroup = useMemo(() => listInventories.map((item) => {
        return (
            <ListGroupItem key={`listinventoriesgroup${item.id}`}>
                <ShowIf show={!isMobile}>
                    <Row className="pl-3 pr-3 rowITemInventories">
                        <Col>
                            {moment(item.start_date).format('LL')}
                        </Col>
                        <Col>
                            {moment(item.completed_date).format('LL')}
                        </Col>
                        <Col>
                            {numeral(item.total_on_hand_value).format('$0,0.00')} {item.unitMeasure}
                        </Col>
                        <Col>
                            <Row>
                                <Col className="actionItemInventories" onClick={() => getItemsInventory(item)}>
                                    <MdRemoveRedEye color="#c7d2dc" className="ml-2" size="27" />
                                    <p>View</p>
                                </Col>
                                <Col className="actionItemInventories" onClick={() => downloadSpreadsheet(item)}>
                                    <MdCloudDownload color="#c7d2dc" className="ml-2" size="27" />
                                    <p>Download</p>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </ShowIf>
                <ShowIf show={isMobile}>
                    <div className="rowITemInventories">
                        <div className="d-flex justify-content-between">
                            <span>Start Date</span>
                            <p>{moment(item.start_date).format('LL')}</p>
                        </div>
                        <div className="d-flex justify-content-between">
                            <span>Complete Date</span>
                            <p>{moment(item.completed_date).format('LL')}</p>
                        </div>
                        <div className="d-flex justify-content-between">
                            <span>Total On Hand Value</span>
                            <div className="d-flex align-items-center" onClick={() => getItemsInventory(item)}>
                                <p className="m-0">{numeral(item.total_on_hand_value).format('$0,0.00')} {item.unitMeasure}</p>
                                <MdKeyboardArrowRight color="#c7d2dc" className="ml-1" size="28" />
                            </div>
                        </div>
                    </div>
                </ShowIf>

            </ListGroupItem>
        );
    }), [listInventories]);

    useEffect(() => {
        completeInventory(page);
    }, []);


    return (
        <Fragment>

            <div className={isMobile ? "" : "p-3"} style={{
                height: 700,
                overflow: 'auto'
            }}>
                <ShowIf show={!isMobile}>
                    <ListGroupItem className="bg-transparent text-light-blue">
                        <Row className="pl-4 pr-3 rowInventoriesTitle">
                            <Col>Start Date</Col>
                            <Col>Complete Date</Col>
                            <Col>Total On Hand Value</Col>
                            <Col></Col>
                        </Row>
                    </ListGroupItem>

                </ShowIf>
                <InfiniteScroll
                    className='w-100'
                    initialLoad={false}
                    pageStart={page}
                    loadMore={() => { completeInventory(page) }}
                    hasMore={loadMore && !isLoading}
                    useWindow={false}
                >

                    {listInventoriesGroup}
                </InfiniteScroll>

            </div>
        </Fragment>

    );
};

export default Inventories;
