import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Row, Col, Button, Input, Label } from "reactstrap";
import { FaAngleDown, FaAngleUp, FaEnvelope, FaRegEnvelope, FaUserMinus } from 'react-icons/fa';
import { FiSlash } from 'react-icons/fi'
import { isMobile } from 'react-device-detect';
import '../Distributor.scss';
import moment from 'moment';
import validate from 'validate.js';
import Select from 'react-select';
import { OGPAccessTypeOptions } from "../../../../../config/constants";
import { InputCheckboxControlled } from "../../../../../SharedComponents/InputCheckbox/InputCheckbox";
import ReactDatePicker from 'react-datepicker';
import { debounce } from 'lodash';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import { useDispatch, useSelector } from 'react-redux';
import { updateDistributorCheck } from '../../../../../reducers/distributors/distributorsActions';
import IconTooltipSolid from '../../../../../SharedComponents/iconTooltip/IconTooltipSolid';
import { RiMailCheckLine, RiMailLine } from 'react-icons/ri';
import { AiOutlineUserAdd, AiOutlineUserDelete } from "react-icons/ai";


const DsrRow = (props) => {
  const { data, updateDist, sendInvite, deleteDist, isEditable } = props;

  const [name, setName] = useState(data.name || '');
  const [email, setEmail] = useState(data.email || '');
  const [phone, setPhone] = useState(data.phone || '');
  const [is_primary, setIs_primary] = useState(data.is_primary || false);
  const [access_type, setAccess_type] = useState(data.access_type || '');
  const [isUpdate, setIsUpdate] = useState(false);
  const [showBtnCalendar, setShowBtnCalendar] = useState(data.access_type === 'limited' ? 1 : 0);
  const [startDate, setStartDate] = useState(data.start_date ? new Date(data.start_date) : null);
  const [endDate, setEndDate] = useState(data.end_date ? new Date(data.end_date) : null);
  const [colorStatus, setColorStatus] = useState('#9bb1c1');
  const [backgroundStatus, setBackgroundStatus] = useState('rgb(238 243 246)')
  const [showCalendar, setShowCalendar] = useState(false);
  const [messageInvite, setMessageInvite] = useState('');
  const dispatch = useDispatch();
  const [error, setError] = useState({});

  const useDebounce = (callback) => {
    const d = callback;
    const callbackfunc = useCallback(debounce(d, 500), []);
    return [callbackfunc];
  };

  function handleDateChange(date) {
    // initial change start by setting the startDate
    if (!startDate && !endDate) {
      setStartDate(date);
      // startDate has been set, set the end date
    } else if (startDate && !endDate) {
      setEndDate(date);
    }
    // user is choosing another range => set the start date
    // and set the endDate back to null
    if (startDate && endDate) {
      setStartDate(date);
      setEndDate(null);
    }
  }


  const sendInvitation = () => {
    if (!validateSentInvite()) return;
    const params = {
      sendInvite: 1
    };
    sendInvite(params, data.id);
    setColorStatus('rgb(207 207 10)');
    setMessageInvite('Invite sent');
    setBackgroundStatus('#fffae7');
    data.invite_status = 'sent';
  };


  const fshowCalendar = () => {
    setShowCalendar(!showCalendar);
  }


  const update = (name, email, phone, accessType, startDate, endDate, is_primary) => {
    const params = {
      name,
      email,
      phone: String(phone),
      accessType,
      startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : '',
      endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : '',
      is_primary
    };
    updateDist(params, data.id)
  }



  const selectReceiveOrderGuide = () => {
    setIs_primary(!is_primary);
  }

  // useEffect(() => {
  //   if (!isUpdate)
  //     return;
  //   // setIs_primary(valueCheckPrimary === data.id ? true : false);
  //   // if (valueCheckPrimary === data.id)
  //   callback(name, email, phone, access_type, startDate, endDate, is_primary);

  // }, [is_primary])

  const [callback] = useDebounce(update);

  const getColorByStatus = () => {
    switch (data.invite_status) {
      case 'sent':
        setColorStatus('rgb(207 207 10)');
        setMessageInvite('Invite sent');
        setBackgroundStatus('#fffae7');
        break;
      case 'accepted':
        setColorStatus('#2ad92a');
        setMessageInvite('Invite Accepted');
        setBackgroundStatus('#efe');
        break;
      case null:
        setColorStatus('#9bb1c1');
        setMessageInvite('Send Invite');
        setBackgroundStatus('rgb(238 243 246)');

        break;
      default:
        break;
    }
  };

  const validateSentInvite = () => {
    const form = {
      name: name,
      email: email,
      access: access_type
    }
    const constrains = {
      name: { presence: { allowEmpty: false } },
      access: { presence: { allowEmpty: false } },
      email: { presence: { allowEmpty: false }, email: true },
    };
    const result = validate(form, constrains);
    setError(result ? result : {});
    return result ? false : true

  }


  useEffect(() => {
    getColorByStatus();
    //showSendInvite();
    setIsUpdate(true);
    if (isUpdate) {
      // showSendInvite();
      callback(name, email, phone, access_type, startDate, endDate, is_primary);
      setShowCalendar(false);

    }
  }, [name, email, phone, access_type]);

  useEffect(() => {
    if (isUpdate && !showCalendar) {
      callback(name, email, phone, access_type, startDate, endDate, is_primary);
    };
  }, [showCalendar]);

  useEffect(() => {
    if (isUpdate) {
      callback(name, email, phone, access_type, startDate, endDate, is_primary);
    }
  }, [is_primary])

  const deleteDsr = () => {
    const params = {
      is_deleted: data.is_deleted ? 0 : 1
    };
    deleteDist(data.id, params);
  };


  const onChangeOGPA = (e) => {
    setAccess_type(e.value);
    setShowBtnCalendar(e.showCalendar);
  };
  return (
    <Row className='mx-0 px-0'>
      <Col xs={2}>
        <Input type='text' value={name} onChange={e => setName(e.target.value)} />
        <Label className='text-danger'>{error.name ? error.name[0] : ''}</Label>
      </Col>
      <Col xs={3}>
        <Input type='text' label='Email' value={email} onChange={e => setEmail(e.target.value)} />
        <Label className='text-danger'>{error.email ? error.email[0] : ''}</Label>
      </Col>
      <Col xs={2}><Input type='text' value={phone} onChange={e => setPhone(e.target.value)} /></Col>
      <Col xs={1} className="d-flex align-items-center justify-content-center">
        <ShowIf show={!data.is_deleted}>
          <InputCheckboxControlled active={is_primary} value={is_primary} onClick={selectReceiveOrderGuide} />
        </ShowIf>
      </Col>
      <Col xs={1}>
        <ShowIf show={!data.is_deleted}>
          <Select className='fb-select buttonH' classNamePrefix='select'
            onChange={e => onChangeOGPA(e)}
            defaultValue={OGPAccessTypeOptions.find(_i => _i.value === access_type)}
            options={OGPAccessTypeOptions}
          />
          <Label className='text-danger'>{error.access ? error.access[0] : ''}</Label>
        </ShowIf>

      </Col>
      <Col xs={2}>
        <ShowIf show={showBtnCalendar && !data.is_deleted}>
          <Button color='white' className="buttonH ml-3" onClick={fshowCalendar}>
            {
              `${startDate ? moment(startDate).format('MMM D, YYYY') : ''} - 
             ${endDate ? moment(endDate).format('MMM D, YYYY') : ''}`

            }
            {
              showCalendar ?
                <FaAngleUp color='dark-blue' style={{ fontSize: '20px' }} />
                :
                <FaAngleDown color='dark-blue' style={{ fontSize: '20px' }} />
            }
          </Button>
        </ShowIf>

        {
          showCalendar &&
          <ReactDatePicker
            calendarClassName="calendarD"
            onChange={(date) => handleDateChange(date)}
            // selectsStart={true}
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
            inline={true}
            selectsRange
          />
        }

      </Col>
      <Col xs={1} className='px-0 d-flex flex-direction-row justify-content-space-between'>
        <Col className='px-1 d-flex justify-content-center'  >
          <ShowIf show={!data.is_deleted}>
            <ShowIf show={isEditable}>
              <Button onClick={() => sendInvitation()} color="rgb(238 243 246)" style={{ fontSize: 11, backgroundColor: backgroundStatus, height: 46 }} >
                <ShowIf show={data.invite_status === null}>
                  <IconTooltipSolid invoiceDate='test' Icon={FaEnvelope} colorIcon={colorStatus} color={colorStatus} elemId={`message_${data.id}_`} >
                    {messageInvite}
                  </IconTooltipSolid>
                </ShowIf>
                <ShowIf show={data.invite_status === 'sent'}>
                  <IconTooltipSolid invoiceDate='test' Icon={RiMailLine} colorIcon={colorStatus} color={colorStatus} elemId={`message_${data.id}_`} >
                    {messageInvite}
                  </IconTooltipSolid>
                </ShowIf>
                <ShowIf show={data.invite_status === 'accepted'}>
                  <IconTooltipSolid invoiceDate='test' Icon={RiMailCheckLine} colorIcon={colorStatus} color={colorStatus} elemId={`message_${data.id}_`} >
                    {messageInvite}
                  </IconTooltipSolid>
                </ShowIf>
              </Button>

            </ShowIf>

          </ShowIf>

          <ShowIf show={!isEditable}>
            <Button  color="rgb(238 243 246)" style={{ fontSize: 11, backgroundColor: 'rgb(238 243 246)', height: 46 }} >
            
              <IconTooltipSolid Icon={FaEnvelope} colorIcon={'#9bb1c1'} color={'#9bb1c1'} elemId={`message_${data.id}_`} >

              </IconTooltipSolid>
              {!isEditable &&
                <FiSlash style={{
                  position: 'absolute',
                  right: 22,
                  top: 22
                }} size='14' color='#e0661f' />
              }
            </Button>

          </ShowIf>
        </Col>
        <Col className='px-1 d-flex justify-content-center'>
          <Button color='gray' style={{ fontSize: 13, height: 46 }} onClick={() => deleteDsr()} >
            <ShowIf show={!data.is_deleted}>
              <IconTooltipSolid invoiceDate='test' Icon={AiOutlineUserDelete} colorIcon='dark-blue' color='dark-blue' elemId={`remove_${data.id}_`} >
                Remove
              </IconTooltipSolid>
              {/* <AiOutlineUserDelete color='dark-blue' style={{ fontSize: '20px' }} /> */}
            </ShowIf>
            <ShowIf show={data.is_deleted}>
              <IconTooltipSolid invoiceDate='test' Icon={AiOutlineUserAdd} colorIcon='dark-blue' color='dark-blue' elemId={`remove_${data.id}_`} >
                Reactivate
              </IconTooltipSolid>
            </ShowIf>
          </Button>
        </Col>
        {/* <Col className='px-1 d-flex justify-content-center'>
          <IoIosCopy color='gray' onClick={() => duplicateDsr()} style={{ fontSize: '20px' }} />
        </Col>
        <Col className='px-1 d-flex justify-content-center'>
          <MdDelete color='gray' onClick={() => deleteDsr()} style={{ fontSize: '20px' }} />
        </Col> */}
      </Col>
    </Row >
  )

}

export default DsrRow;