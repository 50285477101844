export default function reducer(state = {
  cartItems: [],
  cartItemsByDistributor: [], 
  cartCount: 0,
  lastModified: '',
  unsyncedItems: [],
  animation: {type:null, data:null},
  orderTotal : 0,
  savingOptions : []
}, action) {
  switch (action.type) {
    case 'SET_CART_ITEMS':
      return {
        ...state, 
        cartItems: action.payload
    };
    case 'SET_CART_DISTRIBUTOR':
      return {
        ...state, 
        cartItemsByDistributor: action.payload
    };
    case 'SET_CART_COUNT':
      return {
        ...state, 
        cartCount: action.payload
    };
    case 'SET_LAST_MODIFIED':
      return {
        ...state, 
        lastModified: action.payload
    };
    case 'SET_UNSYNCED_ITEMS':
      return {
        ...state, 
        unsyncedItems: action.payload
    };
    case 'SET_ANIMATION':
      return {
        ...state, 
        animation: action.payload
    };
    case 'SET_ORDER_TOTAL':
      return {
        ...state, 
        orderTotal: action.payload
    };
    case 'SET_SAVING_OPTIONS':
      return {
        ...state, 
        savingOptions: action.payload
    };
    case 'CLEAR_CART_DATA':
      return {
        cartItems: [],
        cartItemsByDistributor: [], 
        cartCount: 0,
        lastModified: '',
        unsyncedItems: [],
        animation: {type:null, data:null},
        orderTotal : 0,
        savingOptions : []        
      };
    default:
      return state;
  }
};