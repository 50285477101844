import React from 'react';
import { Row, Col } from 'reactstrap';
import numeral from 'numeral';
import ShowIf from '../../../../../../../SharedComponents/ShowIf/ShowIf';
import { isMobile } from 'react-device-detect';

const ItemDetailsInventoryLinkItemInfo = (props) => {
  const {
    name, brand, distributor, price, packSize, isCustomPackSize
  } = props;

  return (
    <Col>
      <Row>
        <Col className='text-main text-capitalize'>{name}</Col>
      </Row>
      <ShowIf show={!isMobile}>
      <Row>
        <Col xs={6}>
          <Row>
            <Col className='text-label text-truncate'>{brand}</Col>
          </Row>
          <Row>
            <Col>{numeral(price).format('$0,0.00')}</Col>
          </Row>
        </Col>
        <Col xs={6}>
          <Row>
            <Col className='text-label text-truncate'>{distributor}</Col>
          </Row>
          <Row>
            <Col className={isCustomPackSize ? ' font-italic': ''}>{packSize}</Col>
          </Row>
        </Col>
      </Row>
      </ShowIf>
    </Col>
  );
};

export default ItemDetailsInventoryLinkItemInfo;