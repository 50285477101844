
import React from 'react';
import { Card, Row, Col } from "reactstrap";
import { isMobile } from 'react-device-detect';

const OrderGuideDetailRow = (props) => {
    const { data } = props;
    if (isMobile) {  
        return (
            <Card>
                <Col>
                    <Row>
                        <Col className='py-2'>
                            DIN
                        </Col>
                        <Col className='py-2 text-right'>
                            {data.din}
                        </Col>
                    </Row>
                    <Row>
                        <Col className='col-5 py-2'>
                            Description
                        </Col>
                        <Col  className='col-7 py-2 text-right text-capitalize text-truncate'>
                            {data.name}
                        </Col>
                    </Row>
                    <Row>
                        <Col className='py-2'>
                            UOM
                        </Col>
                        <Col className='py-2 text-right'>
                            {data.unit_measure}
                        </Col>
                    </Row>
                    <Row>
                        <Col className='py-2'>
                            UOM Price
                        </Col>
                        <Col className='py-2 text-right'>
                            {data.price}
                        </Col>
                    </Row>
                    <Row>
                        <Col className='py-2'>
                            Packsize
                        </Col>
                        <Col className='py-2 text-right'>
                            {data.pack_size}
                        </Col>
                    </Row>
                    <Row>
                        <Col className='py-2'>
                            Unit Price
                        </Col>
                        <Col className='py-2 text-right'>
                            {data.unit_price}
                        </Col>
                    </Row>
                    <Row>
                        <Col className='py-2'>
                            Catch Weight
                        </Col>
                        <Col className='py-2 text-right'>
                            {data.is_catch_weight ? 'Yes' : 'No'}
                        </Col>
                    </Row>
                    <Row>
                        <Col className='py-2'>
                            Brand
                        </Col>
                        <Col className='py-2 text-right'>
                            {data.brand}
                        </Col>
                    </Row>
                </Col>
            </Card>
        )

    }

    return (
        <Card>
           <Row className='py-3 px-3'>
                <Col xs={1}>
                    {data.din}
                </Col>
                <Col xs={3} className='text-capitalize text-truncate'>
                    {data.name}
                </Col>
                <Col xs={1}>
                    {data.unit_measure}
                </Col>
                <Col xs={2}>
                    ${data.price}
                </Col>
                <Col xs={1}>
                    {data.pack_size}
                </Col>
                <Col xs={1} >
                    {data.unit_price}
                </Col>
                <Col xs={1}>
                    {data.is_catch_weight ? 'Yes' : 'No'}
                </Col>
                <Col xs={2}>
                    {data.brand}
                </Col>
            </Row>
       </Card>
    )

}

export default OrderGuideDetailRow