import React, { useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, Button, ModalHeader, Row } from 'reactstrap';
import useModal from '../useModal';
import { isMobile } from "react-device-detect";
import './ConfirmationModal.scss'
import { FaCheckCircle } from 'react-icons/fa';

const ConfirmationModal = (props) => {
    const { id, header, message, onOk, getProps } = props;
    const modalprops = useModal(id);

    const onClickOk = () => {
        if (onOk) {
            onOk();
        };
        modalprops.toggle();
    };


    useEffect(() => {
        if (getProps)
            getProps(modalprops);
    }, [])


    return (
        <Modal {...modalprops} backdrop='static' keyboard={false} className={isMobile ? 'confirmationModal mobile' : 'confirmationModal'} >

            <ModalBody className='text-center'>
                <Row>
                    <FaCheckCircle size='40'></FaCheckCircle>
                </Row>
                <Row>
                    <h1>{header}</h1>
                </Row>
                <Row>
                    <p>
                        {message}
                    </p>
                </Row>
                <Row>
                    <Button color='green' onClick={onClickOk}>Ok</Button>
                </Row>

            </ModalBody>
        </Modal>
    );
};

export default ConfirmationModal;

