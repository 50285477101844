import React, { Fragment } from 'react';
import { Col, Input } from 'reactstrap';

const RecipeInfoInput = (props) => {
  const {
    label, type, placeholder, set, value, className, error, onBlur, onFocus
  } = props;
  
  return (
    <Fragment>
      <Col className='text-label font-weight-bold mt-3 mb-1'>{label}</Col>
      <Col className={className}>
        <Input type={type} placeholder={placeholder} onChange={set} value={value} className={error ? 'border-error' : ''} onBlur={onBlur} onFocus={onFocus} />
      </Col>
    </Fragment>
  );
}

export default RecipeInfoInput;