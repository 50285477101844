import React, { useEffect, useRef, createRef, useCallback } from 'react';
import { Col, Button } from 'reactstrap';
import './Header.scss';
import ShowIf from '../ShowIf/ShowIf';
import { IoMdSearch, IoIosOptions } from 'react-icons/io';
import InputSearch from '../InputSearch/InputSearch';
import { isMobile } from 'react-device-detect';
import { colors, delay, localStorageConst } from '../../config/constants';
import { useDispatch } from 'react-redux';
import { showModal } from '../../reducers/modals/modalsActions';
import SearchFiltersModal from '../Modals/SearchFiltersModal/SearchFiltersModal';
import { useSelector } from 'react-redux';
import useHeader from './useHeader';
import WelComeTourModal from "../../SharedComponents/Modals/WelComeTourModal/WelcomeTourModal";
import { debounce } from 'lodash';
import comparisonViewsApi from '../../api/comparisonViewsApi';
import { selectInventoryList, setInventorySearch } from '../../reducers/items/itemsActions';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import * as $ from 'jquery';

const HeaderSearch = (props) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const { show, clearSearch, enterSearch, showFilters, search, setSearch } = props;
  const [searchN, setSearchN] = useState('');
  const listInventory = useSelector(({ items }) => items.inventory.list);
  const [inventoriesV, setInventoriesV] = useState([]);
  const [withItems, setWithItems] = useState(false);
  const [firstTime, setFirstTime] = useState(true);
  let storeView = useSelector(({ items }) => items.view);
  if (history.location.pathname.startsWith('/shopping-lists')) {
    storeView = 'list';
  }

  const openFilters = () => {
    dispatch(showModal(SearchFiltersModal));
  };

  const { clickMenu,
    enterSearchs,
    clearSearchs,
    setSearchs,
    searchs,
    clickBacks,
    openModalWC,
    tourElementSearch,
    tourElementFilters,
    startTour,
    goToNextTourModal,
    skipDemo } = useHeader.useActions(dispatch);

  const { pathname } = history.location;
  const numTourModal = useSelector(state => state.modals.numTourModal);


  useEffect(() => {
    switch (numTourModal) {
      case 2:
        if (!isMobile)
          clickMenu();
        startTour();
        break;
      case 3:
        startTour();
        break;
      case 4:
        startTour();
        break;
      default:
        break;
    }
  }, [numTourModal]);


  const clearSearchI = () => {
    setSearchN("");
    setWithItems(false);
    setFirstTime(true)
    dispatch(setInventorySearch(''));
    $("HTML, BODY").animate({
      scrollTop: 0
    });
    history.push({
      pathname: pathname,
      search: ''
    });
  }

  const useDebounce = (callback) => {
    const d = callback;
    const callbackfunc = useCallback(debounce(d, delay.tap), []);
    return [callbackfunc];
  };

  const goToSearch = (comparisonViewId, nameGroup) => {
    setFirstTime(true)
    setWithItems(false)
    setSearchN(nameGroup);
    $("HTML, BODY").animate({
      scrollTop: 0
    });
    dispatch(setInventorySearch(nameGroup));

    history.push({
      pathname: pathname,
      search: searchN ? `?query=${nameGroup}` : ''
    });

    const itemSelected = listInventory.find(_i => _i.id == comparisonViewId);
    dispatch(selectInventoryList(itemSelected));
    localStorage.setItem(localStorageConst.keys.listSelected, JSON.stringify(itemSelected));
  }

  const getFilterGroup = (valueSearch) => {
    comparisonViewsApi.getFilterGroup(valueSearch)
      .then(res => {
        setWithItems(true);
        setInventoriesV(res.data);
        // const comparisonViewId = res.data[0].comparisonViewId;
        // console.log(`Realizando busqeuda.  . ${comparisonViewId}`)

      })
      .catch(err => console.log(err))
  };


  const [callback] = useDebounce(getFilterGroup);

  useEffect(() => {
    setFirstTime(false);
    !firstTime && callback(searchN);
  }, [searchN])

  const onChange = (evt) => {
    if(evt.currentTarget.value == ""){
      setSearchN("");
      setWithItems(false);
      setFirstTime(true)
      dispatch(setInventorySearch(''));
      $("HTML, BODY").animate({
        scrollTop: 0
      });
      history.push({
        pathname: pathname,
        search: ''
      });
    }else{
      setSearchN(evt.currentTarget.value);
    }
  }

  const onBlurEvent = () => {
    setTimeout(() => {
      console.log(withItems)
      setWithItems(false);
    }, 1000);
  }


  return (
    <ShowIf show={show}>
      <Col className='pl-0'>
        <em ref={tourElementSearch}></em>
        <ShowIf show={storeView === 'inventory'}>
          <InputSearch
            type='text' placeholder='Search...'
            onChange={onChange} value={searchN}
            clearValue={clearSearchI} onEnter={enterSearch}
            withItems={withItems} items={inventoriesV}
            goToSearch={goToSearch} onBlurEvent={onBlurEvent}
          />
        </ShowIf>
        <ShowIf show={storeView === 'list'}>
          <InputSearch type='text' placeholder='Search...'
            onChange={setSearch} value={search}
            clearValue={clearSearch} onEnter={enterSearch} />
        </ShowIf>
      </Col>
      <ShowIf show={!isMobile}>
        <Col className='w-fc'>
          <Button color='green' onClick={enterSearch}>
            <IoMdSearch color='white' /> Search
          </Button>
        </Col>
        <ShowIf show={showFilters}>
          <Col className='w-fc'>
            <Button color='green' onClick={openFilters}>
              <em ref={tourElementFilters}></em>

              <IoIosOptions color='white' /> Filters
            </Button>
          </Col>
        </ShowIf>
      </ShowIf>
      <ShowIf show={isMobile && showFilters}>
        <Col className='w-fc px-0' onClick={openFilters}>
          <IoIosOptions size={28} color={colors.dark} />
        </Col>
      </ShowIf>

      <ShowIf show={openModalWC}>
        <WelComeTourModal
          openModal={openModalWC}
          tourElement={numTourModal == 2 ? tourElementSearch : tourElementFilters}
          startTour={startTour}
          skipDemo={skipDemo}
          goToNextTourModal={() => goToNextTourModal(numTourModal + 1)}
        />
      </ShowIf>
    </ShowIf>
  );
};

export default HeaderSearch;
