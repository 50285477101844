import React from 'react';
import ShowIf from '../ShowIf/ShowIf';
import { Col } from 'reactstrap';
import { colors } from '../../config/constants';
import { isMobile } from 'react-device-detect';
import { FaChevronLeft } from 'react-icons/fa';

const HeaderBackButton = (props) => {
  const { show, clickBack } = props;
  return (
    <ShowIf show={show}>
      <Col className='w-fc pr-0 clickable' onClick={clickBack}>
        <FaChevronLeft  className='-mt3' color={colors.dark} size={20} />
      </Col>
      <ShowIf show={!isMobile}>
        <Col className='w-fc pl-1 clickable' onClick={clickBack}>
          <h5 className='font-weight-bold m-0'>Back</h5>
        </Col>
      </ShowIf> 
    </ShowIf>
  )
};

export default HeaderBackButton;