
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Container } from 'reactstrap';
import DistributorList from './DistributorList/DistributorList';
import DistributorDetail from './DistributorDetail/DistributorDetail';
import OrderGuideDetail from './OrderGuideDetail/OrderGuideDetail';


const Distributor = () => {

    return (
        <Switch>
            <Route exact path='/distributor' component={DistributorList} />
            <Route exact path='/distributor/:id' component={DistributorDetail} />
            <Route exact path='/distributor/:id/order-guide/:ogId' component={OrderGuideDetail} />
            <Route exact path='/distributor/:id/order-guide/' component={OrderGuideDetail} />
            <Route>
                <Redirect to='/distributor' />
            </Route>
        </Switch>
    )

}

export default Distributor;