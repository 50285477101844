import { sortOptions, inventoryOptions } from "../../config/constants";

export const saveView = (view) => {
  return {
    type: 'SET_VIEW',
    payload: view
  }
};
export const setInventorySearch = (search) => {
  return {
    type: 'SET_INVENTORY_SEARCH',
    payload: search
  }
};
export const saveInventoryList = (list, init) => {
  return {
    type: 'SET_INVENTORY_LIST',
    payload: [list, init]
  }
};
export const selectInventoryList = (selected) => {
  return {
    type: 'SET_INVENTORY_LIST_SELECTED',
    payload: selected
  }
};

export const setInventoryOptionsRearrangeDisabled = (selectedSort) => {  
  return {
    type: 'SET_INVENTORY_OPTIONS_REARRANGE',
    payload: {
      id: 1,
      name: inventoryOptions.keys.rearrange,
      disabled: (selectedSort.default === 1) ? false : true
    }
  }
};

export const insertInventoryList = (list) => {
  return {
    type: 'INSERT_INVENTORY_LIST',
    payload: {
      // index: index,
      list: list
    }
  }
};
export const editInventoryList = (listId, value) => {
  return {
    type: 'EDIT_INVENTORY_LIST',
    payload: {
      listId: listId,
      value: value
    }
  }
};
export const removeInventoryList = (listId) => {
  return {
    type: 'REMOVE_INVENTORY_LIST',
    payload: listId
  }
};

export const saveInventoryItems = (items) => {
  return {
    type: 'SET_INVENTORY_ITEMS',
    payload: items
  }
};

export const saveInventoryItemsPage = (page) => {
  return {
    type: 'SET_INVENTORY_ITEMS_PAGE',
    payload: page
  }
};

export const saveInventoryItemsPageTotal = (page) => {
  return {
    type: 'SET_INVENTORY_ITEMS_PAGE_TOTAL',
    payload: page
  }
};

export const insertInventoryGroup = (group, index) => {
  return {
    type: 'INSERT_INVENTORY_GROUP',
    payload: {
      index: index,
      group: group
    }
  }
};

export const removeInventoryGroup = (groupId) => {
  return {
    type: 'REMOVE_INVENTORY_GROUP',
    payload: groupId
  }
};

export const editOneInventoryGroup = (groupId, value) => {
  return {
    type: 'EDIT_ONE_INVENTORY_GROUP',
    payload: {
      groupId: groupId,
      value: value
    }
  }
};

export const sortInventoryGroups = () => {
  return {
    type: 'SORT_INVENTORY_GROUPS',
  }
};

export const sortInventoryGroupsNew = (index) => {
  return {
    type: 'SORT_INVENTORY_GROUPS_NEW',
    payload: {
      index: index
    }
  }
};

export const editOneInventoryGroupItem = (groupId, itemId, value, idLabel) => {
  return {
    type: 'EDIT_ONE_INVENTORY_GROUP_ITEM',
    payload: {
      groupId: groupId,
      idLabel: idLabel ? idLabel : 'itemDistributorId',
      itemId: itemId,
      value: value
    }
  }
};

export const removeInventoryGroupItem = (groupId, itemId, idLabel) => {
  return {
    type: 'REMOVE_INVENTORY_GROUP_ITEM',
    payload: {
      groupId: groupId,
      idLabel: idLabel ? idLabel : 'itemDistributorId',
      itemId: itemId,
    }
  }
};

export const saveInventoryOnHandTotal = (onHandTotal) => {
  return {
    type: 'SET_ON_HAND_TOTAL',
    payload: onHandTotal
  }
};

export const saveListItems = (items) => {
  return {
    type: 'SET_LIST_ITEMS',
    payload: items
  }
};

export const setInventorySortBy = (sort) => {
  return {
    type: 'SET_INVENTORY_SORT',
    payload: sort
  }
};

export const setListSortBy = (sort) => {
  return {
    type: 'SET_LIST_SORT',
    payload: sort
  }
};

export const setListSortByOptions = (sortOptions) => {
  return {
    type: 'SET_LIST_SORT_OPTIONS',
    payload: sortOptions
  }
};

export const setListPageCount = (page) => {
  return {
    type: 'SET_LIST_PAGE_COUNT',
    payload: page
  }
};

export const setListLoadMore = (loadMore) => {
  return {
    type: 'SET_LIST_LOAD_MORE',
    payload: loadMore
  }
};

export const setListIsLoading = (loadMore) => {
  return {
    type: 'SET_LIST_IS_LOADING',
    payload: loadMore
  }
};

export const setListSearch = (search) => {
  return {
    type: 'SET_LIST_SEARCH',
    payload: search
  }
};

export const setListFilters = (purchasedOnly, distributorIds, shoppingIds, pastOrderIds) => {
  return {
    type: 'SET_LIST_FILTERS',
    payload: {
      purchasedOnly: purchasedOnly,
      distributorIds: distributorIds,
      shoppingIds: shoppingIds,
      pastOrderIds: pastOrderIds
    }
  }
};

export const clearItemsData = () => {
  return {
    type: 'CLEAR_ITEMS_DATA',
  };
};

export const clearAllItemsData = () => {
  return {
    type: 'CLEAR_ALL_ITEMS_DATA',
  };
};