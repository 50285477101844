import React, { Fragment, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import SignUpInput from '../Subcomponents/SignUpInput';
import useSignUp from '../useSignUp';

const SignUpLocationInformation = ({ index, number, setData, init }) => {
  const emptyLocation = {
    locationName: '',
    phoneNumber: '',
    locationAddress: '',
    city: '',
    state: '',
    zipcode: ''
  };

  const {
    locationName, phoneNumber, locationAddress,
    city, state, zipcode, stateOptions
  } = useSignUp.useLocation(init ? init : emptyLocation, setData, index);


  
  return (
    <Fragment>
      <Row>
        <Col className='font-weight-bold mb-3'>Location {number}</Col>
      </Row>
      <Row className='mb-3'>
        <SignUpInput label='Location Name *' placeholder='Location Name' {...locationName} />
        <SignUpInput label='Phone Number *' placeholder='(123) 123-1234' {...phoneNumber} />
      </Row>
      <Row className='mb-3'>
        <SignUpInput label='Location Address *' placeholder='Location Address' {...locationAddress} />
      </Row>
      <Row className='mb-3'>
        <SignUpInput label='City *' placeholder='City' {...city} />
        <SignUpInput label='State *' options={stateOptions} {...state} isDropdown />
        <SignUpInput label='Zipcode *' placeholder='Zipcode' {...zipcode} />
      </Row>
    </Fragment>
  );
};

export default SignUpLocationInformation;