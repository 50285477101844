import React from 'react';
import { Row, Col } from 'reactstrap';

const LoginHeader = ({logo}) => {
  return (
    <Row className='m-0 text-center login-header mb-3'>
      <Col className='pt-3 pb-3'>
        <img src={logo} alt='Company Logo' className='company-logo' />
      </Col>
    </Row>
  )
};

export default LoginHeader;