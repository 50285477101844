import db from './db';
import {dbStores} from './dbConstants';

const itemsTable = dbStores.tables.items;
const itemsBaseTable = dbStores.tables.itemsBase;


// Items

export const saveAllItems = async (items) => {
  let nItems=[];
  items.forEach((item, i) => {
    const nItem = { 
      id: item.id,
      imageUrl: item.imageUrl,
      brand: item.brand,
      brandImageUrl: item.brandImageUrl,
      description: item.description,
      name: item.name,
      foodbamCustomDescription: item.foodbamCustomDescription,
      din: item.din,
      distributor: item.distributor,
      distributorId: item.distributorId,
      type: item.type,
      purchasedPrice: item.purchasedPrice,
      price: item.price,
      unitPrice: item.unitPrice,
      unitMeasure: item.unitMeasure,
      unitQuantity: item.unitQuantity,
      unitSize: item.unitSize,
      unitType: item.unitType,
      uom: item.uom,
      packSize: item.packSize,
      packSizeUom: item.packSizeUom,
      rebate: item.rebate,
      savings: item.savings,
      showSavings: item.showSavings,
      savingItems: item.savingItems,
      sortIndex: item.sortIndex,
      status: item.status,
      itemDistributorId: item.itemDistributorId,
      itemId: item.itemId,
      lastPurchasedDate: item.lastPurchasedDate,
      lastPurchasedQty: item.lastPurchasedQty,
      matchType: item.matchType,
      merchantId: item.merchantId,
      midpId: item.midpId,
      notes: item.notes,
      orderGuideId: item.orderGuideId,
      endDate: item.endDate,
      caseQuantity: item.caseQuantity,
      count: item.count,
      isCatchWeight: item.isCatchWeight,
      isFixedWeight: item.isFixedWeight,
      isPunchedOut: item.isPunchedOut,
      isPurchased: item.isPurchased,
      isMarketPrice: item.isMarketPrice,
      isPartner: item.isPartner,
      isPriceOutdated: item.isPriceOutdated,
      isUnpurchasable: item.isUnpurchasable,
      isElectronicInvoiceItem: item.isElectronicInvoiceItem,
      isSubRecipe: item.isSubRecipe,
      subRecipeId: item.subRecipeId,
      updateTime: item.updateTime,
      averageWeight: item.averageWeight,
      belongsToGroup: item.belongsToGroup
    };    
    nItems.push(nItem);
  });

  return await db.table(itemsTable).bulkAdd(nItems);  
};

export const deleteAllItems = async () => {
  return await db.table(itemsTable).clear();  
};

// ItemsBase

export const saveBaseItems = async (sortByOptions, sortBy, product, search, filters) => {
  const params = {    
    sortByOptions: sortByOptions,
    sortBy: sortBy,
    product: product,
    search: search,
    filters: filters
  } 
  return await db.table(itemsBaseTable).add(params);    
};

export const deleteBaseItems = async () => {
  return await db.table(itemsBaseTable).clear();
};
