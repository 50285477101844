import React, {useEffect} from 'react';
import { Container, Row } from 'reactstrap';
import MainAppRouter from './MainAppRouter';
import Sidebar from '../../SharedComponents/Sidebar/Sidebar';
import './MainApp.scss';
import { useSelector } from 'react-redux';
import useShoppingCart from '../../SharedComponents/ShoppingCart/useShoppingCart';
import useShoppingCartInterface from '../../SharedComponents/ShoppingCart/useShoppingCartInterface';

const MainApp = () => {
  const isSidebarOpen = useSelector(state => state.userInterface.isSidebarOpen);
  const activeLocation = useSelector(({user}) => user.activeLocation);
  // const { getCartItems } = useShoppingCart.useCartItems();
  const { getCartItemsInterface } = useShoppingCartInterface.useCartItems();
 

  useEffect(() => {
    // getCartItems();
    getCartItemsInterface();
  }, [activeLocation]);

  return (
    <Container fluid={true} className='main-app p-0'>
      <Row className='m-0'>
        <Sidebar />
        <MainAppRouter isSidebarOpen={isSidebarOpen} />
      </Row>
    </Container>
  );
};

export default MainApp;