import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Card, Nav, NavItem, NavLink, TabContent, TabPane, Input } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { MdEdit } from 'react-icons/md';
import CreatableSelect from 'react-select/creatable';
import numeral from 'numeral';
import qs from 'query-string';
import * as $ from 'jquery';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import noImage from '../../../../../assets/images/no-image.png';
import searchBox from '../../../../../assets/images/search-box.png';
import accountingApi from '../../../../../api/accountingApi';

const ItemsAndDistributors = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const filters = useSelector(({items}) => items.list.filters);

  const [tab, setTab] = useState('items');

  const [distributors, setDistributors] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [codes, setCodes] = useState([]);

  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);

  const urlObj = qs.parseUrl(history.location.search);
  let search = urlObj && urlObj.query && urlObj.query.query;

  const getDistributors = () => {
    accountingApi.getDistributors()
      .then(({data}) => {
        setDistributors(data.map((distributor) => {
          distributor.editTermsMode = false;
          if (!distributor.payableTerms) {
            distributor.payableTerms = '';
          }
          return distributor;
        }));
      })
      .catch(error => console.log('accountingApi getDistributors Error:', error));
  };

  const getSuppliers = () => {
    accountingApi.getSuppliers()
      .then(({data}) => {
        data.unshift({id:null, name:'-- None --'});
        setSuppliers(data.map((supplier) => {
          supplier.name = '' + supplier.name;
          return supplier;
        }));
      })
      .catch(error => console.log('accountingApi getSuppliers Error:', error));
  };

  const getCategories = () => {
    accountingApi.getCategories()
      .then(({data}) => {
        data.unshift({id:null, name:'-- None --'});
        setCategories(data.map((category) => {
          category.name = '' + category.name;
          return category;
        }));
      })
      .catch(error => console.log('accountingApi getCategories Error:', error));
  };

  const getGlCodes = () => {
    accountingApi.getGlCodes()
      .then(({data}) => {
        data.unshift({id:null, code:'-- None --'});
        setCodes(data.map((code) => {
          code.code = '' + code.code;
          return code;
        }));
      })
      .catch(error => console.log('accountingApi getGlCodes Error:', error));
  };

  const getItems = (newPage) => {
    if (isLoading) return;

    let filterOptions = {
      sort: {
        id: 5,
        name: 'frequent',
        type: 1,
        desc: 'Most Purchased'
      },
      product: 'ALL'
    };

    filterOptions = search ? {search, ...filterOptions, ...filters} : {...filterOptions, ...filters};

    setIsLoading(true);
    setPage(newPage);
    accountingApi.getItems(filterOptions, newPage).then(res => {
      if (newPage > 1) {
        setItems(items.concat(res.data));
      } else {
        setItems(res.data);
      }
      setLoadMore(res._meta.pageCount > res._meta.currentPage);
      setIsLoading(false);
    })
    .catch(error => console.log('accountingApi getItems Error:', error));
  };

  const editTerms = (i, distributor) => {
    setDistributors(distributors.map((currentDistributor, j) => {
      if (i == j) {
        currentDistributor.editTermsMode = true;
      } else {
        currentDistributor.editTermsMode = false;
      }
      return currentDistributor;
    }));
  };

  useLayoutEffect(() => {
    distributors.forEach((currentDistributor) => {
      if (currentDistributor.editTermsMode === true) {
        $('#terms-'+currentDistributor.id).focus();
      }
    });
  }, [distributors]);

  const setPayableTerms = (evt, i, distributor) => {
    let newValue;
    if (evt && evt.currentTarget) {
      newValue = evt.currentTarget.value ? evt.currentTarget.value : '';
    } else {
      newValue = evt ? evt : '';
    }
    setDistributors(distributors.map((currentDistributor, j) => {
      if (i == j) {
        currentDistributor.payableTerms = newValue;
      }
      return currentDistributor;
    }));
  };

  const updateTerms = (i, distributor) => {
    if (!distributor.payableTerms || !Number(distributor.payableTerms)) {
      return;
    }
    accountingApi.updateDistributorPayableTerms(distributor.id, distributor.payableTerms).then(res => {
      setDistributors(distributors.map((currentDistributor, j) => {
        currentDistributor.editTermsMode = false;
        return currentDistributor;
      }));
    })
    .catch(error => console.log('accountingApi updateDistributorPayableTerms Error:', error));
  };

  //Supplier
  const editSupplier = (i, item) => {
    setItems(items.map((currentItem, j) => {
      if (i == j) {
        currentItem.editSupplierMode = true;
      } else {
        currentItem.editSupplierMode = false;
      }
      currentItem.editCategoryMode = false;
      currentItem.editCodeMode = false;
      return currentItem;
    }));
  };

  // useLayoutEffect(() => {
  //   items.forEach((currentItem) => {
  //     if (currentItem.editSupplierMode === true) {
  //       $('#code-'+currentItem.id).focus();
  //     }
  //   });
  // }, [items]);

  const updateSupplier = (evt, i, item) => {
    // if (!item.id) {
    //   return;
    // }

    const supplier = suppliers.find(supplier => supplier.name == evt.value);
    if (!supplier) {
      return;
    }

    if (item.accountingId) {
      const params = {
        merchant_accounting_supplier_id: supplier.id,
        item_distributor_id: item.itemDistributorId
      };
      accountingApi.updateMerchantAccounting(item.accountingId, params).then(res => {
        setItems(items.map((currentItem, j) => {
          currentItem.editSupplierMode = false;
          if (i == j) {
            currentItem.accountingSupplier = supplier.name;
          }
          return currentItem;
        }));
      })
      .catch(error => console.log('accountingApi updateMerchantAccounting Error:', error));
    } else {
      const params = {
        merchant_accounting_supplier_id: supplier.id,
        item_distributor_id: item.itemDistributorId
      };
      accountingApi.addMerchantAccounting(params).then(({data}) => {
        setItems(items.map((currentItem, j) => {
          currentItem.editSupplierMode = false;
          if (i == j) {
            currentItem.accountingId = data.id;
            currentItem.accountingSupplier = supplier.name;
          }
          return currentItem;
        }));
      })
      .catch(error => console.log('accountingApi addMerchantAccounting Error:', error));
    }

  };

  const addSupplier = (text, i, item) => {
    if (text.trim().length === 0) {
      return;
    }

    const name = text.trim();
    const exists = suppliers.some((supplier) => {
      return supplier.name.toLowerCase() == name.toLowerCase();
    });

    if (exists) {
      return;
    }

    accountingApi.addSupplier(name).then(({data}) => {
      data.name = '' + data.name;
      setSuppliers([...suppliers, data]);

      if (item.accountingId) {
        const params = {
          merchant_accounting_supplier_id: data.id,
          item_distributor_id: item.itemDistributorId
        };
        accountingApi.updateMerchantAccounting(item.accountingId, params).then(res => {
          setItems(items.map((currentItem, j) => {
            currentItem.editSupplierMode = false;
            currentItem.editCategoryMode = false;
            currentItem.editCodeMode = false;
            if (i == j) {
              currentItem.accountingSupplier = data.name;
            }
            return currentItem;
          }));
        })
        .catch(error => console.log('accountingApi updateMerchantAccounting Error:', error));
      } else {
        const params = {
          merchant_accounting_supplier_id: data.id,
          item_distributor_id: item.itemDistributorId
        };
        accountingApi.addMerchantAccounting(params).then((res) => {
          setItems(items.map((currentItem, j) => {
            currentItem.editSupplierMode = false;
            currentItem.editCategoryMode = false;
            currentItem.editCodeMode = false;
            if (i == j) {
              currentItem.accountingId = data.id;
              currentItem.accountingSupplier = data.name;
            }
            return currentItem;
          }));
        })
        .catch(error => console.log('accountingApi addMerchantAccounting Error:', error));
      }

    })
    .catch(error => console.log('accountingApi addSupplier Error:', error));

  };

  //GL Code
  const editCode = (i, item) => {
    setItems(items.map((currentItem, j) => {
      if (i == j) {
        currentItem.editCodeMode = true;
      } else {
        currentItem.editCodeMode = false;
      }
      currentItem.editSupplierMode = false;
      currentItem.editCategoryMode = false;
      return currentItem;
    }));
  };

  // useLayoutEffect(() => {
  //   items.forEach((currentItem) => {
  //     if (currentItem.editCodeMode === true) {
  //       $('#code-'+currentItem.id).focus();
  //     }
  //   });
  // }, [items]);

  const updateCode = (evt, i, item) => {
    // if (!item.id) {
    //   return;
    // }

    const code = codes.find(code => code.code == evt.value);
    if (!code) {
      return;
    }

    if (item.accountingId) {
      const params = {
        merchant_accounting_gl_code_id: code.id,
        item_distributor_id: item.itemDistributorId
      };
      accountingApi.updateMerchantAccounting(item.accountingId, params).then(res => {
        setItems(items.map((currentItem, j) => {
          currentItem.editCodeMode = false;
          if (i == j) {
            currentItem.accountingCode = code.code;
          }
          return currentItem;
        }));
      })
      .catch(error => console.log('accountingApi updateMerchantAccounting Error:', error));
    } else {
      const params = {
        merchant_accounting_gl_code_id: code.id,
        item_distributor_id: item.itemDistributorId
      };
      accountingApi.addMerchantAccounting(params).then(({data}) => {
        setItems(items.map((currentItem, j) => {
          currentItem.editCodeMode = false;
          if (i == j) {
            currentItem.accountingId = data.id;
            currentItem.accountingCode = code.code;
          }
          return currentItem;
        }));
      })
      .catch(error => console.log('accountingApi addMerchantAccounting Error:', error));
    }

  };

  const addCode = (text, i, item) => {
    if (text.trim().length === 0) {
      return;
    }

    const name = text.trim();
    const exists = codes.some((code) => {
      return code.code.toLowerCase() == name.toLowerCase();
    });

    if (exists) {
      return;
    }

    accountingApi.addGlCode(name).then(({data}) => {
      data.code = '' + data.code;
      setCodes([...codes, data]);

      if (item.accountingId) {
        const params = {
          merchant_accounting_gl_code_id: data.id,
          item_distributor_id: item.itemDistributorId
        };
        accountingApi.updateMerchantAccounting(item.accountingId, params).then(res => {
          setItems(items.map((currentItem, j) => {
            currentItem.editSupplierMode = false;
            currentItem.editCategoryMode = false;
            currentItem.editCodeMode = false;
            if (i == j) {
              currentItem.accountingCode = data.code;
            }
            return currentItem;
          }));
        })
        .catch(error => console.log('accountingApi updateMerchantAccounting Error:', error));
      } else {
        const params = {
          merchant_accounting_gl_code_id: data.id,
          item_distributor_id: item.itemDistributorId
        };
        accountingApi.addMerchantAccounting(params).then(({data}) => {
          setItems(items.map((currentItem, j) => {
            currentItem.editSupplierMode = false;
            currentItem.editCategoryMode = false;
            currentItem.editCodeMode = false;
            if (i == j) {
              currentItem.accountingId = data.id;
              currentItem.accountingCode = data.code;
            }
            return currentItem;
          }));
        })
        .catch(error => console.log('accountingApi addMerchantAccounting Error:', error));
      }
    })
    .catch(error => console.log('accountingApi addGlCode Error:', error));

  };

  //Category
  const editCategory = (i, item) => {
    setItems(items.map((currentItem, j) => {
      if (i == j) {
        currentItem.editCategoryMode = true;
      } else {
        currentItem.editCategoryMode = false;
      }
      currentItem.editSupplierMode = false;
      currentItem.editCodeMode = false;
      return currentItem;
    }));
  };

  // useLayoutEffect(() => {
  //   items.forEach((currentItem) => {
  //     if (currentItem.editCategoryMode === true) {
  //       $('#code-'+currentItem.id).focus();
  //     }
  //   });
  // }, [items]);

  const updateCategory = (evt, i, item) => {
    // if (!item.id) {
    //   return;
    // }

    const category = categories.find(category => category.name == evt.value);
    if (!category) {
      return;
    }

    if (item.accountingId) {
      const params = {
        merchant_accounting_category_id: category.id,
        item_distributor_id: item.itemDistributorId
      };
      accountingApi.updateMerchantAccounting(item.accountingId, params).then(res => {
        setItems(items.map((currentItem, j) => {
          currentItem.editCategoryMode = false;
          if (i == j) {
            currentItem.accountingCategory = category.name;
          }
          return currentItem;
        }));
      })
      .catch(error => console.log('accountingApi updateMerchantAccounting Error:', error));
    } else {
      const params = {
        merchant_accounting_category_id: category.id,
        item_distributor_id: item.itemDistributorId
      };
      accountingApi.addMerchantAccounting(params).then(({data}) => {
        setItems(items.map((currentItem, j) => {
          currentItem.editCategoryMode = false;
          if (i == j) {
            currentItem.accountingId = data.id;
            currentItem.accountingCategory = category.name;
          }
          return currentItem;
        }));
      })
      .catch(error => console.log('accountingApi addMerchantAccounting Error:', error));
    }

  };

  const addCategory = (text, i, item) => {
    if (text.trim().length === 0) {
      return;
    }

    const name = text.trim();
    const exists = categories.some((category) => {
      return category.name.toLowerCase() == name.toLowerCase();
    });

    if (exists) {
      return;
    }

    accountingApi.addCategory(name).then(({data}) => {
      data.name = '' + data.name;
      setCategories([...categories, data]);

      if (item.accountingId) {
        const params = {
          merchant_accounting_category_id: data.id,
          item_distributor_id: item.itemDistributorId
        };
        accountingApi.updateMerchantAccounting(item.accountingId, params).then(res => {
          setItems(items.map((currentItem, j) => {
            currentItem.editSupplierMode = false;
            currentItem.editCategoryMode = false;
            currentItem.editCodeMode = false;
            if (i == j) {
              currentItem.accountingCategory = data.name;
            }
            return currentItem;
          }));
        })
        .catch(error => console.log('accountingApi updateMerchantAccounting Error:', error));
      } else {
        const params = {
          merchant_accounting_category_id: data.id,
          item_distributor_id: item.itemDistributorId
        };
        accountingApi.addMerchantAccounting(params).then(({data}) => {
          setItems(items.map((currentItem, j) => {
            currentItem.editSupplierMode = false;
            currentItem.editCategoryMode = false;
            currentItem.editCodeMode = false;
            if (i == j) {
              currentItem.accountingId = data.id;
              currentItem.accountingCategory = data.name;
            }
            return currentItem;
          }));
        })
        .catch(error => console.log('accountingApi addMerchantAccounting Error:', error));
      }
    })
    .catch(error => console.log('accountingApi addCategory Error:', error));

  };

  const scrollListener = () => {
    if (tab == 'distributors') {
      return;
    }

    const threshold = 600;
    if (window.innerHeight + document.documentElement.scrollTop + threshold >= document.documentElement.offsetHeight
      && loadMore && !isLoading) {
        getItems(page+1);
    }
  };

  useEffect(() => {
    window.addEventListener('mousewheel', scrollListener);
    window.addEventListener('scroll', scrollListener);
    window.addEventListener('resize', scrollListener);

    return () => {
      window.removeEventListener('mousewheel', scrollListener);
      window.removeEventListener('scroll', scrollListener);
      window.removeEventListener('resize', scrollListener);
    };
  }, [tab, page, loadMore, isLoading]);

  useEffect(() => {
    getItems(1);
    getDistributors();
    getSuppliers();
    getCategories();
    getGlCodes();
  }, []);

  useEffect(() => {
    getItems(1);
  }, [history.location.search, filters]);

  return (
    <Container fluid={true} className='accounting my-4'>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={'clickable' + (tab === 'items' ? ' active' : '')}
            onClick={() => setTab('items') }
          >
            Items
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={'clickable' + (tab === 'distributors' ? ' active' : '')}
            onClick={() => setTab('distributors') }
          >
            Distributors
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={tab}>
        <TabPane tabId='items'>
          <Row className='my-3 text-truncate' style={{display: 'flex', alignItems: 'center'}}>
            <Col xs={1} className='p-0'></Col>
            <Col xs={2} className='grey-text'>Description</Col>
            <Col xs={1} className='grey-text'>Brand</Col>
            <Col xs={1} className='grey-text'>Distributor</Col>
            <Col xs={1} className='grey-text'>Price</Col>
            <Col xs={1} className='grey-text'>UOM</Col>
            <Col xs={1} className='grey-text' style={{flex: '0 0 13%', maxWidth: '13%'}}>Supplier</Col>
            <Col xs={2} className='grey-text' style={{flex: '0 0 13%', maxWidth: '13%'}}>Category</Col>
            <Col xs={2} className='grey-text' style={{flex: '0 0 13%', maxWidth: '13%'}}>GL Code</Col>
          </Row>

          {
            items.map((item, index) => {
              return (
                <Card id={item.id} key={'accounting-item-' + index + item.accountingId} className='py-3 rounded-0 border-top-transparent border-bottom'>
                  <Row ng-classname="{'bottom-border-none': item.showSavings, 'dim': item.isPunchedOut}">
                    <Col xs={1}>
                      <div className='d-flex align-items-center justify-content-center'>
                        <img className='no-image' src={item.imageUrl || item.brandImageUrl || noImage} />
                      </div>
                    </Col>
                    <Col xs={2}>
                      <ShowIf show={!item.description}><div className='text-truncate text-capitalize' title={item.name}>{item.name}</div></ShowIf>
                      <ShowIf show={item.description}><div className='text-truncate text-capitalize' title={item.description}>{item.description}</div></ShowIf>
                      <Col className='additional-info p-0'>
                        <Col className='p-0 text-truncate'>{item.din || '&nbsp;'} </Col>
                      </Col>
                    </Col>
                    <Col xs={1}>
                      <div className='text-truncate'>{item.brand || ''}</div>
                    </Col>
                    <Col xs={1}>
                      <div className='text-truncate'>{item.distributor ||''}</div>
                    </Col>
                    <Col xs={1}>
                      <ShowIf show={item.isCatchWeight !== 1 && item.isFixedWeight !== 1 && item.isMarketPrice !== 1 && item.price !== 0 && item.price !== null}>
                        <div className='price text-truncate'>
                          {numeral(item.price).format('$0,0.00')} {item.unitMeasure}
                        </div>
                      </ShowIf>
                      <ShowIf show={item.isMarketPrice === 1 || item.price === 0 || item.price === null}>
                        <div className='price'>
                          <span className='market-price'>MKT</span> &nbsp;&nbsp;<span>{item.unitMeasure}</span>
                        </div>
                      </ShowIf>
                      <ShowIf show={item.isCatchWeight === 1 && item.isMarketPrice !== 1}>
                      <div className='price text-truncate'>
                        <ShowIf show={item.unitPrice}><span>{numeral(item.price).format('$0,0.00')} {item.packSizeUom}</span></ShowIf>
                        <span >(~ {numeral(item.price).format('$0,0.00')} {item.unitMeasure})</span>
                      </div>
                      </ShowIf>
                      <ShowIf show={item.isFixedWeight === 1 && item.isMarketPrice !== 1}>
                        <div className='price text-truncate'>
                          <ShowIf show={item.isMarketPrice !== 1}>
                            <span className='catch-price grey-text'>({numeral(item.price).format('$0,0.00')} {item.unitMeasure})</span>
                          </ShowIf>
                        </div>
                      </ShowIf>
                    </Col>
                    <Col xs={1}>
                      <div className='price text-truncate'>
                        <ShowIf show={item.isCatchWeight !== 1 && item.isFixedWeight !== 1}>{item.packSize || ''}</ShowIf>
                      </div>
                      <div className='price text-truncate'>
                        <ShowIf show={item.isCatchWeight === 1 && item.isMarketPrice !== 1}>{item.packSize || ''}</ShowIf>
                      </div>
                      <div className='price text-truncate'>
                        <ShowIf show={item.isFixedWeight === 1 && item.isMarketPrice !== 1}>{item.packSize || ''}</ShowIf>
                      </div>
                    </Col>
                    <Col xs={1} style={{flex: '0 0 13%', maxWidth: '13%', paddingRight: '0px'}}>
                      <ShowIf show={!item.editSupplierMode}>
                        <div className='text-wrap'>
                          <span><MdEdit className='clickable' onClick={() => editSupplier(index, item)} /></span>
                          {item.accountingSupplier || ''}
                        </div>
                      </ShowIf>
                      <ShowIf show={item.editSupplierMode}>
                        <CreatableSelect
                          onChange={(evt) => updateSupplier(evt, index, item)}
                          onCreateOption={(text) => addSupplier(text, index, item)}
                          options={suppliers.map((supplier) => {return {label: supplier.name, value: supplier.name}})}
                          value={item.accountingSupplier}
                        />
                      </ShowIf>
                    </Col>
                    <Col xs={2} style={{flex: '0 0 13%', maxWidth: '13%', paddingRight: '0px'}}>
                      <ShowIf show={!item.editCategoryMode}>
                        <div className='text-wrap'>
                          <span><MdEdit className='clickable' onClick={() => editCategory(index, item)} /></span>
                          {item.accountingCategory || ''}
                        </div>
                      </ShowIf>
                      <ShowIf show={item.editCategoryMode}>
                        <CreatableSelect
                          onChange={(evt) => updateCategory(evt, index, item)}
                          onCreateOption={(text) => addCategory(text, index, item)}
                          options={categories.map((category) => {return {label: category.name, value: category.name}})}
                          value={item.accountingCategory}
                        />
                      </ShowIf>
                    </Col>
                    <Col xs={2} style={{flex: '0 0 13%', maxWidth: '13%'}}>
                      <ShowIf show={!item.editCodeMode}>
                        <div className='text-wrap'>
                          <span><MdEdit className='clickable' onClick={() => editCode(index, item)} /></span>
                          {item.accountingCode || ''}
                        </div>
                      </ShowIf>
                      <ShowIf show={item.editCodeMode}>
                        <CreatableSelect
                          onChange={(evt) => updateCode(evt, index, item)}
                          onCreateOption={(text) => addCode(text, index, item)}
                          options={codes.map((code) => {return {label: code.code, value: code.code}})}
                          value={item.accountingCode}
                        />
                      </ShowIf>
                    </Col>
                  </Row>
                </Card>
              );
            })
          }

          <ShowIf show={!isLoading && items && items.length === 0} className='mt-5'>
            <Col xs={2}>
              <img src={searchBox} className='img-fluid' />
            </Col>
            <Col xs={1} className='supporting-text' style={{color: '#143357'}}>
              <p>Sorry, there are no exact match results found</p>
              <p>Search Suggestions</p>
              <ul>
                <li>Check your spelling</li>
                <li>Try to use less specific words</li>
              </ul>
            </Col>
          </ShowIf>
        </TabPane>

        <TabPane tabId='distributors'>
          <Row className='my-3 text-truncate grey-text'>
            <Col xs={6} className='pl-4'>Name</Col>
            <Col xs={6}>Payable Terms</Col>
          </Row>
          <div id='distributors_container'>
            {
              distributors.map((distributor, index) => {
                return (
                  <Card key={distributor.id} id={distributor.id} className='p-3 rounded-0 border-top-transparent border-bottom'>
                    <Row>
                      <Col xs={6}>
                        <div className='text-truncate text-capitalize'>{distributor.nickname}</div>
                      </Col>
                      <Col xs={6}>
                        <ShowIf show={!distributor.editTermsMode}>
                          <div className='text-truncate'>
                            {(distributor.payableTerms ? distributor.payableTerms + ' days' : '')}
                            <span><MdEdit className='clickable' onClick={() => editTerms(index, distributor)} /></span>
                          </div>
                        </ShowIf>
                        <ShowIf show={distributor.editTermsMode}>
                          <div className='form-group m-0'>
                            <Input id={'terms-'+distributor.id} type='number' value={distributor.payableTerms} onChange={(evt) => setPayableTerms(evt, index, distributor)} onBlur={() => updateTerms(index, distributor)} />
                          </div>
                        </ShowIf>
                      </Col>
                    </Row>
                  </Card>
                );
              })
            }
          </div>
        </TabPane>
      </TabContent>
    </Container>
  );
};

export default ItemsAndDistributors;
