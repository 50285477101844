import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Container } from 'reactstrap';
import Header from '../../../../SharedComponents/Header/Header';
import CardsList from './CardsList/CardsList';
import CardForm from './CardForm/CardForm';
import './ManageCards.scss';

const ManageCards = () => {
  return (
    <Switch>
      <Route exact path='/manage-cards'>
        <Container fluid={true} className='m-0 p-0'>
          <Header showMenu={false} showLogo={false} showBack={true} title='Credit Card List' showCart={false} />
          <CardsList />
        </Container>
      </Route>
      <Route exact path='/manage-cards/add-card'>
        <Container fluid={true} className='m-0 p-0'>
          <Header showMenu={true} showLogo={false} showBack={false} title='Add Credit Card' showCart={false} />
          <CardForm />
        </Container>
      </Route>
      <Route exact path='/manage-cards/update-card/:id'>
        <Container fluid={true} className='m-0 p-0'>
          <Header showMenu={true} showLogo={false} showBack={false} title='Update Credit Card' showCart={false} />
          <CardForm />
        </Container>
      </Route>
      <Route>
        <Redirect to='/manage-cards' />
      </Route>
    </Switch>
  );
};

export default ManageCards;
