import React, { useState, useEffect, useRef } from 'react'
import { colors } from '../../../../../config/constants';
import { Row, Col, Button, Card, CardBody, Collapse, Progress, CardHeader, Input } from 'reactstrap'
import { FaLink, FaClock, FaTrash } from 'react-icons/fa';
import InputCounter from '../../../../../SharedComponents/InputCounter/InputCounter';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import noImage from '../../../../../assets/images/no-image.png';
import specialRequestImg from '../../../../../assets/images/special-request.png';
import { FaExclamation } from 'react-icons/fa';
import IconBadge from '../../../../../SharedComponents/IconBadge/IconBadge';
import numeral from 'numeral';
import ReviewOrderCard from './ReviewOrderCard';
import { isMobile } from "react-device-detect";
import { FaMoneyBill } from 'react-icons/fa';
import useReviewOrder from './useReviewOrder';
import { useDispatch } from 'react-redux';
import CountModal from "../../../../../SharedComponents/Modals/CountModal/CountModal";
import { showModal } from '../../../../../reducers/modals/modalsActions';
import { saveHeaderIndexRevOrder } from '../../../../../reducers/header/headerActions';
import { setValuesForCheckout } from '../../../../../reducers/reviewOrder/reviewOrderActions';
import SimpleModal from "../../../../../SharedComponents/Modals/SimpleModal/SimpleModal";
import ReviewOrderRowMobile from './ReviewOrderRowMobile';
import QuestionModal from '../../../../../SharedComponents/Modals/QuestionModal/QuestionModal';
import { SwipeableList, SwipeableListItem } from '@sandstreamdev/react-swipeable-list';
import '@sandstreamdev/react-swipeable-list/dist/styles.css';
import { useHistory } from 'react-router-dom';



const ReviewOrderRow = (props) => {
    const { listCartItem, changeShowDetail, goToItemsDistributor, updateListCart, displayComparisonView } = props;
    const [priceTotalCard, setpriceTotalCard] = useState(0);
    const [priceTotalCardR, setPriceTotalCardR] = useState(0);
    const [listCartItemH, setListCartItemH] = useState(listCartItem)
    const [totalItem, setTotalItem] = useState(0);
    const [progressValue, setProgressValue] = useState(0);
    const [optionsDeliveryDate, setoptionsDeliveryDates] = useState([])
    const [updateByModal, setUpdateByModal] = useState()
    const history = useHistory();

    const dispatch = useDispatch();

    const { dataDistributor } = useReviewOrder.useDataForDistributor(listCartItem[0].distributorId);

    const useUpdateCart = useReviewOrder.useUpdateCart();

    const changeValue = (newCaseQuantity, shoppingCartId) => {

        let priceAux = 0;
        let totalItemAux = 0;
        const listCartItemA = listCartItemH
            .map((res) => {
                if (res.shoppingCartId === shoppingCartId) {
                    res.caseQuantity = newCaseQuantity;
                    res['priceTotalRow'] = numeral(res.caseQuantity * res.price).format('$0,0.00');
                }
                priceAux += (res.caseQuantity * res.price);
                totalItemAux += (res.caseQuantity)
                return res;
            });

        let priceFormat = numeral(priceAux).format('$0,0.00')
        setTotalItem(totalItemAux);
        setpriceTotalCard(priceFormat);
        setPriceTotalCardR(priceAux);
        setListCartItemH(listCartItemA);
    }


    const calculateTotal = () => {
        let priceAux = 0;
        let totalItemAux = 0;
        listCartItemH.forEach((res) => {
            priceAux += (res.caseQuantity * res.price);
            res['priceTotalRow'] = numeral(res.caseQuantity * res.price).format('$0,0.00');
            totalItemAux += (res.caseQuantity);
        });
        let priceFormat = numeral(priceAux).format('$0,0.00')
        setpriceTotalCard(priceFormat)
        setPriceTotalCardR(priceAux);
        setTotalItem(totalItemAux);
    }

    useEffect((res) => {
        calculateTotal();
    }, [])


    const text = `This item was added as a special request. It is not guaranteed that the distributor will be able to deliver this item. Please contact your distributor directly to confirm availability`

    const getClassName = (isSpecialItem) => {
        if (isSpecialItem) {
            return 'details special'
        } else {
            return 'details'
        }
    }

    const getClassNameRow = (isSpecialItem) => {
        if (isMobile && isSpecialItem) {
            return 'contentDetails mobile isEspecial'
        } else if (isMobile && !isSpecialItem) {
            return 'contentDetails mobile'
        } else if (!isMobile) {
            return 'contentDetails m-0'
        }
    }


    const goToDetail = (cartItem) => {
        changeShowDetail(cartItem);
    }

    const openChangeCant = (cartItem) => {
        const modalProps = {
            defaultValue: cartItem.caseQuantity,
            menu: true,
            description: cartItem.isSpecialItem ?
                cartItem.description.charAt(0).toUpperCase() + cartItem.description.slice(1) :
                cartItem.name.charAt(0).toUpperCase() + cartItem.name.slice(1),
            title: `Edit quantity`,
            onSave: (newQuantity) => {
                changeValue(newQuantity, cartItem.shoppingCartId)
                useUpdateCart.useUpdateCartAmount(newQuantity, cartItem, null);
                // const params = {
                //     parLevel: newParLevel
                // };
                // comparisonViewsApi.patchGroup(comparisonViewId, groupId, params).then(({ data }) => {
                //     dispatch(editOneInventoryGroup(groupId, params));
                // }).catch(err => console.error(err));
            }
        };

        dispatch(showModal(CountModal, modalProps));
    }
    const diffMinOrderAmount = () => {
        const minOrder = dataDistributor.minOrderAmount;
        const priceTotal = parseFloat(priceTotalCard.replace('$', '').replace(',', ''));
        return numeral(minOrder - priceTotal).format('$0,0.00');
    }

    const validateProgress = () => {

        if (dataDistributor)
            if (dataDistributor.skipMinOrder) {
                return true;
            } else {
                if (dataDistributor.isMinOrderAmountRequired == true ||
                    dataDistributor.isMinOrderAmountRequired == 1) {
                    return true;
                } else {
                    return false;
                }
            }
    }

    const validateShowByList = () => {
        let count = listCartItemH.filter(res => res.caseQuantity > 0);
        if (count.length == 0) {
            return false;
        }
        return true;
    }

    const validationMinimumOrderAmoun = () => {
        let value = false;
        if (!listCartItem[0].isSpecialItem) {
            if (validateProgress()) {
                value = progressValue >= 100 ? false : true;
                if (value) {
                    const props = {
                        header: `Price below $${dataDistributor.minOrderAmount}`,
                        message: `<p style="font-size : 13px">Minimum price required for placing order is ${dataDistributor.minOrderAmount} !</p>`,
                    };
                    dispatch(showModal(SimpleModal, props));
                    return false;
                };
            };
        };
        return true;
    };

    const goToCheckout = () => {

        // Validation minimun order amount
        const valResult = validationMinimumOrderAmoun();

        //
        if (valResult) {
            const valuesCheckout = {
                cartList: listCartItemH,
                dataDistributor: dataDistributor,
                priceTotalCard: {
                    priceFormat: priceTotalCard,
                    priceReal: priceTotalCardR
                }
            };

            dispatch(setValuesForCheckout(valuesCheckout));
            // dispatch(saveHeaderIndexRevOrder(3));
            history.push('/checkout-ordering/shipping-payment')
            dispatch(saveHeaderIndexRevOrder(5));
        };

    };

    const resetCant = (item) => {
        const qProps = {
            message: `Are you sure you want to remove this item?`,
            leftButton: {
                title: 'Delete',
                color: 'orange',
                onClick: () => updateCart(0, item)
            },
            rightButton: {
                title: 'Cancel',
                color: 'green',
                // onClick: () => goBack()
            }
        };
        dispatch(showModal(QuestionModal, qProps));
    }



    const updateCart = (newCaseQuantity, cartItem) => {
        changeValue(newCaseQuantity, cartItem.shoppingCartId)
        useUpdateCart.useUpdateCartAmount(newCaseQuantity, cartItem, null);
    };

    const contentList = listCartItemH.map((cartItem, index) => {
        let newImage = cartItem.imageUrl ? cartItem.imageUrl : (cartItem.brandImageUrl ? cartItem.brandImageUrl : null);
        // cartItem.isPunchedOut = 1;
        return (
            cartItem.caseQuantity ?
                <Row key={'keyROrow-' + index}>
                    <Col>
                        <Card style={{
                            border: cartItem.isSpecialItem ? '1px solid #6ed79a' : '',
                            backgroundColor: cartItem.isPunchedOut == 1 ? '#eef2f5' : ''
                        }} >
                            <ShowIf show={cartItem.isSpecialItem}>
                                <img className='specialImg' src={specialRequestImg}></img>
                            </ShowIf>

                            <Row className={getClassNameRow(cartItem.isSpecialItem)}>

                                <ShowIf show={!isMobile}>
                                    {/* <Col xs='1' className='content-align-center'>
                                </Col> */}
                                    <Col className='content-align-center w-fc' style={{ maxWidth: '226px' }}>
                                        <InputCounter
                                            item={cartItem}
                                            value={cartItem.caseQuantity}
                                            unitM={cartItem.unitMeasure}
                                            indexCart={cartItem.shoppingCartId}
                                            changeValue={changeValue}
                                            updateByModal={updateByModal} />
                                    </Col>
                                    <Col className='clickable content-align-center w-image'>
                                        <img className='no-image' src={newImage ? newImage : noImage}></img>
                                    </Col>
                                    <Col xs='4' className={getClassName(cartItem.isSpecialItem) + ' clickable'} onClick={() => goToDetail(cartItem)}>
                                        <Row>
                                            <h3>{cartItem.isSpecialItem ? cartItem.description : cartItem.name}</h3>
                                        </Row>
                                        <ShowIf show={cartItem.isSpecialItem}>
                                            <Row>
                                                <Col>
                                                    <IconBadge idPop='RORPop1' text={text} FaIcon={FaExclamation} size={8} color={colors.white} />
                                                </Col>
                                            </Row>
                                        </ShowIf>
                                        <ShowIf show={!cartItem.isSpecialItem}>
                                            <Row>
                                                <p>{cartItem.din}</p>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <p>{cartItem.brand}</p>
                                                </Col>
                                                <Col>
                                                    <p>{cartItem.distributor}</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <h3>{cartItem.packSize}</h3>
                                            </Row>
                                        </ShowIf>
                                    </Col>
                                </ShowIf>

                                <ShowIf show={isMobile}>
                                    <SwipeableList className='swipeableList'>
                                        {({ className, ...rest }) => (
                                            <div className={className +
                                                (cartItem.isPunchedOut == 1 ? ' swipeContentPunchedOut' : ' swipeContent')}>
                                                <SwipeableListItem
                                                    swipeLeft={{
                                                        content: <div className='swipeText'>
                                                            <FaTrash className='clickable' color={colors.white} />
                                                            <h1>Delete</h1>
                                                        </div>,
                                                        action: () => resetCant(cartItem)
                                                    }}
                                                >
                                                    <Row style={{
                                                        backgroundColor: cartItem.isPunchedOut == 1 ? '#eef2f5' : ''

                                                    }}>
                                                        <Col xs='2' className='content-align-center pr-0'>
                                                            <p className='cantItem'>{cartItem.caseQuantity} {cartItem.unitMeasure}</p>
                                                        </Col>
                                                        <Col className='w-image-sm content-align-center' style={{ maxHeight: 'initial' }}>
                                                            <img className='no-image' src={newImage ? newImage : noImage}></img>
                                                        </Col>
                                                        <Col xs='4' className={getClassName(cartItem.isSpecialItem) + ' clickable'} onClick={() => openChangeCant(cartItem)}>
                                                            <Row className='height20'>
                                                                <h3>{cartItem.isSpecialItem ? cartItem.description : cartItem.name}</h3>
                                                            </Row>
                                                            <ShowIf show={cartItem.isSpecialItem}>
                                                                <Row>
                                                                    <Col>
                                                                        <IconBadge idPop='RORPop1' text={text} FaIcon={FaExclamation} size={8} color={colors.white} />
                                                                    </Col>
                                                                </Row>
                                                            </ShowIf>
                                                            <ShowIf show={!cartItem.isSpecialItem}>
                                                                <Row className='height20'>
                                                                    <p>{cartItem.din}</p>
                                                                </Row>
                                                                <Row className='height20'>
                                                                    <Col>
                                                                        <p>{cartItem.brand}</p>
                                                                    </Col>
                                                                </Row>
                                                                <Row className='height35'>
                                                                    <h3>{cartItem.packSize}</h3>
                                                                </Row>
                                                            </ShowIf>
                                                        </Col>
                                                        <div className={getClassName(cartItem.isSpecialItem) + ' floatRight'}
                                                            style={{ top: 13 }}>
                                                            <h3>{cartItem.priceTotalRow}</h3>
                                                            <ShowIf show={cartItem.belongsToGroup === 1 && displayComparisonView === 1}>
                                                                <ShowIf show={!cartItem.isSpecialItem}>
                                                                    <Button size='lg-circle' color='gray' onClick={() => goToItemsDistributor(cartItem)}>
                                                                        <FaLink color={colors.lightBlueText} size={20} style={{ transform: 'rotate(45deg)' }} />
                                                                    </Button>
                                                                </ShowIf>
                                                            </ShowIf>

                                                        </div>
                                                    </Row>




                                                </SwipeableListItem>
                                            </div>
                                        )}
                                    </SwipeableList>


                                </ShowIf>

                                <ShowIf show={!isMobile}>
                                    <div className={getClassName(cartItem.isSpecialItem) + ' floatRight'}>
                                        <h3>{cartItem.priceTotalRow}</h3>
                                        <ShowIf show={!isMobile && cartItem.belongsToGroup === 1 && displayComparisonView === 1}>

                                            <ShowIf show={!cartItem.isSpecialItem}>
                                                <Button size='lg-circle' color='gray' onClick={() => goToItemsDistributor(cartItem)}>
                                                    <FaLink color={colors.lightBlueText} size={20} style={{ transform: 'rotate(45deg)' }} />
                                                </Button>
                                            </ShowIf>
                                        </ShowIf>
                                    </div>
                                </ShowIf>





                                {/* <ShowIf show={cartItem.isPurchased}>
                                    <div className='headerIcon'>

                                        <span className={isMobile ? 'iconText iconTextM  left' : 'iconText left'} >LAST PURCHASED</span>
                                        <span className='textDate'>{cartItem.lastPurchasedQty} {cartItem.unitMeasure} ON {cartItem.lastPurchasedDate}</span>
                                    </div>

                                </ShowIf> */}
                            </Row>
                        </Card >
                    </Col >
                </Row > : ''


        )
    });



    useEffect(() => {

        if (dataDistributor) {
            const minOrder = dataDistributor.minOrderAmount;
            const priceTotal = parseFloat(priceTotalCard.replace('$', '').replace(',', ''));
            const valueProgress = minOrder > 0 ? ((priceTotal * 100) / minOrder) : 100;
            setProgressValue(valueProgress);
        }

    }, [priceTotalCard])



    useEffect(() => {

        if (dataDistributor) {
            const minOrder = dataDistributor.minOrderAmount;
            const priceTotal = parseFloat(priceTotalCard.replace('$', '').replace(',', ''));
            const valueProgress =  minOrder > 0 ? ((priceTotal * 100) / minOrder) : 100;
            setProgressValue(valueProgress);

            const options = dataDistributor.deliveryDates.map(res => {
                return {
                    value: res,
                    label: res
                }
            });

            setoptionsDeliveryDates(options);
        }
    }, [dataDistributor])




    return (

        <div>
            <ShowIf show={validateShowByList()}>
                <ShowIf show={!isMobile}>
                    <Row>
                        <Col xs="8">
                            <ShowIf show={!listCartItem[0].isSpecialItem}>
                                <ShowIf show={validateProgress()}>
                                    <ShowIf show={progressValue < 100}>
                                        <Card className="cardProgress">
                                            <div className='textProgress'>
                                                <FaMoneyBill />
                                                {dataDistributor ? diffMinOrderAmount() : ''} to reach minimum order amount
                        </div>
                                            <Progress value={progressValue} />
                                        </Card>
                                    </ShowIf>
                                </ShowIf>
                            </ShowIf>
                            {contentList}
                        </Col>
                        <Col>
                            <ReviewOrderCard
                                special={listCartItem[0].isSpecialItem}
                                priceTotal={priceTotalCard}
                                lineItems={listCartItemH.filter(res => res.caseQuantity > 0).length}
                                totalItems={totalItem}
                                distributorName={dataDistributor ? dataDistributor.nickname : ' ..'}
                                checkout={goToCheckout} />
                        </Col>
                    </Row>
                </ShowIf>
                <ShowIf show={isMobile}>
                    <ReviewOrderRowMobile
                        listCartItem={listCartItemH}
                        dataDistributor={dataDistributor}
                        priceTotalCard={priceTotalCard}
                        priceTotalCardR={priceTotalCardR}
                        validateProgress={validateProgress}
                        progressValue={progressValue}
                        diffMinOrderAmount={diffMinOrderAmount}
                        contentList={contentList}
                        optionsDeliveryDate={optionsDeliveryDate}
                        validationMinimumOrderAmoun={validationMinimumOrderAmoun}
                        updateListCart={updateListCart}
                    />
                </ShowIf>
            </ShowIf>


        </div>


    )
}

export default ReviewOrderRow;
