import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { mobileClassName } from '../../../../../config/constants';
import useCarList from './useCarList';

const CardsList = () => {
  const { cards, cardsMap } = useCarList.useApi();

  return (
    <Container fluid={true} className={'credit-card-list my-5 rounded' + mobileClassName}>
      {cardsMap}
      <Row>
        {
          isMobile ?
            <Col xs={{size: 12, offset: 0}}>
              <Link to='/manage-cards/add-card' className="btn btn-block btn-lg btn-green mt-4">Add Card</Link>
            </Col>
          :
            <Col xs={{size: 4, offset: 4}}>
              <Link to='/manage-cards/add-card' className="btn btn-block btn-lg btn-green mt-4">Add Card</Link>
            </Col>
        }
      </Row>
    </Container>
  );
};

export default CardsList;
