import { useSelector } from "react-redux";
import useInventoryList from './useInventoryList';
import useInventoryListOffline from './useInventoryListOffline';


const useApi = (setLists) => {
  const connection = useSelector(({connection}) => connection.online);

  const inventoryListOnline = useInventoryList.useApi(setLists);
  const inventoryListOffline = useInventoryListOffline.useApi(setLists);
  const { getLists } = connection ? inventoryListOnline : inventoryListOffline;

  return {
    getLists
  };
};

export default {
  useApi
};
