import React, { useMemo, useState } from 'react';
import { Card, Row, Col } from 'reactstrap';
import numeral from 'numeral';
import { FaAngleDown } from 'react-icons/fa';
import { colors } from '../../../../../config/constants';
import Select from 'react-select';


const PosDetailRow = (props) => {
  const item = props.children;
  const { listRecipes, changeRecipe } = props;
  const [itemSelect, setItemSelect] = useState(listRecipes.find(_i => _i.value === item.recipe_id));

  const onChangeSelect = (iSelect) => {
    changeRecipe(iSelect, item.item_name);
    setItemSelect(iSelect);
    // itemm.recipe_id = itemSelect.value;
    // setItemm(itemm);
  };

  const selectM = useMemo(() => {
    console.log('Entro')
    return (
      <Select
        onChange={onChangeSelect}
        className='fb-select pl-4 w-75 m-auto'
        placeholder='Select Recipe'
        value={itemSelect}
        options={listRecipes} />
    )
  }, [itemSelect])

  return (
    <Card className='pos-item'>
      <Row>
        <Col xs={3}>
          {item.item_name}
        </Col>
        <Col xs={2}>
          {item.pos_category}
        </Col>
        <Col xs={1} className='text-right'>
          {item.quantity_sold}
        </Col>
        <Col xs={1} className='text-right'>
          {item.quantity_void}
        </Col>
        <Col xs={1} className='text-right'>
          {item.quantity_net}
        </Col>
        <Col xs={3} className='text-center'>
          {selectM}
        </Col>
        <Col xs={1} className='text-right'>
          {numeral(item.price).format('$0,0.00')}
        </Col>
      </Row>
    </Card>
  );
};

export default PosDetailRow;
