import useInput from "../../SharedEffects/useInput";
import { useState, useEffect } from "react";
import recipeApi from "../../../api/recipeApi";
import useConvert from "../../Conversion/useConversion";

const useApi = (recipeId) => {
  const search = useInput.useText('');
  const [page, setPage] = useState(1);
  const [loadMore, setLoadMore] = useState(true);
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [adding, setAdding] = useState(false);
  const [addingItem, setAddingItem] = useState({ name: '', menu: { name: '' }, subRecipeYieldUom: '' });
  const [uom, setUom] = useState({ label: 'lb', value: 0 });
  const addItemForm = {
    recipeAmount: useInput.useText('', { validate: { fraction: { strict: false } } }),
    waste: useInput.useText('', { isNumber: true }),
    conversion: {
      item: {
        packSize: useInput.useText('', { validate: { fraction: { strict: false } } }),
        uom: addingItem.subRecipeYieldUom
      },
      recipe: {
        packSize: useInput.useText('', { validate: { fraction: { strict: false } } }),
        uom: uom.label
      }
    }
  };

  const toggleAdding = (i) => {
    if (!adding) {
      setAddingItem(i);
    }
    addItemForm.recipeAmount.setValue('');
    addItemForm.waste.setValue('');
    addItemForm.conversion.item.packSize.setValue('');
    addItemForm.conversion.recipe.packSize.setValue('');
    setUom({ label: 'lb', value: 0 });
    setAdding(!adding);
  };

  useEffect(() => {
    const canConvert = useConvert.useCanConvert(uom.label, addItemForm.conversion.item.uom);
    if (canConvert) {
      let itemVal = 1;
      let nVal = useConvert.useConvert(1, uom.label, addItemForm.conversion.item.uom);
      if (nVal < 1) {
        itemVal = useConvert.useConvert(1, addItemForm.conversion.item.uom, uom.label);
        nVal = 1;
      }
      addItemForm.conversion.item.packSize.setValue(nVal);
      addItemForm.conversion.recipe.packSize.setValue(itemVal);
    }
  }, [uom]);

  const getSubrecipes = (page, loadMoreForce, s) => {
    if (loadMore || loadMoreForce) {
      setIsLoading(true);
      recipeApi.getSubrecipes(recipeId, page, s).then(res => {
        setIsLoading(false);
        const loadMoreNew = res._meta.pageCount > res._meta.currentPage;
        const pageNew = loadMoreNew ? page + 1 : 1;
        setLoadMore(loadMoreNew);
        if (page === 1) {
          setItems(res.data);
        } else {
          setItems(items.concat(res.data));
        }
        setPage(pageNew);
      });
    }
  };

  const onClear = () => {
    search.clearValue();
    getSubrecipes(1, true);
    // setLoadMore(!loadMore);
    // setPage(1);
    // setIsLoading(false);
    // setItems([]);
    // // Set reset values, values get update in the next render
    // getSubrecipes(1, '', true);
  };

  const onSearch = (s) => {
    getSubrecipes(1, true, s);
    // setPage(1);
    // setLoadMore(!loadMore);
    // setLoadMore(true);
    // setIsLoading(false);
    // setItems([]);
    // // Set reset values, values get update in the next render
    // getSubrecipes(1, s, true);
  };

  useEffect(() => {
    getSubrecipes(1, '');
  }, []);

  return {
    page, items, getSubrecipes, loadMore,
    isLoading, onClear, onSearch, search, toggleAdding,
    adding, addingItem, addItemForm,
    uom: { set: setUom, value: uom },
    canConvert: useConvert.useCanConvert(uom.label, addItemForm.conversion.item.uom)
  };
};

export default {
  useApi
}
