

const useActions = () => {
  const identifyFullStory = (id, name, email) =>{
    if(window['_fs_namespace']){
      window[window['_fs_namespace']].identify(id,{
          displayName : name,
          email : email
      });
  }
  };

  return { identifyFullStory }
};

export default {
  useActions
};