import React from "react";
import "./InputCheckbox.scss";
import classNames from "classnames";
import { useState } from "react";

const InputCheckbox = (props) => {
  const className = props.className || "";
  const {
    value,
    active,
    undetermined,
    onClick,
    onChange,
    defaultChecked,
  } = props;

  return (
    <label className={"checkbox-container " + className}>
      {props.children}
      <input
        type="checkbox"
        onClick={(e) => {
          if (onClick) {
            onClick(e);
          }
        }}
        onChange={(e) => {
          if (onChange) {
            onChange(e);
          }
        }}
        {...{ value, defaultChecked, active }}
      />
      <span
        className={classNames("checkbox-checkmark", {
          undetermined: undefined !== undetermined && undetermined,
        })}
      />
    </label>
  );
};

const InputCheckboxControlled = (props) => {
  const className = props.className || "";
  const {
    value,
    active,
    undetermined,
    onClick,
    onChange,
    defaultChecked,
  } = props;

  return (
    <label className={"checkbox-container " + className}>
      {props.children}
      <input
        type="checkbox"
        onClick={(e) => {
          if (onClick) {
            onClick(e);
          }
        }}
        onChange={(e) => {
          if (onChange) {
            onChange(e);
          }
        }}
        checked={active}
        {...{ value, defaultChecked }}
      />
      <span
        className={classNames("checkbox-checkmark", {
          undetermined: undefined !== undetermined && undetermined,
        })}
      />
    </label>
  );
};

export default InputCheckbox;

export { InputCheckboxControlled };
