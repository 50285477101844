import React from 'react';
import { Col, Button } from 'reactstrap';
import RecipeInfoInput from './RecipeInfoInput';
import RecipeInfoTextarea from './RecipeInfoTextarea';
import Select from 'react-select';
import RecipeInfoSubrecipeInfo from './RecipeInfoSubrecipeInfo';
import ShowIf from '../../../../../../SharedComponents/ShowIf/ShowIf';
import { isMobile } from 'react-device-detect';

const RecipeInfo = (props) => {
  const {
    recipeName, menuPrice, recipeYield,
    recipeYieldUom, description, procedure, saveInfo,
    menuCategory, isSubrecipe, menuList: options, isRecipeInfoValid,
    create, createRecipe, error
  } = props;

  const subrecipeProps = {
    recipeYield, recipeYieldUom, isSubrecipe
  };

  const clickSave = () => {
    if (isRecipeInfoValid()) {
      const params = {
        name: recipeName.value,
        recipe_menu_category_id: menuCategory.value.value,
        price: menuPrice.value,
        description: description.value,
        procedure: procedure.value,
        is_sub_recipe: isSubrecipe.value,
        sub_recipe_yield: recipeYield.value,
        sub_recipe_yield_uom: recipeYieldUom.value,
      };

      if (create) {
        createRecipe(params, true, true);
      } else {
        saveInfo(params);
      }
    }
  };

  return (
    <ShowIf show={!isMobile}>
      <Col className='p-0 w-360px flex-column border-right pb-3 align-items-center'>
        <RecipeInfoInput type='text' label='Recipe Name' error={recipeName.error} onBlur={recipeName.onBlur} onFocus={recipeName.onFocus}
          set={recipeName.set} value={recipeName.value} placeholder='Recipe Name'
        />
        <Col className='text-label font-weight-bold mt-3 mb-1'>Menu Category</Col>
        <Col>
          <Select className='fb-select' classNamePrefix='select'
            onChange={menuCategory.set} value={menuCategory.value}
            options={options} isDisabled={create}
          />
        </Col>
        <ShowIf show={!isSubrecipe.value}>
          <RecipeInfoInput type='number' label='Menu Price $' error={menuPrice.error} onBlur={menuPrice.onBlur} onFocus={menuPrice.onFocus}
            set={menuPrice.set} value={menuPrice.value} placeholder='Menu Price'
          />
        </ShowIf>
        <RecipeInfoSubrecipeInfo {...subrecipeProps} />
        <RecipeInfoTextarea set={description.set} value={description.value} label='Description (Optional)' />
        <RecipeInfoTextarea set={procedure.set} value={procedure.value} label='Procedure (Optional)' />
        <Col>
          <Button onClick={clickSave} color='green'>{create ? 'Create' : 'Save'}</Button>
        </Col>
      </Col>
    </ShowIf>
  );
}

export default RecipeInfo;