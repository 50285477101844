import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import useModal from '../useModal';
import ShowIf from '../../ShowIf/ShowIf';

const AlertModal = (props) => {
  const { id, message, onOk, btnLabel, btnSize, footerClass, title, color } = props;
  const modalprops = useModal(id);
  const showTitle = title ? true : false;

  const onClickOk = () => {
    if (onOk) {
      onOk();
    };
    modalprops.toggle();
  };

  return (
    <Modal {...modalprops} backdrop='static' keyboard={false}>
      <ShowIf show={showTitle}>
        <ModalHeader className='text-center'>{title}</ModalHeader>
      </ShowIf>
      <ModalBody className='text-center'>
        {message}
      </ModalBody>
      <ModalFooter className={footerClass}>
        <div className="text-center w-100">
          <Button color={color} size={btnSize} onClick={onClickOk}>{btnLabel}</Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

AlertModal.defaultProps = {
  footerClass: '',
  btnLabel: 'Ok!',
  btnSize: 'sm',
  color: 'green'
};

export default AlertModal;
