export default function reducer(state = {
  isExpand: false,
  textSearchValue: '',
  updateInventoryEditAll: false
}, action) {
  switch (action.type) {
    case 'SET_EXPAND_ALL':
      return {
        ...state,
        isExpand: action.payload
      };
    case 'SET_TEXT_SEARCH_INVENTORY':
      return {
        ...state,
        textSearchValue: action.payload
      };
    case 'SET_UPDATE_INVENTORY_EDIT_ALL':
      return {
        ...state,
        updateInventoryEditAll: action.payload
      };

    default:
      return state;
  }
};