import { useState, useEffect } from 'react';
import itemApi from '../../../../../api/itemApi';
import { useSelector, useDispatch } from 'react-redux';
import { saveView, saveListItems, setListSearch, setListSortByOptions, setListSortBy} from "../../../../../reducers/items/itemsActions";
import { sortOptions } from '../../../../../config/constants';

const useApi = () => {
  const dispatch = useDispatch();
  const items = useSelector(({ items }) => items.list.items);
  const sort = useSelector(({items}) => items.list.sortBy);
  const filters = useSelector(({items}) => items.list.filters);
  const search = useSelector(({items}) => items.list.search);
  const cItems = useSelector(({cart}) => cart.cartItems);
  const [page, setPage] = useState(1);
  const [loadMore, setLoadMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  
  const setItemsStructure = (items) => {
    const today = new Date();
    const nitems = items.map((item) => (
      {
        ...item,
        isPriceOutdated: (!item.endDate) ? item.isPriceOutdated : ( new Date(item.endDate).getTime() <= today.getTime() ? 1 : 0),
        showSavings: (item.savings === 0) ? false : true,
        savingItems: (item.savings === 0) ? null : [],
        caseQuantity: (cItems.filter(ci => (!item.isSpecialItem && item.itemDistributorId === ci.itemDistributorId)).caseQuantity) //0
      }
    ));
    return nitems;
  };

  const getListItems = (page) => {
    if (page === 1 || (page > 1 && loadMore)) {
      setIsLoading(true);
      itemApi.getList(sort,filters,page,search)
      .then(res => {
          const loadMoreNew = res._meta.pageCount > res._meta.currentPage;
          const pageNew = page + 1;
          const structuredItems = setItemsStructure(res.data);
          if (page === 1) {
            dispatch(saveListItems(structuredItems));
          }else{
            dispatch(saveListItems([...items, ...structuredItems]));
          }
          setLoadMore(loadMoreNew);
          setIsLoading(false);
          setPage(pageNew);            
      })
      .catch(error => console.log(error));
    }
  };

  useEffect(() => {
    dispatch(saveView('list'));    
    dispatch(setListSearch(''));
    dispatch(setListSortByOptions(sortOptions.listView));
    dispatch(setListSortBy(sortOptions.listView.filter(lw => lw.default === 1)[0]));
    dispatch(saveListItems([]));
    getListItems(1);
  }, [])

  useEffect(() => {
    if (sort) {
      dispatch(saveListItems([]));
      getListItems(1);
    }
  }, [sort, search, filters]);

  return { getListItems, items, sort, filters, page, loadMore, isLoading, search };
};

export default {
  useApi
};
