
export default function reducer(state = {
  list: [],
  pageCount: 1,

}, action) {
  switch (action.type) {
    case 'SET_LIST':
      return {
        ...state,
        list: action.payload
      };    
    
    case 'SET_COUNT':
      return {
        ...state,
        pageCount: action.payload
      };    

    case 'CLEAR_LIST':
      return {
        list: [],
        pageCount: 1,
      };
      
    default:
      return state;
  }
};