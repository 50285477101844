import React, { Fragment, useState, useEffect } from 'react';
import { isMobile } from "react-device-detect";
import { Card, Row, Col, Button } from 'reactstrap';
import { MdAttachMoney } from 'react-icons/md';
import { AiOutlineDollar } from 'react-icons/ai';
import useSavingsButton from './useSavingsButton';
import './SavingsButton.scss';
import ShowIf from '../ShowIf/ShowIf';
import { localStorageConst } from '../../config/constants';
import { useSelector } from 'react-redux';

const SavingsButton = (props) => {
  const {item, parentItem} = props;
  const { savingsOpen, setSavingsOpen, savingsMap, savingsNumber, displaySavings, buttonTitle, buttonColor} = useSavingsButton.useOpen(item, parentItem);
  // const activeLocation = JSON.parse(localStorage.getItem(localStorageConst.keys.activeLocation));
  const displayMatch = useSelector(({user}) => user.activeLocation.display_match);
  const [showSavings, setShowSavings] = useState(false);

  useEffect(() => {
    if (displayMatch === 1){
      setShowSavings(true);
    }
  }, []);

  return (
    <Fragment>
      <ShowIf show={showSavings}>
        <ShowIf show={savingsOpen}>
          {displaySavings ? savingsMap : ''}
        </ShowIf>      
        <Card className ={`mt-0 ${isMobile ? `mb-1` : ``}`}>
          <Row>
            <Col>
              <Button 
                className={`d-flex align-item-center savings `+(isMobile ? `justify-content-left ` : `justify-content-center `)} 
                color={buttonColor} block 
                onClick={() => setSavingsOpen(!savingsOpen)}>
                  {isMobile ? <AiOutlineDollar size={14}/> : <MdAttachMoney size={14}/>} &nbsp; 
                  {` ${buttonTitle}`} {savingsNumber > 0 ? `: ${savingsNumber}` : ``} 
                  {displaySavings}
              </Button>
            </Col>
          </Row>
        </Card>
      </ShowIf>      
    </Fragment>
  );

};

export default SavingsButton;