import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import comparisonViewsApi from "../../api/comparisonViewsApi";
import { saveGroups, saveList, saveListSelected, saveSortBy, saveSearch, clearGroupsData } from "../../reducers/groups/groupsActions";
import { isMobile } from 'react-device-detect';
import {limitOptions} from '../../config/constants';
import { spinner } from '../../reducers/userInterface/userInterfaceActions';
import SyncModal from '../Modals/SyncModal/SyncModal';
import { showModal } from '../../reducers/modals/modalsActions';
import useConnection from '../OfflineMode/useConnection';

const useViewsSync = () => {

  const dispatch = useDispatch();
  const list = useSelector(({ items }) => items.inventory.list);
  const listSelected = useSelector(({ items }) => items.inventory.listSelected);
  const sort = useSelector(({ items }) => items.inventory.sortBy);
  const search = useSelector(({ items }) => items.inventory.search);
  const groups = useSelector(({ groups }) => groups.groups);

  const [page, setPage] = useState();
  const [loadMore, setLoadMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const {syncToLocal} = useConnection.useSyncLocal();

  dispatch(saveList(list));
  dispatch(saveListSelected(listSelected));
  dispatch(saveSortBy(sort));
  dispatch(saveSearch(search));

  const getViews = () => {
    if (!page){ return; }
    const params = {
      page: page,
      limit: limitOptions.inventoryView,
      filterOptions: JSON.stringify({ sort: sort }),
      filters: search ? JSON.stringify([{ field: 'name', operator: 'like', value: search, table: 'generic_group' }]) : null,
      analytics: 1
    };

    setIsLoading(true);
    dispatch(spinner(false));
    comparisonViewsApi.getAllViews(params).then((res) => {
      setLoadMore(res._meta.pageCount>page);
      setPage( page + 1 );
      dispatch(saveGroups([...groups, ...res.data.map(d => {
        return {
          ...d,
          expand: isMobile ? false : true,
          parLevel: d.parLevel ? d.parLevel : 0,
          items: d.items.map(i => {
            return {
              ...i,
              isCustomPackSize: i.customPackSize ? !(i.customPackSize === i.packSize) : false
            }
          })
        }
      })]));
      setIsLoading(false);
      dispatch(spinner(true));
    }).catch(err => {
      dispatch(spinner(true));
      console.error(err)
    });
  };

  const getAllViews = () => {
    const syncModalProps = {
      header: 'Loading Screen',
      message: 'Syncing your data to work offline'
    };
    dispatch(showModal(SyncModal, syncModalProps));
    dispatch(clearGroupsData());
    setLoadMore(true);
    setPage(1);
  };

  useEffect(() => {
    if(loadMore && !isLoading){
      getViews();
    }
    if (!loadMore && !isLoading){
      syncToLocal();
    }
  }, [page, isLoading]);



  return {
    getAllViews
  };

}


const useInventoryListSync = () => {

  const dispatch = useDispatch();
  const list = useSelector(({ items }) => items.inventory.list);
  const listSelected = useSelector(({ items }) => items.inventory.listSelected);
  const sort = useSelector(({ items }) => items.inventory.sortBy);
  const search = useSelector(({ items }) => items.inventory.search);
  const groups = useSelector(({ groups }) => groups.groups);

  const [page, setPage] = useState();
  const [loadMore, setLoadMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const {syncToLocal} = useConnection.useSyncLocal();

  dispatch(saveList(list));
  dispatch(saveListSelected(listSelected));
  dispatch(saveSortBy(sort));
  dispatch(saveSearch(search));

  const getGroups = () => {
    if (!page){ return; }
    const params = {
      page: page,
      limit: limitOptions.inventoryView,
      expand: 'items,purchased,onHandTotal,orderTotal,globalOnHandTotal,units',
      filterOptions: JSON.stringify({ sort: sort }),
      filters: search ? JSON.stringify([{ field: 'name', operator: 'like', value: search, table: 'generic_group' }]) : null,
      analytics: 1
    };

    setIsLoading(true);
    dispatch(spinner(false));
    comparisonViewsApi.groups(listSelected.id, params).then((res) => {
      setLoadMore(res._meta.pageCount>page);
      setPage( page + 1 );
      dispatch(saveGroups([...groups, ...res.data.map(d => {
        return {
          ...d,
          expand: isMobile ? false : true,
          parLevel: d.parLevel ? d.parLevel : 0,
          items: d.items.map(i => {
            return {
              ...i,
              isCustomPackSize: i.customPackSize ? !(i.customPackSize === i.packSize) : false
            }
          })
        }
      })]));
      setIsLoading(false);
      dispatch(spinner(true));
    }).catch(err => {
      dispatch(spinner(true));
      console.error(err)
    });
  };

  const getAllGroups  = () => {
    const syncModalProps = {
      header: 'Loading Screen',
      message: 'Preparing your data to work offline'
    };
    dispatch(showModal(SyncModal, syncModalProps));
    dispatch(clearGroupsData());
    setLoadMore(true);
    setPage(1);
  };

  useEffect(() => {
    if(loadMore && !isLoading){
      getGroups();
    }
    if (!loadMore && !isLoading){
      syncToLocal();
    }
  }, [page, isLoading]);



  return {
    getAllGroups
  };

}

export default {
  useViewsSync, useInventoryListSync
};
