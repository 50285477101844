import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { MdEdit, MdDelete } from 'react-icons/md';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import useSingleShoppingList from './useSingleShoppingList';
import Header from '../../../../../SharedComponents/Header/Header';
import ListItemRow from './ListItemRow/ListItemRow';
import QuestionModal from '../../../../../SharedComponents/Modals/QuestionModal/QuestionModal';
import { showModal } from '../../../../../reducers/modals/modalsActions';
import shoppingListsApi from '../../../../../api/shoppingListsApi';
import emptyList from '../../../../../assets/images/empty-list.png';
import './SingleShoppingList.scss';

const SingleShoppingList = () => {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const { list } = useSingleShoppingList.useSingleListApi();
  const { items, setItems, page, isLoading, loadMore, getListItems } = useSingleShoppingList.useListItemsApi();

  const deleteShoppingListItem = (itemDistributorId) => {
    shoppingListsApi.deleteListItem(params.id, itemDistributorId).then(() => {
      setItems(items.filter(item => item.itemDistributorId != itemDistributorId));
    }).catch(err=> {
      console.log(err);
    });
  };
  const itemsMap = items.map((item) => <ListItemRow key={`list-item-${item.itemId}`} onDelete={deleteShoppingListItem}>{item}</ListItemRow>);

  const goToShoppingListForm = () => {
    history.push(`/shopping-lists/update-list/${params.id}`);
  };

  const openDeleteListModal = () => {
    const modelProps = {
      message: 'Are you sure you want to delete this shopping list?',
      leftButton: {
        title: 'Yes',
        color: 'orange',
        onClick: () => {
          shoppingListsApi.deleteList(params.id).then(() => {
            history.push('/shopping-lists');
          }).catch(err=> {
            console.log(err);
          });
        }
      },
      rightButton: {
        title: 'No',
        color: 'green'
      }
    }
    dispatch(showModal(QuestionModal, modelProps));
  };

  const rightButton = {icon: [
    <MdEdit onClick={goToShoppingListForm} className='clickable' size={22} />,
    <MdDelete onClick={openDeleteListModal} className='clickable' size={22} />
  ]};

  const backFunction = () => {
    history.push('/shopping-lists');
  };

  if (list && list.name && !isLoading && items && items.length == 0) {
    return (
      <Container fluid={true} className='m-0 p-0'>
        <Header showMenu={false} showLogo={false} showBack={true} backFunct={backFunction} title={list && list.name || ' '} showCart={false} rightButton={rightButton} />
        <Container fluid={true} className='shopping-list mt-4 mb-5 rounded'>
          <Row>
            <Col xs={12} className='text-center'>
              <img src={emptyList} alt='Empty List' />
              <p className='mt-3'>This shopping list is empty.</p>
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }

  return (
    <Container fluid={true} className='m-0 p-0'>
      <Header showMenu={false} showLogo={false} showBack={true} backFunct={backFunction} title={list && list.name || ' '} showCart={false} rightButton={rightButton} />
      <Container fluid={true} className='shopping-list mt-4 mb-5 rounded'>
        <InfiniteScroll
          initialLoad={false}
          pageStart={page}
          loadMore={getListItems}
          hasMore={loadMore && !isLoading}
          useWindow={true}
        >
          {itemsMap}
        </InfiniteScroll>
      </Container>
    </Container>
  );

};

export default SingleShoppingList;
