export const saveList = (orders) => {
  return {
    type: 'SET_LIST',
    payload: orders
  }
};

export const saveCount = (count) => {
  return {
    type: 'SET_COUNT',
    payload: count
  }
};

export const clearList = () => {
  return {
    type: 'CLEAR_LIST',
  };
};