import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Container } from 'reactstrap';
import Header from '../../../../SharedComponents/Header/Header';
import PosList from './PosList/PosList';
import PosDetail from './PosDetail/PosDetail';
import './Pos.scss';

const Pos = () => {
  return (
    <Switch>
      <Route exact path='/pos'>
        <Container fluid={true} className='m-0 p-0'>
          <Header showMenu={true} showLogo={false} showBack={false} title='POS' showCart={false} />
          <PosList />
        </Container>
      </Route>
      <Route path='/pos/:date'>
        <Container fluid={true} className='m-0 p-0'>
          <Header showMenu={false} showLogo={false} showBack={true} title='POS Detail' showCart={false} />
          <PosDetail />
        </Container>
      </Route>
      <Route>
        <Redirect to='/pos' />
      </Route>
    </Switch>
  );
};

export default Pos;
