import React from 'react';
import { Row, Col } from 'reactstrap';
import { isMobile } from 'react-device-detect';

const SignUpHeader = ({ title }) => {

  return (
    <Row className={isMobile ? 'pt-4' : 'pt-5'}>
      <Col>
        <h1 className={isMobile ? 'text-title mobile' : 'text-title'}>{title}</h1>
      </Col>
    </Row>
  );
};

export default SignUpHeader;