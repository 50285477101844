import React, {useState}  from 'react';
import { Modal, ModalBody, ModalHeader, Row, Col, Input, ModalFooter, Button, CustomInput } from 'reactstrap';
import { IoIosAddCircle, IoIosRemoveCircle } from 'react-icons/io';
import {colors} from '../../../../../../config/constants';
import {showModal} from '../../../../../../reducers/modals/modalsActions';
import { useDispatch } from 'react-redux';
import useModal from '../../../../../../SharedComponents/Modals/useModal';
import useInput from "../../../../../../SharedComponents/SharedEffects/useInput";
import RejectCreateModal from './RejectCreateModal'
import '../../OrderHistory.scss';

const OrderReceiveRejectModal = (props) => {
    const dispatch = useDispatch();
    const { id, menu, title, fullMenu, item, onSave, selectedOrder, getOrdersById} = props;
    const modalProps = useModal(id, menu, fullMenu);
    const [rejectOption, setRejectOption ] = useState('');
    const [option1, setOption1 ] = useState(false);
    const [option2, setOption2 ] = useState(false);
    const [option3, setOption3 ] = useState(false);
    const [option4, setOption4 ] = useState(false);
    const [disabledButton, setDisabledButton ] = useState(true);
    const { value: valueQuantity, set: setQuantity } = useInput.useText(item.quantity);
    const { value: description, set: setDescription } = useInput.useText();

    const onchangeCheck = (opt) => {
        switch (opt) {
            case 1: 
                setRejectOption('Bad or damaged');
                setOption1(true);
                setOption2(false);
                setOption3(false);
                setOption4(false);
                setDisabledButton(false);
                break;
            case 2: 
                setRejectOption('Wrong item');
                setOption1(false);
                setOption2(true);
                setOption3(false);
                setOption4(false);
                setDisabledButton(false);
                break;
            case 3: 
                setRejectOption('Not ordered');
                setOption1(false);
                setOption2(false);
                setOption3(true);
                setOption4(false);
                setDisabledButton(false);
                break;
            case 4: 
                setRejectOption(description);
                setOption1(false);
                setOption2(false);
                setOption3(false);
                setOption4(true);
                setDisabledButton(false);
                break;
            default:
                break;
        }
    }

    const clickSave = () => {
        if (onSave) {
            const data = {
                itemQuantity: valueQuantity,
                id: id,
                rejectReason: option4 ? description: rejectOption
            }
            onSave(data);
            modalProps.toggle();
            dispatch(showModal(RejectCreateModal, {selectedOrder: selectedOrder, getOrdersById : getOrdersById}));
        }
    };

    const plusQuantity = (quantity) =>{
        if(quantity < item.quantity ) {
            setQuantity(quantity + 1);
        }
    }

    const minusQuantity = (quantity) => {
        if(quantity > 1) {
            setQuantity(quantity - 1);
        }
    }

    const close = () => {
        modalProps.toggle();
        onSave(item, true);
    }

    return (
        <Modal {...modalProps}>
            <ModalHeader toggle={close}>{title}</ModalHeader>
            <ModalBody className='px-4'>
                <Row className='py-3'>
                    <Col>
                        How many items are you rejecting?
                    </Col>
                </Row>
                <Row>
                    <Col xs={2} className='clickable' onClick={() => minusQuantity(valueQuantity)}>
                        <IoIosRemoveCircle color={colors.blueDark} size={40} />
                    </Col>
                    <Col xs={2} className='p-0'>
                        <Input className='oh-text-area text-center' readOnly value={valueQuantity} onChange={setQuantity} style={{backgroundColor:'white'}}></Input>
                    </Col>
                    <Col xs={2} className='clickable' onClick={() => plusQuantity(valueQuantity)}>
                        <IoIosAddCircle color={colors.blueDark} size={40} />
                    </Col>
                </Row>
                <Row className='py-3'>
                    <Col>
                        Why is this being rejected
                    </Col>
                </Row>
                <Row className='py-3 clickable'>
                    <Col className=''>
                        <CustomInput id='idBadDamaged' type='checkbox' label='Bad or damaged' checked={option1} onChange={() => onchangeCheck(1)}  />
                    </Col>
                </Row>
                <Row className='py-3'>
                    <Col>
                        <CustomInput id='idWrongItem'  type='checkbox' label='Wrong item' checked={option2} onChange={() => onchangeCheck(2)} />
                    </Col>
                </Row>
                <Row className='py-3'>
                    <Col>
                        <CustomInput id='idNotOrdered'  type='checkbox' label='Not ordered' checked={option3} onChange={() => onchangeCheck(3)} />
                    </Col>
                </Row>
                <Row className='py-3'>
                    <Col>
                        <CustomInput id='idOther'  type='checkbox' label='Other' checked={option4} onChange={() => onchangeCheck(4)} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <textarea id='idDescription' className='w-100 oh-text-area' placeholder='Please be as specific as possible...' onChange={setDescription} value={description} disabled={!option4}></textarea>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color='green' onClick={() => clickSave()} disabled={disabledButton}>Submit</Button>
            </ModalFooter>
        </Modal>
    )

}

export default OrderReceiveRejectModal;