import React from "react";
import "./RecipeCostingSubHeader.scss";
import classNames from "classnames";
import moment from "moment";
import { isMobile } from "react-device-detect";
import {
  Row,
  Nav,
  Col,
  NavItem,
  NavLink,
  ButtonGroup,
  Button,
} from "reactstrap";
import DatePicker from "react-datepicker";
import ShowIf from "../../../../../SharedComponents/ShowIf/ShowIf";
import { InputCheckboxControlled } from "../../../../../SharedComponents/InputCheckbox/InputCheckbox";
import { MdLocationOn, MdTrendingFlat } from "react-icons/md";
import { IoMdPrint, IoMdTrash } from "react-icons/io";
import { FaCopy } from "react-icons/fa";

const styles = {
  inputWrapper: {
    alignItems: "center",
  },
  inputLabel: {
    margin: 0,
  },
};

const RecipeCostingSubHeader = (props) => {
  const {
    tabs,
    changeTab,
    dateFilter,
    posBgnDate,
    setPOSBgnDate,
    posEndDate,
    setPOSEndDate,
    nRecipes,
    selectAllRecipes,
    selectedRecipes,
    duplicateRecipes,
    printRecipes,
    deleteRecipes,
  } = props;

  const nRecipesSelected = selectedRecipes.length;

  const TabFilter = (props) => {
    const { isActive, updateDateFilter, index, name } = props;

    return (
      <NavItem className="clickable">
        <NavLink
          className={classNames({ active: isActive })}
          onClick={() => updateDateFilter(index)}
        >
          {name}
        </NavLink>
      </NavItem>
    );
  };

  return (
    <>
      <Row
        className="m-0 p-0 recipe-costing-header"
        style={{ justifyContent: "space-between" }}
      >
        <Col className="recipe-costing-actions">
          <Col>
            <Col xs={4} sm={2} md={1}>
              <InputCheckboxControlled
                undetermined={
                  nRecipesSelected > 0 && nRecipesSelected !== nRecipes
                }
                active={nRecipesSelected > 0}
                onClick={(e) => selectAllRecipes(e)}
              />
            </Col>
            <Col xs={8} sm={10} md={2}>
              {nRecipesSelected > 0 ? "Deselect All" : "Select All"}
            </Col>
            <ShowIf show={nRecipesSelected > 0}>
              <Col xs={12} sm={12} md={9}>
                <Row>
                  <Col>
                    {nRecipesSelected +
                      " " +
                      (nRecipesSelected === 1 ? "recipe" : "recipes") +
                      " selected"}
                    <ButtonGroup className="recipe-costing-action-buttons">
                      {/* <Button className="copy-to-location">
                        <MdTrendingFlat />
                        <MdLocationOn /> Copy to Location
                      </Button>
                      <div className="divider" /> */}
                      <Button onClick={duplicateRecipes}>
                        <FaCopy /> Duplicate
                      </Button>
                      <div className="divider" />
                      <Button onClick={printRecipes}>
                        <IoMdPrint /> Print
                      </Button>
                      <div className="divider" />
                      <Button onClick={deleteRecipes}>
                        <IoMdTrash /> Delete
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </Col>
            </ShowIf>
          </Col>
        </Col>
        <Nav tabs className="recipe-costing">
          {tabs.map((tabName, index) => {
            return (
              <TabFilter
                key={index}
                index={index}
                name={tabName}
                updateDateFilter={changeTab}
                isActive={dateFilter === index}
              />
            );
          })}
        </Nav>
      </Row>
      <ShowIf show={"Custom" === tabs[dateFilter]}>
        <Row className="recipe-costing-header">
          <Col
            xs={12}
            style={styles.inputWrapper}
            className={
              "border-top " +
              (isMobile
                ? "d-flex flex-column justify-content-center"
                : "d-flex justify-content-center") +
              " py-4"
            }
          >
            <label
              style={styles.inputLabel}
              htmlFor="startDateInput"
              className={"pr-2" + (isMobile ? "" : " pl-2")}
            >
              Start Date:
            </label>
            <DatePicker
              id="startDateInput"
              className={isMobile ? "mb-3" : ""}
              selected={posBgnDate.toDate()}
              onChange={(date) =>
                setPOSBgnDate(moment(date).hour(0).minute(0).second(0))
              }
            />
            <label
              style={styles.inputLabel}
              htmlFor="endDateInput"
              className={"pr-2" + (isMobile ? "" : " pl-2")}
            >
              End Date:
            </label>
            <DatePicker
              id="endDateInput"
              className={isMobile ? "mb-3" : ""}
              selected={posEndDate.toDate()}
              onChange={(date) =>
                setPOSEndDate(moment(date).hour(23).minute(59).second(59))
              }
            />
          </Col>
        </Row>
      </ShowIf>
    </>
  );
};

export default RecipeCostingSubHeader;
