import { useState, useEffect } from "react";
import analyticsApi from "../../../../../api/analyticsApi";

const useApi = () => {
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [canLoadMore, setCanLoadMore] = useState(true);

  const getLastPurchasedItems = (page = 1) => {
    setIsLoading(true);
    analyticsApi
      .getPurchasedItems(
        page,
        3
      )
      .then((res) => {
        setItems(items.concat(res.data));
        setPage(page);
        setCanLoadMore(res._meta.totalCount > 0 && res._meta.currentPage < res._meta.pageCount);
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
  };

  const nextPage = () => {
    setPage(page + 1);
  };

  useEffect(() => {
    getLastPurchasedItems(page);
  }, [page]);

  return {
    items,
    page,
    nextPage,
    isLoading,
    canLoadMore,
    getLastPurchasedItems,
  };
};

export default { useApi };
