import Dexie from 'dexie';
import {dbMain, dbStores} from './dbConstants';

let stores = {};
const structure = dbStores.structure;
structure.map(st => { stores[st.name] = st.fields });

const db = new Dexie(dbMain.name);
db.version(dbMain.version).stores(stores);

export default db;