import React from 'react';
import { Row, Col } from 'reactstrap';

const ItemDetailsInfoCol = ({label, value}) => {
  return (
    <Col>
      <Row>
        <Col className='text-label mb-1'>{label}</Col>
      </Row>
      <Row>
        <Col>{value}</Col>
      </Row>
    </Col>
  );
};

export default ItemDetailsInfoCol;