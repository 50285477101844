export default function reducer(state = {
  online: true,
  open: false,
  modal: null
}, action) {
  switch (action.type) {
    case 'SET_CONNECTION':
      return {
        ...state, 
        online: action.payload
    };
    case 'SET_OPEN':
      return {
        ...state, 
        open: action.payload
    };
    case 'SET_MODAL':
      return {
        ...state, 
        modal: action.payload
    };
    default:
      return state;
  }
};