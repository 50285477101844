import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col } from 'reactstrap';
import useModal from '../useModal';
import { isMobile } from "react-device-detect";
import { useSelector,useDispatch } from 'react-redux';
import { saveOpenSync } from '../../../reducers/sync/syncActions';
import syncImg from '../../../assets/images/sync.gif';
import './SyncModal.scss';

const SyncModal = (props) => {
    const { id, header, message } = props;
    const modalprops = useModal(id);
    const dispatch = useDispatch();
    const currentOpenSync = useSelector(({sync}) => sync.openSync);
  
    useEffect(() => {
      if(currentOpenSync===false){
        modalprops.toggle();
        dispatch(saveOpenSync(true));
      }      
    }, [currentOpenSync]);

    return (
        <Modal {...modalprops} backdrop='static' keyboard={false} className={isMobile ? 'simpleModal mobile' : 'simpleModal'} >
            <ModalHeader>
                {header}
            </ModalHeader>
            <ModalBody className='text-center'>
                <Row className='my-2'>
                  <Col className='sync-content'> <img src={syncImg} alt='sync gif' className='sync-img'/> </Col>
                </Row>
                <Row className='my-2'>
                  <Col> {message} </Col>
                </Row>                
            </ModalBody>
        </Modal>
    );
};

export default SyncModal;

