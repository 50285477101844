import React, {Fragment, useEffect} from 'react';
import { useSelector } from 'react-redux';
import useConnection from './useConnection';
import { localStorageConst } from '../../config/constants';

const OfflineMode = (props) => {

  const connection = JSON.parse(localStorage.getItem(localStorageConst.keys.connection)); // useSelector(({ connection }) => connection.online);

  let {status} = useConnection.useStatus();
  const {checkItemsToSync} = useConnection.useSyncServer();
  const {connectionChange} = useConnection.useActions();

  useEffect(() => {
    if(connection !== status){      
      const syncInventory = JSON.parse(localStorage.getItem(localStorageConst.keys.inventorySync));
      if(syncInventory){
        // checkItemsToSync();
        connectionChange(status, true, true);
        return;
      }
      connectionChange(status);
    }
  }, [status]);

  return (
    <Fragment >
    </Fragment>
  );
};

export default OfflineMode;
