import React from 'react';
import ReactDOM from 'react-dom';
import OfflineMode from './SharedComponents/OfflineMode/OfflineMode';
import * as serviceWorker from './serviceWorker';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { StripeProvider } from 'react-stripe-elements';
import App from './Views/App/App';
import store from './reducers/store';
import * as $ from 'jquery';
import './config/customValidations';
import './config/scss/combinedScss.scss';

window.jQuery = window.$ = $;

ReactDOM.render(
  <Provider store={store}>    
    <HashRouter>
      <OfflineMode/>
      <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
        <App />
      </StripeProvider>
    </HashRouter>
  </Provider>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
