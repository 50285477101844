import { useSelector } from "react-redux";
import {saveAll, deleteAll, saveBase, deleteBase, find, findOneBase} from '../../local/CartLocal';
import {syncParam} from '../../local/dbConstants';
import useShoppingCart from '../ShoppingCart/useShoppingCart';

const useOptions = () => {

  const cartItems = useSelector(({ cart }) => cart.cartItems);
  const cartCount = useSelector(({ cart }) => cart.cartCount);
  const cartLastModified = useSelector(({ cart }) => cart.lastModified);
  const cartOrderTotal = useSelector(({ cart }) => cart.orderTotal);
  const {syncBatch} = useShoppingCart.useBatchUpdateCart();


  const syncCartToLocal = () => {
    deleteBase()
    .then(() => { saveBase(cartLastModified, cartCount, cartOrderTotal); })
    .then(() => { deleteAll(); })
    .then(() => { saveAll(cartItems); })
    .catch(error => { console.log(`Error on saving cart ${error}`); });        
  };

  const syncCartToServer = () => {
    let syncLastModified = '';
    findOneBase()
    .then((response) => {
      if(!response[0]){ return; }
      syncLastModified = response[0].lastModified;
      return find(syncParam);
    })
    .then((cartItems) => {
      if(!cartItems){ return; }
      if(cartItems.lengh===0){ return; }
      return syncBatch(cartItems, syncLastModified, true);
    })
    .then(() => { deleteBase(); })
    .then(() => { deleteAll(); })
    .catch(error => { console.log(`Error on syncCartToServer ${error}`); });

  };

  return {
    syncCartToLocal, syncCartToServer
  };
};

export default {
  useOptions, 
};