import React, { useState } from 'react';
import { CustomInput, Button } from 'reactstrap';
import { FaPen, FaTrash } from 'react-icons/fa';
import { cardIconHandler } from '../../ManageCards/cardIconHandler';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';


const ShippingAndPaymentCard = (props) => {
    const { card, onchange, updateCard, deleteCard } = props;
    const [show, setShow] = useState(false);
    const [deleteBtn, setDeleteBtn] = useState(false)

    const showDelete = () => setDeleteBtn(!deleteBtn)

    const showIconMenter = () => {
        if (!show) setShow(true)
    }
    const showIconMleave = () => {
        if (show) setShow(false)
    }

    return (
        <div onMouseEnter={showIconMenter} onMouseLeave={showIconMleave}>
            <CustomInput checked={card.checked} onChange={() => onchange(card)} type='checkbox' id={'checkCard-' + card.token}  >
                <img src={cardIconHandler(card)}></img> &nbsp;&nbsp;&nbsp;{card.last4}
                <ShowIf show={show}>
                    <ShowIf show={!deleteBtn}>
                        <FaPen onClick={()=> updateCard(card.token)} className='marginL200 clickable' />
                        <FaTrash onClick={showDelete} className='marginL30 clickable' />
                    </ShowIf>
                    <ShowIf show={deleteBtn}>
                        <Button onClick={showDelete} type='button' className='btn btn-green buttonD marginL150' >Cancel</Button>
                        <Button type='button' className='btn btn-orange buttonD' onClick={() => deleteCard(card.token)}>Delete</Button>
                    </ShowIf>
                </ShowIf>
            </CustomInput>
        </div>
    )
}

export default ShippingAndPaymentCard
