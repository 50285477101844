import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Button } from 'reactstrap';
import { MdDelete } from 'react-icons/md';
import { FaExclamationCircle, FaCheckCircle } from 'react-icons/fa';
import { colors } from '../../../../../config/constants'
import useModal from '../../../../../SharedComponents/Modals/useModal';
import AlertModal from '../../../../../SharedComponents/Modals/AlertModal/AlertModal';
import { showModal } from '../../../../../reducers/modals/modalsActions';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import accountingApi from '../../../../../api/accountingApi';
import * as $ from 'jquery';

const BulkUploadModal = (props) => {
  const dispatch = useDispatch();

  const [file, setFile] = useState(null);

  const { id } = props;
  const modalProps = useModal(id);

  const allowedTypes = [
    '',
    'csv',
    'xls',
    'xlsx',
    'text/csv',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.oasis.opendocument.spreadsheet'
  ];
  const sizeLimit = 24000000;

  const openFileDialog = () => {
    $('#img-upload').click();
  };

  const selectFile = () => {
    const newFile = $('#img-upload').prop('files')[0];
    setFile(newFile);
  };

  const uploadFile = () => {
    if (!file) {
      return;
    }
    accountingApi.uploadFile(file)
      .then(res => {
        modalProps.toggle();
        dispatch(showModal(AlertModal, {
          message: <div className='text-center'><FaCheckCircle className='d-block mx-auto my-3' color={colors.green} size={70} />Your file was uploaded successfully.</div>,
          footerClass: 'justify-content-center',
          btnLabel: 'Ok',
          btnSize: 'lg'
        }));
    }).catch(error => {console.log('accountingApi uploadFile Error:', error)});
  };

  return (
    <Modal {...modalProps}>
      <ModalHeader className='bg-white' toggle={modalProps.toggle}>Bulk Upload</ModalHeader>
      <ModalBody>
        <ShowIf show={file==null}>
          <Row>
            <Col xs={12} className='text-center py-2'>
              <input id='img-upload' type='file' className='d-none' onChange={() => selectFile()} accept='.csv, .xls, .xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.oasis.opendocument.spreadsheet' />
              <div className='upload-file-outline' onClick={openFileDialog}><img src={props.uploadFileIcon} style={{marginTop: '-2px', marginRight: '7px'}} />Upload excel or csv file</div>
            </Col>
          </Row>
        </ShowIf>
        <ShowIf show={file!==null}>
          <Row>
            <Col xs={12} className='text-center mt-2 mb-3'>
              {file && file.name} ({file && file.type}) <MdDelete onClick={() => setFile(null)} className='clickable' style={{marginTop: '-2px'}} />
            </Col>
          </Row>
        </ShowIf>
        <ShowIf show={file && (file.size > sizeLimit || allowedTypes.indexOf(file.type)==-1)}>
          <Row>
            <Col xs={12} className='text-center mt-2 my-2'><FaExclamationCircle color={colors.orange} size={70} /></Col>
          </Row>
        </ShowIf>
        <ShowIf show={file && file.size > sizeLimit}>
          <Row>
            <Col xs={12} className='text-center mt-2 my-2'>File size is too big.</Col>
          </Row>
        </ShowIf>
        <ShowIf show={file && allowedTypes.indexOf(file.type)==-1}>
          <Row>
            <Col xs={12} className='text-center mt-2 my-2'>Please choose another file type.</Col>
          </Row>
        </ShowIf>
        <Row>
          <Col xs={12} className='text-center py-2'>Not sure which format or file to upload?</Col>
        </Row>
        <Row>
          <Col size={12} className='text-center py-2'>
            <a href={props.template} target='_blank'>Download Template</a>
          </Col>
        </Row>
        <ShowIf show={file && file.size <= sizeLimit && allowedTypes.indexOf(file.type)>-1}>
          <Row>
            <Col size={12} className='text-center py-2'>
              <Button className='btn btn-green' onClick={uploadFile}>Save</Button>
            </Col>
          </Row>
        </ShowIf>
      </ModalBody>
    </Modal>
  );
};

BulkUploadModal.defaultProps = {
  uploadFileIcon: null,
  template: '#',
};

export default BulkUploadModal;
