import React, { Fragment } from 'react';
import ShowIf from '../../../../../../SharedComponents/ShowIf/ShowIf';
import { isMobile } from 'react-device-detect';
import RecipeIngredientsItemRowMobile from './RecipeIngredientsItemRowMobile';
import RecipeIngredientsItemRowDesktop from './RecipeIngredientsItemRowDesktop';

const RecipeIngredientsItemRow = (props) => {
  return (
    <Fragment>
      <ShowIf show={isMobile}>
        <RecipeIngredientsItemRowMobile {...props} />
      </ShowIf>
      <ShowIf show={!isMobile}>
        <RecipeIngredientsItemRowDesktop {...props} />
      </ShowIf>
    </Fragment>
  );
};

export default RecipeIngredientsItemRow;