import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { showModal } from '../../reducers/modals/modalsActions';
import ItemUnavailableModal from '../Modals/ItemUnavailableModal/ItemUnavailableModal';
import PunchedOutModal from '../Modals/PunchedOutModal/PunchedOutModal';
import { debounce } from 'lodash';
import { delay, itemCart } from '../../config/constants';
import { cartAnimations, processStatus, localStorageConst } from '../../config/constants';

import {getAll, findOne, insertOne, updateOne, findOneBase, updateBase} from '../../local/CartLocal';
import { saveCartItems, saveOrderTotal, saveUnsyncedItems, saveLastModified, saveCartCount, saveAnimation } from '../../reducers/cart/cartActions';

const useDebounce = (callback, delay) => {
  const d = callback;
  const callbackfunc = useCallback(debounce(d, delay), []);
  return [callbackfunc];
};

const useCartUpdateOffline = (setCartAmount, groupTotalProps) => {

  const dispatch = useDispatch();
  const { validateOffline } = useCartValidateOffline();

  const cartCount = useSelector(({ cart }) => cart.cartCount);
  const [prevCartCount, setPrevCartCount] = useState(cartCount);

  const [updating, setUpdating] = useState(processStatus.start);
  const [operation, setOperation] = useState('');
  const [differ, setDiffer] = useState(0);
  const [prevCartAmount, setPrevCartAmount] = useState(0);

  const [uItem, setUItem] = useState(null);
  const [uParentItem, setUParentItem] = useState(null);
  const [uNewCartAmount, setUNewCartAmount] = useState(0);
  const [uSameModifiedItems, setUSameModifiedItems] = useState(null);


  const cartItems = useSelector(({ cart }) => cart.cartItems);
  const { updateItemsInCart } = useCartItemUpdate(cartItems);

  const verifyUpdate = () => {
    // let verify = false;
    let newCartCount = 0;
    const item = uItem; const parentItem = uParentItem; const newCartAmount = uNewCartAmount; const sameModifiedItems = uSameModifiedItems;

    getAll().then(cartItems => {
      cartItems.forEach(cItem => { newCartCount = newCartCount + parseFloat(cItem.caseQuantity);});
      const param = {cartCount : newCartCount};
      findOneBase().then(response => {
        updateBase(param, response[0].id).then(cartBase => {
          // dispatch(saveCartCount(newCartCount));
          updateItemsInCart(item, newCartAmount);
        })
        .catch(error => { console.log(`Error updating cartBase ${error}`); });
      })
      .catch(error => { console.log(`Error in get cartBase ${error}`); });
    })
    .catch(error => {
      console.log(`Error getting cart item ${error}`);
    });

    // const item = uItem; const parentItem = uParentItem; const newCartAmount = uNewCartAmount; const sameModifiedItems = uSameModifiedItems;
    // if (!item) return verify;

    // if (!item.itemDistributorId && !item.shoppingCartId) return verify;

    //Verify savings
    // if (parentItem) {
    //   if (item.savings > 0) {
    //     if (operation === 'add') {
    //       dispatch(saveAnimation({ type: cartAnimations.type.savingItemAdded, data: null }));
    //     }
    //   }
    // }

    //Verify rebates
    // if (item.rebate !== null && item.rebate > 0) {
    //   if (operation === 'add') {
    //     const data = (parseFloat(newCartAmount) * parseFloat(item.rebate));
    //     dispatch(saveAnimation({ type: cartAnimations.type.rebatesEarned, data: data }));
    //   }
    // }

    //Update Totals on Inventory view
    // if (groupTotalProps) {
    //   const { setTotalGroupSpent, comparisonViewId, id } = groupTotalProps;
    //   comparisonViewsApi.getGroupTotals(comparisonViewId, id).then((res) => {
    //     setTotalGroupSpent(res.data.purchased);
    //   }).catch(err => console.error(err));
    // }
  };

  const update = (newCartAmount, oldCartAmount, item, parentItem, setCartAmount) => {

    setUpdating(processStatus.processing);

    let params = [];
    if(item.itemDistributorId !== null){
      findOne(item.itemDistributorId).then(response => {

        if(response.length > 0){ //Update
          params = {
            caseQuantity: newCartAmount,
            toUpdate: 1
          }
          updateOne(params, item.itemDistributorId).then(response => {
            setUpdating(processStatus.end);
          })
          .catch(error => {
            console.log(`Error updating cart item ${error}`);
          });
        }else{ //Insert
          params = {
            shoppingCartId: null,
            isSpecialItem: false,
            itemDistributorId: item.itemDistributorId,
            description: (item.name ? item.name : item.description),
            unit: null,
            uom: item.unitMeasure,
            packsize: item.packSize,
            price: item.price,
            caseQuantity: newCartAmount,
            toUpdate: 1
          }
          insertOne(params).then(response => {
            setUpdating(processStatus.end);
          })
          .catch(error => {
            console.log(`Error adding item to cart ${error}`);
          });
        }
      })
      .catch(error => {
        console.log(`Error! ${error}`);
      });
    }
  };

  const [callback] = useDebounce(update, delay.cart);


  const updateCartAmountOffline = (newCartAmount, item, setCartAmount, cartAmount, parentItem) => {
    setUItem(item);
    setUParentItem(parentItem);
    setUNewCartAmount(newCartAmount);

    if (!validateOffline(item, newCartAmount)) {
      return;
    }

    if (newCartAmount === '') {
      setCartAmount(newCartAmount);
      return;
    }

    setCartAmount(newCartAmount);

    callback(newCartAmount, cartAmount, item, parentItem, setCartAmount);

  };


  useEffect(() => {
    if (updating === processStatus.end) {
      verifyUpdate();
    }
  }, [updating]);

  return { updateCartAmountOffline };
};

const useCartValidateOffline = () => {

  const dispatch = useDispatch();

  const validateOffline = (item, value) => {

    if (!!item.isUnpurchasable && item.isUnpurchasable === 1) {
      return false;
    }

    if (!!item.status && item.status !== 'active') {
      dispatch(showModal(ItemUnavailableModal));
      return false;
    }

    if (!!item.isPunchedOut && item.isPunchedOut === 1) {
      //Punched out item
      return false;
    }

    return true;
  };


  return { validateOffline };

};

const useCartItemUpdate = (prevCartItems) => {
  const dispatch = useDispatch();
  let newCartItems = prevCartItems;

  const updateItemsInCart = (item, newCartAmount) => {
    let itemFound = false;
    let totalQty = 0;
    let totalOrder = 0;
    let index = null;

    newCartItems.map((ci, i) => {
      if (ci.isSpecialItem) {
        if (item.shoppingCartId === ci.shoppingCartId) {
          itemFound = true;
          ci.caseQuantity = newCartAmount;
          if (newCartAmount === 0) { index = i; }
        }
      } else {
        if (item.itemDistributorId === ci.itemDistributorId) {
          itemFound = true;
          ci.caseQuantity = newCartAmount;
          if (newCartAmount === 0) { index = i; }
        }
      }
      totalQty = totalQty + parseInt(ci.caseQuantity);
      totalOrder = totalOrder + (parseFloat(ci.caseQuantity) * parseFloat(ci.price));
    });

    if (!itemFound) {
      const newItem = (!item.itemDistributorId) ? {
        caseQuantity: parseFloat(newCartAmount),
        price: 0,
        id: Math.random(),
        distributorId: parseInt(item.distributorId),
        din: null,
        packSize: null,
        // unitMeasure: item.uom,
        unit: item.uom,
        description: item.description,
        searchText: undefined,
        rebate: undefined,
        swapAcceptId: undefined,
        isSpecialItem: true,
        distributor: null,
        shoppingCartId: null,
        isMarketPrice: 0,
        active: 0,
        caseSavings: undefined,
        belongsToGroup: 0
      } : {
          caseQuantity: parseFloat(newCartAmount),
          price: (item.isMarketPrice === 1 ? 0 : parseFloat(item.price)),
          itemDistributorId: item.itemDistributorId,
          brand: item.brand,
          brandImageUrl: item.brandImageUrl,
          imageUrl: item.imageUrl,
          name: item.name,
          packSize: item.packSize,
          status: item.status,
          type: item.type,
          unitPrice: item.unitPrice,
          distributorId: parseInt(item.distributorId),
          unitMeasure: item.unitMeasure,
          description: item.description,
          searchText: item.searchText,
          rebate: parseFloat(item.rebate),
          swapAcceptId: (item.swapAcceptId !== undefined && item.swapAcceptId !== null) ? item.swapAcceptId : null,
          isSpecialItem: false,
          distributor: item.distributor,
          shoppingCartId: null,
          isPriceOutdated: item.isPriceOutdated,
          isMarketPrice: item.isMarketPrice,
          active: item.active,
          din: item.din,
          isPunchedOut: item.isPunchedOut,
          belongsToGroup: item.belongsToGroup,
          // parLevel: item.parLevel,
          // onHand: item.onHand,
          isPartner: item.isPartner,
          isPurchased: item.isPurchased,
          lastPurchasedDate: item.lastPurchasedDate,
          lastPurchasedQty: item.lastPurchasedQty,
          savings: (item.swapAcceptId !== null && item.savings !== null) ? parseFloat(item.savings) : null
        };
      newCartItems.push(newItem);
      totalQty = totalQty + parseInt(newItem.caseQuantity);
      totalOrder = totalOrder + (parseFloat(newItem.caseQuantity) * parseFloat(newItem.price));
    }

    if (newCartAmount === 0 && index !== null) {
      newCartItems.splice(index, 1);
    }

    dispatch(saveCartItems(newCartItems));
    localStorage.setItem(localStorageConst.keys.cartItems, JSON.stringify(newCartItems));
    dispatch(saveCartCount(totalQty));
    dispatch(saveOrderTotal(totalOrder));
  };

  return {
    updateItemsInCart
  };
};

const useCartItemMergeOffline = (item) => {
  const cartItems = useSelector(({ cart }) => cart.cartItems);
  const [caseQuantity, setCaseQuantity] = useState(0);

  const mergeItemWithCartOffline = () => {
    // console.log({cartItems})
    cartItems.map((ci) => {
      if (!!ci.itemDistributorId) {
        if (item.itemDistributorId === ci.itemDistributorId) {
          setCaseQuantity(ci.caseQuantity);
        }
      }
    });
  };

  useEffect(() => {
    mergeItemWithCartOffline()
  }, []);

  return {
    caseQuantity, setCaseQuantity
  };
};

export default {
  useCartValidateOffline, useCartUpdateOffline, useCartItemMergeOffline
};
