import { isMobile } from "react-device-detect";

export const mobileClassName = isMobile ? ' is-mobile' : '';
export const modalMenuClassName = isMobile ? 'fade-down' : '';
export const modalFullMenuClassName = isMobile ? 'fade-down full' : '';
export const modalTourInventoryViewClassName = isMobile ? '' : 'modal-inventoryView';

export const noImage = 'src/assets/images/no-image.png';
export const embedlyKey = process.env.REACT_APP_EMBEDLY_KEY;
export const embedlyApi = process.env.REACT_APP_EMBEDLY_URL;

export const timezone = {
  to: 'America/New_York',
  from: 'UTC'
};

export const fullstory = {
  debug: false,
  host: 'fullstory.com',
  script: 'edge.fullstory.com/s/fs.js',
  org: 'A3WMV',
  namespace: 'FS',
  localstorage: '_fs_uid'
};

export const drift = {
  version: '0.3.1',
  load: 'k3dzbzayuard'
};

export const connection = {
  mode: {
    online: 'Online',
    offline: 'Offline'
  }
};

export const colorsGraph = [
  '#263238', '#b71c1c', '#78909c', '#ef5350',
  '#424242', '#c2185b', '#212121', '#f06292',
  '#4e342e', '#7b1fa2', '#795548', '#ba68c8',
  '#bf360c', '#5e35b1', '#ff7043', '#7c4dff',
  '#ff9800', '#283593', '#ff6d00', '#ffab00',
  '#304ffe', '#ffca28', '#2962ff', '#ffd600',
  '#2196f3', '#ffea00', '#0091ea', '#03a9f4',
  '#00b8d4', '#00acc1', '#009688', '#00695c',
  '#00c853', '#388e3c', '#2e7d32', '#558b2f',
  '#33691e', '#827717', '#37474f', '#455a64',
  '#546e7a', '#607d8b', '#616161', '#757575',
  '#3e2723', '#5d4037', '#6d4c41', '#8d6e63',
  '#a1887f', '#dd2c00', '#ff3d00', '#d84315',
  '#e64a19', '#f4511e', '#ff5722', '#1b5e20',
  '#43a047', '#4caf50', '#00897b', '#00796b',
  '#004d40', '#006064', '#00838f', '#0097a7',
  '#ff1744', '#00bcd4', '#039be5', '#0288d1',
  '#0277bd', '#01579b', '#1e88e5', '#1976d2',
  '#1565c0', '#0d47a1', '#448aff', '#2979ff',
  '#7986cb', '#ff4081', '#5c6bc0', '#3f51b5',
  '#3949ab', '#536dfe'
];

export const currency = '$';
export const colors = {
  dark: '#122439',
  green: '#6ed79a',
  lightBlueText: '#9BB1C2',
  lightBlueBorder: '#DCE8F2',
  orange: '#fc745a',
  blanck: 'rgba(72, 82, 90, 0.94)',
  blue: '#006EBA',
  blueDark: '#143357',
  yellow: '#FEBA5D',
  white: 'white',
  red: '#f95738',
  lightBlueSav: '#008cf3'
};

export const localStorageConst = {
  keys: {
    connection: 'connection',
    userName: 'userName',
    authKey: 'authKey',
    currentRole: 'currentRole',
    userInfo: 'userInfo',
    activeLocation: 'activeLocation',
    locations: 'locations',
    cartItems: 'cartItems',
    lastModifiedCart: 'lastModifiedCart',
    savingOptions: 'savingOptions',
    view: 'view',    
    listSelected: 'listSelected',
    listItems: 'listItems',
    listItemsPage: 'listItemsPage',
    inventoryItems: 'inventoryItems',
    inventoryItemsPage: 'inventoryItemsPage',
    inventorySync: 'inventorySync',
    inventoryItemsToSync: 'inventoryItemsToSync',
    inventoryList: 'inventoryList',
    inventorySortBySelected: 'inventorySortBySelected',
  },
  compression: false
};


export const sortOptions = {
  listView: [
    { value: 'A-Z', label: 'A-Z', id: 1, name: 'alpha', type: 0, desc: 'A-Z', default: 0, drag: 0 },
    { value: 'Z-A', label: 'Z-A', id: 2, name: 'alpha', type: 1, desc: 'Z-A', default: 0, drag: 0 },
    { value: 'Price: Low to High', label: 'Price: Low to High', id: 3, name: 'price', type: 0, desc: 'Price - Low to High', default: 0, drag: 0 },
    { value: 'Price: High to Low', label: 'Price: High to Low', id: 4, name: 'price', type: 1, desc: 'Price - High to Low', default: 0, drag: 0 },
    { value: 'Most Purchased', label: 'Most Purchased', id: 5, name: 'frecuent', type: 1, desc: 'Most Purchased', default: 1, drag: 0 },
    { value: 'Greatest Savings', label: 'Greatest Savings', id: 8, name: 'savings', type: 1, desc: 'Greatest Savings', default: 0, drag: 0 },
    { value: 'Custom', label: 'Custom', id: 6, name: 'custom', type: 1, desc: 'Custom', default: 0, drag: 1 },
  ],
  listViewSearch: [
    { value: 'Sort by relevance', label: 'Sort by relevance', id: 7, name: 'relevant', type: 1, desc: 'Sort by relevance', default: 1, drag: 0 },
    { value: 'A-Z', label: 'A-Z', id: 1, name: 'alpha', type: 0, desc: 'A-Z', default: 0, drag: 0 },
    { value: 'Z-A', label: 'Z-A', id: 2, name: 'alpha', type: 1, desc: 'Z-A', default: 0, drag: 0 },
    { value: 'Price: Low to High', label: 'Price: Low to High', id: 3, name: 'price', type: 0, desc: 'Price: Low to High', default: 0, drag: 0 },
    { value: 'Price: High to Low', label: 'Price: High to Low', id: 4, name: 'price', type: 1, desc: 'Price: High to Low', default: 0, drag: 0 },
    { value: 'Most Purchased', label: 'Most Purchased', id: 5, name: 'frecuent', type: 1, desc: 'Most Purchased', default: 0, drag: 0 },
    { value: 'Greatest Savings', label: 'Greatest Savings', id: 8, name: 'savings', type: 1, desc: 'Greatest Savings', default: 0, drag: 0 },
    { value: 'Custom', label: 'Custom', id: 6, name: 'custom', type: 1, desc: 'Custom', default: 0, drag: 1 },
  ],
  inventoryView: [
    { value: 'A-Z', label: 'A-Z', id: 1, name: 'alpha', type: 0, desc: 'A-Z', default: 0 },
    { value: 'Z-A', label: 'Z-A', id: 2, name: 'alpha', type: 1, desc: 'Z-A', default: 0 },
    { value: 'Custom', label: 'Custom', id: 6, name: 'custom', type: 1, desc: 'Custom', default: 1 },
  ]
};

export const inventoryOptions = {
  keys: {
    rearrange: 'Rearrange',
    expand: 'Expand',
  }
};

export const limitOptions = {
  searchText: 10,
  listView: isMobile ? 25 : 50,
  inventoryView: isMobile ? 25 : 70,
  recipes: 20
};

export const placeOrderViews = {
  list: 'list',
  inventory: 'inventory',
};

export const itemCart = {
  min: 1,
  max: 10000,
};

export const delay = {
  cart: 1000,
  cartCheck: 2000,
  onHand: 200,
  tap: 500,
  touchDelta: 10
};

export const onHandTypes = {
  cs: 'cs',
  uom: 'uom',
  pack : 'pack'
};

export const inventoryItemTypes = {
  custom: 'custom',
  subrecipe: 'subrecipe',
  generic: 'item'
};

export const cartAnimations =  {
  type : {
    none : null,
    cartUpdated : 'cartUpdated',
    rebatesEarned : 'rebatesEarned',
    savingItemAdded: 'savingItemAdded',
    specialRequest: 'specialRequest'
  },
  delay: 5000,
};

export const processStatus = {
  start: 'start',
  processing: 'processing',
  end: 'end'
};

export const savings = {
  exactMatch: 'ExactMatch',
  similarMatch: 'SimilarMatch'
};

export const orderImageUpload = {
  allowTypes: ["application/pdf", "image/png", "image/jpeg", "image/jpg"],
  downloadTypes: ["pdf"],
  displayTypes: ["png", "jpeg", "jpg"],
  sizeLimit: 24000000
};

export const cameraConstraints = {
  video: {
    facingMode: "environment",
    height: {
      min: 576,
      ideal: 720,
      max: 1080
    },
    width: {
      min: 1024,
      ideal: 1280,
      max: 1920
    }
  }
};

export const validateRequired = {
  validate: {
    value: {
      length: {
        minimum: 1
      }
    }
  }
};

export const validateFraction = {
  format: {
    pattern: "[0-9]+[\/][0-9]+"
  }
};

export const validateState = {
  validate: {
    value: {
      format: {
        pattern: "[A-Za-z]+"
      },
      length: {
        maximum: 2
      }
    }
  }
};

export const validateZipcode = {
  format: {
    pattern: "^-?[0-9]*$"
  },
  length: {
    maximum: 5
  }
};

export const usState = [
  {
    "name": "Alabama",
    "abbreviation": "AL"
  },
  {
    "name": "Alaska",
    "abbreviation": "AK"
  },
  {
    "name": "Arizona",
    "abbreviation": "AZ"
  },
  {
    "name": "Arkansas",
    "abbreviation": "AR"
  },
  {
    "name": "California",
    "abbreviation": "CA"
  },
  {
    "name": "Colorado",
    "abbreviation": "CO"
  },
  {
    "name": "Connecticut",
    "abbreviation": "CT"
  },
  {
    "name": "Delaware",
    "abbreviation": "DE"
  },
  {
    "name": "District Of Columbia",
    "abbreviation": "DC"
  },
  {
    "name": "Florida",
    "abbreviation": "FL"
  },
  {
    "name": "Georgia",
    "abbreviation": "GA"
  },
  {
    "name": "Hawaii",
    "abbreviation": "HI"
  },
  {
    "name": "Idaho",
    "abbreviation": "ID"
  },
  {
    "name": "Illinois",
    "abbreviation": "IL"
  },
  {
    "name": "Indiana",
    "abbreviation": "IN"
  },
  {
    "name": "Iowa",
    "abbreviation": "IA"
  },
  {
    "name": "Kansas",
    "abbreviation": "KS"
  },
  {
    "name": "Kentucky",
    "abbreviation": "KY"
  },
  {
    "name": "Louisiana",
    "abbreviation": "LA"
  },
  {
    "name": "Maine",
    "abbreviation": "ME"
  },
  {
    "name": "Maryland",
    "abbreviation": "MD"
  },
  {
    "name": "Massachusetts",
    "abbreviation": "MA"
  },
  {
    "name": "Michigan",
    "abbreviation": "MI"
  },
  {
    "name": "Minnesota",
    "abbreviation": "MN"
  },
  {
    "name": "Mississippi",
    "abbreviation": "MS"
  },
  {
    "name": "Missouri",
    "abbreviation": "MO"
  },
  {
    "name": "Montana",
    "abbreviation": "MT"
  },
  {
    "name": "Nebraska",
    "abbreviation": "NE"
  },
  {
    "name": "Nevada",
    "abbreviation": "NV"
  },
  {
    "name": "New Hampshire",
    "abbreviation": "NH"
  },
  {
    "name": "New Jersey",
    "abbreviation": "NJ"
  },
  {
    "name": "New Mexico",
    "abbreviation": "NM"
  },
  {
    "name": "New York",
    "abbreviation": "NY"
  },
  {
    "name": "North Carolina",
    "abbreviation": "NC"
  },
  {
    "name": "North Dakota",
    "abbreviation": "ND"
  },
  {
    "name": "Ohio",
    "abbreviation": "OH"
  },
  {
    "name": "Oklahoma",
    "abbreviation": "OK"
  },
  {
    "name": "Oregon",
    "abbreviation": "OR"
  },
  {
    "name": "Pennsylvania",
    "abbreviation": "PA"
  },
  {
    "name": "Puerto Rico",
    "abbreviation": "PR"
  },
  {
    "name": "Rhode Island",
    "abbreviation": "RI"
  },
  {
    "name": "South Carolina",
    "abbreviation": "SC"
  },
  {
    "name": "South Dakota",
    "abbreviation": "SD"
  },
  {
    "name": "Tennessee",
    "abbreviation": "TN"
  },
  {
    "name": "Texas",
    "abbreviation": "TX"
  },
  {
    "name": "Utah",
    "abbreviation": "UT"
  },
  {
    "name": "Vermont",
    "abbreviation": "VT"
  },
  {
    "name": "Virginia",
    "abbreviation": "VA"
  },
  {
    "name": "Washington",
    "abbreviation": "WA"
  },
  {
    "name": "West Virginia",
    "abbreviation": "WV"
  },
  {
    "name": "Wisconsin",
    "abbreviation": "WI"
  },
  {
    "name": "Wyoming",
    "abbreviation": "WY"
  }
];


export const unitsOfMeasure = [
  { id: 'lb', name: "lb" },
  { id: 'pint', name: "pint" },
  { id: 'oz', name: "oz" },
  { id: 'bunch', name: "bunch" },
  { id: 'ea', name: "each" },
  { id: 'cs', name: "case" }
];

export const packSizeUomsConversion = [
  { id: 12774, label: "in", type: "Length" },
  { id: 12777, label: "m", type: "Length" },
  { id: 12775, label: "yd", type: "Length" },
  { id: 19, label: "lb", type: "Mass" },
  { id: 20, label: "oz", type: "Mass" },
  { id: 16, label: "kg", type: "Mass" },
  { id: 21, label: "ct", type: "Mass" },
  { id: 17, label: "g", type: "Mass" },
  { id: 3549, label: "fl oz", type: "Volume" },
  { id: 12, label: "l", type: "Volume" },
  { id: 15, label: "cup", type: "Volume" },
  { id: 3548, label: "quart", type: "Volume" },
  { id: 3547, label: "gal", type: "Volume" },
  { id: 9, label: "ml", type: "Volume" },
  { id: 31, label: "pint", type: "Volume" },
  { id:15726, label:"tsp", type :"Amount"},
  { id:15727, label:"tbsp", type :"Amount"},
];

export const packSizeUomsCustomItem = [
  { id: 12782, shorthand: "ft", type_id: 6 },
  { id: 16, shorthand: "kg", type_id: 8 },
  { id: 17, shorthand: "g", type_id: 8 },
  { id: 19, shorthand: "lb", type_id: 8 },
  { id: 20, shorthand: "oz", type_id: 8 },
  { id: 9, shorthand: "ml", type_id: 13 },
  { id: 12, shorthand: "l", type_id: 13 },
  { id: 31, shorthand: "pint", type_id: 13 },
  { id: 3547, shorthand: "gal", type_id: 13 },
  { id: 3549, shorthand: "fl oz", type_id: 13 },
  { id: 30, shorthand: "qt", type_id: 14 },
  { id: 39, shorthand: "ct", type_id: 14 },
  { id: 207, shorthand: "bag", type_id: 14 },
  { id: 343, shorthand: "tub", type_id: 14 },
  { id: 383, shorthand: "bunch", type_id: 14 },
  { id: 385, shorthand: "can", type_id: 14 },
  { id: 412, shorthand: "cans", type_id: 14 },
  { id: 15726, shorthand: "tsp", type_id: 14 },
  { id: 15727, shorthand: "tbsp", type_id: 14 }
];

export const getTypeConversion = {
  'ml': 'Volume',
  'l': 'Volume',
  'lt': 'Volume',
  'cup': 'Volume',
  'ga': 'Volume',
  'gal': 'Volume',
  'qt': 'Volume',
  'quart': 'Volume',
  'fl oz': 'Volume',
  'pt': 'Volume',
  'pint': 'Volume',
  'kg': 'Mass',
  'gr': 'Mass',
  'g': 'Mass',
  'lb': 'Mass',
  'oz': 'Mass',
  'z': 'Mass',
  'ct': 'Mass',
  'm': 'Length',
  'in': 'Length',
  '"': 'Length',
  "'": 'Length',
  'ft': 'Length',
  'pies': 'Length',
  'yd': 'Length'
};

export const baseValueConversion = {
  Volume: {
    'ml': 1000,
    'l': 1,
    'lt': 1,
    'cup': 4.22675,
    'ga': 0.264172,
    'gal': 0.264172,
    'qt': 1.05669,
    'quart': 1.05669,
    'fl oz': 33.814,
    'oz': 33.814,
    'z': 33.814,
    'pt': 2.11337642,
    'pint': 2.11337642
  },
  Mass: {
    'kg': 1,
    'gr': 1000,
    'g': 1000,
    'lb': 2.20462,
    'oz': 35.274,
    'z': 35.274,
    'ct': 0.157473
  },
  Length: {
    'm': 1,
    'in': 39.3701,
    '"': 39.3701,
    'ft': 3.28084,
    'pies': 3.28084,
    "'": 3.28084,
    'yd': 1.09361
  }
};

export const windowBase = {
  desktop: { width: 1366, height: 768 }
};

export const orderItemFields = {
  orderId: 'order_item_id',
  description: 'item_description',
  unitPrice: 'merchant_unit_price',
  price: 'merchant_price',
  unitQuantity: 'unitQuantity',
  unitSize: 'unitSize',
  packSizeUnit: 'packSizeUnit',
  packSize: 'pack_size',
  total: 'total'
};

export const OGPAccessTypeOptions = [
  { value: 'none', label: "No Access", showCalendar: 0 },
  { value: 'unlimited', label: "Unlimited", showCalendar: 0 },
  { value: 'limited', label: "Limited", showCalendar: 1 }
];

export const OGExpirationDays = 7;
