import React, { Fragment } from 'react'
import './iconTooltip.scss';
import { Tooltip, Col } from 'reactstrap';
import useInput from '../SharedEffects/useInput';
import ShowIf from '../ShowIf/ShowIf';

const IconTooltipSolid = (props) => {
  const { Icon, elemId, children, color, colorIcon, invoiceDate } = props;
  const { toggle, value } = useInput.useCheckbox();


  return (
    <Fragment>
      <div className='d-inline-flex mx-1' id={`${elemId}`} >
        <Icon color={color} size={17} />
      </div>
      <ShowIf show={invoiceDate}>
        <Tooltip innerClassName='TooltipCustom shadow solid w-320px'
          placement='top'
          autohide={false}
          isOpen={value}
          target={elemId}
          toggle={toggle}
        >
          {children}
        </Tooltip>
      </ShowIf>

    </Fragment >
  )
};

export default IconTooltipSolid;
