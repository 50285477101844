import React, { Fragment, useState } from 'react';
import { Modal, ModalBody, ModalFooter, Button, ModalHeader, Row, Col, Input } from 'reactstrap';
import './UserInputsModal.scss'
import useModal from '../useModal';
import { isMobile } from "react-device-detect";

const UserInputsModal = (props) => {
    const { id, header, onOk, usernameP } = props;

    const [username, setUsername] = useState(usernameP);
    const [password, setPassword] = useState('');


    const modalprops = useModal(id);

    const onClickOk = () => {
        if (onOk) {
            onOk(username, password);
        };
        modalprops.toggle();
    };
    const cancel = () => {
        modalprops.toggle();
    }


    return (

        <Modal {...modalprops} backdrop='static' keyboard={false} className={isMobile ? 'selectModal mobile' : 'selectModal'} >
            <ModalHeader toggle={modalprops.toggle} >{header}</ModalHeader>
            <ModalBody>
                <Fragment>
                    <Row className='px-3 py-2 text-gray'>
                        Website Username
                    </Row>
                    <Row className='px-3'>
                        <Input type='text' value={username} onChange={e => setUsername(e.target.value)} />
                    </Row>
                    <Row className='px-3 py-2 text-gray' >
                        Website Password
                    </Row>
                    <Row className='px-3'>
                        <Input type='password' value={password} onChange={e => setPassword(e.target.value)} />
                    </Row>

                </Fragment>
            </ModalBody>
            <ModalFooter className="justify-content-between">
                <Button color="white" onClick={cancel}>Cancel </Button>
                <Button color="green" onClick={onClickOk}>Submit </Button>
            </ModalFooter>
        </Modal>
    );
};

export default UserInputsModal;

