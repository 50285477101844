import React, { useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { isMobile } from "react-device-detect";
import ShowIf from '../../ShowIf/ShowIf';
import { saveOpen, saveModal } from '../../../reducers/connection/connectionActions';
import { useSelector, useDispatch } from 'react-redux';
import useModal from '../useModal';

const ConnectionModal = (props) => {
  const { id, title, message, onOk, btnLabel, btnSize, footerClass, color } = props;
  const showTitle = title ? true : false;
  const modalprops = useModal(id);
  const dispatch = useDispatch();
  const currentOpen = useSelector(({ connection }) => connection.open);
  const currentModal = useSelector(({ connection }) => connection.modal);
  const modals = useSelector(({ modals }) => modals.modals);

  const onClickOk = () => {
    if (onOk) {
      onOk();
    };
    dispatch(saveOpen(false));
    dispatch(saveModal(null));
    modalprops.toggle();
  };

  useEffect(() => {
    if (currentOpen) {
      currentModal.modalprops.toggle();
    }
    const newModal = modals[modals.length - 1];
    const cnModal = { id: newModal.id, modalprops: modalprops };
    dispatch(saveOpen(true));
    dispatch(saveModal(cnModal));
  }, []);


  return (
    <Modal {...modalprops} backdrop='static' keyboard={false}>
      <ShowIf show={showTitle}>
        <ModalHeader className='text-center'>{title}</ModalHeader>
      </ShowIf>
      <ModalBody className='text-center'>
        {message}
      </ModalBody>
      <ModalFooter className={footerClass}>
        <div className="text-center w-100">
          <Button color={color} size={btnSize} onClick={onClickOk}>{btnLabel}</Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

ConnectionModal.defaultProps = {
  footerClass: '',
  btnLabel: 'Ok!',
  btnSize: 'sm',
  color: 'green'
};

export default ConnectionModal;