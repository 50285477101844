import React from "react";
import ListGroup from "../../../../../SharedComponents/ListGroup/ListGroup";
import ListGroupItem from "../../../../../SharedComponents/ListGroup/ListGroupItem";
import SavingSuggestion from "./SavingSuggestion";

const SavingsSuggestions = (props) => {
  const { suggestions } = props;

  return (
    <ListGroup title="Savings Suggestions" className={props.className}>
      {suggestions.map((item) => {
        return (
          <ListGroupItem id={`saving_${item.itemDistributorId}`} key={item.itemDistributorId}>
            <SavingSuggestion item={item} />
          </ListGroupItem>
        );
      })}
    </ListGroup>
  );
};

export default SavingsSuggestions;
