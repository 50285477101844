import React, { useLayoutEffect } from 'react';
import numeral from 'numeral';
import Highcharts from 'highcharts';
import { Row, Col } from 'reactstrap';

const OrdersGraph = (props) => {
  let currentDistributor;

  const humanizeString = (str) => {
    str = '' + str;
    str = str.split(/(?=[A-Z])/);
    str = str.join(' ');
    return str.charAt(0).toUpperCase() + str.substr(1);
  };

  const getRandomColor = () => {
    let letters = '0123456789ABCDEF'.split('');
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const addTitle = (chart, totalOrdersAmount) => {
      if (chart.title) { chart.title.destroy(); }
      if (chart.title2) { chart.title2.destroy(); }
      let r = chart.renderer,
          x = chart.series[0].center[0] + chart.plotLeft,
          y = chart.series[0].center[1] + chart.plotTop;
      chart.title = r.text('<b>' + totalOrdersAmount + '</b>', 0, 0)
        .css({ color: '#143357', fontSize: '22px', 'line-height': '22px', 'font-family': 'Roboto-Regular' })
        .hide()
        .add();
      chart.title2 = r.text('Total Orders', 0, 0)
        .css({ color: '#143357', fontSize: '16px', 'line-height': '16px', 'font-family': 'Roboto-Regular' })
        .hide()
        .add();
      let bbox = chart.title.getBBox();
      let bbox2 = chart.title2.getBBox();
      chart.title.attr({ x: x - (bbox.width / 2), y: y - 10 }).show();
      chart.title2.attr({ x: x - (bbox2.width / 2), y: y + (bbox.width / 4) + 10 }).show();
  };

  const setGeneralParams = (type, typeId, name) => {
    const distributorId = typeId;
    const distributorName = name;
    currentDistributor = {distributorId, distributorName};
    if (props.onDistributorClick) {
      props.onDistributorClick(currentDistributor);
    }
  };

  const ordersGraph = () => {
    if (!props.analyticsData || !props.analyticsData.total_orders || !props.analyticsData.total_orders.detail) {
      return;
    }

    let seriesDistributor = [];
    let totalOrdersAmount = props.analyticsData.total_orders.total || 0;
    if (props.analyticsData.total_orders.detail) {
      for (let i = 0; i < props.analyticsData.total_orders.detail.distributorData.length; i++) {
        props.analyticsData.total_orders.detail.distributorData[i].color = (props.analyticsData.total_orders.detail.distributorData[i].color !== null) ? "#" + props.analyticsData.total_orders.detail.distributorData[i].color : getRandomColor();
        let dataObjDist = {
          LocationName: props.analyticsData.total_orders.detail.distributorData[i].distributor,
          y: props.analyticsData.total_orders.detail.distributorData[i].total,
          distributorId: props.analyticsData.total_orders.detail.distributorData[i].distributor_id,
          color: props.analyticsData.total_orders.detail.distributorData[i].color
        };
        seriesDistributor.push(dataObjDist);
      }

      let totalOrdersGraph = new Highcharts.chart('container_orders', {
        chart: {
          height: (5 / 10 * 100) + '%',
          margin: [0, 350, 0, 0],
          plotBackgroundColor: null,
          plotBorderWidth: 0,
          plotShadow: false,
          events: {
            load: function () { addTitle(this, totalOrdersAmount); },
            redraw: function () { addTitle(this, totalOrdersAmount); }
          }
        },
        tooltip: {
          formatter: function () {
            return '<span>' + this.point.LocationName + ' : ' + '<b>' + this.y + '</b></span>';
          }
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          pie: {
            size: 250,
            dataLabels: {
              enabled: true,
              distance: -50,
              style: {
                fontWeight: 'bold',
                color: 'white'
              }
            },
            startAngle: -90,
            endAngle: 270,
            center: ['50%', '50%']
          },
          series: {
            point: {
              events: {
                legendItemClick: function (e) {
                  setGeneralParams('Distributor', this.distributorId, this.LocationName);
                  let color = Highcharts.color;
                  let series = this.series;
                  for (let i = 0; i < series.data.length; i++) {
                    let point = series.data[i];
                    if (point === this) {
                      point.update({
                        //color: series.chart.options.colors[this.index]
                      });
                    } else {
                      point.update({
                        //color: color(point.color).setOpacity(this === point ? 1 : 0.3).get()
                      });
                    }
                  }
                  return false;
                },
                click: function (e) {
                  setGeneralParams('Distributor', this.distributorId, this.LocationName);
                }
              }
            },
            states: {
              hover: {
                enabled: true,
                halo: {
                  size: 0
                }
              }
            }
          }
        },
        series: [{
          type: 'pie',
          name: 'Total Orders',
          size: '90%',
          innerSize: '96%',
          data: seriesDistributor,
          showInLegend: true,
          dataLabels: {
            enabled: false
          }
        }],
        legend: {
          layout: 'vertical',
          align: 'right',
          verticalAlign: 'middle',
          width: 300,
          // margin: 2,
          useHTML: true,
          padding: 0,
          itemMarginTop: 10,
          itemMarginBottom: 10,
          x: -10,
          labelFormatter: function () {
            const distributorId = currentDistributor && currentDistributor.distributorId || null;
            if (this.distributorId === distributorId) {
              return '<div style="color:#006DBA;">' + this.LocationName + ' <span class="ml-1">' + this.y + '</span></div>';
            } else {
              return '<div>' + this.LocationName + ' <span class="ml-1">' + this.y + '</span></div>';
            }
          }
        },
      });

    }
  };

  useLayoutEffect(() => {
    ordersGraph();
  }, [props.analyticsData]);

  return (
    <Row className='text-center mb-2'>
      <Col xs={12}>
        <div style={{display:'flex', height: '400px'}}>
          <div id='container_orders' style={{height: '400px', maxWidth: '100%', margin: '0 auto'}}></div>
        </div>
      </Col>
    </Row>
  );
};

export default OrdersGraph;
