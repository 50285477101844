import React from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';
import './tourmodal.scss';
import { useSelector } from 'react-redux';
import tipTour from '../../../assets/images/tip-tour.png'
import { isMobile } from "react-device-detect";

const WelComeTourModal = (props) => {
    const { openModal, tourElement, skipDemo, goToNextTourModal } = props;

    console.log(tourElement)
    let positions = "";
    const numTourModal = useSelector(state => state.modals.numTourModal);

    let styleModal = {};
    let arrowClass = '';
    let firstText = ``;
    let secondText = ``
    let imgClass = '';

    switch (numTourModal) {
        case 1:
            firstText = `All the cards on the table!`;
            secondText = `This section allows you to purchase from all your current vendors. Look at all your options for purchasing a product in one view.`
            if (isMobile) {
                styleModal = {
                    margin: 'auto',
                    top: '20%'
                }
                arrowClass = '';
                imgClass = 'hiddeImg';
            } else {

                styleModal = {
                    // top: `calc(${positions.top - 40}px)`,
                    // left: `${positions.right}px`,
                    // position: "absolute"
                    left: "276px",
                    position: "absolute",
                    top: "164px"
                };
                arrowClass = 'arrowLeft';
                imgClass = 'imgLeft';
            }
            break;

        case 2:
            firstText = `Time to Search and Rescue`;
            secondText = `This section allows you to search for generic items within the selected Inventory list`
            arrowClass = 'arrowLeft';
            imgClass = 'imgLeft';

            if (isMobile) {
                styleModal = {
                    margin: 'auto',
                    top: '60px'
                }
                arrowClass = 'arrowTop';
                imgClass = 'imgTop';
            } else {
                // positions = tourElement.current.getBoundingClientRect();
                styleModal = {
                    // top: `calc(${positions.top - 40}px)`,
                    // left: `${positions.right + 100}px`,
                    // position: "absolute"
                    left: "40%",
                    position: "absolute",
                    top: "-18.5px"
                }

            }

            break;

        case 3:
            firstText = `You can have whatever you like!`;
            secondText = `This section allows you to select distributors you want to see results from. You can also filter by past orders or shopping lists.`
            arrowClass = 'arrowRight';
            imgClass = 'imgRight';

            if (isMobile) {
                styleModal = {
                    margin: 'auto',
                    top: '60px'
                }
                arrowClass = 'arrowTopRight';
                imgClass = 'imgTopRight';
            } else {
                styleModal = {
                    // top: `calc(${positions.top - 40}px)`,
                    // left: `${positions.right - 330}px`,
                    // position: "absolute"
                    right: "13%",
                    position: "absolute",
                    top: "-10px"
                }
            }

            break;


        case 4:
            firstText = `Make it your own`;
            secondText = `Use our simple "inventory view" to set up an easy way to count your items, view par levels and even order shelf-to-sheet! Simply count your product by entering the on hand amount, you'll get the total on hand value automatically calculated for you at the end. Inventory made easy!`
            arrowClass = 'arrowRight';
            imgClass = 'imgRight';
            if (isMobile) {
                styleModal = {
                    margin: 'auto',
                    top: '120px'
                }
                arrowClass = 'arrowTopRight';
                imgClass = 'imgTopRight';
            } else {
                styleModal = {
                    // top: `calc(${positions.top + 33}px)`,
                    // left: `${positions.right - 200}px`,
                    // position: "absolute"
                    right: '5%',
                    position: "absolute",
                    top: "63px"
                }

            }


            break;
        default:
            break;
    }

    return (
        <Modal className="modalTour" isOpen={openModal} style={styleModal}>
            <ModalBody>

                <div className={arrowClass}>
                </div>
                <h1>{firstText}</h1>
                <img src={tipTour} className={imgClass}></img>

                <p>{secondText}</p>
                <div className="buttons">
                    <Button color="green" onClick={goToNextTourModal}>Got It</Button>
                    <span onClick={skipDemo}>Skip Demo</span>
                </div>

            </ModalBody>
        </Modal>
    );
};

export default WelComeTourModal;