import React, { Fragment } from 'react';
import { Col, Tooltip } from 'reactstrap';
import useInput from '../SharedEffects/useInput';
import { IoMdHeart, IoMdHeartEmpty } from 'react-icons/io';
import ShowIf from '../ShowIf/ShowIf';
import { colors } from '../../config/constants';
import { isMobile } from 'react-device-detect';

const HeartBookmark = ({elemId, fill, onClick}) => {
  const tooltip = useInput.useCheckbox(false);
  return (
    <Fragment>
      <Col className='w-fc px-1' id={`${elemId}_heart`} onClick={onClick}>
        <ShowIf show={!fill}>
          <IoMdHeartEmpty className='clickable' size={20} color={colors.lightBlueText} />
        </ShowIf>
        <ShowIf show={fill}>
          <IoMdHeart className='clickable' size={20} color={colors.red} />
        </ShowIf>
      </Col>
      <ShowIf show={!isMobile}>
        <Tooltip innerClassName='text-tooltip' placement='top-start'
          hideArrow={true} isOpen={tooltip.value}
          toggle={tooltip.toggle}
          target={`${elemId}_heart`}>
            Preferred
        </Tooltip>
      </ShowIf>
    </Fragment>
  );
};

export default HeartBookmark