import itemApi from "../../../api/itemApi"
import { useState, useEffect, useRef } from "react";
import useInput from "../../SharedEffects/useInput";
import recipeApi from "../../../api/recipeApi";
import { useSelector, useDispatch } from 'react-redux';
import useConnection from '../../OfflineMode/useConnection';
import AlertModal from '../AlertModal/AlertModal';
import { showModal } from "../../../reducers/modals/modalsActions";

const useApi = (filters, linkItems, idLabel, initSearch, includeSubrecipe, disabled) => {
  const [ items, setItems ] = useState([]);
  const [ subrecipeItems, setSubrecipeItems ] = useState([]);
  const [ searchEnter, setSearchEnter ] = useState(initSearch);
  const [ page, setPage ] = useState(1);
  const [ loadMore, setLoadMore] = useState(true);
  const [ isLoading, setIsLoading] = useState(false);
  const prevFilters = useRef(filters);
  const search = useInput.useText(initSearch);
  const dispatch = useDispatch();

  const isAllLink = () => {
    let all = true;
    for (let i = 0; i < items.length; i++) {
      let found = false;
      for (let ii = 0; ii < linkItems.items.length; ii++) {
        const item = items[i];
        const linkItem = linkItems.items[ii];
        if (item[idLabel] === linkItem[idLabel]) {
          found = true;
        }
      }
      if (!found) {
        all = false;
        break;
      }
    }

    return all;
  };

  const linkAll = () => {
    if (isAllLink()) {
      linkItems.clear();
    } else {
      linkItems.add(items);
    }
  };
  
  const getListItems = (page, items, filters, search, loadMoreForce) => {
    if(disabled){
      dispatch(showModal(AlertModal, {message:'Please enable online mode to add a new generic ingredient.'}));
      return;
    }
    if (page && (loadMore || loadMoreForce)) {
      setIsLoading(true);
      const sort = {id:7, name:"relevant", type:1, desc:"Sort by relevance"};
      itemApi.getList(sort, filters, page, search).then(res => {
        setIsLoading(false);
        const loadMoreNew = res._meta.pageCount > res._meta.currentPage;
        const pageNew = loadMoreNew  ? page + 1 : 1;
        const data = res.data.map(d => {
          return {
            ...d,
            uniqid: idLabel+'_'+d[idLabel],
            quantity: 0
          };
        })
        setLoadMore(loadMoreNew);
        if (page === 1) {
          setItems(data);
        } else {
          setItems(items.concat(data));
        }
        setPage(pageNew);
      });
    }
  };

  const onClear = () => {
    search.clearValue();
    setSearchEnter('');
    setPage(1);
    setLoadMore(true);
    setIsLoading(false);
    setItems([]);
    // Set reset values, values get update in the next render
    getListItems(1, [], filters, "", true);
  };

  const onSearch = (s) => {
    setPage(1);
    setLoadMore(true);
    setIsLoading(false);
    setItems([]);
    setSearchEnter(s);
    // Set reset values, values get update in the next render
    getListItems(1, [], filters, s, true);
  };

  useEffect(() => {
    if ( JSON.stringify(filters) !== JSON.stringify(prevFilters.current)) {
      setPage(1);
      setLoadMore(true);
      setIsLoading(false);
      setItems([]);
      // Set reset values, values get update in the next render
      getListItems(1, [], filters, search.value);
      prevFilters.current = filters;
    }
  }, [filters]);

  const editQuantity = (uniqid, newQuantity) => {
    setItems(items.map(i => {
      if (i.uniqid !== uniqid) return i;
      return {
        ...i,
        quantity: newQuantity
      }
    }))
  };

  const getSubrecipeFirst = (cb) => {
    if (!includeSubrecipe) {
      cb();
    } else {
      let page = 1;
      let subrecipes = [];
      const load = () => {
        recipeApi.getAllSubrecipes(page).then((res) => {
          subrecipes.push(...res.data.map(d => {
            return {
              ...d,
              uniqid: 'subrecipeId_'+d.id,
              subRecipeId: d.id
            }
          }));
          const loadMore = res._meta.pageCount > res._meta.currentPage;
          if (loadMore) {
            page = page + 1;
            load();
          } else {
            setSubrecipeItems(subrecipes);
            cb();
          }
        }).catch(err => console.error(err));
      };
      load();
    }
  };

  useEffect(() => {
    getSubrecipeFirst(() => {
      getListItems(page, items, filters, search.value);
    });
  }, []);

  return {
    page, items, getListItems, loadMore, isLoading, searchEnter,
    linkAll, isAllLink, onSearch, onClear, search, subrecipeItems, editQuantity
  }
};

const useFilters = (distributors, shoppingLists, pastOrders) => {
  const [ showFilters, setShowFilters ] = useState(false);
  const init = { distributorIds: [], shoppingIds: [], pastOrderIds: [] };
  const [ filters, setFilters ] = useState(init);

  const toggleFilters = () => {
    distributors.set(distributors.value.map(d => {
      return {
        ...d,
        check: filters.distributorIds.includes(d.id)
      };
    }))
    shoppingLists.set(shoppingLists.value.map(s => {
      return {
        ...s,
        check: filters.shoppingIds.includes(s.id)
      };
    }))
    pastOrders.set(pastOrders.value.map(o => {
      return {
        ...o,
        check: filters.pastOrderIds.includes(o.id)
      };
    }))
    setShowFilters(!showFilters);
  };

  const apply = () => {
    setFilters({
      distributorIds: distributors.value.filter(d => d.check).map(d => d.id),
      shoppingIds: shoppingLists.value.filter(s => s.check).map(s => s.id),
      pastOrderIds: pastOrders.value.filter(o => o.check).map(o => o.id),
    });
    toggleFilters();
  };

  const reset = () => {
    distributors.set(distributors.value.map(d => {
      return {
        ...d,
        check: false
      };
    }))
    shoppingLists.set(shoppingLists.value.map(s => {
      return {
        ...s,
        check: false
      };
    }))
    pastOrders.set(pastOrders.value.map(o => {
      return {
        ...o,
        check: false
      };
    }))
    setFilters(init);
  };

  return {
    toggleFilters, filters, setFilters, showFilters,
    actions: { apply, reset }
  };
};

const useLink = () => {
  const [ linkItems, setLinkItems ] = useState([]);

  const add = (comingItem, clear) => {
    const alreadyOnList = linkItems.map(i => { return i.uniqid})
    let item = [];
    if (Array.isArray(comingItem)) {
      item = comingItem;
    } else {
      item.push(comingItem);
    }
    if (clear) {
      setLinkItems(item);
    } else {
      setLinkItems(linkItems.concat(item.filter(i => !alreadyOnList.includes(i.uniqid))));
    }
  };

  const remove = (id) => {
    setLinkItems(linkItems.filter(l => l.uniqid !== id));
  };

  const clear = () => {
    setLinkItems([]);
  };

  return {
    add, remove, clear, items: linkItems, set: setLinkItems
  }
};

const useModalConnection = () => {
  const [disabled, setDisabled] = useState(false);
  let {status} = useConnection.useStatus();

  useEffect(() => {
    setDisabled(status ? false : true);
  }, [status]);

  return { disabled, setDisabled }
};

export default {
  useApi, useFilters, useLink, useModalConnection
};