import React, { useState, useEffect } from 'react';
import Header from '../../../../SharedComponents/Header/Header';
import InventoryViewContent from './InventoryViewContent';
import moment from 'moment';

const InventoryView = (props) => {

    const view = props.location.pathname.split('/')[2];
    const [title, setTitle] = useState('');


    useEffect(() => {
        switch (view) {
            case 'inventories':
                setTitle('inventories');
                break;
            case 'review-inventory':
                setTitle('Review Inventory');
                break;
            case 'inventories-detail':
                const itemInventory = props.location.state ? props.location.state.item : {
                    start_date: JSON.parse(localStorage.getItem('itemInventory')).start_date
                };
                setTitle(`Inventory Summary ${moment(itemInventory.start_date).format('LL')}`);
                break;
            default:
                break;
        }
    }, [view])

    return (
        <div>
            <Header showMenu={true} showLogo={false} showBack={false} title={title} showCart={true} />
            <InventoryViewContent />
        </div>
    );
};

export default InventoryView;
