import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import useDistributor from '../useDistributor';
import { isMobile } from 'react-device-detect';
import Header from '../../../../../SharedComponents/Header/Header';
import DistributorRow from "./DistributorRow";

const DistributorList = (props) => {
    const { distributors } = useDistributor.useApi(props);

    const distributorsMap = distributors.map(c => 
      <DistributorRow  key={c.id} id={c.id} data={c} />
    );

    const addDist = () => {
      console.log('Adding distributor')
    };

    const SubHeaderDistributor= (
        <Row className='header h-inherit px-4 py-4 align-items-center m-0 text-gray'>
            <Col xs={2} className='pl-5'>
                Distributor
            </Col>
            <Col xs={2} className='pl-5'>
                Email
            </Col>
            <Col xs={2} style={{paddingLeft:'4em', paddingRight:'0em'}}>
                Distributor Sales Rep Name
            </Col>
            <Col xs={2} style={{paddingLeft:'4em'}}>
                Phone Numbers
            </Col>
            <Col xs={2}>
                Last Price Update
            </Col>
            <Col xs={1} className='px-0'>
                Number of Items
            </Col>
            <Col xs={1} className='px-0'>
            </Col>            
        </Row>
    )

    if (isMobile) { 
        return (
            <Container fluid={true} className='px-0'>
                <Header showMenu={true} showLogo={isMobile ? false : true} showBack={false} title='Distributors' showCart={false} />
                {distributorsMap}
            </Container>
        )
    }

    return (
        <Container fluid={true} className='my-3' >
            <Header showMenu={true} showLogo={isMobile ? false : true} showBack={false} title='Distributors' showCart={false} >
                {SubHeaderDistributor}
            </Header>
            {distributorsMap}
        </Container>
    )
}

export default DistributorList;