import React, { useEffect, useState } from 'react'
import useModal from '../useModal';
import { modalTourInventoryViewClassName } from '../../../config/constants';
import { Modal, ModalBody, Button } from 'reactstrap';
import inventTutoImg1 from '../../../assets/images/inventory-tutorial-1.png';
import inventTutoImg2 from '../../../assets/images/inventory-tutorial-2.png';
import inventTutoImg3 from '../../../assets/images/inventory-tutorial-3.png';
import inventTutoImg4 from '../../../assets/images/inventory-tutorial-4.png';
import inventTutoImg5 from '../../../assets/images/inventory-tutorial-5.png';
import inventTutoImg6 from '../../../assets/images/inventory-tutorial-6.png';
import ShowIf from '../../ShowIf/ShowIf';
import { useDispatch } from 'react-redux';
import { toggleSidebar } from '../../../reducers/userInterface/userInterfaceActions';



const WhatInventoryViewModal = (props) => {
    const { id } = props;
    const modalProps = useModal(id);
    const dispatch = useDispatch();

    const [numInventoryTutorialView, setNumInventoryTutorialView] = useState(1);
    let params = {
        inventTutoImg: '',
        firstText: '',
        secondText: ''
    }
    const [paramsInventoryTutorialView, setParamsInventoryTutorialView] = useState(params);

    const nextView = () => {
        setNumInventoryTutorialView(numInventoryTutorialView + 1)
    };

    const backView = () => {
        setNumInventoryTutorialView(numInventoryTutorialView - 1)
    };

    const clickMenu = () => {
        dispatch(toggleSidebar());
    };



    useEffect(() => {
        switch (numInventoryTutorialView) {
            case 1:
                params.inventTutoImg = inventTutoImg1;
                params.firstText = `This little piggy went to the market`;
                params.secondText = `We want you to know what you are really getting. In this section you can build <br>
                              a view that will allow you to compare items across multiple distributors. Look <br>
                              at all your options for purcharsing a product in one quick view.`
                break;

            case 2:
                params.inventTutoImg = inventTutoImg2;
                params.firstText = `Organize your digital walk-in`;
                params.secondText = `Create your own title for the products you want to compare or group <br>
                                    together and organize your comparison view the way you have <br>
                                    organized your walk-ins or storage area. Cheers to easy ordering!`
                break;

            case 3:
                params.inventTutoImg = inventTutoImg3;
                params.firstText = `Now, Connect the dots`;
                params.secondText = `Link relevant products from different distributors to a <br>
                                    generic item name to keep them all in one place. <br>
                                    Let's get organized here!`
                break;

            case 4:
                params.inventTutoImg = inventTutoImg4;
                params.firstText = `Look both ways before you cross the street`;
                params.secondText = `Now that you have products in one spot, check out the prices that are most beneficial <br>
                                     to your kitchen at any given moment. See what prices are coming in each direction so you <br>
                                     make the best purcharse. HINT: Lowest price is always on the left hand side.`
                break;


            case 5:
                params.inventTutoImg = inventTutoImg5;
                params.firstText = `The Baked Good`;
                params.secondText = `See what was cooking this whole time. Check the prices and changes from all vendors over a period <br>
                                    of time. Look month to month to see how the product pricing has fluctuated. Foodbam can <br>
                                    negotiate contact pricing, if you see too much fluctuation from your vendor - call us so we can help!`
                break;

            case 6:
                params.inventTutoImg = inventTutoImg6;
                params.firstText = `The Final Frontier`;
                params.secondText = `1. "Edit" - edit generic items or link/unlink items. <br>
                                     2. "Purchase" - put that stuff in your cart and checkout! <br>
                                     3. "Analytics" - see how your pricing has been trending overtime.`
                break;

            default:
                modalProps.toggle();
                clickMenu();
                setNumInventoryTutorialView(1)
                break;
        }

        setParamsInventoryTutorialView(params);

    }, [numInventoryTutorialView])
    return (

        <Modal isOpen={modalProps.isOpen} modalClassName={modalTourInventoryViewClassName}>

            <ModalBody className='px-0 pt-0'>
                <div className='contentBody'>
                    <Button className='closeButton' close onClick={modalProps.toggle} />
                    <img src={paramsInventoryTutorialView.inventTutoImg}></img>
                </div>
                <div className='contentText'>
                    <span>{paramsInventoryTutorialView.firstText}</span>
                    <div dangerouslySetInnerHTML={{ __html: paramsInventoryTutorialView.secondText }} />

                </div>

                <div className={numInventoryTutorialView !== 1 ? 'contentButtons' : 'contentButtons buttonBlock'}>
                    <ShowIf show={numInventoryTutorialView !== 1}>
                        <Button className='colorWhite' color='default' onClick={backView}>Back</Button>
                    </ShowIf>
                    <Button className='colorGreen' color='green' onClick={nextView}>
                        {numInventoryTutorialView != 6 ? 'Next' : 'Get Started'}
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default WhatInventoryViewModal
