import { useState, useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import comparisonViewsApi from "../../../../../api/comparisonViewsApi";
import { saveInventoryList, saveInventoryItems, removeInventoryGroup, editOneInventoryGroup, saveView, sortInventoryGroups, saveInventoryItemsPage, saveInventoryItemsPageTotal, editOneInventoryGroupItem } from "../../../../../reducers/items/itemsActions";
import * as $ from 'jquery';
import { isMobile } from 'react-device-detect';
import AddFoodbamItemModal from '../../../../../SharedComponents/Modals/AddFoodbamItemModal/AddFoodbamItemModal';
import { showModal } from '../../../../../reducers/modals/modalsActions';
import { packSizeUomsConversion, limitOptions } from '../../../../../config/constants';
import OptionsModal from '../../../../../SharedComponents/Modals/OptionsModal/OptionsModal';
import QuestionModal from "../../../../../SharedComponents/Modals/QuestionModal/QuestionModal";
import useInput from "../../../../../SharedComponents/SharedEffects/useInput";
import validate from "validate.js";
import InventoryListModal from "../../../../../SharedComponents/Modals/InventoryListModal/InventoryListModal";
import { localStorageConst } from '../../../../../config/constants';
import moment from "moment";
import useConversions from '../../../../../SharedComponents/Conversion/useConversion';
import {findOneGroup, updateOneGroup} from '../../../../../local/ComparisonViewLocal';
import useConnection from '../../../../../SharedComponents/OfflineMode/useConnection';
import AlertModal from '../../../../../SharedComponents/Modals/AlertModal/AlertModal';

const useApi = () => {

  const dispatch = useDispatch();
  const items = useSelector(({ items }) => items.inventory.items);
  const sort = useSelector(({ items }) => items.inventory.sortBy);
  const listSelected = useSelector(({ items }) => items.inventory.listSelected);
  const search = useSelector(({ items }) => items.inventory.search);
  const [page, setPage] = useState(1);
  const [loadMore, setLoadMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const expandOne = items.some((i) => i.expand);
  const { convertAllBase } = useConversions.useConvertAll();

  const getInventoryList = (save) => {
    comparisonViewsApi.get().then(({ data }) => {
      if (save) {
        let listSelected = [];
        const invListSelected = JSON.parse(localStorage.getItem(localStorageConst.keys.listSelected));
        if (!invListSelected) {
          listSelected = data[0];
        } else {
          const listSelectedExists = data.filter(slist => slist.id === invListSelected.id)[0];
          listSelected = (!listSelectedExists) ? data[0] : listSelectedExists;
        }
        dispatch(saveInventoryList(data, listSelected));
      } else {
        dispatch(saveInventoryList(data));
      }
    });
  };

  const getInventoryItems = (page, search, loadMoreForce) => {
    const params = {
      page: page,
      limit: limitOptions.inventoryView,
      expand: 'items,purchased,onHandTotal,orderTotal,globalOnHandTotal,units',
      filterOptions: JSON.stringify({ sort: sort }),
      filters: search ? JSON.stringify([{ field: 'name', operator: 'like', value: search, table: 'generic_group' }]) : null,
      analytics: 1
    };
    if (page === 1 || (page > 1 && (loadMore || loadMoreForce))) {
      setIsLoading(true);
      comparisonViewsApi.groups(listSelected.id, params).then((res) => {
        setIsLoading(false);
        const loadMoreNew = res._meta.pageCount > res._meta.currentPage;
        const pageNew = page + 1;
        setLoadMore(loadMoreNew);
        setPage(pageNew);
        dispatch(saveInventoryItemsPage(pageNew));
        dispatch(saveInventoryItemsPageTotal(res._meta.pageCount));
        if (page === 1) {
          dispatch(saveInventoryItems(res.data.map(d => {
            return {
              ...d,
              parLevel: d.parLevel ? d.parLevel : 0,
              expand: !isMobile,
              items: d.items.map(i => {
                const itemEndDate = moment(i.endDate);
                const itemFullPrice = i.isCustomItem === 1 ? i.price :
                            ( itemEndDate.isValid() ?
                                (itemEndDate.isAfter(moment()) ? i.price : (i.isFromInvoice == 1 ? (i.purchasedPrice > 0 ? i.purchasedPrice : i.price) : i.price) ) :
                                (i.isPriceOutdated ? (i.isFromInvoice == 1 ? (i.purchasedPrice > 0 ? i.purchasedPrice : i.price) : i.price) : i.price)
                            );
                const { unfixedFinalUnitPrice } = convertAllBase(i, d.unitId, d.units, itemFullPrice);
                return {
                  ...i,
                  // analytics: i.analytics.length === 0 ? [] : [...i.analytics, {price: i.analytics[i.analytics.length-1].price, startDate: moment().format('YYYY-MM-DD')}],
                  isCustomPackSize: i.customPackSize ? !(i.customPackSize === i.packSize) : false,
                  finalUnitPrice: unfixedFinalUnitPrice,
                }
              })
            }
          })));
        } else {
          dispatch(saveInventoryItems([...items, ...res.data.map(d => {
            return {
              ...d,
              // expand: isMobile ? false : expandOne,
              expand: expandOne,
              parLevel: d.parLevel ? d.parLevel : 0,
              items: d.items.map(i => {
                const itemEndDate = moment(i.endDate);
                const itemFullPrice = i.isCustomItem === 1 ? i.price :
                            ( itemEndDate.isValid() ?
                                (itemEndDate.isAfter(moment()) ? i.price : (i.isFromInvoice == 1 ? (i.purchasedPrice > 0 ? i.purchasedPrice : i.price) : i.price) ) :
                                (i.isPriceOutdated ? (i.isFromInvoice == 1 ? (i.purchasedPrice > 0 ? i.purchasedPrice : i.price) : i.price) : i.price)
                            );
                const { unfixedFinalUnitPrice } = convertAllBase(i, d.unitId, d.units, itemFullPrice);
                return {
                  ...i,
                  isCustomPackSize: i.customPackSize ? !(i.customPackSize === i.packSize) : false,
                  finalUnitPrice: unfixedFinalUnitPrice,
                }
              })
            }
          })]));
        }
      });
    }
  };

  useEffect(() => {
    getInventoryList(true);
    dispatch(saveView('inventory'));
  }, []);

  useEffect(() => {
    if (listSelected) {
      getInventoryItems(1, search, true);
    }
  }, [sort, search]);

  useEffect(() => {
    if (listSelected) {
      dispatch(saveInventoryItems([]));
      getInventoryItems(1, search, true);
    }
  }, [listSelected]);

  return {
    getInventoryItems, getInventoryList, listSelected,
    items, loadMore, isLoading, page, sort
  };
};

const useHorizontalScroll = (id, item, items, isGroup) => {
  const [scrollPos, setScrollPos] = useState(0);
  const [w, setW] = useState(0);
  const canEditComparisonView = useSelector(({ user }) => user.editComparisonView) ? true : false;

  useLayoutEffect(() => {
    const w = $(`#g_${id}_scroll`).width();
    setW(w);
  }, [item]);

  const canScrollRight = () => {
    const len = !canEditComparisonView || isGroup ? items.length : items.length + 1;
    let last;
    if (isGroup) {
      last = isMobile ? (w / 2 * (len)) : (260 * (len) + 260);
    } else {
      last = isMobile ? (w / 2 * (len) + w / 2) : (260 * (len) + 260);
    }
    const nextScrollRight = isMobile ? (w / 2 * (scrollPos + 1)) : (260 * (scrollPos + 1));
    return (last - nextScrollRight) > w;
  };
  const scrollRight = () => {
    const nextScrollRight = isMobile ? (w / 2 * (scrollPos + 1)) : (260 * (scrollPos + 1));
    if (canScrollRight()) {
      $(`#g_${id}_scroll`).animate({ scrollLeft: nextScrollRight }, 200);
      setScrollPos(scrollPos + 1);
    }
  };
  const scrollLeft = () => {
    const nextScrollLeft = isMobile ? (w / 2 * (scrollPos - 1)) : (260 * (scrollPos - 1));
    const canScrollLeft = scrollPos > 0;
    if (canScrollLeft) {
      $(`#g_${id}_scroll`).animate({ scrollLeft: nextScrollLeft }, 200);
      setScrollPos(scrollPos - 1);
    }
  };

  const snapOffset = (e) => {
    const rawScroll = $(`#g_${id}_scroll`).scrollLeft();
    const rawPos = rawScroll / (w / 2) + 0.1;
    const newPos = Math.floor(rawPos);
    setScrollPos(newPos);
  };

  return {
    canScrollRight: canScrollRight(), scrollRight, scrollLeft, scrollPos, snapOffset
  }
};

const useAdd = (itemData) => {
  const dispatch = useDispatch();
  const { convertAllBase } = useConversions.useConvertAll();
  const {
    id, name, comparisonViewId, items
  } = itemData;
  const connection = useSelector(({connection}) => connection.online);

  const openAdd = (connectivity) => {
    if(!connectivity){
      dispatch(showModal(AlertModal, {message:'You need internet connectivity to use this feature', footerClass: 'justify-content-center'}));
      return;
    }

    const modalProps = {
      title: 'Link items to ' + name,
      initSearch: name,
      initItems: items,
      includeSubrecipe: true,
      isLink: true,
      idLabel: 'itemDistributorId',
      onAdd: (allItems) => {
        const params = {
          items: allItems.filter(i => !i.isSubRecipe && !i.isCustomItem).map(i => { return i.itemDistributorId }),
          subrecipes: allItems.filter(i => i.isSubRecipe).map(i => { return i.subRecipeId }),
        };
        comparisonViewsApi.patchGroup(comparisonViewId, id, params).then((res) => {
          comparisonViewsApi.getGroup(comparisonViewId, id).then(({ data }) => {
            dispatch(editOneInventoryGroup(id, {
              items: data.items.map(i => {
                const itemEndDate = moment(i.endDate);
                const itemFullPrice = i.isCustomItem === 1 ? i.price :
                            ( itemEndDate.isValid() ?
                                (itemEndDate.isAfter(moment()) ? i.price : (i.isFromInvoice == 1 ? (i.purchasedPrice > 0 ? i.purchasedPrice : i.price) : i.price) ) :
                                (i.isPriceOutdated ? (i.isFromInvoice == 1 ? (i.purchasedPrice > 0 ? i.purchasedPrice : i.price) : i.price) : i.price)
                            );
                const { finalUnitPrice } = convertAllBase(i, data.unitId, data.units, itemFullPrice);

                return {
                  ...i,
                  isCustomPackSize: i.customPackSize ? !(i.customPackSize === i.packSize) : false,
                  finalUnitPrice: finalUnitPrice,
                }
              })
            }))

            //For OfflineMode with internet connectivity
            if(!connection){
              findOneGroup(id).then((result) => {
                if(result){
                  if(result[0].items.length>0){
                    const data = result[0];
                    const nItems = data.items.map(i => {
                      const itemEndDate = moment(i.endDate);
                      const itemFullPrice = i.isCustomItem === 1 ? i.price :
                                  ( itemEndDate.isValid() ?
                                      (itemEndDate.isAfter(moment()) ? i.price : (i.isFromInvoice == 1 ? (i.purchasedPrice > 0 ? i.purchasedPrice : i.price) : i.price) ) :
                                      (i.isPriceOutdated ? (i.isFromInvoice == 1 ? (i.purchasedPrice > 0 ? i.purchasedPrice : i.price) : i.price) : i.price)
                                  );
                      const { finalUnitPrice } = convertAllBase(i, data.unitId, data.units, itemFullPrice);
                      return {
                        ...i,
                        isCustomPackSize: i.customPackSize ? !(i.customPackSize === i.packSize) : false,
                        finalUnitPrice: finalUnitPrice,
                      }
                    });
                    const params = {items : nItems};
                    updateOneGroup(params,id).then((res) => {
                    }).catch(err => console.error(err));
                  }
                }
              }).catch(err => console.error(err));
            }
          }).catch(err => console.error(err));
        }).catch(err => console.error(err));
      },
      onAddCustom: (data) => {
        const { packSize, unitMeasure, ...values } = data;
        const params = {
          ...values,
          unitMeasure: unitMeasure.name,
          packSizeVal: `${packSize.outerPack}/${packSize.innerPack} ${packSize.uom.shorthand}`
        };
        comparisonViewsApi.postGroupCustomItem(comparisonViewId, id, params).then(({ data }) => {
          comparisonViewsApi.getGroup(comparisonViewId, id).then(({ data }) => {
            dispatch(editOneInventoryGroup(id, {
              items: data.items.map(i => {
                const itemEndDate = moment(i.endDate);
                const itemFullPrice = i.isCustomItem === 1 ? i.price :
                            ( itemEndDate.isValid() ?
                                (itemEndDate.isAfter(moment()) ? i.price : (i.isFromInvoice == 1 ? (i.purchasedPrice > 0 ? i.purchasedPrice : i.price) : i.price) ) :
                                (i.isPriceOutdated ? (i.isFromInvoice == 1 ? (i.purchasedPrice > 0 ? i.purchasedPrice : i.price) : i.price) : i.price)
                            );
                const { finalUnitPrice } = convertAllBase(i, data.unitId, data.units, itemFullPrice);
                return {
                  ...i,
                  isCustomPackSize: i.customPackSize ? !(i.customPackSize === i.packSize) : false,
                  finalUnitPrice: finalUnitPrice,
                }
              })
            }))

            //For OfflineMode with internet connectivity
            if(!connection){
              findOneGroup(id).then((result) => {
                if(result){
                  if(result[0].items.length>0){
                    const data = result[0];
                    const nItems = data.items.map(i => {
                      const itemEndDate = moment(i.endDate);
                      const itemFullPrice = i.isCustomItem === 1 ? i.price :
                                  ( itemEndDate.isValid() ?
                                      (itemEndDate.isAfter(moment()) ? i.price : (i.isFromInvoice == 1 ? (i.purchasedPrice > 0 ? i.purchasedPrice : i.price) : i.price) ) :
                                      (i.isPriceOutdated ? (i.isFromInvoice == 1 ? (i.purchasedPrice > 0 ? i.purchasedPrice : i.price) : i.price) : i.price)
                                  );
                      const { finalUnitPrice } = convertAllBase(i, data.unitId, data.units, itemFullPrice);
                      return {
                        ...i,
                        isCustomPackSize: i.customPackSize ? !(i.customPackSize === i.packSize) : false,
                        finalUnitPrice: finalUnitPrice,
                      }
                    });
                    const params = {items : nItems};
                    updateOneGroup(params,id).then((res) => {
                    }).catch(err => console.error(err));
                  }
                }
              }).catch(err => console.error(err));
            }
          }).catch(err => console.error(err));
        }).catch(err => console.error(err));
      }
    };
    dispatch(showModal(AddFoodbamItemModal, modalProps));
  };

  return {
    openAdd
  };
};

const useConversion = (item, getInventoryItems) => {
  const dispatch = useDispatch();
  const units = [{ label: 'Original', value: { id: null } }, ...packSizeUomsConversion.map(u => { return { value: u, label: u.label } })];
  const [unit, setUnit] = useState(item.unitId ? units.filter(u => u.value.id === item.unitId)[0] : units[0]);
  const canEditComparisonView = useSelector(({ user }) => user.editComparisonView) ? true : false;
  const { convertAll } = useConversions.useConvertAll();

  const updateItems = (unitToConvert) => {
    // if(unitToConvert.label !== 'Original'){
      item.items.map(i => {
        const itemEndDate = moment(i.endDate);
        const itemFullPrice = i.isCustomItem === 1 ? i.price :
                            ( itemEndDate.isValid() ?
                                (itemEndDate.isAfter(moment()) ? i.price : (i.isFromInvoice == 1 ? (i.purchasedPrice > 0 ? i.purchasedPrice : i.price) : i.price) ) :
                                (i.isPriceOutdated ? (i.isFromInvoice == 1 ? (i.purchasedPrice > 0 ? i.purchasedPrice : i.price) : i.price) : i.price)
                            );

        const { finalUnitPrice } = convertAll(i, unitToConvert, itemFullPrice);
        const { isCustomItem, isSubRecipe, genericCustomItemId, subRecipeId, itemDistributorId } = i;
        if (isCustomItem) {
          dispatch(editOneInventoryGroupItem(item.id, genericCustomItemId, { finalUnitPrice: finalUnitPrice }, 'genericCustomItemId'));
        } else if (isSubRecipe) {
          dispatch(editOneInventoryGroupItem(item.id, subRecipeId, { finalUnitPrice: finalUnitPrice }, 'subRecipeId'));
        } else {
          dispatch(editOneInventoryGroupItem(item.id, itemDistributorId, { finalUnitPrice: finalUnitPrice }));
        }
      });
    // }
  };

  const onConvert = (u) => {
    comparisonViewsApi.postGroupUnit(item.id, u.value.id).then(res => {
      setUnit(u);
      updateItems(u);
    }).catch(err => console.error(err));
  };

  const onConvertMobile = () => {
    if (canEditComparisonView) {
      const modalProps = {
        title: 'Show Items In',
        options: units.map(u => {
          return {
            label: u.label,
            className: u.label === 'Original' ? 'text-light-blue' : '',
            onClick: () => {
              onConvert(u);
            }
          }
        })
      }
      dispatch(showModal(OptionsModal, modalProps));
    };
  };

  return {
    units, unit, onConvert: (!isMobile ? onConvert : onConvertMobile)
  }
};

const useAddGroups = (id) => {
  const [showAdd, setShowAdd] = useState({ top: false, down: false });
  const dispatch = useDispatch();

  const addTop = (conectivity) => {
    if(!conectivity){
      dispatch(showModal(AlertModal, {message:'You need internet connectivity to use this feature', footerClass: 'justify-content-center'}));
      return;
    }
    setShowAdd({ down: false, top: true });
  };

  const addDown = (conectivity) => {
    if(!conectivity){
      dispatch(showModal(AlertModal, {message:'You need internet connectivity to use this feature', footerClass: 'justify-content-center'}));
      return;
    }
    setShowAdd({ top: false, down: true });
  };

  const hideAll = () => {
    setShowAdd({ top: false, down: false });
  };

  useEffect(() => {
    if (showAdd.top) {
      const position = $("#add_top_" + id).offset().top;
      $("HTML, BODY").animate({
        scrollTop: position - 200
      }, 1200, 'swing', () => {
        $("#add_top_" + id + '_title').focus();
      });
    } else if (showAdd.down) {
      const position = $("#add_down_" + id).offset().top;
      $("HTML, BODY").animate({
        scrollTop: position - 200
      }, 1200, 'swing', () => {
        $("#add_down_" + id + '_title').focus();
      });
    }
  }, [showAdd])

  return {
    showAdd, addDown, addTop, hideAll
  };
};

const useMoveGroup = (group) => {
  const dispatch = useDispatch();
  const openMoveToInventory = (conectivity) => {
    if(!conectivity){
      dispatch(showModal(AlertModal, {message:'You need internet connectivity to use this feature', footerClass: 'justify-content-center'}));
      return;
    }
    dispatch(showModal(InventoryListModal, { isMove: true, group: group }));
  };

  return {
    openMoveToInventory
  };
};

const useDeleteGroup = (group) => {
  const dispatch = useDispatch();
  const {
    comparisonViewId, id
  } = group;

  const deleteGroupApi = () => {
    comparisonViewsApi.deleteGroup(comparisonViewId, id).then(() => {
      dispatch(removeInventoryGroup(id));
      dispatch(sortInventoryGroups());
    }).catch(err => console.error(err));
  };

  const deleteGroup = (conectivity) => {
    if(!conectivity){
      dispatch(showModal(AlertModal, {message:'You need internet connectivity to use this feature', footerClass: 'justify-content-center'}));
      return;
    }

    const modalProps = {
      message: group.belongsToRecipe ? 'Are you sure you want to delete this comparison view generic group, it will also remove those items from the recipe?' : 'Are you sure you want to delete this generic item?',
      leftButton: {
        title: 'Delete',
        color: 'orange',
        onClick: deleteGroupApi
      },
      rightButton: {
        title: 'Cancel',
        color: 'green'
      }
    };

    dispatch(showModal(QuestionModal, modalProps));
  };

  return {
    deleteGroup
  };
};

const useEditGroupName = (group) => {
  const {
    comparisonViewId, id, name
  } = group;

  const dispatch = useDispatch();
  const [isShowEdit, setIsShowEdit] = useState(false);
  const editInput = useInput.useText(name);


  const showEdit = () => {
    setIsShowEdit(true);
  };

  useEffect(() => {
    if (isShowEdit) {
      $(`#edit_name_input_${id}`).focus();
    }
  }, [isShowEdit]);

  const saveEdit = () => {
    setIsShowEdit(false);
    const r = validate.single(editInput.value, { length: { minimum: 3 } });
    if (!r) {
      const params = {
        name: editInput.value
      };
      comparisonViewsApi.patchGroup(comparisonViewId, id, params).then(() => {
        dispatch(editOneInventoryGroup(id, params));
      }).catch(err => console.error(err));
    } else {
      editInput.setValue(name);
    }
  };

  return {
    inputProps: { value: editInput.value, onChange: editInput.set, placeholder: 'Generic Item Name', id: `edit_name_input_${id}` },
    isShowEdit, showEdit, saveEdit
  };
};

const useGroupOptionsMenu = (addProps, openAdd, deleteGroup, showEdit, openMoveToInventory) => {
  const dispatch = useDispatch();

  const openGroupOptions = (conectivity) => {
    const menuProps = {
      options: [
        { label: 'Add New Item', onClick: () => { openAdd(conectivity) } },
        { label: 'Add New Generic Item Above', onClick: () => { addProps.addTop(conectivity) } },
        { label: 'Add New Generic Item Below', onClick: () => { addProps.addDown(conectivity) } },
        { label: 'Edit', onClick: showEdit },
        { label: 'Move', onClick: () => { openMoveToInventory(conectivity) } },
        { label: 'Delete', className: 'text-orange', onClick: () => { deleteGroup(conectivity) } },
      ]
    };

    dispatch(showModal(OptionsModal, menuProps));
  };

  return {
    openGroupOptions
  };
};

const useSorting = () => {

  const sortItems = (nitems, unitToConvert) => {
    // if (unitToConvert.label === 'Original') {
    //   nitems.sort((a, b) =>{
    //     return a.isPunchedOut - b.isPunchedOut ||  b.isSubRecipe - a.isSubRecipe || a.unitPrice - b.unitPrice || a.price - b.price
    //   });
    // }else{
      nitems.sort((a, b) =>{
        return a.isPunchedOut - b.isPunchedOut ||  b.isSubRecipe - a.isSubRecipe || a.finalUnitPrice - b.finalUnitPrice || a.price - b.price
      });
    // }

    return nitems;
  };

  return {
    sortItems
  }
};

const useStatusConnection = () => {
  const [conectivity, setConectivity] = useState(true);
  let {status} = useConnection.useStatus();

  useEffect(() => {
    setConectivity(status);
  }, [status]);

  return { conectivity, setConectivity }
};

export default {
  useApi, useHorizontalScroll, useAdd,
  useConversion, useAddGroups, useGroupOptionsMenu,
  useDeleteGroup, useEditGroupName, useMoveGroup,
  useSorting, useStatusConnection
};
