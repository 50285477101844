import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ReviewOrder from './ReviewOrder/ReviewOrder';
import ShippingAndPayment from "./ShippingAndPayment/ShippingAndPayment";
import FinalReview from './FinalReview/FinalReview';
import Savings from './Savings/Savings';

const CheckoutOrderingContent = () => {

  return (
    <Switch>
      <Route exact path='/checkout-ordering/review-order' component={ReviewOrder} />
      <Route exact path='/checkout-ordering/savings' component= {Savings} />
      <Route exact path='/checkout-ordering/shipping-payment' component={ShippingAndPayment} />
      <Route exact path='/checkout-ordering/final-review' component={FinalReview} />

    </Switch >
  );
};

export default CheckoutOrderingContent;
