import { useState, useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { saveInventoryList, saveInventoryItems, selectInventoryList, clearItemsData, emoveInventoryGroup, editOneInventoryGroup,
  saveView, saveInventoryItemsPage, saveInventoryItemsPageTotal } from "../../../../../reducers/items/itemsActions";
import { getInventoryLists, getGroupsByList } from '../../../../../local/ComparisonViewLocal';
import { isMobile } from 'react-device-detect';
import { localStorageConst } from '../../../../../config/constants';
import { packSizeUomsConversion, limitOptions } from '../../../../../config/constants';
import { spinner } from '../../../../../reducers/userInterface/userInterfaceActions';
import useConversions from '../../../../../SharedComponents/Conversion/useConversion';
import moment from "moment";

const useApi = () => {

  const dispatch = useDispatch();
  const items = useSelector(({ items }) => items.inventory.items);
  const sort = useSelector(({ items }) => items.inventory.sortBy);
  const list = useSelector(({ items }) => items.inventory.list);
  const listSelected = useSelector(({ items }) => items.inventory.listSelected);
  const search = useSelector(({ items }) => items.inventory.search);
  const [page, setPage] = useState(1);
  const [loadMore, setLoadMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const expandOne = items.some((i) => i.expand);
  const {convertAllBase}  = useConversions.useConvertAll();

  const getInventoryList = (save) => {
    getInventoryLists()
    .then(data => {
      if (save) {
        let listSelected = [];
        const invListSelected = JSON.parse(localStorage.getItem(localStorageConst.keys.listSelected));
        if (!invListSelected) {
          listSelected = data[0];
        } else {
          const listSelectedExists = data.filter(slist => slist.id === invListSelected.id)[0];
          listSelected = (!listSelectedExists) ? data[0] : listSelectedExists;
        }
        dispatch(saveInventoryList(data, listSelected));
      } else {
        dispatch(saveInventoryList(data));
      }
    })
    .catch(error => {
      console.log(`Error in getInventoryLists ${error}`);
    });

  };

  const getInventoryItems = () => {
      dispatch(spinner(true));
      setIsLoading(true);
      getGroupsByList(listSelected.id)
      .then(data => {
        const pageNew = Math.ceil(data.length/limitOptions.inventoryView) + 1;
        setIsLoading(false);
        setLoadMore(false);
        setPage(pageNew);
        dispatch(saveInventoryItemsPage(pageNew));
        dispatch(saveInventoryItemsPageTotal(pageNew-1));
        data.sort((a, b) => { return a.sort - b.sort });
        dispatch(saveInventoryItems(data.map(d => {
          return {
            ...d,
            parLevel: d.parLevel ? d.parLevel : 0,
            expand: !isMobile,
            items: d.items.map(i => {
              const itemEndDate = moment(i.endDate);
              const itemFullPrice = i.isCustomItem === 1 ? i.price :
                          ( itemEndDate.isValid() ?
                              (itemEndDate.isAfter(moment()) ? i.price : (i.isFromInvoice == 1 ? (i.purchasedPrice > 0 ? i.purchasedPrice : i.price) : i.price) ) :
                              (i.isPriceOutdated ? (i.isFromInvoice == 1 ? (i.purchasedPrice > 0 ? i.purchasedPrice : i.price) : i.price) : i.price)
                          );
              const {unfixedFinalUnitPrice} = convertAllBase(i, d.unitId, d.units, itemFullPrice);
              return {
                ...i,
                isCustomPackSize: i.customPackSize ? !(i.customPackSize === i.packSize) : false,
                finalUnitPrice: unfixedFinalUnitPrice,
              }
            })
          }
        })));
        dispatch(spinner(false));
      })
      .catch(error => {
        console.log(`Error in getGroupsByList ${error}`);
      });
  };

  useEffect(() => {
    getInventoryList(true);
    dispatch(saveView('inventory'));
  }, []);

  useEffect(() => {
    if (listSelected) {
      dispatch(clearItemsData());
      getInventoryItems();
    }
  }, [listSelected]);

  return {
    getInventoryItems, getInventoryList, listSelected,
    items, loadMore, isLoading, page, sort
  };
};


export default {
  useApi
};
