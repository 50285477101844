import axios from "axios";

const get = () => {
  return axios.get('/recipe-menu-category');
};

const patch = (id, params) => {
  return axios.patch(`/recipe-menu-category/${id}`, params);
};

const post = (params) => {
  return axios.post(`/recipe-menu-category`, params);
};

const deleted = (id) => {
  return axios.delete(`/recipe-menu-category/${id}`);
};

export default {
  get, patch, post, delete: deleted
};