import React, { Fragment, useState, useEffect } from 'react';
import { isMobile } from "react-device-detect";
import PropTypes from 'prop-types';
import { Card, Row, Col, Tooltip } from 'reactstrap';
import './PlaceOrderList.scss';
import noImage from '../../../../../assets/images/no-image.png';
import usePlaceOrderListRow from './usePlaceOrderListRow';
import ShoppingCartItem from '../../../../../SharedComponents/ShoppingCartItem/ShoppingCartItem';
import RebateIcon from '../../../../../SharedComponents/Icons/RebateIcon/RebateIcon';
import moment from 'moment';
import useShoppingCartItem from '../../../../../SharedComponents/ShoppingCartItem/useShoppingCartItem';
import {savings} from '../../../../../config/constants';
import SavingsButton from '../../../../../SharedComponents/SavingsButton/SavingsButton';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';

const PlaceOrderListRow = (props) => {
  const item = props.children;
  const useShoppingCartItemProps = useShoppingCartItem.useCartItemMerge(item);
  const {dragHandleProps, parentItem} = props;
  const showCart = props.showCart;
  const [outline, setOutline] = useState('');
  const [back, setBack] = useState('');
  const [image, setImage] = useState('');
  const [description, setDescription] = useState('');
  const { priceAndPackSection } = usePlaceOrderListRow.usePriceAndPackSection(item);
  const {overlay, setOverlay} = useShoppingCartItem.useOverlay();

  const [isSaving, setIsSaving] = useState(!!item.matchId ? true : false);

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  
  const outlineHandler = () => {
    let newOutline = (item.isUnpurchasable) ? 'unpurchaseable-outline' : 'main-outline';
    newOutline = isSaving ? (item.savings > 0 ? (item.matchType === savings.exactMatch ? 'exact-match-outline' : 'similar-match-outline') : 'cero-match-outline') : newOutline;
    setOutline(newOutline);
  };

  const imageHandler = () => {
    const newImage = item.imageUrl ? item.imageUrl : (item.brandImageUrl ? item.brandImageUrl : null);
    setImage(newImage);
  };

  useEffect(() => {
    setBack((item.isPunchedOut ? ' dim' : ''));
    outlineHandler();
    imageHandler();
    setDescription((item.foodbamCustomDescription ? item.foodbamCustomDescription : ''));    
  }, []);

  const name = item.name ? item.name : item.description;

  return (
    <Fragment>
      <Card className={'list-item clickable ' + (isMobile ? `is-mobile position-relative`:``) + ` ${outline}`  + `${back}` } {...dragHandleProps}>
        <Row className='icons text-uppercase'>
          <div  className='left'>
            {(!isSaving) ? '' : (item.savings>0 ? <span className={item.matchType === savings.exactMatch ? 'exact-match-tag' : 'similar-match-tag'}></span> : '') }  
          </div>
          <div className='right'>
            {(!item.isElectronicInvoiceItem) ? null : <span className='electronic-tag'>{isMobile ? ``: `Electronic Invoice`}</span>}
            {(!item.isUnpurchasable) ? null : <span className='unpurchase-tag'>{isMobile ? ``: `Call-In Item`}</span>}
            {(!item.orderGuideId) ? null : <span className='order-guide-tag'>{isMobile ? ``: `Order Guide`}</span>}
            {(!item.isPurchased) ? null : 
              <Fragment>
                <span className='purchase-tag' id={`purchasedTooltip-${item.id}`}>{isMobile ? ``: `Purchased`}</span>
                <Tooltip placement="top" isOpen={tooltipOpen} target={`purchasedTooltip-${item.id}`} toggle={toggle}>
                  {`Last Purchased ${item.lastPurchasedQty} ${item.unitMeasure} on ${moment(item.lastPurchasedDate).format("MM/DD/YYYY")}`}
                </Tooltip>
              </Fragment>
            }          
          </div>
        </Row>
        <Row>
          <Col className={`col w-fc d-flex align-items-center justify-items-center ` + (isMobile ? `p-1 is-mobile`:``)}>
            <Col className={`col wc-img ` + (isMobile ? `is-mobile` : ``)}>
              <img src={image ? image : noImage} className={`w-img ` + (isMobile ? `is-mobile` : ``)} alt='item'/>
              <RebateIcon item={item}/>
            </Col>
          </Col>
          <Col className={(isMobile ? `col-7 `:`col-7 `) + ` p-1`}>
            <Row className='col-12 m-0 p-0'>
              <Col className='col-12 text-main text-capitalize p-0'>
                {name.length > 50 ? name.substring(0,50) + '...' : name}
              </Col>
            </Row>
            <Row className='col-12 m-0 p-0'>
              <Col className='col-12 text-gray text-truncate p-0'>
                {item.din}
              </Col>
            </Row>
            <ShowIf show={!isMobile}>
              <Row className='col-12 m-0 p-0'>
                <Col className={'col-9 text-gray text-lowercase p-0' + ( description.length > 100 ? 'text-truncate' : '')}>
                  {description}
                </Col>
                {(description.length > 100 ? <Col className={'col-3 text-gray'}>(click to read more)</Col> : null)}
              </Row>
            </ShowIf>
            <Row className='col-12 m-0 p-0'>
              <ShowIf show={isMobile}>
                <Col className='col-5 text-gray text-truncate p-0'>{item.brand}</Col>
                <Col className='col-2 p-0'></Col>
                <Col className='col-5 text-gray text-truncate p-0'>{item.distributor}</Col>
              </ShowIf>
              <ShowIf show={!isMobile}>
                <Col className='col-4 text-gray text-truncate p-0'>{item.brand}</Col>
                <Col className='col-6 text-gray text-truncate p-0'>{item.distributor}</Col>
              </ShowIf>
            </Row>
            <Row className='col-12 m-0 p-0 text-main'>
              {priceAndPackSection}
            </Row>          
          </Col>
          <ShowIf show={showCart}>
            <Col className={(isMobile ? ` p-0 pr-1 `:` `) + ` d-flex align-items-center justify-content-end `} >
              <ShoppingCartItem useShoppingCartItemProps={useShoppingCartItemProps} item={item} useInitialLeft={false} overlayProps={{overlay: false, setOverlay}} square={false} parentItem={parentItem}/>
            </Col>
          </ShowIf>
        </Row>
        <ShowIf show={isMobile}>
          <Row className='m-0 position-absolute w-100 h-100 bg-white animate-left' style={{top: 0, left: overlay ? '0%' : '105%' }}>
            <Col className='col d-flex align-items-center justify-content-center'>
              <ShoppingCartItem useShoppingCartItemProps={useShoppingCartItemProps} item={item} useInitialLeft={false} overlayProps={{overlay: true, setOverlay}} square={false} parentItem={parentItem}/>
            </Col>
          </Row>
        </ShowIf>
      </Card>
      {(item.showSavings && !item.matchId) ? <SavingsButton item={item} parentItem={item}/> : ''}
    </Fragment>
  );
};


PlaceOrderListRow.propTypes = {
  showCart: PropTypes.bool
};

PlaceOrderListRow.defaultProps = {
  showCart: true
};

export default PlaceOrderListRow;