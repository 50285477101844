import React from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import useInput from '../../SharedEffects/useInput';
import useSearchFiltersModal from './useSearchFiltersModal';
import useModal from '../useModal';
import { modalMenuClassName } from '../../../config/constants';
import SearchFiltersModalFooter from './SearchFiltersModalFooter';
import SearchFiltersModalBody from './SearchFiltersModalBody';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { isMobile } from 'react-device-detect';

const SearchFiltersModal = (props) => {
  const { id } = props;
  const modalProps = useModal(id);
  const history = useHistory();
  const listFilters = useSelector(({items}) => items.list.filters);
  const shoppingListFilters = useSelector(({shoppingLists}) => shoppingLists.filters);
  const filters = history.location.pathname.startsWith('/shopping-lists') ? shoppingListFilters : listFilters;

  const { toggle : togglePurchasedOnly, value : purchasedOnly } = useInput.useCheckbox(filters.purchasedOnly);
  const dist = useSearchFiltersModal.useDistributors(filters.distributorIds);
  const past = useSearchFiltersModal.usePastOrders(filters.pastOrderIds);
  const shop = useSearchFiltersModal.useShoppingLists(filters.shoppingIds);

  const bodyProps = {
    togglePurchasedOnly, purchasedOnly,
    ...dist, ...past, ...shop
  };

  return (
    <Modal {...modalProps} size='lg' modalClassName={modalMenuClassName}>
      <ModalHeader className={isMobile ? 'pt-0' : 'pb-0'} toggle={modalProps.toggle} />
      <SearchFiltersModalBody {...bodyProps} />
      <SearchFiltersModalFooter {...bodyProps} toggleModal={modalProps.toggle} />
    </Modal>
  );
};

export default SearchFiltersModal;
