
import React, { useState } from 'react';
import { Row, Col, Button, Card, CardBody, Collapse, Progress, CardHeader, Input } from 'reactstrap'
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import { FaMoneyBill, FaSortUp, FaSortDown, FaPen, FaCalendarDay, FaClock } from 'react-icons/fa';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { showModal } from '../../../../../reducers/modals/modalsActions';
import NoteModal from '../../../../../SharedComponents/Modals/NoteModal/NoteModal';
import { setValuesForCheckout } from '../../../../../reducers/reviewOrder/reviewOrderActions';
import { saveHeaderIndexRevOrder } from '../../../../../reducers/header/headerActions';
import ShippingAndPaymentMobile from '../ShippingAndPayment/ShippingAndPaymentMobile';
import useReviewOrder from './useReviewOrder';

const ReviewOrderRowMobile = (props) => {

    const { listCartItem,
        dataDistributor,
        priceTotalCard,
        priceTotalCardR,
        validateProgress,
        progressValue,
        diffMinOrderAmount,
        contentList,
        optionsDeliveryDate,
        validationMinimumOrderAmoun,
        updateListCart
    } = props;

    const [valueDeliveryDate, setValueDeliveryDate] = useState()
    const [valueDeliveryTime, setValueDeliveryTime] = useState()
    const [valueNote, setValueNote] = useState()
    const [isOpen, setIsOpen] = useState(false);
    const useValuesReviewOrder = useReviewOrder.useValuesReviewOrder();
    const [showDelTime, setShowDelTime] = useState(false);
    const [optionsDeliveryTime, setOptionsDeliveryTime] = useState([]);
    const dispatch = useDispatch();

    const toggleCollapse = () => setIsOpen(!isOpen);

    const changeDeliveryDate = (props) => {
        setValueDeliveryDate(props.value);
        console.log(dataDistributor)
        const resultVal = useValuesReviewOrder.useValShowDelTime(props.value, dataDistributor.isFoodbamDelivery);
        setShowDelTime(resultVal);
        const options = useValuesReviewOrder.useOptionsDeliveryTime(props.value, dataDistributor.id);
        setOptionsDeliveryTime(options);
    }

    const changeDeliveryTime = (props) => {
        setValueDeliveryTime(props.value);

    }

    const validateNext = () => {
        if (showDelTime) {
            if (valueDeliveryDate && valueDeliveryTime) {
                return false
            } else {
                return true
            }
        } else {
            if (valueDeliveryDate) {
                return false
            } else {
                return true
            }
        }
    }
    // dispatch(showModal(ShippingAndPaymentMobile));

    const goToCheckoutMobile = () => {

        const valResult = validationMinimumOrderAmoun();

        if (valResult) {
            const valuesCheckout = {
                cartList: listCartItem,
                dataDistributor: dataDistributor,
                priceTotalCard: {
                    priceFormat: priceTotalCard,
                    priceReal: priceTotalCardR
                },
                deliveryDetail: {
                    valueDeliveryDate,
                    valueDeliveryTime,
                    valueNote
                }
            };

            const props = {
                updateListCart
            }
            dispatch(setValuesForCheckout(valuesCheckout));
            dispatch(showModal(ShippingAndPaymentMobile, props));
        };
    }


    const openModalBottom = () => {
        const modalProps = {
            defaultValue: valueNote,
            menu: true,
            title: 'Add Delivery Note',
            buttonTitle: 'SAVE',
            onSave: (note) => {
                setValueNote(note);
            }
        };

        dispatch(showModal(NoteModal, modalProps));
    }

    return (
        <div className='contentOrderingMobile'>
            <Card className='review-order-card mobile'>
                <CardHeader>
                    {dataDistributor ? dataDistributor.nickname : ' ..'}
                    <span>{priceTotalCard}</span>
                </CardHeader>
                <CardBody>
                    <ShowIf show={!listCartItem[0].isSpecialItem}>
                        <ShowIf show={validateProgress()}>
                            <ShowIf show={progressValue < 100}>
                                <div className="cardProgress mobile">
                                    <div className='textProgress'>
                                        <FaMoneyBill />
                                        {dataDistributor ? diffMinOrderAmount() : ''} to reach minimum order amount
                        </div>
                                    <Progress value={progressValue} />
                                </div>
                            </ShowIf>

                        </ShowIf>

                    </ShowIf>
                    <hr></hr>
                    <Row>
                        <Col>
                            <p>{listCartItem.filter(res => res.caseQuantity > 0).length} Items</p>
                        </Col>
                        <Col className='text-end'>
                            <p onClick={toggleCollapse}>View details
                               {isOpen ? <FaSortUp className='faSortUp' /> : <FaSortDown className='faSortDown' />}
                            </p>
                        </Col>
                    </Row>
                    <hr></hr>

                    <Collapse isOpen={isOpen}>
                        {contentList}
                        <h4 className='totalSubTotal'>{dataDistributor ? dataDistributor.nickname : ' ..'}  Sub Total : {priceTotalCard}</h4>
                    </Collapse>
                    <ShowIf show={!listCartItem[0].isSpecialItem}>
                        <Row className='mt25'>
                            <Col xs='9'>
                                <Input type='text' placeholder='Enter promo code' />
                            </Col>
                            <Col xs='3'>
                                <Button disabled>Apply</Button>
                            </Col>
                        </Row>
                    </ShowIf>


                    <Row className='mt25'>
                        <Col xs='2' className='text-right'>
                            <FaPen className='faPen' />
                        </Col>
                        <Col xs='10'>
                            <Button outline className='buttonDelivery' onClick={openModalBottom}>{valueNote ? valueNote : 'Add delivery note'}</Button>
                        </Col>
                    </Row>
                    <Row className='mt25'>
                        <Col xs='2' className='text-right'>
                            <FaCalendarDay className='faCalendar' />
                        </Col>
                        <Col xs='10'>

                            <Select

                                onChange={changeDeliveryDate}
                                className='fb-select'
                                placeholder='Pick delivery date'
                                options={optionsDeliveryDate} />
                        </Col>
                    </Row>

                    <ShowIf show={showDelTime}>
                        <Row className='mt25'>
                            <Col xs='2' className='text-right'>
                                <FaClock className='faCalendar' />
                            </Col>
                            <Col xs='10'>
                                <Select
                                    onChange={changeDeliveryTime}
                                    className='fb-select'
                                    placeholder='Pick delivery time'
                                    options={optionsDeliveryTime} />
                            </Col>
                        </Row>
                    </ShowIf>


                    <Row className='mt25'>
                        <Col>
                            <Button onClick={goToCheckoutMobile} disabled={validateNext()} color='green'>Submit {dataDistributor ? dataDistributor.nickname : ' ..'}  Order {priceTotalCard}</Button>
                        </Col>
                    </Row>
                    <div className='review-order-footer mobile'>
                    </div>
                </CardBody>
            </Card>
        </div>
    )
}


export default ReviewOrderRowMobile;