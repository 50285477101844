import React, { Fragment, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Input } from 'reactstrap';
import MinusButton from '../MinusButton/MinusButton';
import PlusButton from '../PlusButton/PlusButton';
import { isMobile } from 'react-device-detect';
import useConversion from '../Conversion/useConversion';
import { onHandTypes } from '../../config/constants';
import { InventoryContext } from "../../Views/MainApp/Views/PlaceOrder/PlaceOrderInventory/PlaceOrderInventoryItem/context/InventoryContext";
import useOnHandItemInterface from './useOnHandItemInterface';
import { cloneWith } from 'lodash';
import { useSelector } from 'react-redux';
import ShowIf from '../ShowIf/ShowIf';

const OnHandItemReview = ({ onHandType, item, isCustomPackSize, unitToConvert, comparisonViewId, groupId, groupTotalProps, type }) => {

  const { disabled } = useOnHandItemInterface.useInputConnection();
  const { unitQuantity, unitSize, packSizeUom, customPackSize, packSize } = item;
  const onHandProps = { comparisonViewId, groupId, onHandType, ...item, unitToConvert };
  const isActiveInventoryEdit = useSelector(({ user }) => user.isActiveInventoryEdit);
  // const isActiveInventoryEdit = true;

  const inventoryContext = useContext(InventoryContext);
  const { OH, OHUom, OHPack } = inventoryContext;


  const getHandBase = (onHandType) => {
    switch (onHandType) {
      case onHandTypes.cs: return OH; break;
      case onHandTypes.pack: return OHPack; break;
      case onHandTypes.uom: return OHUom; break;
      default: break;
    }
  };
  // let onHandBase = onHandType === onHandTypes.cs ? OH : OHUom;
  let onHandBase = getHandBase(onHandType);

  onHandBase = (onHandBase === undefined || onHandBase === null || onHandBase === '') ? 0 : onHandBase;
  const [onHandAmount, setOnHandAmount] = useState(onHandBase);

  const { updateOnHand, updateTotalInit, updateOnHandEdit } = useOnHandItemInterface.useOnHandUpdate(onHandProps, setOnHandAmount, groupTotalProps);
  const { valid } = useOnHandItemInterface.useOnHandValidate()

  let finalPackSize = isCustomPackSize ? customPackSize : packSize;
  let finalPackSizeUom = packSizeUom;

  if (unitToConvert.label !== 'Original') {
    finalPackSize = useConversion.usePackSize(unitQuantity, unitSize, packSizeUom, unitToConvert.value);
    finalPackSizeUom = finalPackSize ? unitToConvert.label : finalPackSizeUom;
  };


  const handleKeypress = (e) => {
    if (e.charCode === 13) {
      if (isActiveInventoryEdit) {
        updateOnHandEdit(onHandAmount, onHandAmount, OH, OHUom, OHPack, item.inventoryId, item.itemId);
      } else {
        updateOnHand(onHandAmount, onHandAmount, OH, OHUom, OHPack, onHandProps);
      }
    }
  };

  const onHandHandler = (e) => {
    if (!valid(e.target.value)) { return; }
    if (isActiveInventoryEdit) {
      updateOnHandEdit(e.target.value, onHandAmount, OH, OHUom, OHPack, item.inventoryId, item.itemId);
    } else {
      updateOnHand(e.target.value, onHandAmount, OH, OHUom, OHPack, onHandProps);

    }
  };

  const onHandClick = (newOnHandAmount) => {
    // saveItem();
    if (!valid(newOnHandAmount)) { return; }
    if (isActiveInventoryEdit) {
      updateOnHandEdit(newOnHandAmount, onHandAmount, OH, OHUom, OHPack, item.inventoryId, item.itemId);
    } else {
      updateOnHand(newOnHandAmount, onHandAmount, OH, OHUom, OHPack, onHandProps);
    }
  };

  useEffect(() => {
    updateTotalInit(OH, OHUom, OHPack);
  }, []);

  return (
    <Fragment>
      <ShowIf show={type === "edit" && isActiveInventoryEdit}>
        <Col className={'w-fc pl-0 justify-content-end' + (isMobile ? ' pr-1' : '')}>
          <MinusButton square onClick={() => { onHandClick(parseFloat(onHandAmount) - 1) }} isBlue isActive={onHandAmount > 0} disabled={disabled} />
        </Col>
      </ShowIf>

      <ShowIf show={type !== "edit"}>
        <Col className={'w-fc pl-0 justify-content-end' + (isMobile ? ' pr-1' : '')}>
          <MinusButton square onClick={() => { onHandClick(parseFloat(onHandAmount) - 1) }} isBlue isActive={onHandAmount > 0} disabled={disabled} />
        </Col>
      </ShowIf>

      <Col className={'p-0 justify-content-center' + (isMobile ? '' : ' w-squared-input') + (type === "edit" ? (isMobile ? " pr-1" : " pr-3") : "")}>
        <Input type='text' className={isMobile ? 'text-center p-0' : ''} value={onHandAmount} onChange={onHandHandler} onKeyPress={handleKeypress} placeholder='0' disabled={ disabled ? disabled : (type === "edit" ? (!isActiveInventoryEdit ? 'disabled' : '') : '')} />
      </Col>
      {/*
      <ShowIf show={type !== "edit"}>
        <Col className={'col w-fc justify-content-left' + (isMobile ? ' px-1' : '')}>
          {onHandType === onHandTypes.cs ? item.unitMeasure : (onHandType == 'pack' ? `${finalPackSizeUom}` : finalPackSizeUom)}
        </Col>
      </ShowIf> */}

      <ShowIf show={type === "edit" && isActiveInventoryEdit}>
        <Col className='w-fc p-0 justify-content-start'>
          <PlusButton square onClick={() => { onHandClick(parseFloat(onHandAmount) + 1) }} isBlue isActive={onHandAmount > 0} disabled={disabled} />
        </Col>
      </ShowIf>

      <ShowIf show={type !== "edit"}>
        <Col className={'w-fc justify-content-start' + (isMobile ? " pl-1" : " pl-3")}>
          <PlusButton square onClick={() => { onHandClick(parseFloat(onHandAmount) + 1) }} isBlue isActive={onHandAmount > 0} disabled={disabled} />
        </Col>
      </ShowIf>
    </Fragment>
  );
};


OnHandItemReview.propTypes = {
  onHandType: PropTypes.string
};

OnHandItemReview.defaultProps = {
  onHandType: onHandTypes.cs
};

export default OnHandItemReview;
