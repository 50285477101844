import React from 'react';
import useModal from '../useModal';
import { Modal, ModalBody, ModalHeader, Col } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import ShowIf from '../../ShowIf/ShowIf';

const OptionsModal = (props) => {
  const { id, options, title } = props;
  const modalProps = useModal(id, true);

  const optionsMap = options.map((o, i) =>
    <Col key={i} onClick={ () => { if (o.onClick && !o.disabled) o.onClick(); modalProps.toggle(); }} className={'item' + (o.selected ? ' bg-light' : '') + (o.disabled ? ' bg-light text-light-blue' : '') + (o.className ? ' '+o.className : '')}>
      {o.label}
      <ShowIf show={o.description}>
        <br /> <span className='text-label'>{o.description | o.name | o.label}</span>
      </ShowIf>
    </Col>
  );

  return (
    <Modal {...modalProps}>
      <ShowIf show={title}>
        <ModalHeader className='bg-white border-0' toggle={ !isMobile ? modalProps.toggle : null}>{title}</ModalHeader>
      </ShowIf>
      <ModalBody className='px-0 pt-0'>
        {optionsMap}
      </ModalBody>
    </Modal>
  );
};

export default OptionsModal;