import { createStore } from 'redux';
import { combineReducers } from 'redux';
import connection from './connection/connectionReducer';
import sync from './sync/syncReducer';
import userInterface from './userInterface/userInterfaceReducer';
import company from './company/companyReducer';
import user from './user/userReducer';
import modals from './modals/modalsReducer';
import items from './items/itemsReducer';
import cart from './cart/cartReducer';
import distributors from './distributors/distributorsReducer';
import header from './header/headerReducer';
import recipeCosting from './recipeCosting/recipeCostingReducer';
import reviewOrder from './reviewOrder/reviewOrderReduces';
import groupEvent from './groupEvent/groupEventReducer';
import inventoryView from './inventoyView/inventoryViewReducer';
import groups from './groups/groupsReducer';
import orders from './orders/ordersReducer';
import shoppingLists from './shoppingLists/shoppingListsReducer';


const reducer = combineReducers({
  // Add Reducers here
  connection, sync, userInterface, company, user, modals,
  items, cart, distributors, header,
  recipeCosting, reviewOrder, groupEvent, inventoryView,
  groups, orders, shoppingLists
});

export default createStore(reducer);
