import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showModal } from '../../../../../reducers/modals/modalsActions';
import AlertModal from '../../../../../SharedComponents/Modals/AlertModal/AlertModal';
import shoppingListsApi from '../../../../../api/shoppingListsApi';
import ListItemRow from './ListItemRow/ListItemRow';

const useSingleListApi = () => {
  const [list, setList] = useState(null);
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (params.id) {
      shoppingListsApi.getListById(params.id).then(({data}) => {
        setList(data);
      }).catch(err=> {
        if (err.code === 404) {
          dispatch(showModal(AlertModal, {
            message: 'Shopping List Not Found',
            onOk: () => { history.replace('/shopping-lists') }
          }));
        }
        console.error('shoppingListspApi getListById Error:', err);
      });
    }
  }, []);

  return { list, setList };
};

const useListItemsApi = () => {
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);

  const params = useParams();

  const getListItems = (page) => {

    setIsLoading(true);
    shoppingListsApi.getListItems(params.id, page)
      .then(res => {
        setItems(items.concat(res.data));
        setPage(page);
        setLoadMore(res._meta.pageCount > res._meta.currentPage);
        setIsLoading(false);
    })
    .catch(error => console.log('shoppingListspApi getListItems Error:', error));

  };

  useEffect(() => {
    if (params.id) {
      getListItems(page);
    }
  }, []);

  return {
    items, setItems, page, isLoading, loadMore, getListItems
  };
};

export default {
  useSingleListApi,
  useListItemsApi
};
