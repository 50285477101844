
export default function reducer(state = {
  list: []
}, action) {
  switch (action.type) {
    case 'SET_DISTRIBUTOR_LIST':
      return {
        ...state,
        list: action.payload
      }
    default:
      return state;
  }
};