import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter, Row, Col, Button } from 'reactstrap';
import useModal from '../useModal';
import { modalMenuClassName } from '../../../config/constants';

const QuestionModal = (props) => {
  const { id, message, leftButton, rightButton, menu, buttonStyle, belowButtons } = props;
  const modalprops = useModal(id);

  const clickLeft = () => {
    if (leftButton.onClick) {
      leftButton.onClick()
    };
    modalprops.toggle();
  };

  const clickRight = () => {
    if (rightButton.onClick) {
      rightButton.onClick()
    };
    modalprops.toggle();
  };

  return (
    <Modal {...modalprops} backdrop='static' keyboard={false} modalClassName={menu ? modalMenuClassName : ''}>
      <ModalBody className={'text-center' + (menu ? ' py-3' : '')}>
        {message}
      </ModalBody>
      <ModalFooter className={buttonStyle ? buttonStyle : ``}>
        <Row>
          <Col><Button color={leftButton.color} onClick={clickLeft} style={{height:'100%'}}>{leftButton.title}</Button></Col>
          <Col><Button color={rightButton.color} onClick={clickRight}>{rightButton.title}</Button></Col>            
        </Row>
        {
          belowButtons ?
          <Row>
            <Col style={{ fontSize: '0.8em', fontWeight: 'bold' }}>
              {belowButtons}
            </Col>
          </Row>
          : ''
        }
        
      </ModalFooter>
    </Modal>
  );
};

const btnShape = PropTypes.shape({
  color: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onClick: PropTypes.func
});

QuestionModal.defaultProps = {
  buttonStyle: 'justify-content-center'
}

QuestionModal.propTypes = {
  rightButton: btnShape,
  leftButton: btnShape,
  menu: PropTypes.bool,
  message: PropTypes.string,
};

export default QuestionModal;