import axios from 'axios';
import moment from 'moment-timezone';


const get = () => {
  return axios.get('/orders');
};

const post = (params) => {
  const sendParams = params;
  const timezone = moment.tz.guess();
  sendParams.timezone = timezone;

  return axios.post('/orders', sendParams );
};


const getByPage = (page) => {
  const params = {
    page: page,
    limit: 24
  }
  return axios.get('/orders', { params });
};

//--INI ORDER HISTORY
const downloadExcel = () => {
  return axios.get('orders/download-order-history');
};

const getOrdersById = (id) => {
  return axios.get(`/orders/${id}?expand=items`);
};

const downloadOrder365 = (id) => {
  return axios.get(`/orders/${id}/download-order-365`);
};

const getOrdersByInventory = (id) => {
  return axios.get(`/orders/${id}/inventory-items`);
};

const preReject = (id, params) => {
    return axios.post(`/orders/pre-reject/${id}`, params);
}

const checkItems = (id, params) => {
  return axios.post(`/orders/check-items/${id}`, params);
}

const survey = (id, params) => {
  return axios.post(`/orders/survey/${id}`, params);
}

const createCustomItem = (params) => {
  return axios.post(`/order-items/create-custom`, params);
};

const bulkCreate = (params) => {
  return axios.post(`/order-items/bulk-create`, params);
};

const updateItems = (id, params) => {
  return axios.patch(`/orders/${id}`, params);
}

const getInvoiceImages = (id) => {
  return axios.get(`/orders/${id}/invoices-images`);
};

const uploadInvoiceImage = (id, file) => {
  let formData = new FormData();
  formData.append('image', file);
  return axios.post(`/orders/${id}/invoices-images`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
};

const deleteInvoiceImage = (id, imgId) => {
  return axios.delete(`/orders/${id}/invoices-images/${imgId}`);
};


//--END ORDER HISTORY


const downloadQuickbooks = (startDate, endDate) => {
  const params = {
    startDate: startDate,
    endDate: endDate
  };
  return axios.get('orders/download-order-quickbooks', { params });
};


export default {
  get,
  getByPage,
  getOrdersById,
  downloadOrder365,
  downloadExcel,
  getOrdersByInventory,
  preReject,
  checkItems,
  survey,
  createCustomItem,
  bulkCreate,
  updateItems,
  getInvoiceImages,
  uploadInvoiceImage,
  deleteInvoiceImage,
  downloadQuickbooks,
  post
};
