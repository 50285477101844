import React, { Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import ShowIf from '../../../SharedComponents/ShowIf/ShowIf';
import { FaCheck } from 'react-icons/fa';
import { isMobile } from 'react-device-detect';

const SignUpSteps = ({ step, maxStep }) => {
  let array = [];
  for (let i = 0; i < maxStep; i++) {
    array.push(i + 1);
  };

  const mapSteps = array.map((s, i) =>
    <Fragment key={`step_${s}`}>
      <Col className={'align-items-center p-0 text-center border border-dark-blue rounded-circle squared-35px' + (step >= s ? ' text-white bg-dark-blue' : ' text-dark-blue')}>
        <ShowIf show={step <= s}>
          {s}
        </ShowIf>
        <ShowIf show={step > s}>
          <FaCheck color='white' alignmentBaseline='central' />
        </ShowIf>
      </Col>
      <ShowIf show={s !== maxStep}>
        <Col className='w-80px border-top mx-2 p-0'></Col>
      </ShowIf>
    </Fragment>
  )
  return (
    <Row className={isMobile ? 'mx-0 align-items-center mt-4 mb-4' : 'mx-0 align-items-center mt-4 mb-5'}>
      {mapSteps}
    </Row>
  );
}

export default SignUpSteps;