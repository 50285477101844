import { useEffect } from 'react';
import useRecipeCostingRecipe from '../../RecipeCostingRecipe/useRecipeCostingRecipe';

const useRecipeInfoModal = (props, modalProps) => {
  const {
    onSave, menuInfo, recipeInfo
  } = props;

  const {
    recipeName, menuCategory, menuPrice, isSubrecipe,
    recipeYield, recipeYieldUom, description, procedure, isRecipeInfoValid
  } = useRecipeCostingRecipe.useInfoForms(recipeInfo ? recipeInfo.isSubRecipeUsed : false);

  useEffect(() => {
    menuCategory.set(menuInfo);
    if (recipeInfo) {
      recipeName.setValue(recipeInfo.name);
      menuPrice.setValue(recipeInfo.price);
      recipeYield.setValue(recipeInfo.subRecipeYield);
      recipeYieldUom.setValue(recipeInfo.subRecipeYieldUom);
      isSubrecipe.set(recipeInfo.isSubRecipe);
      description.setValue(recipeInfo.description);
      procedure.setValue(recipeInfo.procedure);
    }
  }, []);

  const clickSave = () => {
    if (isRecipeInfoValid()) {
      const params = {
        name: recipeName.value,
        recipe_menu_category_id: menuCategory.value.value,
        price: menuPrice.value,
        description: description.value,
        procedure: procedure.value,
        is_sub_recipe: isSubrecipe.value,
        sub_recipe_yield: recipeYield.value,
        sub_recipe_yield_uom: recipeYieldUom.value,
      };
      onSave(params, true, true);
      modalProps.toggle();
    }
  };

  return {
    clickSave, recipeName, menuCategory, menuPrice,
    isSubrecipe, recipeYield, recipeYieldUom,
    description, procedure
  }
};

export default useRecipeInfoModal;