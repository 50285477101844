import React, { useState, useEffect } from 'react';
import posApi from '../../../../../api/posApi';
import PosListRow from './PosListRow';

const useApi = () => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    posApi.getPos().then(({data}) => {
      setItems(data);
    }).catch(err=> {
      console.error('posApi get Error:', err);
    });
  }, []);

  const itemsMap = items.map(item => <PosListRow key={`pos-date-${item.date}`}>{item}</PosListRow>);

  return { items, itemsMap };
};

export default {
  useApi
};
