import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import posApi from '../../../../../api/posApi';
import recipeApi from '../../../../../api/recipeApi';
import { showModal } from '../../../../../reducers/modals/modalsActions';
import SelectModal from '../../../../../SharedComponents/Modals/SelectModal/SelectModal';
const useApi = () => {

  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [listRecipes, setListRecipes] = useState([]);
  // const options = listReject.map(res => {
  //   return {
  //     label: res,
  //     value: res
  //   };
  // });
  const dispatch = useDispatch();
  const params = useParams();
  const date = params.date;

  const getPosDetail = (page) => {

    setIsLoading(true);
    posApi.getPosDetail(date, page)
      .then(res => {
        setItems(items.concat(res.data));
        setPage(page);
        setLoadMore(res._meta.pageCount > res._meta.currentPage);
        setIsLoading(false);
      })
      .catch(error => console.log(error));

  };

  const getRecipes = (page) => {

    recipeApi.getAllRecipesBySearch(1)
      .then(res => {
        setListRecipes(res.data.map(_i => {
          return {
            label: _i.name,
            value: _i.id
          }
        }));
      })
      .catch(error => console.log(error));

  };

  const changeRecipe = (itemSelect, item_name) => {
    console.log(itemSelect)
    recipeApi.assingRecipe({
      item_name: item_name,
      recipe_id: itemSelect.value,
      force: true
    })
      .then(res => {
        console.log(res);
      })
      .catch(error => console.log(error));
    // console.log(itemSelect);
    // console.log(item_name)
  }


  // const openModalRecipes = () => {
  //   const props = {
  //     header: `Reject`,
  //     message: `We'd like to improve your experience with Foodbam. What is your problem?`,
  //     options: listRecipes,
  //     onOk: (optionValue) => {
  //       console.log(optionValue)
  //     }
  //   };
  //   dispatch(showModal(SelectModal, props));
  // }


  useEffect(() => {
    getRecipes();
    getPosDetail(page);
  }, []);

  return {
    items, page, isLoading, loadMore, getPosDetail, listRecipes, changeRecipe
  };
};

export default {
  useApi
};
