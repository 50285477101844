import React, { Fragment } from 'react';
import { Col, Tooltip } from 'reactstrap';
import useInput from '../SharedEffects/useInput';
import { IoMdSwap } from 'react-icons/io';
import { colors } from '../../config/constants';

const AutoAddedBookmark = ({elemId}) => {
  const tooltip = useInput.useCheckbox(false);
  return (
    <Fragment>
      <Col className='w-fc px-1' id={`${elemId}_aa`}>
        <IoMdSwap className='clickable' size={20} color={colors.lightBlueText} />
      </Col>
      <Tooltip innerClassName='text-tooltip' placement='top-start' className='text-white'
        hideArrow={true} isOpen={tooltip.value}
        toggle={tooltip.toggle}
        target={`${elemId}_aa`}>
          Auto Added
        </Tooltip>
    </Fragment>
  );
};

export default AutoAddedBookmark