import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { saveCartItems, saveLastModified, saveUnsyncedItems, saveCartCount, saveCartDistributor, saveOrderTotal, saveSavingOptions } from "../../reducers/cart/cartActions";
import { localStorageConst } from '../../config/constants';
import GoTo from '../../SharedComponents/GoTo/useGoTo';
import {showModal} from '../../reducers/modals/modalsActions';
import { getAll, findOneBase } from '../../local/CartLocal';

const useApi = () => {
  const dispatch = useDispatch();

  const setCartStructureOffline = (nCartItems) => {
    let totalQty = 0;
    let totalOrder = 0;
    nCartItems.forEach(cItem => {
      totalQty = totalQty + parseInt(cItem.caseQuantity);
      totalOrder = totalOrder + (parseFloat(cItem.caseQuantity) * parseFloat( cItem.price ));
    });
    return { totalQty, totalOrder };
  };

  const getCartItemsOffline = () => {
      getAll()
        .then((res) => { 
          const { totalQty, totalOrder } = setCartStructureOffline(res);
          dispatch(saveCartItems(res));
          // console.log({res,totalQty, totalOrder})
          dispatch(saveCartCount(totalQty));
          dispatch(saveOrderTotal(totalOrder));
          localStorage.setItem(localStorageConst.keys.cartItems, JSON.stringify(res));
          return findOneBase();
        })
        .then((base) => {
          dispatch(saveLastModified(base.lastModified));
          localStorage.setItem(localStorageConst.keys.lastModifiedCart, base.lastModified);
          dispatch(saveUnsyncedItems(base.unsyncedItems));
          return;
        })
        .catch(error => {
          console.log(error)
        });
  };

  return { getCartItemsOffline };
};

const useCartItems = () => {
  const { getCartItemsOffline } = useApi();
  const dispatch = useDispatch();
  const cartItems = useSelector(({ cart }) => cart.cartItems);
  const cartCount = useSelector(({ cart }) => cart.cartCount);
  const lastModified = useSelector(({ cart }) => cart.lastModified);
  const unsyncedItems = useSelector(({ cart }) => cart.unsyncedItems);

  return {
    cartItems, cartCount, lastModified, unsyncedItems, getCartItemsOffline
  };
};



export default {
  useCartItems,
};