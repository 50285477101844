import React from 'react';
import { Row, Col, Input } from 'reactstrap';
import ShowIf from '../../../SharedComponents/ShowIf/ShowIf';
import Select from 'react-select';
import { isMobile } from 'react-device-detect';
import InputMask from "react-input-mask";

const SignUpInput = (props) => {
  const {
    label, type, placeholder, isDropdown,
    options, set, value, xs, mb, ...rest
  } = props;


  const getMaxLength = () => {
    if (placeholder === "Zipcode" || placeholder === '12345') {
      return 5
    };
  };

  const isPassword = () => {
    if (placeholder == 'Password' || placeholder == 'Confirm Password') {
      return 'password';
    } else {
      return '';
    };
  };


  return (
    <Col xs='12' lg='4'>
      <Row>
        <Col className='text-label mb-1'>{label}</Col>
      </Row>
      <Row className={isMobile ? 'mb-3' : ''}>
        <Col>
          <ShowIf show={!isDropdown}>
            <ShowIf show={placeholder == '(123) 123-1234'}>
              <InputMask className='inputMask' mask="(999) 999-9999" placeholder={placeholder} maxLength={getMaxLength()} type={isPassword()} onChange={set} value={value} />
            </ShowIf>
            <ShowIf show={placeholder != '(123) 123-1234'}>
              <Input placeholder={placeholder} maxLength={getMaxLength()} type={isPassword()} onChange={set} value={value} />

            </ShowIf>
          </ShowIf>
          <ShowIf show={isDropdown}>
            <Select {...rest} className='fb-select' classNamePrefix='select' options={options} hideSelectedOptions={true} menuPlacement='auto' onChange={set} value={value} />
          </ShowIf>
        </Col>
      </Row>
    </Col>
  );
};

export default SignUpInput;