import { getInventoryLists } from '../../../local/ComparisonViewLocal';

const useApi = (setLists) => {
  const getLists = () => {    
    
    getInventoryLists().then(data => {
      setLists(data);
    })
    .catch(error => { console.log(`Error in getInventoryLists ${error}`); });

  };

  return {
    getLists
  };
};


export default {
  useApi
};