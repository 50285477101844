import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useModal from '../useModal';
import { Modal, ModalBody, ModalHeader, Row, Col, Input, ModalFooter, Button } from 'reactstrap';
import useInput from '../../SharedEffects/useInput';
import { isMobile } from 'react-device-detect';
import MinusButton from '../../MinusButton/MinusButton';
import PlusButton from '../../PlusButton/PlusButton';
import ShowIf from '../../ShowIf/ShowIf';

const NoteModal = (props) => {

  const { id, onSave, menu, defaultValue, buttonTitle, title } = props;
  const modalProps = useModal(id, menu);
  const [note, setNote] = useState(defaultValue);
  const clickSave = () => {
    if (onSave) onSave(note);
    modalProps.toggle();
  };

  const styleH1 = {
    fontSize: 15,
    padding: '0px 24px',
    textAlign: 'center',
    color: '#143357'
  };
  const styleInput = {
    margin: '15px 14px 0px',
    height: '200px'
  };

  const clearText = () => {
    setNote('');
  }


  return (
    <Modal {...modalProps}>
      <ShowIf show={title}>
        <ModalHeader className='bg-white' toggle={modalProps.toggle} style={{ color: '#143357' }}>{title}</ModalHeader>
      </ShowIf>
      <ModalBody>
        <Row className='align-items-center justify-content-center'>
          <h1 style={styleH1}>Add your delivery note here e.g. Back door not between 11 am and 1 pm</h1>
          <Input type='textarea' value={note} onChange={e => setNote(e.target.value)} style={styleInput} placeholder='Add your notes here . . ' ></Input>
        </Row>
      </ModalBody>
      <ModalFooter >
        <Col size='6'>
          <Button disabled={note ? false : true} onClick={clearText} style={{ height: 43 }} block={isMobile} className='px-4' color='green' onClick={clickSave}>CLEAR</Button>
        </Col>
        <Col size='6'>
          <Button style={{ height: 45 }} block={isMobile} className='px-4' color='green' onClick={clickSave}>{buttonTitle}</Button>
        </Col>

      </ModalFooter>
    </Modal>
  );
};


export default NoteModal;