import React, {useState} from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Button, CustomInput } from 'reactstrap';
import useModal from '../../../../../SharedComponents/Modals/useModal';
import './OrderGuideUpload.scss';
import { MdCloudQueue } from 'react-icons/md';
import { colors } from '../../../../../config/constants';

const NoTemplateModal = (props) => {
  const { id, onOk, btnLabel, btnSize, footerClass, color } = props;
    const modalprops = useModal(id);

    const onCancel = () => {
        modalprops.toggle();
        };

    const onClickOk = () => {
        onOk();
        modalprops.toggle();
    };

  return (
    <Modal {...modalprops} backdrop='static' keyboard={false}>
        <ModalBody className='text-center'>
            <Row className='px-3 py-3'>
                <Col className='d-flex align-items-center justify-content-center '>
                    <div className=''>
                        <MdCloudQueue  color={colors.lightBlueText} style={{fontSize:'7em'}}/>
                    </div>
                    <div className='x-icon'>
                        <span>X</span>                        
                    </div>
                </Col>                
            </Row>
            <Row className='py-2 px-3'>
                <Col className='text-left'>
                    An approved template was not found for this order guide. Please review the order guides under Price Update History for successful uploads, and resubmit using the approved template.
                </Col>
            </Row>
            <Row className='py-2 px-3'>
                <Col className='text-left'>
                    Or you can request a new template which can take up to 3 days to approve.
                </Col>
            </Row> 
        </ModalBody>
        <ModalFooter>
            <Row className='w-100'>
                <Col className='w-100 text-left'>
                    <Button color={color} size={btnSize} onClick={onCancel} className='btn-outlined'>Cancel</Button>
                </Col>
                <Col className='w-100 text-right'>
                    <Button color={color} onClick={onClickOk}>Request Template</Button>
                </Col>
            </Row>            
        </ModalFooter>
    </Modal>
  );
};

NoTemplateModal.defaultProps = {
  footerClass: '',
  btnLabel: 'Ok!',
  btnSize: 'sm',
  color: 'green'
};

export default NoTemplateModal;
