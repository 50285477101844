import React from 'react';
import { Col, Button, Form, Row } from 'reactstrap';
import AdminLoginFormDropdown from './AdminLoginFormDropdown';
import { useHistory } from 'react-router-dom';

const AdminLoginForm = (props) => {
  const {
    locations, setLocationSearch, setLocation,
    users, setUser, location, onSubmit, submitDisabled
  } = props;

  const history = useHistory();

  const goToLogout = () => {
    history.push('/logout');
  };

  return (
    <Form className='admin-login-form flex-column m-auto' noValidate="novalidate" onSubmit={onSubmit}>
      <AdminLoginFormDropdown label='Locations' placeholder='Select location...' options={locations} setSearch={setLocationSearch} set={setLocation} />
      <AdminLoginFormDropdown label='Users' placeholder='Select user...' options={users} set={setUser} isDisabled={location ? false : true} />
      <Row className='m-0 text-right mt-4 justify-content-end'>
        <Col className='w-fc'>
          <Button disabled={submitDisabled()} type='submit' color='green' size='lg'>Login As</Button>
        </Col>
        <Col className='w-fc'>
          <Button onClick={goToLogout} color='secondary' size='lg'>Logout</Button>
        </Col>
      </Row>
    </Form>
  );
};

export default AdminLoginForm;