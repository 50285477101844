import React from 'react';
import { Modal, ModalBody, ModalFooter, Button, ModalHeader } from 'reactstrap';
import useModal from '../useModal';
import './SimpleModal.scss'
import { isMobile } from "react-device-detect";

const SimpleModal = (props) => {
    const { id,header, message, onOk } = props;
    const modalprops = useModal(id);

    const onClickOk = () => {
        if (onOk) {
            onOk();
        };
        modalprops.toggle();
    };

    return (
        <Modal {...modalprops} backdrop='static' keyboard={false} className={isMobile ? 'simpleModal mobile' : 'simpleModal'} >
            <ModalHeader>
                {header}
            </ModalHeader>
            <ModalBody className='text-center'>
                <div dangerouslySetInnerHTML={{ __html: message }} />
            </ModalBody>
            <ModalFooter>
                <Button color='blue' onClick={onClickOk}>Ok</Button>
            </ModalFooter>
        </Modal>
    );
};

export default SimpleModal;

