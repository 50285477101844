import React, { Fragment } from 'react';
import { Row, FormGroup, Col, Button } from 'reactstrap';
import { FaCheck } from 'react-icons/fa';
import { CardElement, injectStripe, Elements } from 'react-stripe-elements';


const SingUpPayment = (props) => {
    const { setDataCard, contactInformation } = props;

    return (
        <div className="contentPayment">
            <Row>
                <h1>$149</h1>
                <h2>$99/month</h2>
            </Row>
            <Row>
                <p>
                    Save time and money with the complete back-of-house technology solution for restaurants.
                </p>
            </Row>
            <Row>
                <div className='checkContent'>
                    <FaCheck color='white' alignmentBaseline='central' />
                </div>
                <p>
                    Use Foodbam to order from all your vendors
                </p>
            </Row>
            <Row>
                <div className='checkContent'>
                    <FaCheck color='white' alignmentBaseline='central' />
                </div>
                <p>
                    Conduct recipe-costing
                </p>
            </Row>
            <Row>
                <div className='checkContent'>
                    <FaCheck color='white' alignmentBaseline='central' />
                </div>
                <p>
                    Track your inventory
                </p>
            </Row>
            <Row>
                <div className='checkContent'>
                    <FaCheck color='white' alignmentBaseline='central' />
                </div>
                <p>
                    Analyze your purchase data
                </p>
            </Row>
            <hr></hr>
            <Row>
                <h3>Enter payment details:</h3>

            </Row>
            <Elements><StripeForm setDataCard={setDataCard} contactInformation={contactInformation} /></Elements>
        </div>

    );

};



const _StripeForm = (props) => {

    const { setDataCard, contactInformation } = props;
    const handleCardChange = (props) => {
        if (props.complete) {
            getToken();
        } else {
            setDataCard({
                complete: false
            });
        };
    };

    const getToken = () => {
        const tokenData = {
            type: 'card',
            address_line1: contactInformation.locationAddress.value,
            address_line2: '',
            address_city: contactInformation.city.value,
            address_state: contactInformation.state.value.value,
            address_zip: String(contactInformation.zipcode.value)
        };
        console.log(tokenData)
        props.stripe.createToken(tokenData).then(payload => {

            const data = {
                token: payload.token.id,
                cardId: payload.token.card.id,
                complete: true
            };
            setDataCard(data);

        });
    }


    const cardElementOptions = {
        style: {
            base: {
                color: '#202020',
                lineHeight: '17px',
                fontSmoothing: 'antialiased',
                fontSize: '15px',
                '::placeholder': {
                    color: '#76777b'
                }
            },
            invalid: {
                color: '#dc3545',
                iconColor: '#dc3545'
            }
        }
    };


    return (
        <Row>
            <Col xs={12} lg={12} style={{ padding: 0 }}>
                <CardElement
                    onChange={handleCardChange}
                    hidePostalCode={true}
                    {...cardElementOptions}
                />
            </Col>
        </Row>
    );
};

const StripeForm = injectStripe(_StripeForm);
export default SingUpPayment;