import axios from "axios";

const merchantLogin = (params) => {
  return axios.post('users/merchant-login', params);
};

const currentuser = () => {
  return axios.get('users/current-user');
};

const requestPasswordReset = (email) => {
  return axios.post('users/request-password-reset', {email});
};

const resetPassword = (token, params) => { 
  return axios.post('users/reset-password', params, {params: {token}});
  
};

const changeLocation = (activeLocationId, id) => {
  const params = {
    active_location_id: activeLocationId,
    id: id
  }
  return axios.put(`users/${id}`, params);
};

export default {
  merchantLogin,
  currentuser,
  requestPasswordReset,
  resetPassword,
  changeLocation
};
