import React from 'react';
import { Col } from 'reactstrap';

const ItemDetailsInventoryLinkItemImage = ({image}) => {
  return (
    <Col className='w-image-sm pr-0'>
      <img className='no-image' alt='item' src={image} />
    </Col>
  );
};

export default ItemDetailsInventoryLinkItemImage;