import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { saveHeaderIndexRevOrder } from '../../reducers/header/headerActions';
import { localStorageConst, placeOrderViews } from '../../config/constants';

const useActions = () => {
  const dispatch = useDispatch();
  const history = useHistory();  
  const activeLocation = useSelector(({user}) => user.activeLocation);
  const view = useSelector(({items}) => items.view);
  
  const placeOrder = () =>{
    const localView = localStorage.getItem(localStorageConst.keys.view);
    // const itemsView = view ? view : (localView ? localView : ( activeLocation.display_comparison_view ? placeOrderViews.inventory : placeOrderViews.list));
    const itemsView = localView ? localView : ( activeLocation.display_comparison_view ? placeOrderViews.inventory : placeOrderViews.list);
    history.replace(`/place-order/${itemsView}`);
  };

  const placeOrderDefault = () => {
    const localView = localStorage.getItem(localStorageConst.keys.view);
    const defaultPath = `/place-order/${localView}`;
    if (history.location.pathname === defaultPath){          
      history.replace(`/`);
      history.replace(defaultPath);
    }else{
      history.replace(defaultPath);
    }
  };

  const goTo = () => {
    const path = history.location.pathname;
    const localView = localStorage.getItem(localStorageConst.keys.view);
    // const itemsView = view !== '' ? view : (localView ? localView : 'list');
    const itemsView = localView ? localView : (view !== '' ? view : 'list');
    const newLocation = (path === '/login' || path === '/signup' || path === '/') ? `${path}` : `/place-order/${itemsView}`;
    history.replace(newLocation);
  };

  const addSpecialRequest = () =>{    
    history.push('/special-request');
  };
  
  const reviewOrder = () =>{    
    history.push('/checkout-ordering/savings');
    dispatch(saveHeaderIndexRevOrder(2));
  }; 

  return { placeOrder, placeOrderDefault, addSpecialRequest, reviewOrder, goTo}
};

export default {
  useActions
};