import React from 'react';
import { Container, Row } from 'reactstrap';
import './SignUp.scss';
import SignUpSidebar from './SignUpSidebar';
import SignUpContent from './SignUpContent';
import useSignUp from './useSignUp';
import ShowIf from '../../SharedComponents/ShowIf/ShowIf';
import { isMobile } from 'react-device-detect';
import Header from '../../../src/SharedComponents/Header/Header';

const SignUp = () => {
  const contactInformationState = useSignUp.useContactInformation();
  const { step, goTo, showLocation, showDistributor } = useSignUp.useSteps(contactInformationState.howManyLocations.value.value);
  const locationsState = useSignUp.useLocationsInformation(contactInformationState.howManyLocations.value.value);

  const distributorsState = useSignUp.useDistributorInformation();
  const legalState = useSignUp.useLegalInformation();
  const valuesHeader = {
    showMenu: false,
    showLogo: false,
    showBack: true,
    showCart: false,
    showReviewOrder: false,
    title: 'Create Account',
    showDet_ReviewOrder: false
  }
  return (
    <Container fluid={true} className={isMobile ? 'p-0 sign-up-app' : 'p-0 sign-up-app pr-5'}>
      <ShowIf show={isMobile}>
        <Header {...valuesHeader} />
      </ShowIf>
      <Row>
        <ShowIf show={!isMobile}>
          <SignUpSidebar />
        </ShowIf>
        <SignUpContent contactInformation={contactInformationState} locations={locationsState}
          step={step} goTo={goTo} showLocation={showLocation}
          showDistributor={showDistributor} distributors={distributorsState}
          legalInformation={legalState}
        />
      </Row>
    </Container>
  );
};

export default SignUp;