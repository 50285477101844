import React from 'react';
import bookmarkImg from '../../assets/images/bookmark-green.png';
import Bookmark from './Bookmark';

const LastPurchasedBookmark = ({elemId}) => {
  return (
    <Bookmark img={bookmarkImg} label='Last Purchased' elemId={`${elemId}_lp`} />
  );
};

export default LastPurchasedBookmark