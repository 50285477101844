import React, {useState} from 'react';
import { Card, Row, Col, Button } from 'reactstrap';
import { useHistory, Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import ShowIf from '../../../../SharedComponents/ShowIf/ShowIf';
import moreSvg from '../../../../assets/images/more.svg';

const ShoppingListsRow = (props) => {
  const list = props.children;
  const [hover, setHover] = useState(false);
  const history = useHistory();

  const goToShoppingList = () => {
    history.push(`/shopping-lists/${list.id}`);
  };

  if (isMobile) {
    return (
      <Card className='list'>
        <Row className='clickable' onClick={goToShoppingList}>
          <Col xs={10}>
            <div className='my-0'>{list.name}</div>
          </Col>
          <Col xs={2} className='text-right'>
            <img src={moreSvg} />
          </Col>
        </Row>
      </Card>
    );
  }

  return (
    <Card className='list'>
      <Row className='clickable' onClick={goToShoppingList} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        <Col xs={10}>
          <div className='my-2'>{list.name}</div>
        </Col>
        <Col xs={2} className='text-right'>
          <ShowIf show={hover}>
            <Button className='btn btn-orange'>View Items</Button>
          </ShowIf>
        </Col>
      </Row>
    </Card>
  );
};

export default ShoppingListsRow;
