import { baseValueConversion, getTypeConversion } from "../../config/constants";
import { fraction, number } from 'mathjs';
import validate from 'validate.js';

const usePackSize = (unitQuantity, unitSize, packSizeUom, newUnit) => {
  let nVal = "";
  let conversion = 0;
  const baseValue = baseValueConversion[newUnit.type ? newUnit.type : getTypeConversion[newUnit.label]];
  if (baseValue) {
    if (packSizeUom) {
      if (baseValue[packSizeUom.toLowerCase()]) {
        conversion = unitSize * ( baseValue[newUnit.label] / baseValue[packSizeUom.toLowerCase()] );
        conversion = Math.round(conversion * 100) / 100;
        nVal = unitQuantity+'/'+conversion+' '+newUnit.label;
      }
    }
  }
  return nVal;
};

const useUnitPrice = (price, unitPrice, finalPackSize, packSizeUom, newUnit) => {
  const {unitQuantity, unitSize} = useGetPackSizeParts(finalPackSize);
  const baseValue = baseValueConversion[newUnit.type ? newUnit.type : getTypeConversion[newUnit.label]];
  let newUnitPrice = unitPrice;
  if (baseValue) {
    if (packSizeUom) {
      if (baseValue[packSizeUom.toLowerCase()]){
        if(unitQuantity && unitSize){
          const nUnitPrice = price / (unitQuantity*unitSize);
          newUnitPrice = Math.round(nUnitPrice * 100) / 100;
        }else{
          newUnitPrice = unitPrice * ( baseValue[packSizeUom.toLowerCase()] / baseValue[newUnit.label] );
          newUnitPrice = Math.round(unitPrice * 100) / 100;
        }

      }
    }
  }
  return newUnitPrice;
};

const useGetPackSizeParts = (packSize) => {
  let unitQuantity = null;
  let unitSize = null;
  if (typeof packSize === 'string'){
    if(packSize){
      // console.log('packSize',packSize)
      const nPackSize = packSize;
      const parts = nPackSize.split(' ');
      const units = parts[0].split('/');
      unitQuantity = units[0];
      unitSize = units[1];
    }
  }

  return {unitQuantity, unitSize};
};

const usePackSizeParts = (unitQuantity, unitSize, packSizeUom, newUnit) => {
  let nVal = "";
  let conversion = 0;
  const baseValue = baseValueConversion[newUnit.type ? newUnit.type : getTypeConversion[newUnit.label]];
  if (baseValue) {
    if (packSizeUom) {
      if (baseValue[packSizeUom.toLowerCase()]) {
        conversion = unitSize * ( baseValue[newUnit.label] / baseValue[packSizeUom.toLowerCase()] );
        conversion = Math.round(conversion * 100) / 100;
        nVal = unitQuantity+'/'+conversion+' '+newUnit.label;
      }
    }
  }
  return {nVal, conversion, newUnit};
};

const useConvert = (input, fromUnit, toUnit) => {
  var num = validate.single(input, {fraction: {strict: true}}) ? 0 : input;
  let nVal = num ? number(fraction(num)) : 0;
  const baseValue = baseValueConversion[getTypeConversion[fromUnit && fromUnit.toLowerCase()]];
  if (baseValue) {
    if (baseValue[ toUnit && toUnit.toLowerCase()]) {
      nVal = nVal * ( baseValue[toUnit && toUnit.toLowerCase()] / baseValue[fromUnit && fromUnit.toLowerCase()] );
      nVal = Math.round(nVal * 100) / 100;
    }
  }
  return nVal;
};

const useCanConvert = (fromUnit, toUnit) => {
  const baseValue = baseValueConversion[getTypeConversion[fromUnit && fromUnit.toLowerCase()]];
  if (baseValue) {
    if (baseValue[toUnit && toUnit.toLowerCase()]) {
      return true;
    }
  }
  return false;
};

const useConvertAll = () => {
  const convertAllBase = (item, unitId, units, itemFullPrice, isCustomPackSizeProp) => {
    const {
      price, unitPrice, name,
      isCustomPackSize, packSize, customPackSize, packSizeUom,
      unitQuantity, unitSize, unitType
    } = item;

    const fullPrice = !itemFullPrice ? price : itemFullPrice;
    const isCustomPackSizeFull = (isCustomPackSizeProp !== undefined) ? isCustomPackSizeProp : (customPackSize ? !(customPackSize === packSize) : false);

    const bUnit = (!!unitId) ? units.filter(u => u.unitId === unitId)[0] : {id: null};
    const initPackSize = isCustomPackSizeFull ? customPackSize : packSize;
    const initUnitPrice = unitPrice;
    const initPackSizeUom = packSizeUom;

    const fixedPackSize = convertPacksize(unitQuantity, unitSize, initPackSizeUom, bUnit);
    const finalPackSize = (!!unitId) ? fixedPackSize : initPackSize;
    let finalPackSizeUom = (!!unitId) ? (finalPackSize ? bUnit.label : initPackSizeUom) : initPackSizeUom;

    const unit = (isCustomPackSizeFull || !!unitId) ? bUnit : {type: unitType, label: finalPackSizeUom};
    const fixedUnitPrice = convertUnitPrice(fullPrice, initUnitPrice, finalPackSize, finalPackSizeUom, unit);
    let finalUnitPrice = fullPrice === 0 ? 0 : fixedUnitPrice;

    const unfixedUnitPrice = convertUnfixedUnitPrice(fullPrice, initUnitPrice, finalPackSize, finalPackSizeUom, unit);
    const unfixedFinalUnitPrice = fullPrice === 0 ? 0 : unfixedUnitPrice;

    if (!item.isCatchWeight && item.packSizeUom != 'lb' && unitSize < 1) {
      // console.log('item', item);
      // console.log('finalPackSizeUom', finalPackSizeUom);
      // console.log('finalPackSize', finalPackSize);
      // console.log('unitQuantity', unitQuantity);
      // console.log('unitSize', unitSize);
      // console.log('finalUnitPrice', finalUnitPrice);
      finalUnitPrice = itemFullPrice / unitQuantity;
      // console.log('finalUnitPrice', finalUnitPrice);
      finalPackSizeUom = unitSize + ' ' + finalPackSizeUom;
    }

    return {finalPackSize, finalPackSizeUom, finalUnitPrice, unfixedFinalUnitPrice};
  };

  const convertAllFromBase = (item, unitId, units, itemFullPrice, unitPrice, isCustomPackSize, customPackSize, unitQuantity, unitSize) => {
    const {
      price,
      packSize, packSizeUom,
      unitType
    } = item;

    const fullPrice = itemFullPrice;

    const bUnit = (!!unitId) ? units.filter(u => u.unitId === unitId)[0] : {id: null};
    const initPackSize = isCustomPackSize ? customPackSize : packSize;
    const initUnitPrice = unitPrice;
    const initPackSizeUom = packSizeUom;

    const fixedPackSize = convertPacksize(unitQuantity, unitSize, initPackSizeUom, bUnit);
    const finalPackSize = (!!unitId) ? fixedPackSize : initPackSize;
    const finalPackSizeUom = (!!unitId) ? (finalPackSize ? bUnit.label : initPackSizeUom) : initPackSizeUom;

    const unit = (isCustomPackSize || !!unitId) ? bUnit : {type: unitType, label: finalPackSizeUom};
    const fixedUnitPrice = convertUnitPrice(fullPrice, initUnitPrice, finalPackSize, finalPackSizeUom, unit);
    const finalUnitPrice = fullPrice === 0 ? 0 : fixedUnitPrice;

    return {finalPackSize, finalPackSizeUom, finalUnitPrice};
  };

  const convertAll = (item, unitToConvert, itemFullPrice) => {
    const {
      price, unitPrice, //unitToConvert,
      isCustomPackSize, packSize, customPackSize, packSizeUom,
      unitQuantity, unitSize, unitType
    } = item;

    const fullPrice = !itemFullPrice ? price : itemFullPrice;

    const initPackSize = isCustomPackSize ? customPackSize : packSize;
    const initUnitPrice = unitPrice;
    const initPackSizeUom = packSizeUom;

    const fixedPackSize = convertPacksize(unitQuantity, unitSize, initPackSizeUom, unitToConvert.value);
    const finalPackSize = (unitToConvert.label !== 'Original') ? fixedPackSize : initPackSize;
    let finalPackSizeUom = (unitToConvert.label !== 'Original') ? (finalPackSize ? unitToConvert.label : initPackSizeUom) : initPackSizeUom;

    const unit = (isCustomPackSize || unitToConvert.label !== 'Original') ? unitToConvert.value : {type: unitType, label: finalPackSizeUom};
    const fixedUnitPrice = convertUnitPrice(fullPrice, initUnitPrice, finalPackSize, finalPackSizeUom, unit);
    let finalUnitPrice = fullPrice === 0 ? 0 : fixedUnitPrice;

    if (!item.isCatchWeight && item.packSizeUom != 'lb' && unitSize < 1) {
      // console.log('item', item);
      // console.log('finalPackSizeUom', finalPackSizeUom);
      // console.log('finalPackSize', finalPackSize);
      // console.log('unitQuantity', unitQuantity);
      // console.log('unitSize', unitSize);
      // console.log('finalUnitPrice', finalUnitPrice);
      finalUnitPrice = itemFullPrice / unitQuantity;
      // console.log('finalUnitPrice', finalUnitPrice);
      finalPackSizeUom = '(' + unitSize + ' ' + finalPackSizeUom + ')';
    }

    return {finalPackSize, finalPackSizeUom, finalUnitPrice};
  };

  const convertPacksize = (unitQuantity, unitSize, packSizeUom, newUnit) => {
    let nVal = "";
    let conversion = 0;
    const baseValue = baseValueConversion[newUnit.type ? newUnit.type : getTypeConversion[newUnit.label]];
    if (baseValue) {
      if (packSizeUom) {
        if (baseValue[packSizeUom.toLowerCase()]) {
          conversion = unitSize * ( baseValue[newUnit.label] / baseValue[packSizeUom.toLowerCase()] );
          conversion = Math.round(conversion * 100) / 100;
          nVal = unitQuantity+'/'+conversion+' '+newUnit.label;
        }
      }
    }
    return nVal;
  };

  const convertUnitPrice = (price, unitPrice, finalPackSize, packSizeUom, newUnit) => {
    const {unitQuantity, unitSize} = getPacksizeParts(finalPackSize);
    const baseValue = baseValueConversion[newUnit.type ? newUnit.type : getTypeConversion[newUnit.label]];
    let newUnitPrice = unitPrice;
    if (baseValue) {
      if (packSizeUom) {
        if (baseValue[packSizeUom.toLowerCase()]){
          if(unitQuantity && unitSize){
            const nUnitPrice = price / (unitQuantity*unitSize);
            newUnitPrice = Math.round(nUnitPrice * 100) / 100;
          }else{
            newUnitPrice = unitPrice * ( baseValue[packSizeUom.toLowerCase()] / baseValue[newUnit.label] );
            newUnitPrice = Math.round(unitPrice * 100) / 100;
          }

        }
      }
    }
    return newUnitPrice;
  };

  const convertUnfixedUnitPrice = (price, unitPrice, finalPackSize, packSizeUom, newUnit) => {
    const {unitQuantity, unitSize} = getPacksizeParts(finalPackSize);
    const baseValue = baseValueConversion[newUnit.type ? newUnit.type : getTypeConversion[newUnit.label]];
    let newUnitPrice = unitPrice;
    if (baseValue) {
      if (packSizeUom) {
        if (baseValue[packSizeUom.toLowerCase()]){
          if(unitQuantity && unitSize){
            const nUnitPrice = price / (unitQuantity*unitSize);
            newUnitPrice = nUnitPrice;
          }else{
            newUnitPrice = unitPrice * ( baseValue[packSizeUom.toLowerCase()] / baseValue[newUnit.label] );
            newUnitPrice = unitPrice;
          }

        }
      }
    }
    return newUnitPrice;
  };

  const getPacksizeParts = (packSize) => {
    let unitQuantity = null;
    let unitSize = null;
    if (typeof packSize === 'string'){
      if(packSize){
        const nPackSize = packSize;
        const parts = nPackSize.split(' ');
        const units = parts[0].split('/');
        unitQuantity = units[0];
        unitSize = units[1];
      }
    }

    return {unitQuantity, unitSize};
  };

  return {convertAllBase, convertAll, convertAllFromBase};
};

export default {
  usePackSize, useUnitPrice, useGetPackSizeParts, usePackSizeParts,
  useConvert, useCanConvert, useConvertAll
};
