import React, { Fragment, useState } from 'react';
import { Card, Row, Col, Button } from "reactstrap";
import { MdCloudDownload } from 'react-icons/md';
import { IoIosEye, IoMdTrash } from 'react-icons/io';
import { MdEdit } from 'react-icons/md';
import { colors, timezone } from "../../../../../config/constants";
import { isMobile } from 'react-device-detect';
import '../Distributor.scss';
import useDistributor from '../useDistributor';
import Moment from 'react-moment';
import moment from 'moment-timezone';

const DistributorRow = (props) => {
  const { data } = props;
  const [distributor, setDistributor] = useState(data);
  const { viewLastOrderGuide, editDist, deleteDist } = useDistributor.useRow(props);

  const lastOGDate = !distributor.lastOrderGuide ? '' : moment.utc(distributor.lastOrderGuide.create_time).tz(timezone.to).format("MM/DD/YYYY hh:mm A z");
  

  const distributorsDsr = (distributor.dsrs.length > 0) ?
    distributor.dsrs.map(dsr =>
      <Row key={`${distributor.id}_dsr_${dsr.id}`} className='mx-0 px-0'>
        <Col xs={5} className='text-truncate'>{dsr.email}</Col>
        <Col xs={4} className='text-truncate'>{dsr.name}</Col>
        <Col xs={3} className='text-truncate'>{dsr.phone}</Col>
      </Row>
    ) :
    ''
    ;

  const distributorsDsrMobile = (distributor.dsrs.length > 0) ?
    distributor.dsrs.map(dsr =>
      <Col xs={12} key={`${distributor.id}_dsr_${dsr.id}`} className='d-flex justify-content-end'>
        {dsr.email}
      </Col>
    ) :
    ''
    ;


  if (isMobile) {
    return (
      <Card>
        <Col className='pb-3'>
          <Row>
            <Col className='py-2'>
              {distributor.nickname}
            </Col>
          </Row>
          <Row className='py-2'>
            <Col xs={5} className='pr-0 text-gray text-left'>
              Last Price Update
            </Col>
            <Col xs={7} className='pl-0 text-right'> 
              {lastOGDate}
            </Col>
          </Row>
          <Row className='py-2 text-gray'>
            <Col className='text-gray'>
              Emails
                        </Col>
          </Row>
          <Row className='py-1'>
            {distributorsDsrMobile}
          </Row>
          <Row>
            <Col xs={6} className='px-3'>
              <Button color='white' className='dist-btn-outline' onClick={() => viewLastOrderGuide(distributor)} style={{ width: '100%' }}>
                <IoIosEye color={colors.lightBlueText} size={20} />
              </Button>
            </Col>
            <Col xs={6} className='px-3'>
              <Button color='white' className='dist-btn-outline' onClick={editDist} style={{ width: '100%' }}>
                <MdEdit color={colors.lightBlueText} size={20} />
              </Button>
            </Col>
          </Row>
        </Col>
      </Card>
    )

  }

  return (
    <Card className='mx-3 p-3'>
      <Row className='d-flex flex-direction-row align-items-center '>
        <Col xs={2}>
          {distributor.nickname}
        </Col>
        <Col xs={6}>
          {distributorsDsr}
        </Col>
        <Col xs={2} className='px-1'>
          { lastOGDate }
        </Col>
        <Col xs={1} className='px-1'>
          {
            !distributor.lastOrderGuide ? '' : distributor.lastOrderGuide.item_count
          }
        </Col>
        <Col xs={1} className='col-1 d-flex flex-direction-row justify-content-end'>
          <Col className='px-1'>
            <Button color={colors.lightBlueBorder} className='dist-btn-outline' onClick={() => viewLastOrderGuide(distributor)} style={{ width: '100%' }}>
              <IoIosEye color={colors.lightBlueText} size={20} />
            </Button>
          </Col>
          <Col className='px-1'>
            <Button color={colors.lightBlueBorder}  className='dist-btn-outline' onClick={editDist} style={{ width: '100%' }}>
              <MdEdit color={colors.lightBlueText} size={20} />
            </Button>
          </Col>
        </Col>
      </Row>
    </Card>
  )

}

export default DistributorRow;