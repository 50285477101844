import React from 'react';
import { colors } from '../../../config/constants';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';
import IconBadgeSolid from '../../IconBadge/IconBadgeSolid';
import { FaExclamation } from 'react-icons/fa';
import useGoTo from '../../GoTo/useGoTo';
import { useDispatch } from 'react-redux';
import { showModal } from "../../../reducers/modals/modalsActions";
import SpecialRequestModal from '../../Modals/SpecialRequestModal/SpecialRequestModal';
import { isMobile } from 'react-device-detect';

const PunchedOutIcon = (props) => { 
  // const {elemId} = props;
  // const {addSpecialRequest} = useGoTo.useActions();
  const { elemId, item } = props;
  const dispatch = useDispatch();

  const addSpecialRequest = () => {
    const mprops = {
      item: item ? item : null,
      menu: isMobile ? true : false,
      fullMenu: isMobile ? true : false      
    }
    dispatch(showModal(SpecialRequestModal, mprops));
  };

  return (    
      <IconBadgeSolid Icon={FaExclamation} colorIcon='white' color={colors.orange} elemId={`punchedOut-`+elemId}>
        <Row className="my-2">
          <Col className="text-bold">Sorry!</Col>
        </Row>
        <Row className="mb-3">
          <Col className="mx-3 px-3">
            Your distributor has removed an item in your order from your active order guide. You can find the item, as it will appear "grayed out". You cannot place your order with the grayed out item, but you can remove the grayed out item and request this item as a "special request".'
          </Col>
        </Row>
        <Row className='p-3'>
          <Col>
            <Button block color='green' onClick={() => addSpecialRequest()}>Add Special Request</Button>
          </Col>
        </Row>
      </IconBadgeSolid>
  );
};

PunchedOutIcon.propTypes = {
  item: PropTypes.any
};

PunchedOutIcon.defaultProps = {
  item: {}
};

export default PunchedOutIcon;