import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Header from '../../../../../SharedComponents/Header/Header';
import useRecipeCostingMenu from './useRecipeCostingMenu';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import RecipeCostingRecipe from '../RecipeCostingRecipe/RecipeCostingRecipe';
import { colors } from '../../../../../config/constants';
import { isMobile } from 'react-device-detect';
import { GoPlus } from 'react-icons/go';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import listWhite from '../../../../../assets/images/list-white.svg';
import { useSelector, useDispatch } from 'react-redux';
import { showModal } from '../../../../../reducers/modals/modalsActions';
import RecipeInfoModal from '../Modals/RecipeInfoModal/RecipeInfoModal';
import { clearRecipeInfo, setIngredientsList } from '../../../../../reducers/recipeCosting/recipeCostingActions';

const RecipeCostingMenu = () => {
  const { menuName, recipeList, selected, createRecipe, recipeDateFilter, recipeListTable } = useRecipeCostingMenu.useApi();
  const spinner = useSelector(({userInterface}) => userInterface.spinner);
  const dispatch = useDispatch();
  const history = useHistory();

  const newRecipe = () => {
    const modalProps = {
      menu: isMobile,
      isEdit: false,
      lockMenu: true,
      menuInfo: selected,
      onSave: createRecipe
    };
    dispatch(clearRecipeInfo());
    dispatch(showModal(RecipeInfoModal, modalProps));
  };

  const redirectToNewRecipe = () => {
    dispatch(setIngredientsList([]));
    dispatch(clearRecipeInfo(selected));
    history.push(`new-recipe`);
  };

  const rightButton = isMobile ? {icon: <GoPlus onClick={newRecipe} color={colors.dark} size={22} />} : {button: {onClick: redirectToNewRecipe, color: 'green', title:'New Recipe'}};

  return (
    <Switch>
      <Route exact path='/recipe-costing/new-recipe'>
        <RecipeCostingRecipe create createRecipe={createRecipe} />
      </Route>
      <Route exact path='/recipe-costing/:menuId'>
        <Header showMenu={false} showLogo={false} showBack={true} showTitle title={menuName} showCart={false} rightButton={rightButton}>
          {recipeDateFilter}
        </Header>
        <Container fluid={true} className={'m-0 ' + (isMobile ? 'p-0' : 'pt-3')}>
          {recipeListTable}
          <ShowIf show={recipeList.length === 0 && !spinner}>
            <Row className='m-0 text-center py-4'>
              <Col className='pt-3'>
                <img src={listWhite} alt='recipe' />
              </Col>
            </Row>
            <Row className='m-0 text-center'>
              <Col>
                {"There are no recipes here yet!"}
                <br/>
                {"Press the " + ( isMobile ? "'+'" : "'New Recipe'") + " button above to begin adding recipes."}
              </Col>
            </Row>
          </ShowIf>
        </Container>
      </Route>
      <Route exact path='/recipe-costing/:menuId/recipe/:recipeId'>
        <RecipeCostingRecipe />
      </Route>
      <Route>
        <Redirect to='/recipe-costing' />
      </Route>
    </Switch>
  );
}

export default RecipeCostingMenu;
