import React, { Fragment } from 'react';
import { Col, Button } from 'reactstrap';
import Select from 'react-select';
import ShowIf from '../../ShowIf/ShowIf';
import { isMobile } from 'react-device-detect';
import { FaSortAmountDown } from 'react-icons/fa';
import { colors, sortOptions } from '../../../config/constants';
import { useSelector, useDispatch } from 'react-redux';
import { setListSortBy, setInventorySortBy, setInventoryOptionsRearrangeDisabled } from '../../../reducers/items/itemsActions';
import { showModal } from '../../../reducers/modals/modalsActions';
import InventorySortByModal from '../../Modals/InventorySortByModal/InventorySortByModal';
import {localStorageConst} from '../../../config/constants';


const SubHeaderPlaceOrderSortBy = (props) => {
  const { view } = props;
  const dispatch = useDispatch();
  const inventorySort = useSelector(({items}) => items.inventory.sortBy);
  const listSortOptions = useSelector(({items}) => items.list.sortByOptions);
  const listSort = useSelector(({items}) => items.list.sortBy);
  const options = view === 'list' ? listSortOptions : sortOptions.inventoryView;
  const selected = view === 'list' ? listSort : inventorySort;  

  // const displayOnDropdown = {
  //   label: selected.value ,
  //   value: selected.id
  // };
  // console.log(displayOnDropdown)

  const onSelectSort = (value) => {
    if (view === 'list') {
      dispatch(setListSortBy(value));
    } else {
      localStorage.setItem(localStorageConst.keys.inventorySortBySelected, JSON.stringify(value));
      dispatch(setInventorySortBy(value));
      dispatch(setInventoryOptionsRearrangeDisabled(value));
    }
  };

  const openMobileMenu = () => {
    dispatch(showModal(InventorySortByModal));
  };

  return (
    <Fragment>
      <ShowIf show={!isMobile}>
        <Col className={ view === 'inventory' ? 'd-flex align-items-center pr-0' : 'd-flex offset-md-1 align-items-center pr-0'}>
          <Col className='w-fc text-light-blue p-0'>Sort By:</Col>
          <Col className='w-200px pr-0'>
            <Select className='fb-select' classNamePrefix='select' options={options} value={selected} onChange={onSelectSort} />
          </Col>
        </Col>
      </ShowIf>
      <ShowIf show={isMobile}>
        <Col className='d-flex pr-0'>
          <ShowIf show={view === 'list'}>
            <Col className='w-200px p-0'>
              <Select className='fb-select' classNamePrefix='select' options={options} value={selected} onChange={onSelectSort} />
            </Col>
          </ShowIf>
          <ShowIf show={view === 'inventory'}>
            <Col className='w-fc p-0'>
              <Button color='white' size='square' onClick={openMobileMenu}>
                <FaSortAmountDown color={colors.lightBlueText} />
              </Button>
            </Col>
          </ShowIf>
        </Col>
      </ShowIf>
    </Fragment>
  )
}

export default SubHeaderPlaceOrderSortBy;