import React, { useState } from 'react';
import PlusButton from '../../PlusButton/PlusButton';
import MinusButton from '../../MinusButton/MinusButton';
import useInput from '../../SharedEffects/useInput';
import { Col, Input, Row } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import ShowIf from '../../ShowIf/ShowIf';
import Circle from '../../Icons/Circle/Circle';

const AddFoodbamItemModalCart = (props) => {
  const { unitMeasure, overlay, setOverlay } = props;
  const quantityInput = useInput.useText(0, { isNumber: { min: 0 }, value: props.quantity, set: (newValue) => props.editQuantity(props.uniqid, newValue) });

  const clickButton = (val) => {
    quantityInput.setValue(val);
  };

  return (
    <Row className='align-items-center'>
      <ShowIf show={isMobile}>
        <Col className='justify-content-start w-fc'>
          <Circle content={quantityInput.value} overlay={overlay} isBlue large isActive={quantityInput.value || overlay} onClick={() => setOverlay(!overlay)}/>
        </Col>
      </ShowIf>
      <ShowIf show={!isMobile || overlay}>
        <Col className={'w-fc pl-0 justify-content-end'}>
          <MinusButton onClick={() => clickButton(quantityInput.value - 1)} isBlue isActive={quantityInput.value > 0} />
        </Col>
        <Col className={'p-0 justify-content-center' + (isMobile ? '' : ' w-squared-input')}>
          <Input type='number' placeholder='0' onChange={quantityInput.set} value={quantityInput.value} />
        </Col>
        <Col className={'col w-fc justify-content-left'}>
          {unitMeasure}
        </Col>
        <Col className={'w-fc justify-content-start'}>
          <PlusButton onClick={() => clickButton(quantityInput.value + 1)} isBlue isActive={quantityInput.value > 0} />
        </Col>
      </ShowIf>
    </Row>
  );
};

export default AddFoodbamItemModalCart;