import React, { Fragment, useState } from 'react';
import { Row, Col, Input } from 'reactstrap';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { colors, unitsOfMeasure } from '../../../config/constants';
import ShowIf from '../../ShowIf/ShowIf';
import { isMobile } from 'react-device-detect';
import Select from 'react-select';

const AddCustomItemModalContent = (props) => {
  const {
    description, unitMeasure, distributor, price, packSize, din, brand,
  } = props;
  const [showMore, setShowMore] = useState(false);
  return (
    <Fragment>
      <Row>
        <Col className='text-label'>Description *</Col>
      </Row>
      <Row className='mt-2 mb-3'>
        <Col>
          <Input {...description} placeholder='Enter Item Name' />
        </Col>
      </Row>
      <Row>
        <Col className={'text-label' + (isMobile ? 'pr-2' : '')}>Unit of Measure *</Col>
        <Col className={'text-label' + (isMobile ? 'pl-2' : '')}>Distributor *</Col>
      </Row>
      <Row className='mt-2 mb-3'>
        <Col className={isMobile ? 'pr-2' : ''}>
          <Select isSearchable={false} className='fb-select' classNamePrefix='select' {...unitMeasure} />
        </Col>
        <Col className={isMobile ? 'pl-2' : ''}>
          <Input {...distributor} placeholder='For Example PFG' />
        </Col>
      </Row>
      <Row>
        <Col className={'text-label' + (isMobile ? 'pr-2' : '')}>Price *</Col>
        <Col className={'text-label' + (isMobile ? 'pl-2' : '')}>Pack Size *</Col>
      </Row>
      <Row className='mt-2 mb-3'>
        <Col className={isMobile ? 'pr-2' : ''}>
          <Input type='number' {...price} placeholder='$' />
        </Col>
        <Col className={isMobile ? 'pl-2' : ''}>
          <Row className='align-items-center'>
            <Col className={'pr-0' + (isMobile ? '' : ' w-squared-input')}>
              <Input type='number' {...packSize.outerPack} />
            </Col>
            <Col className='w-fc px-1'>/</Col>
            <Col className={'p-0' + (isMobile ? '' : ' w-squared-input')}>
              <Input type='number' {...packSize.innerPack} />
            </Col>
            <Col className='pl-2'>
              <Select isSearchable={false} className='fb-select' classNamePrefix='select' {...packSize.uom} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className='align-items-center py-2 clickable mt-3 w-fc' onClick={() => setShowMore(!showMore)}>
        <ShowIf show={!showMore}>
          <Col className='text-label w-fc'>Show Details<FaCaretDown className='ml-1' color={colors.lightBlueText} /></Col>
        </ShowIf>
        <ShowIf show={showMore}>
          <Col className='text-label w-fc'>Hide Details<FaCaretUp className='ml-1' color={colors.lightBlueText} /></Col>
        </ShowIf>
      </Row>
      <ShowIf show={showMore}>
        <Row>
          <Col className={'text-label' + (isMobile ? 'pr-2' : '')}>DIN</Col>
          <Col className={'text-label' + (isMobile ? 'pl-2' : '')}>Brand</Col>
        </Row>
        <Row className='mt-2 mb-3'>
          <Col className={isMobile ? 'pr-2' : ''}>
            <Input {...din} placeholder='Item or SKU Number' />
          </Col>
          <Col className={isMobile ? 'pl-2' : ''}>
            <Input {...brand} placeholder='For Example Tyson' />
          </Col>
        </Row>
      </ShowIf>
    </Fragment>
  );
};

export default AddCustomItemModalContent;