import React from 'react';
import { Col } from 'reactstrap';
import { IoMdListBox } from 'react-icons/io';
import { colors } from '../../../config/constants';
import { IoMdSwap } from 'react-icons/io';
import { localStorageConst } from '../../../config/constants';
import { saveView } from '../../../reducers/items/itemsActions';
import { useDispatch } from 'react-redux';

const SubHeaderPlaceOrderViewIcons = (props) => {
  const { view, history } = props;
  const dispatch = useDispatch();

  const goToView = (newView) => {
    if (newView !== view) {
      history.push(`/place-order/${newView}`);
      localStorage.setItem(localStorageConst.keys.view, newView);
      dispatch(saveView(newView));
    }
  };

  const goToInventory = () => {
    goToView('inventory');
  };

  const goToList = () => {
    goToView('list');
  };

  return (
    <Col className='p-0 w-fc d-flex justify-content-end' style={{maxWidth: '78px'}}>
      <Col className='w-fc pr-0 clickable' onClick={goToList} >
        <IoMdListBox color={ view === 'list' ? colors.green : colors.dark } size={24} />
      </Col>
      <Col className='w-fc pl-2 clickable' onClick={goToInventory} >
        <IoMdSwap color={ view === 'inventory' ? colors.green : colors.dark } size={24} />
      </Col>
    </Col>
  );
}

export default  SubHeaderPlaceOrderViewIcons;