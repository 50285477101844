export default function reducer(state = {
  modals: [],
  numTourModal: 1,
}, action) {
  switch (action.type) {
    case 'ADD_MODAL':
      action.payload.id = action.payload.id + '-' + state.modals.length + 1
      return {
        ...state,
        modals: [...state.modals, action.payload]
      };
    case 'REMOVE_MODAL':
      return {
        ...state,
        modals: state.modals.filter(m => m.id !== action.payload)
      };

    case 'NEXT_TOUR_MODAL':
      return {
        ...state,
        numTourModal: action.payload
      };
    default:
      return state;
  }
};