import React, { useState, useEffect } from "react";
import { Col, Button } from "reactstrap";
import {
  MdFullscreen,
  MdFullscreenExit,
  MdCheckCircle,
  MdLocalPrintshop,
} from "react-icons/md";
import { RiCheckboxCircleLine } from "react-icons/ri";
import { colors, inventoryOptions } from "../../../config/constants";
import { FaChartBar } from "react-icons/fa";
import ShowIf from "../../ShowIf/ShowIf";
import { useSelector, useDispatch } from "react-redux";
import { saveInventoryOnHandTotal } from "../../../reducers/items/itemsActions";
import { saveInventoryItems } from "../../../reducers/items/itemsActions";
import { setUserData } from "../../../reducers/user/userAction";
import { showModal } from "../../../reducers/modals/modalsActions";
import AlertModal from "../../Modals/AlertModal/AlertModal";
import { useHistory } from "react-router-dom";
import timestampedInventory from "../../../assets/images/timestamped-inventory.png";
import StartInventoryModal from "../../Modals/StartInventoryModal/StartInventoryModal";
import timestampedInventoryApi from "../../../api/timestampedInventoryApi";
import { isMobile } from "react-device-detect";
import SyncInventory from "./Subcomponents/SyncInventory";
import dragIcon from "../../../assets/images/drag-drop.svg";

const SubHeaderPlaceOrderInventoryOptions = ({ view }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const items = useSelector(({ items }) => items.inventory.items);
  const listSelected = useSelector(({ items }) => items.inventory.listSelected);  
  const rearrangeOption = useSelector(({ items }) => items.inventory.options.filter((o) => o.name === inventoryOptions.keys.rearrange)[0]);

  const [startDate, setStartDate] = useState(new Date());
  const displayInventory = useSelector(({ user }) => user.activeLocation.display_inventory);
  const dataUser = useSelector(({ user }) => user);
  const isStartInventory = dataUser.activeLocation.activeInventory;
  const [nameBtnInventory, setNameBtnInventory] = useState("Start Inventory");

  const [pathName, setPathName] = useState(history.location.pathname);
  const [isAnalyticsMode, setIsAnalyticsMode] = useState(pathName === "/place-order/inventory/analytics" ? true : false);
  const expandAll = items.some((i) => !i.expand);

  const connection = useSelector(({ connection }) => connection.online);

  const clickRearrange = () => {
    history.push({
      pathname: `/sort-inventory/${listSelected.id}`,
    });
  };

  const clickExpand = () => {
    dispatch(
      saveInventoryItems(
        items.map((i) => {
          return { ...i, expand: expandAll };
        })
      )
    );
  };

  useEffect(() => {
    setNameBtnInventory(
      !isStartInventory ? "Start Inventory" : "Review Inventory"
    );
  }, [isStartInventory]);

  const clickInventory = () => {
    timestampedInventoryApi
      .getInventorySpreadsheet()
      .then(({ data }) => {
        if (data) {
          var excelElement = document.createElement("a");
          excelElement.href = data + "?t=" + new Date().getTime();
          excelElement.click();
        } else {
          dispatch(
            showModal(AlertModal, { message: "You have no inventory." })
          );
        }
      })
      .catch((err) => console.error(err));
  };

  const toggleAnalytics = () => {
    if (history.location.pathname === "/place-order/inventory/analytics") {
      setIsAnalyticsMode(false);
      history.push({
        pathname: "/place-order/inventory",
        search: history.location.search,
      });
    } else {
      setIsAnalyticsMode(true);
      history.push({
        pathname: "/place-order/inventory/analytics",
        search: history.location.search,
      });
    }
  };

  const apiStartInventory = (startDate) => {
    const params = {
      start_date: startDate,
    };
    timestampedInventoryApi
      .newInventory(params)
      .then((res) => {
        dispatch(saveInventoryItems([]));
        dispatch(saveInventoryOnHandTotal(0.0));
        dataUser.activeLocation.activeInventory = true;
        dispatch(setUserData(dataUser));
        dispatch(
          saveInventoryItems(
            items.map((i) => {
              return {
                ...i,
                items: i.items.map((j) => {
                  return { ...j, onHand: 0, onHandPack: 0, onHandUom: 0 };
                }),
              };
            })
          )
        );
      })
      .catch((error) => {
        let message = "Something went wrong while processing the information.";
        if (error.data) {
          message = error.data.message;
        }
        const props = {
          message: message,
          onOk: () => {
            // history.push('/logout');
          },
        };
        dispatch(showModal(AlertModal, props));
      });
  };

  const startInventory = (params) => {
    if (!isStartInventory) {
      const props = {
        header: "Start Inventory",
        message: `You are about to start taking inventory. Once you start all existing onhand values will be cleared.`,
        onOk: apiStartInventory,
      };
      dispatch(showModal(StartInventoryModal, props));
    } else {
      history.push({
        pathname: "/inventory-view/review-inventory",
      });
    }
  };

  return (
    <ShowIf show={view === "inventory"}>
      <Col className="d-flex align-items-center p-0 justify-content-end">
        <div className="w-fc pr-1">
          <Button
            size={isMobile ? "square" : ""}
            color="white"
            style={{ fontSize: 15 }}
            onClick={clickRearrange}
            disabled={rearrangeOption.disabled}
          >
            <img
              src={dragIcon}
              alt="lightIcon"
              size={20}
              className="square-img"
            />{" "}
            {isMobile ? "" : <span>&nbsp;Rearrange</span>}
          </Button>
        </div>

        <div className={`w-fc pr-1`}>
          <ShowIf show={isMobile && displayInventory === 1}>
            <Button
              size="square"
              color="white"
              onClick={startInventory}
              disabled={!connection ? true : false}
            >
              {isStartInventory ? (
                <MdCheckCircle size={20} color={colors.lightBlueText} />
              ) : (
                <RiCheckboxCircleLine size={20} color={colors.lightBlueText} />
              )}
            </Button>
          </ShowIf>
          <ShowIf show={!isMobile && displayInventory === 1}>
            <Button
              color="white"
              className="d-flex align-items-center "
              style={{ fontSize: 13 }}
              onClick={startInventory}
              disabled={!connection ? true : false}
            >
              {isStartInventory ? (
                <MdCheckCircle size={20} color={colors.lightBlueText} />
              ) : (
                <RiCheckboxCircleLine size={20} color={colors.lightBlueText} />
              )}
              <span>&nbsp;&nbsp;{nameBtnInventory}</span>
            </Button>
          </ShowIf>
        </div>

        <ShowIf show={displayInventory}>
          <div className="w-fc pr-1">
            <SyncInventory />
          </div>
        </ShowIf>

        <div className="w-fc pr-1">
          <Button size="square" color="white" onClick={clickExpand}>
            {expandAll ? (
              <MdFullscreen size={20} color={colors.lightBlueText} />
            ) : (
              <MdFullscreenExit size={20} color={colors.lightBlueText} />
            )}
          </Button>
        </div>

        <ShowIf show={displayInventory}>
          <div className="w-fc pr-1">
            <Button size="square" color="white" onClick={clickInventory}>
              <MdLocalPrintshop color={colors.lightBlueText} />
            </Button>
          </div>
        </ShowIf>

        <div className="w-fc pr-1">
          <Button
            size="square"
            color={isAnalyticsMode ? "green" : "white"}
            onClick={toggleAnalytics}
          >
            <FaChartBar
              color={isAnalyticsMode ? colors.white : colors.lightBlueText}
            />
          </Button>
        </div>
      </Col>
    </ShowIf>
  );
};

export default SubHeaderPlaceOrderInventoryOptions;
