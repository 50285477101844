import { useState, useEffect } from 'react';
import useInput from '../../../../../../SharedComponents/SharedEffects/useInput';
import noImage from '../../../../../../assets/images/no-image.png';
import subImage from '../../../../../../assets/images/subrecipe-image.png';
import { useDispatch } from 'react-redux';
import { showModal } from '../../../../../../reducers/modals/modalsActions';
import AddFoodbamItemModal from '../../../../../../SharedComponents/Modals/AddFoodbamItemModal/AddFoodbamItemModal';
import QuestionModal from '../../../../../../SharedComponents/Modals/QuestionModal/QuestionModal';
import recipeApi from '../../../../../../api/recipeApi';
import useConvert from '../../../../../../SharedComponents/Conversion/useConversion';
import comparisonViewsApi from '../../../../../../api/comparisonViewsApi';

const useInventory = (init, data, getRecipeData) => {
  const dispatch = useDispatch();
  const recipeAmount = useInput.useText(init.recipeAmount, {validate: {fraction:{strict:false}}});
  const [ uom, setUom ] = useState({label: init.recipeUom, value: 0});
  const linkedItemsInitial = init.linkedItems.map(i => {
    const isCustomPackSize = i.customPackSize ? !(i.customPackSize === i.packSize) : false
    return {
      recipeId: init.recipeId,
      ingredientItemId: init.ingredientItemId,
      name: i.name || i.description,
      distributor: i.distributor,
      brand: i.brand,
      id: i.id,
      genericItemId: i.genericItemId,
      itemDistributorId: i.itemDistributorId,
      subRecipeId: i.subRecipeId,
      genericCustomItemId: i.genericCustomItemId,
      packSize: isCustomPackSize ? i.customPackSize : i.packSize,
      packSizeUom: i.packSizeUom,
      price: i.price,
      isLink: true,
      image: i.isSubRecipe ? subImage : (i.imageUrl ? i.imageUrl : (i.brandImageUrl ? i.brandImageUrl : noImage)),
      waste: '',
      isCustomPackSize: isCustomPackSize,
      isCustomItem: i.isCustomItem,
      isSubRecipe: i.isSubRecipe,
      isPunchedOut: i.isPunchedOut,
      conversion: {
        item: {
          packSize: '',
        },
        recipe: {
          packSize: '',
        }
      }
    }
  });

  const [ linkedItems, setLinkedItems ] = useState(linkedItemsInitial);

  const openLinkItem = () => {
    const modalProps = {
      idLabel: 'itemDistributorId',
      title: 'Link Item',
      isLink: true,
      initItems: linkedItems,
      includeSubrecipe: true,
      initSearch: init.name,
      onAdd: (allItems, newItems) => {
        const newData = [...linkedItems.map(l => {
          let isThere = false;
          // Removing Items
          allItems.map(i => {
            if (i.itemDistributorId === l.itemDistributorId) {
              isThere = true;
            }
          });
          return {
            ...l,
            isLink: isThere
          };
        }), ...newItems.map(i => {
          const isCustomPackSize = i.customPackSize ? !(i.customPackSize === i.packSize) : false
          return {
            recipeId: init.recipeId,
            ingredientItemId: init.ingredientItemId,
            name: i.name || i.description,
            distributor: i.distributor,
            brand: i.brand,
            id: i.isCustomItem ? i.genericCustomItemId : ( i.isSubRecipe ? i.subRecipeId : i.itemDistributorId),
            itemDistributorId: i.itemDistributorId,
            subRecipeId: i.subRecipeId,
            genericCustomItemId: i.genericCustomItemId,
            packSize: isCustomPackSize ? i.customPackSize : i.packSize,
            packSizeUom: i.packSizeUom,
            price: i.price,
            isLink: true,
            image: i.isSubRecipe ? subImage : (i.imageUrl ? i.imageUrl : (i.brandImageUrl ? i.brandImageUrl : noImage)),
            waste: '',
            isCustomPackSize: isCustomPackSize,
            isCustomItem: i.isCustomItem,
            isSubRecipe: i.isSubRecipe,
            isPunchedOut: i.isPunchedOut,
            conversion: {
              item: {
                packSize: '',
              },
              recipe: {
                packSize: '',
              }
            }
          }
        })];
        setLinkedItems(newData);
      },
      onAddCustom: (customItem) => {
        const { packSize, unitMeasure, ...values } = customItem;
        const params = {
          ...values,
          unitMeasure: unitMeasure.name,
          packSizeVal: `${packSize.outerPack}/${packSize.innerPack} ${packSize.uom.shorthand}`
        };
        comparisonViewsApi.postGroupCustomItem(data.comparisonViewId, data.id, params).then(({ data }) => {
          getRecipeData();
          setLinkedItems([
            ...linkedItems,
            {
              recipeId: init.recipeId,
              ingredientItemId: init.ingredientItemId,
              name: data.description,
              distributor: data.distributor,
              brand: data.brand,
              id: data.id,
              genericItemId: data.genericCustomItemId,
              itemDistributorId: null,
              subRecipeId: null,
              genericCustomItemId: data.genericCustomItemId,
              packSize: data.packSize,
              packSizeUom: data.packSizeUom,
              price: data.price,
              isLink: true,
              image: noImage,
              waste: '',
              isCustomPackSize: false,
              isCustomItem: true,
              isSubRecipe: false,
              isPunchedOut: data.isPunchedOut,
              conversion: {
                item: {
                  packSize: '',
                },
                recipe: {
                  packSize: '',
                }
              }
            }
          ]);
        }).catch(err => console.error(err));
      }
    };
    dispatch(showModal(AddFoodbamItemModal, modalProps));
  };

  const setLinkItem = (index, field, value) => {
    const itemUom = linkedItems[index].packSizeUom;
    setLinkedItems(linkedItems.map((l, i) => {
      if (field === 'conversion') {
        if (itemUom && l.packSizeUom === itemUom) {
          return {
            ...l,
            [field]: value
          };
        }
      }
      if (i !== index) return l;
      return {
        ...l,
        [field]: value
      };
    }));
  };

  useEffect(() => {
    setLinkedItems(linkedItems.map(l => {
      const canConvert = useConvert.useCanConvert(uom.label, l.packSizeUom);
      let itemVal = 1;
      let nVal = useConvert.useConvert(1, uom.label, l.packSizeUom);
      if (nVal < 1) {
        itemVal = useConvert.useConvert(1, l.packSizeUom, uom.label);
        nVal = 1;
      }
      return {
        ...l,
        conversion: {
          item: {
            packSize: canConvert ? nVal : l.conversion.item.packSize,
          },
          recipe: {
            packSize: canConvert ? itemVal : l.conversion.recipe.packSize,
          }
        }
      }
    }));
  }, [uom, linkedItems.length]);

  return {
    recipeAmount, linkedItems, openLinkItem, setLinkItem,
    uom: { set: setUom, value: uom }, setLinkedItems,
  }
};

const useConversion = (init) => {
  const recipeAmount = useInput.useText(init.recipeAmount, {validate: {fraction: {strict: false}}});
  const [ uom, setUom ] = useState({label: init.uom, value: 0});
  const waste = useInput.useText(init.waste, {isNumber: true});

  const conversion = {
    item: {
      packSize: useInput.useText(init.conversion.packSize, {validate: {fraction: {strict: false}}}),
      uom: init.conversion.uom
    },
    recipe: {
      packSize: useInput.useText(init.recipe.packSize, {validate: {fraction: {strict: false}}}),
      uom: uom.label
    }
  };

  useEffect(() => {
    const canConvert = useConvert.useCanConvert(uom.label, conversion.item.uom);
    if (canConvert) {
      let itemVal = 1;
      let nVal = useConvert.useConvert(1, uom.label, conversion.item.uom);
      if (nVal < 1) {
        itemVal = useConvert.useConvert(1, conversion.item.uom, uom.label);
        nVal = 1;
      }
      conversion.item.packSize.setValue(nVal);
      conversion.recipe.packSize.setValue(itemVal);
    }
  }, [uom]);

  return {
    recipeAmount, conversion, waste,
    uom: { value: uom, set: setUom },
    canConvert: useConvert.useCanConvert(uom.label, conversion.item.uom)
  }
};

const useDeleteIngredient = (recipeId, ingredientId, toggle, getRecipeData) => {
  const dispatch = useDispatch();

  const deleteIngredient = () => {
    recipeApi.deleteIngredient(recipeId, ingredientId).then(res => {
      getRecipeData();
      toggle();
    }).catch(err => console.error(err));
  };

  const onDelete = () => {
    const mp = {
      message: 'Delete Ingredient?',
      leftButton: {
        title: 'Delete',
        color: 'orange',
        onClick: () => deleteIngredient()
      },
      rightButton: {
        title: 'Cancel',
        color: 'green'
      }
    }
    dispatch(showModal(QuestionModal, mp));
  };

  return {
    onDelete
  }
};

export default {
  useConversion, useInventory, useDeleteIngredient
};