import { sortOptions } from "../../config/constants";

export default function reducer(state = {
  isExpand: false,
  groups: [],
  list: [],
  listSelected: null,
  sortBy: sortOptions.inventoryView.filter(iv => iv.default)[0],
  onHandTotal: 0,
  search: ''

}, action) {
  switch (action.type) {
    case 'SET_GROUPS':
      return {
        ...state,
        groups: action.payload
      }
    case 'SET_LIST':
      return {
        ...state,
        list: action.payload
      }
    case 'SET_LIST_SELECTED':
      return {
        ...state,
        listSelected: action.payload
      }
    case 'SET_SORT_BY':
      return {
        ...state,
        sortBy: action.payload
      }
    case 'SET_SEARCH':
      return {
        ...state,
        search: action.payload
      }
    case 'CLEAR_GROUPS_DATA':
      return {
        isExpand: false,
        groups: [],
        list: [],
        listSelected: null,
        sortBy: sortOptions.inventoryView.filter(iv => iv.default)[0],
        onHandTotal: 0,
        search: ''        
      };
    case 'EDIT_ONE_GROUP_ITEM':
      return {
        ...state,
        groups: state.groups.map(g => {
          if (g.id !== action.payload.groupId) return g;
          return {
            ...g,
            items: g.items.map(i => {
              if (i[action.payload.idLabel] !== action.payload.itemId) return i;
              return {
                ...i,
                ...action.payload.value
              };
            })
          };
        })
      };
    default:
      return state;
  }
};