import React from 'react';
import { Row, Col } from 'reactstrap';
import './SignUp.scss';
import { useSelector } from 'react-redux';

const SignUpSidebar = () => {
  const logo = useSelector(({company}) => company.logo);

  return (
    <Col xs={4} className='sign-up-sidebar'>
      <Row>
        <Col className='text-center pt-5'>
          <img src={logo} alt='company' />
        </Col>
      </Row>
    </Col>
  );
};

export default SignUpSidebar;