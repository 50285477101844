import React from 'react';
import { colors } from '../../../config/constants';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import IconBadgeSolid from '../../IconBadge/IconBadgeSolid';
import { FaExclamation } from 'react-icons/fa';

const CantConvertIcon = (props) => { 
  const {elemId} = props;

  return (
    <IconBadgeSolid Icon={FaExclamation} colorIcon='white' color={colors.yellow} elemId={elemId+'_cant_convert'}>
      <Row className='p-3 text-16'>
        <Col>We are not able to convert the packsize for you selection</Col>
      </Row>
    </IconBadgeSolid>
  );
};

CantConvertIcon.propTypes = {
  elemId: PropTypes.string.isRequired
};

export default CantConvertIcon;