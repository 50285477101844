import React from 'react';
import ShowIf from '../ShowIf/ShowIf';
import { Col, Button } from 'reactstrap';
import { colors } from '../../config/constants';
import { isMobile } from 'react-device-detect';
import { IoIosMenu } from 'react-icons/io';

const HeaderMenuButton = (props) => {
  const { clickMenu, show } = props;
  return (
    <ShowIf show={show}>
      <ShowIf show={isMobile}>
        <Col className='w-fc' onClick={clickMenu}>
          <IoIosMenu color={colors.dark} size={28} />
        </Col>
      </ShowIf>
      <ShowIf show={!isMobile}>
        <Col className='w-fc'>
          <Button onClick={clickMenu} color='green'>Menu</Button>
        </Col>
      </ShowIf>
    </ShowIf>
  )
};

export default HeaderMenuButton;