import React from 'react';
import { Col } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import LinkButton from '../../../../../../../SharedComponents/LinkButton/LinkButton';

const ItemDetailsInventoryLinkItemButton = ({isLink, onClick}) => {
  return (
    <Col className='w-fc'>
      <LinkButton large={!isMobile} isActive={isLink} onClick={onClick} />
    </Col>
  );
};

export default ItemDetailsInventoryLinkItemButton;