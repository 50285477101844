export const saveHeaderContent = (contents) => {
    return {
        type: 'SET_HEADER_CONTENTS_REVIEW_ORDER',
        payload: contents
    }
};


export const saveHeaderIndexRevOrder = (index) => {
    return {
        type: 'SET_HEADER_INDEX_REVIEW_ORDER',
        payload: index
    }
};
