import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import SidebarHeader from './SidebarHeader';
import SidebarBody from './SidebarBody';
import SidebarFooter from './SidebarFooter';
import { connect, useSelector, useDispatch } from 'react-redux';
import './Sidebar.scss';
import ShowIf from '../ShowIf/ShowIf';
import usersApi from '../../api/usersApi';
import useLogin from '../../Views/Login/useLogin';
import { FaCheck } from 'react-icons/fa';
import { colors } from '../../config/constants';
import  useGoto from '../GoTo/useGoTo';
import { clearItemsData } from '../../reducers/items/itemsActions';

const Sidebar = (props) => {
  const dispatch = useDispatch();
  const [isLocationOpen, setIsLocationOpen] = useState(false);
  const { doLogin } = useLogin.useLogin();
  const {placeOrderDefault} = useGoto.useActions();
  const open = useSelector(state => state.userInterface.isSidebarOpen);
  const locations = useSelector(({user}) => user.locations);
  const userId = useSelector(({user}) => user.id);
  const activeLocation = useSelector(({user}) => user.activeLocation);

  const changeLocation = (locId) => {
    if (activeLocation.id !== locId) {
      usersApi.changeLocation(locId, userId).then(({data}) => {
        dispatch(clearItemsData([]));
        doLogin(data);
        placeOrderDefault();
      });
    }
  };

  const locationsMap = locations.map(l => {
    return (
      <Row className='clickable hover-text-green' key={`loca_${l.id}`} onClick={() => changeLocation(l.id)}>
        <Col>{l.nickname}</Col>
        <ShowIf show={activeLocation.id === l.id}>
          <FaCheck color={colors.green} size={20} />
        </ShowIf>
      </Row>
    );
  });

  return (
    <Col className={'sidebar m-0 p-0' + (open ? ' sidebar-open' : '')}>
      <Container className="p-0">
        <SidebarHeader isLocationOpen={{set: setIsLocationOpen, value: isLocationOpen, toggle: () => setIsLocationOpen(!isLocationOpen)}} noLocations={locations.length === 0} />
        <SidebarBody />
        {/* <SidebarFooter /> */}
        <ShowIf show={isLocationOpen}>
          <Row className='sidebar-locations m-0 position-absolute w-100'>
            <Col>
              {locationsMap}
            </Col>
          </Row>
        </ShowIf>
      </Container>
    </Col>
  );
};

const mapStateToProps = (state) => {
  const { userInterface } = state;
  return {
    open: userInterface.isSidebarOpen
  };
};

export default connect(mapStateToProps)(Sidebar);
