export default function reducer(state = {
  authKey: localStorage.getItem('authKey') ? localStorage.getItem('authKey') : 'g31cr6dupg0qxvvIcGVm1UnIBXcHhVDB',
  isLogin: localStorage.getItem('authKey') ? true : false,
  currentRole: localStorage.getItem('currentRole') ? localStorage.getItem('currentRole') : null,
  activeLocation: localStorage.getItem('activeLocation') ? JSON.parse(localStorage.getItem('activeLocation')) : {},
  locations: localStorage.getItem('locations') ? JSON.parse(localStorage.getItem('locations')) : [],
  isActiveInventoryEdit: false
}, action) {
  switch (action.type) {
    case 'SET_USER_DATA':
      return {
        ...action.payload,
        activeLocation: state.activeLocation ? state.activeLocation : {},
        locations: action.payload.locations ? action.payload.locations : [],
        isLogin: true
      };
    case 'CLEAR_USER_DATA':
      return {
        authKey: 'g31cr6dupg0qxvvIcGVm1UnIBXcHhVDB',
        currentRole: null,
        isLogin: false,
        activeLocation: {},
        locations: []
      };
    case 'SET_USER_ACTIVE_LOCATION':
      return {
        ...state,
        activeLocation: action.payload ? action.payload : {}
      };
    case 'SET_ACTIVE_INVENTORY':
      return {
        ...state,
        activeLocation: {
          ...state.activeLocation,
          activeInventory: action.payload
        }
      };
    case 'SET_ACTIVE_INVENTORY_EDIT':
      return {
        ...state,
        isActiveInventoryEdit: action.payload
      };
    default:
      return state;
  }
};
