import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearUserData } from "../../reducers/user/userAction";
import { clearAllItemsData } from "../../reducers/items/itemsActions";
import { clearCartData } from "../../reducers/cart/cartActions";
import { localStorageConst, fullstory } from '../../config/constants';
import comparisonViewsApi from '../../api/comparisonViewsApi';
import useConnection from '../../SharedComponents/OfflineMode/useConnection'

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { getConnectionState } = useConnection.useMode();
  const inventoryList = parseInt(JSON.parse(localStorage.getItem(localStorageConst.keys.inventoryList)));
  const inventoryItemsToSync = parseInt(JSON.parse(localStorage.getItem(localStorageConst.keys.inventoryItemsToSync)));

  // Checking if user clicked Go offline
  // console.log({inventoryList,inventoryItemsToSync});
  if(!getConnectionState()){
    if(Number.isFinite(inventoryList)){
      comparisonViewsApi.takeInventory(inventoryList, 0)
      .then((res) => { /*console.log('res',res);*/ })
      .catch(error => { console.log(`Error updating inventoryList ${error}`); });
    }
  }

  localStorage.clear();
  dispatch(clearAllItemsData());
  dispatch(clearCartData());
  dispatch(clearUserData());

  localStorage.removeItem(localStorageConst.keys.cartItems);
  localStorage.removeItem(localStorageConst.keys.lastModifiedCart);
  localStorage.removeItem(localStorageConst.keys.savingOptions);
  localStorage.removeItem(localStorageConst.keys.view);
  localStorage.removeItem(localStorageConst.keys.listSelected);
  localStorage.removeItem(localStorageConst.keys.listItems);
  localStorage.removeItem(localStorageConst.keys.listItemsPage);
  localStorage.removeItem(localStorageConst.keys.inventoryItems);
  localStorage.removeItem(localStorageConst.keys.inventoryItemsPage);
  localStorage.removeItem(localStorageConst.keys.connection);
  localStorage.removeItem(fullstory.localstorage);
  

  history.push('/login');

};