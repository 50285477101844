import axios from 'axios';

const getPos = () => {
  //return axios.get('pos?key=L242p3IQ7RSZ3V1aoGqijb186hI3900o');
  return axios.get('pos');
};

const getPosDetail = (date, page) => {
  const params = {
    page
  };
  //params.key = 'L242p3IQ7RSZ3V1aoGqijb186hI3900o';
  return axios.get(`pos/detail/${date}`, {params});
};

export default{
  getPos,
  getPosDetail
};
