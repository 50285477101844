import React, { useEffect, useState } from 'react'
import './ShippingAndPayment.scss'
import { Row, Col, Input, Button, CustomInput, NavLink } from 'reactstrap';
import { FaCalendarDay, FaPen, FaClock, FaArrowLeft } from 'react-icons/fa';
import Select from 'react-select';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import cardspApi from '../../../../../api/cardspApi';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import ShippingAndPaymentCard from './ShippingAndPaymentCard';
import CardForm from '../../ManageCards/CardForm/CardForm';
import { saveHeaderIndexRevOrder } from '../../../../../reducers/header/headerActions';
import { setValuesForCheckout } from '../../../../../reducers/reviewOrder/reviewOrderActions';
import useReviewOrder from '../ReviewOrder/useReviewOrder';


const ShippingAndPayment = () => {

    const history = useHistory();
    const headerIndex = useSelector(({ header }) => header.index);
    const valuesCheckout = useSelector(({ reviewOrder }) => reviewOrder.values);
    const [listDeliveryDates, setListDeliveryDates] = useState([]);
    const [valueDeliveryDate, setValueDeliveryDate] = useState();
    const [valueDeliveryTime, setValueDeliveryTime] = useState();
    const [listCard, setListCard] = useState([]);
    const [showNewCard, setShowNewCard] = useState(false);
    const [cardToken, setCardToken] = useState(null);
    const [checkTerms, setCheckTerms] = useState(false);
    const [valueNote, setValueNote] = useState();
    const [showCard, setShowCard] = useState();
    const useValuesReviewOrder = useReviewOrder.useValuesReviewOrder();
    const [showDelTime, setShowDelTime] = useState(false);
    const [optionsDeliveryTime, setOptionsDeliveryTime] = useState([]);

    const dispatch = useDispatch();

    const optionsDeliveryDate = listDeliveryDates.map(res => {
        return {
            value: res,
            label: res
        }
    });


    const getCards = () => {
        cardspApi.getCards().then(({ data }) => {
            if (data.length > 0) {
                data = data.map(res => {
                    res['checked'] = false;
                    if (res.isDefault === 1)
                        res['checked'] = true;
                    return res;
                });
                setListCard(data)
            } else {
                setCheckTerms(true);
            };

            // setCards(data);
        }).catch(err => {
            console.error('getCardDetails get Error:', err);
        });

    }
    const deleteCard = (token) => {
        cardspApi.deleteCard(token).then(() => {
            const newCards = listCard.filter(card => card.token !== token);
            setListCard(newCards);
        }).catch(err => {
            console.log(err);
        });
    };


    useEffect(() => {
        if (headerIndex === 3) {
            history.push('/checkout-ordering/review-order')
        }
        //  else if (headerIndex == 5) {
        //     getCards();
        // }
    }, [headerIndex]);

    useEffect(() => {
        if (valuesCheckout) {
            setListDeliveryDates(valuesCheckout.dataDistributor.deliveryDates);
            const resValidate = useValuesReviewOrder.useShowCard(valuesCheckout);
            setShowCard(resValidate);

            if (resValidate) {
                getCards();
            } else {
                setCheckTerms(true);
            }
        }

    }, [valuesCheckout])


    const changeDeliveryDate = (props) => {
        setValueDeliveryDate(props.value);
        const resultVal = useValuesReviewOrder.useValShowDelTime(props.value, valuesCheckout.dataDistributor.isFoodbamDelivery);
        setShowDelTime(resultVal);
        const options = useValuesReviewOrder.useOptionsDeliveryTime(props.value, valuesCheckout.dataDistributor.id);
        setOptionsDeliveryTime(options)
    }

    const changeDeliveryTime = (props) => {
        setValueDeliveryTime(props.value);
    }

    const validateNext = () => {
        if (showDelTime) {
            if (valueDeliveryDate && valueDeliveryTime) {
                return false
            } else {
                return true
            }
        } else {
            if (valueDeliveryDate) {
                return false
            } else {
                return true
            }
        }
    }

    const goToNewCard = (token) => {
        setCardToken(token);
        setShowNewCard(true);
    }

    const onchangeCheck = (card) => {
        const newListCard = listCard.map(res => {
            res.checked = false;
            if (card.token === res.token) {
                card.checked = true;
            }
            return res;
        })
        setCheckTerms(false);
        setListCard(newListCard);
    }

    const checkWithTerms = () => {
        const newListCard = listCard.map(res => {
            res.checked = false;
            return res;
        })
        setCheckTerms(true);
        setListCard(newListCard);
    }


    const updateCard = (cardToken) => {
        goToNewCard(cardToken);
    }

    const getPayMethod = () => {
        const payParams = {
            type: '',
            card: ''
        }
        if (checkTerms) {
            payParams.type = 'terms'
        } else {
            payParams.type = 'credit_card'
            payParams.card = listCard.filter(res => res.checked == true)[0];
        }
        return payParams;
    }

    const goToNext = () => {

        const newValuesCheckout = {
            ...valuesCheckout,
            paymentMethod: getPayMethod(),
            deliveryDetail: {
                valueDeliveryDate,
                valueDeliveryTime,
                valueNote
            }
        }

        dispatch(setValuesForCheckout(newValuesCheckout));
        dispatch(saveHeaderIndexRevOrder(6));

    }

    const listCardItems = listCard.map(card => {
        return (
            <ShippingAndPaymentCard
                card={card}
                onchange={onchangeCheck}
                updateCard={updateCard}
                deleteCard={deleteCard}
                key={'listCard-' + card.token} />
        )
    });

    return (
        <div className='contentShippingAndPayment'>

            <ShowIf show={!showNewCard}>
                <Row>
                    <Col lg='4'>
                        <h1>Delivery Details </h1>
                        <Row>
                            <Col lg='1'>
                                <FaCalendarDay />
                            </Col>
                            <Col>
                                <Select
                                    onChange={changeDeliveryDate}
                                    className='fb-select'
                                    placeholder='Pick delivery date'
                                    options={optionsDeliveryDate} />
                            </Col>
                        </Row>
                        <ShowIf show={showDelTime}>
                            <Row>
                                <Col lg='1'>
                                    <FaClock />
                                </Col>
                                <Col>
                                    <Select
                                        onChange={changeDeliveryTime}
                                        className='fb-select'
                                        placeholder='Pick delivery time'
                                        options={optionsDeliveryTime} />
                                </Col>
                            </Row>
                        </ShowIf>
                        <Row>
                            <Col lg='1'>
                                <FaPen />
                            </Col>
                            <Col>
                                <Input type='textarea' value={valueNote} onChange={e => setValueNote(e.target.value)} placeholder='Add delivery note (optional)' />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg='1'></Col>
                            <Col>
                                <Button disabled={validateNext()} color='green' onClick={goToNext}>Next</Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col></Col>
                    <Col lg='6'>
                        <h1>Select a Payment Method</h1>
                        <ShowIf show={showCard}>
                            {listCardItems}
                        </ShowIf>
                        <CustomInput type='checkbox' checked={checkTerms} onChange={checkWithTerms} id="payTerms" label='Pay with terms' />
                        <ShowIf show={showCard}>
                            <NavLink href="#" onClick={() => goToNewCard(null)}>Add New Credit Card</NavLink>
                        </ShowIf>

                    </Col>
                </Row>
            </ShowIf>

            <ShowIf show={showNewCard}>
                <FaArrowLeft
                    size={20}
                    className='clickable'
                    style={{ 'marginLeft': 15 }}
                    onClick={() => setShowNewCard(false)} />
                <CardForm cardId={cardToken} />
            </ShowIf>

        </div>
    )
}


export default ShippingAndPayment;
