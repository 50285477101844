import React from 'react';
import { ModalBody, Col, Row } from 'reactstrap';
import { MobileView, BrowserView, isMobile } from 'react-device-detect';
import SearchFilterModalCheckbox from './SearchFilterModalCheckbox';

const SearchFiltersModalBody = (props) => {
  const {
    togglePurchasedOnly, purchasedOnly, allDistributors,
    toggleAllDistributors, distributorsMapDesktop,
    distributorsMapMobile, distributors, allPastOrders, pastOrders,
    toggleAllPastOrders, orderMapDesktop, orderMapMobile, isLoadingOrders,
    allShoppingLists, toggleAllShoppingLists, shoppingMapDesktop,
    shoppingMapMobile, shoppingLists } = props;

  return (
    <ModalBody className='p-0 pb-2'>
      <SearchFilterModalCheckbox rowClassName={'m-0 py-3' + (isMobile ? ' border-bottom' : '')} id='purchasedOnly' value={purchasedOnly} set={togglePurchasedOnly} label='Purchased Items Only' />
      <MobileView>
        <SearchFilterModalCheckbox rowClassName={'m-0 py-3' + (allDistributors || !distributors.length ? '' : ' border-bottom')} id='allDistributors' value={allDistributors} set={toggleAllDistributors} label='All Distributors' />
        <div className={allDistributors ? 'h-anim h-0' : 'h-anim'} >
          {distributorsMapMobile}
        </div>
        <SearchFilterModalCheckbox rowClassName={'m-0 py-3 border-top' + (allShoppingLists || !shoppingLists.length ? '' : ' border-bottom')} id='allShoppingLists' value={allShoppingLists} set={toggleAllShoppingLists} label='All Shopping Lists' />
        <div className={allShoppingLists ? 'h-anim h-0' : 'h-anim'} >
          {shoppingMapMobile}
        </div>
        <SearchFilterModalCheckbox rowClassName='m-0 py-3 border-bottom border-top' id='pastOrders' value={allPastOrders} set={toggleAllPastOrders} label='Past Orders' />
        <div className={allPastOrders ? 'h-anim' : 'h-anim '} >
          { isLoadingOrders ? <Row className='m-2 py-2'><Col xs='12'>Loading ...</Col></Row> : ( pastOrders.length > 0 ? orderMapMobile : '') }
        </div>
      </MobileView>
      <BrowserView>
        <Row className='m-0 py-3'>
          <SearchFilterModalCheckbox colClassName='mx-2 pb-3 border-bottom' col id='allDistributors' value={allDistributors} set={toggleAllDistributors} label='All Distributors' />
          <SearchFilterModalCheckbox colClassName='mx-2 pb-3 border-bottom' col id='allShoppingLists' value={allShoppingLists} set={toggleAllShoppingLists} label='All Shopping Lists' />
          <SearchFilterModalCheckbox colClassName='mx-2 pb-3 border-bottom' col id='pastOrders' value={allPastOrders} set={toggleAllPastOrders} label='Past Orders' />
        </Row>
        <Row className='m-0'>
          <Col className='mx-2 h-300px overflow-auto'>
            {distributorsMapDesktop}
          </Col>
          <Col className='mx-2 h-300px overflow-auto'>
            {shoppingMapDesktop}
          </Col>
          <Col className='mx-2 h-300px overflow-auto'>
            { isLoadingOrders ? <Row><Col xs='12'>Loading ...</Col></Row> : ( pastOrders.length > 0 ? orderMapDesktop : '') }
          </Col>
        </Row>
      </BrowserView>
    </ModalBody>
  );
};

export default SearchFiltersModalBody;