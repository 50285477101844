import React, { useState, useEffect } from 'react';
import ShowIf from '../ShowIf/ShowIf';
import * as $ from 'jquery';
import { useSelector, useDispatch } from 'react-redux';
import { saveAnimation } from '../../reducers/cart/cartActions';
import { cartAnimations } from '../../config/constants';
import './CartAnimation.scss';
import image from '../../assets/images/rebate-sticker.gif';
import audioSource from '../../assets/audios/rebate.wav';
import { Row, Col } from 'reactstrap';
import numeral from 'numeral';

const CartAnimation = () => {
  const dispatch = useDispatch();
  const animation = useSelector(({ cart }) => cart.animation);
  const [showAnimation, setShowAnimation] = useState(animation.type !== cartAnimations.type.none ? true : false);
  const [message, setMessage] = useState('');
  const [audio] = useState(new Audio(audioSource));

  const handleMessage = () => {
    let msg = '';
    switch (animation.type) {
      case cartAnimations.type.cartUpdated:
        msg = 'Shopping Cart Updated!';
        break;
      case cartAnimations.type.rebatesEarned:
        msg = 'Nice '+ numeral(animation.data).format('$0,0.00') +' in rebates earned!';
        break;
      case cartAnimations.type.savingItemAdded:
        msg = 'Saving Item Added to Cart!';
        break;
      case cartAnimations.type.specialRequest:
        msg = 'The Item was added to your order as special request';
        break;
      default:
        msg = '';
        break;
    }
    setMessage(msg);
  };

  const displayAnimation = () => {
    handleMessage();
    audio.play();
    setTimeout(() => {
      dispatch(saveAnimation({type: cartAnimations.type.none, data:null }));
    }, cartAnimations.delay);
  };

  useEffect(() => {
    setShowAnimation(animation.type !== cartAnimations.type.none ? true : false);
    if (animation.type !== cartAnimations.type.none) {
      displayAnimation();
    }

  }, [animation]);

  return (
    <ShowIf show={showAnimation}>
      <Row id='cartAnimation' className='box d-flex flex-direction-row align-items-center'>
        <Col xs={4} className='w-fc align-items-center justify-content-center'>
          <img src={image} alt='Cart Animation' className='img-responsive' />
        </Col>
        <Col xs={8} className='align-items-center justify-content-center'>
          <span className='p-1'>{message}</span>
        </Col>
      </Row>
    </ShowIf>

  )
};

export default CartAnimation;