import React, { useState, createContext } from "react";

export const InventoryContext = createContext();

export const InventoryContextProvider = props => {


  const {
    onHand, onHandUom, onHandPack,
    totalOnHand, totalOnHandAmount,
    collectiveOnHand, collectiveOnHandUom, groupTotalProps,
  } = props;


  const [OH, setOH] = useState(onHand);//unit
  const [OHUom, setOHUom] = useState(onHandUom);//case
  const [OHPack, setOHPack] = useState(onHandPack); //pack
  const [totalOH, setTotalOH] = useState(totalOnHand);
  const [totalCase, setTotalCase] = useState(0);
  const [totalPack, setTotalPack] = useState(0);
  const [totalUnit, setTotalUnit] = useState(0);

  const [totalOHAmount, setTotalOHAmount] = useState(totalOnHandAmount);
  const [collectiveOH, setCollectiveOH] = useState(collectiveOnHand);
  const [collectiveOHUom, setCollectiveOHUom] = useState(collectiveOnHandUom);


  const inventoryContext = {
    OH, setOH,
    OHUom, setOHUom,
    OHPack, setOHPack,
    totalOH, setTotalOH,
    totalOHAmount, setTotalOHAmount,
    collectiveOH, setCollectiveOH,
    collectiveOHUom, setCollectiveOHUom,
    totalCase, setTotalCase,
    totalPack, setTotalPack,
    totalUnit, setTotalUnit
  };

  return (
    <InventoryContext.Provider value={inventoryContext}>
      {props.children}
    </InventoryContext.Provider>
  );
};