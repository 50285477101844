import React, { useState, useEffect } from 'react';
import { Card, Container, Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import PosListRow from './PosListRow';
import usePosList from './usePosList';

const PosList = (props) => {
  const { items, itemsMap } = usePosList.useApi();
  const spinner = useSelector(({userInterface}) => userInterface.spinner);

  const header = (
    <Row>
      <Col xs={5} className='py-2 px-4 text-gray'>
        Date
      </Col>
      <Col xs={6} className='py-2 px-3 text-gray'>
        Total Sales
      </Col>
      <Col xs={1} className='text-gray'>
      </Col>
    </Row>
  );

  if (!spinner && items && items.length == 0) {
    return (
      <Container fluid={true} className='pos-list my-5 rounded'>
        {header}
        <Card className='pos-item'>
          <Row>
            <Col xs={12}>
              No results found.
            </Col>
          </Row>
        </Card>
      </Container>
    );
  }

  return (
    <Container fluid={true} className='pos-list my-5 rounded'>
      {header}
      {itemsMap}
    </Container>
  );
};

export default PosList;
