import React, { useState, useEffect } from 'react';
import { Row, Col, Card } from 'reactstrap';
import { IoIosArrowRoundUp, IoIosArrowRoundDown } from 'react-icons/io';
import numeral from 'numeral';
import moment from 'moment';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import noImage from '../../../../../assets/images/no-image.png';
import analyticsApi from '../../../../../api/analyticsApi';

const ItemsList = (props) => {
  const [sortBy, setSortBy] = useState('-tot_spent');
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);

  const getColumnclass = (sort) => {
    return sortBy.replace('-', '') == sort.replace('-', '') ? ' active ' : '';
  }

  const setSortByAndReset = (sort) => {
    //toggle sort column
    if (sortBy.replace('-', '') == sort.replace('-', '')) {
      if (sortBy.startsWith('-')) {
        sort = sortBy.replace('-', '');
      } else {
        sort = '-' + sortBy;
      }
    }
    setSortBy(sort);
    setItems([]);
  }

  const getItems = (page) => {
    if ((!props.distributor || !props.distributor.distributorId) && (!props.category || !props.category.categoryName)) {
      return;
    }

    let filter = {
      filter_type: props.filterType,
      section: 'purchase',
      limit: 10,
      page: page,
      sort: sortBy
    };

    if (props.distributor && props.distributor.distributorId) {
      filter = {...filter, distributor_id: props.distributor.distributorId};
    } else {
      if (props.category && props.category.categoryName) {
        filter = {...filter, category: props.category.categoryName};
      }
    }
    if (props.filterType == 'custom') {
      filter = {...filter, start_date: moment(props.startDate).format('YYYY-MM-DD'), end_date: moment(props.endDate).format('YYYY-MM-DD')};
    }
    setIsLoading(true);

    analyticsApi.getAnalyticsDetail(filter)
      .then(res => {
        if (page > 1) {
          setItems(items.concat(res.data));
        } else {
          setItems(res.data);
        }
        setPage(page);
        setLoadMore(res._meta.pageCount > res._meta.currentPage);
        setIsLoading(false);
      })
      .catch(error => console.log('analyticsApi getAnalyticsDetail Error:', error));

  };

  const scrollListener = () => {
    const threshold = 600;
    if (window.innerHeight + document.documentElement.scrollTop + threshold >= document.documentElement.offsetHeight
      && loadMore && !isLoading) {
        getItems(page+1);
    }
  };

  useEffect(() => {
    window.addEventListener('mousewheel', scrollListener);
    window.addEventListener('scroll', scrollListener);
    window.addEventListener('resize', scrollListener);

    return () => {
      window.removeEventListener('mousewheel', scrollListener);
      window.removeEventListener('scroll', scrollListener);
      window.removeEventListener('resize', scrollListener);
    };
  }, [page, loadMore, isLoading]);

  useEffect(() => {
    setItems([]);
    getItems(1);
  }, [props.distributor, props.category, sortBy]);

  return (
    <ShowIf show={!!props.distributor || !!props.category}>
      <Card className='mt-3 px-3 pt-3 pb-1'>
        <Row>
          <Col xs={6}>
            <div className='analytics-header'>{props.distributor && props.distributor.distributorName || props.category && props.category.categoryName || ''}</div>
          </Col>
        </Row>

        <Row className={'border-top' + (items.length == 0 ? ' mb-4 ' : '')}>
          <Col xs={1} className='analytics-grid-header py-3'>Image</Col>
          <Col xs={2} className={'analytics-grid-header py-3 clickable' + getColumnclass('name')} onClick={() => setSortByAndReset('name') }>
            Item Name <ShowIf show={sortBy=='name'}><IoIosArrowRoundUp size={18} /></ShowIf><ShowIf show={sortBy!='name'}><IoIosArrowRoundDown size={18} /></ShowIf>
          </Col>
          <Col xs={2} className={'analytics-grid-header py-3 clickable' + getColumnclass('distributor')} onClick={() => setSortByAndReset('distributor') }>
            Distributor <ShowIf show={sortBy=='distributor'}><IoIosArrowRoundUp size={18} /></ShowIf><ShowIf show={sortBy!='distributor'}><IoIosArrowRoundDown size={18} /></ShowIf>
          </Col>
          <Col xs={1} className='analytics-grid-header py-3'>Pack Size</Col>
          <Col xs={2} className={'analytics-grid-header py-3 clickable' + getColumnclass('total_quantity')} onClick={() => setSortByAndReset('total_quantity') }>
            Total Qty Purchased <ShowIf show={sortBy=='total_quantity'}><IoIosArrowRoundUp size={18} /></ShowIf><ShowIf show={sortBy!='total_quantity'}><IoIosArrowRoundDown size={18} /></ShowIf>
          </Col>
          <Col xs={2} className={'analytics-grid-header py-3 clickable' + getColumnclass('tot_spent')} onClick={() => setSortByAndReset('tot_spent') }>
            Total Amount Spent <ShowIf show={sortBy=='tot_spent'}><IoIosArrowRoundUp size={18} /></ShowIf><ShowIf show={sortBy!='tot_spent'}><IoIosArrowRoundDown size={18} /></ShowIf>
          </Col>
          <Col xs={1} className='analytics-grid-header py-3'>Avg Price</Col>
          <Col xs={1} className='analytics-grid-header py-3'>Best Price</Col>
        </Row>

        {
          items.map((item, index) => {
            return (
              <Row key={'purchased-item-'+index+item.name} className='border-top'>
                <Col xs={1} className='analytics-grid-detail my-3 d-flex align-items-center justify-content-center'><img className='no-image' src={item.image_url ? item.image_url : noImage} /></Col>
                <Col xs={2} className='analytics-grid-detail my-3'>{item.name}</Col>
                <Col xs={2} className='analytics-grid-detail my-3'>{item.distributor}</Col>
                <Col xs={1} className='analytics-grid-detail my-3'>{item.pack_size}</Col>
                <Col xs={2} className='analytics-grid-detail my-3'>{item.total_quantity} {item.unit_measure}</Col>
                <Col xs={2} className='analytics-grid-detail my-3'>{numeral(item.tot_spent).format('$0,0.00')}</Col>
                <Col xs={1} className='analytics-grid-detail my-3'>{item.average_price}</Col>
                <Col xs={1} className='analytics-grid-detail my-3'>{item.best_price}</Col>
              </Row>
            );
          })
        }

      </Card>
    </ShowIf>
  );
};

export default ItemsList;
