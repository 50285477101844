import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from "react-device-detect";
import { Col, Input } from 'reactstrap';
import useShoppingCartItem from './useShoppingCartItem';

import { itemCart, placeOrderViews } from '../../config/constants';
import MinusButton from '../MinusButton/MinusButton';
import PlusButton from '../PlusButton/PlusButton';
import { localStorageConst } from '../../config/constants';
import { useSelector } from 'react-redux';
import ShowIf from '../ShowIf/ShowIf';
import useOnHandItemInterface from '../OnHandItem/useOnHandItemInterface';

const ShoppingCartItemGroup = (props) => {
    const { item,
        useInitialLeft,
        useLastRight,
        useShoppingCartItemProps,
        changeItemGroup } = props;
        
    const { caseQuantity, setCaseQuantity } = useShoppingCartItemProps || useShoppingCartItem.useCartItemMerge(item);
    const [cartAmount, setCartAmount] = useState(0);
    const [cartAmountFirst, setCartAmountFirst] = useState(0);
    const groupEvent = useSelector(({ groupEvent }) => groupEvent);

    const [section, setSection] = useState('');
    // const { updateCartAmount } = useShoppingCartItem.useCartUpdate(setCartAmount, groupTotalProps);

    const view = localStorage.getItem(localStorageConst.keys.view);
    const { disabled } = useOnHandItemInterface.useInputConnection();
    const { valid } = useShoppingCartItem.useCartValidate();


    useEffect(() => {
        if (groupEvent.values.cartAmount) {
            if (item.itemDistributorId == groupEvent.values.itemDistributorId) {
                setCartAmount(groupEvent.values.cartAmount);
            }
        };
    }, [groupEvent])


    const cartAmountHandler = (e) => {
        if (!valid(e.target.value)) { return; }
        setCartAmount(e.target.value);
        changeItemGroup(item, cartAmountFirst, e.target.value);
    };

    const cartAmountClick = (newCartAmount) => {
        if (!valid(newCartAmount)) { return; }
        setCartAmount(newCartAmount);
        changeItemGroup(item, cartAmountFirst, newCartAmount);
    };

    const setButtons = () => {
        let newSection = '';
        const minusImg = <MinusButton onClick={() => { cartAmountClick(cartAmount - 1) }} isBlue isActive={(cartAmount >= itemCart.min)} disabled={disabled} />;
        const newMinusButton = (useInitialLeft === false && cartAmount < itemCart.min) ? '' : minusImg;

        const plusImg = <PlusButton onClick={() => { cartAmountClick(cartAmount + 1) }} isBlue isActive={(cartAmount >= itemCart.min && cartAmount <= itemCart.max)} disabled={disabled} />;
        const newPlusButton = (useLastRight === false && cartAmount > itemCart.max) ? '' : plusImg;

        newSection = <Fragment>


            <ShowIf show={cartAmount > 0}>
                {/* <Col style={{ marginLeft: -20, marginRight: 9 }} className={'w-fc pl-0 justify-content-end' + (isMobile ? ' pr-1' : '')}> */}
                <Col className={'w-fc pl-0 justify-content-end' + (isMobile ? ' pr-1' : '')}>
                    {newMinusButton}
                </Col>
                <Col className={'p-0 justify-content-center' + (view !== placeOrderViews.inventory ? ' w-no-squared-input' : (isMobile ? '' : ' w-squared-input'))}>
                    <Input type='text' pattern="[0-9]*" className={isMobile ? 'input p-0 text-center' : 'input'} value={cartAmount} onChange={cartAmountHandler} placeholder='0' disabled={disabled ? 'disabled' : ''} />
                </Col>
                <Col style={{ fontSize: 13 }} className={'col w-fc justify-content-left' + (isMobile ? ' px-1' : '')}>
                    {item.unitMeasure}
                </Col>
            </ShowIf>


            <Col className='w-fc p-0 justify-content-start'>
                {newPlusButton}
            </Col>
        </Fragment>;

        setSection(newSection);

    };

    useEffect(() => {
        setButtons();
    }, []);

    useEffect(() => {
        setCartAmount(caseQuantity);
        setCartAmountFirst(caseQuantity);
    }, [caseQuantity]);

    useEffect(() => {
        setButtons();
    }, [cartAmount, disabled]);

    return (
        <Fragment>
            {section}
        </Fragment>
    );
};

ShoppingCartItemGroup.propTypes = {
    useInitialLeft: PropTypes.bool,
    useLastRight: PropTypes.bool,
    showAll: PropTypes.bool,
    square: PropTypes.bool
};

ShoppingCartItemGroup.defaultProps = {
    useInitialLeft: true,
    useLastRight: true,
    square: true
};

export default ShoppingCartItemGroup;