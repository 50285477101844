import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { showModal } from '../../reducers/modals/modalsActions';
import ConnectionModal from "../../SharedComponents/Modals/ConnectionModal/ConnectionModal";
import { connection } from '../../config/constants';
import { localStorageConst } from '../../config/constants';
import { saveConnection } from '../../reducers/connection/connectionActions';
import useSyncCart from '../../SharedComponents/Sync/useCartSync';
import useItemsSync from '../../SharedComponents/Sync/useItemsSync';

const useStatus = () => {
  let [status, setStatus] = useState(window.navigator.onLine);

  useEffect(() => {
    function handleConnectionStatus() {
      setStatus(window.navigator.onLine);
    }

    window.addEventListener('online', handleConnectionStatus);
    window.addEventListener('offline', handleConnectionStatus);

    return () => {
      window.removeEventListener('online', handleConnectionStatus);
      window.removeEventListener('offline', handleConnectionStatus);
    };
  }, []);

  return { status };
}

const useActions = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const connectionChange = (newConnection, disablePopup = false, disableUpdate = false) => {
    if (disableUpdate) { return; }
    localStorage.setItem(localStorageConst.keys.connection, newConnection);
    dispatch(saveConnection(newConnection));

    if (disablePopup) { return; }
    const path = history.location.pathname;
    const msg = (newConnection) ? "Internet connection is back" :
      (path === '/login' || path === '/signup' || path === '/') ? "Sorry you are offline and won't be able to login/signup" :
        "You lost internet connectivity. Any updates to counts will not be saved. Please reconnect to the internet and click Go Offline to enable Offline mode.";
    const title = (newConnection) ? connection.mode.online : connection.mode.offline;
    setTimeout(() => {
      const props = { title: title, message: msg };
      dispatch(showModal(ConnectionModal, props));
    }, 100);

  };

  return { connectionChange };
}

const useSyncLocal = () => {

  const { syncCartToLocal } = useSyncCart.useOptions();
  const { syncInventoryToLocal } = useItemsSync.useOptions();

  const lists = useSelector(({ items }) => items.inventory.list);
  const groups = useSelector(({ groups }) => groups.groups);
  const listSelected = useSelector(({ items }) => items.inventory.listSelected);
  const sortBy = useSelector(({ items }) => items.inventory.sortBy);
  const search = useSelector(({ items }) => items.inventory.search);
  const onHandTotal = useSelector(({ items }) => items.inventory.onHandTotal);

  const syncToLocal = () => {
    syncInventoryToLocal(lists, groups, listSelected, sortBy, search, onHandTotal);
    syncCartToLocal();
  };

  return { syncToLocal };
};

const useSyncServer = () => {

  const listSelected = useSelector(({ items }) => items.inventory.listSelected);
  const { syncCartToServer } = useSyncCart.useOptions();
  const { syncInventoryItemsToServer, checkItemsToSync } = useItemsSync.useOptions();

  const syncToServer = () => {
    syncInventoryItemsToServer(listSelected);
    syncCartToServer();
  };

  return { syncToServer, checkItemsToSync };
};

const useMode = () => {
  const {status} = useStatus();
  const connection = useSelector(({ connection }) => connection.online);
  // const connectionLocal = JSON.parse(localStorage.getItem(localStorageConst.keys.connection));

  const lists = useSelector(({ items }) => items.inventory.list);
  const listSelected = useSelector(({ items }) => items.inventory.listSelected);

  const getConnectionState = () => {
    const inventorySync = JSON.parse(localStorage.getItem(localStorageConst.keys.inventorySync));
    const cnx = !!inventorySync ? !inventorySync : true;

    return cnx;
  };

  const inputConnectionState = () => {
    
    const inventorySync = JSON.parse(localStorage.getItem(localStorageConst.keys.inventorySync));
    const inventoryItemsToSync = JSON.parse(localStorage.getItem(localStorageConst.keys.inventoryItemsToSync));
    const selected = !!lists ? lists.filter(l => l.id === listSelected.id)[0] : null;

    let inputDisable = false;

    if (!!selected) {
      if(!!inventorySync){
        if(connection && !inventorySync && selected.onInventory){
          inputDisable=true;
        }
        if(!connection && !inventorySync && selected.onInventory){
          inputDisable=true;
        }
      }else{
        if(status && connection && selected.onInventory){
          inputDisable=true;
        }
      }
    }

    return inputDisable;
  };

  return { getConnectionState, inputConnectionState };

};

export default { useStatus, useActions, useSyncLocal, useSyncServer, useMode };
