import comparisonViewsApi from '../../../api/comparisonViewsApi';

const useApi = (setLists) => {
  const getLists = () => {    
    comparisonViewsApi.get().then(({data}) => {
      setLists(data);
    });
  };

  return {
    getLists
  };
};


export default {
  useApi
};