import React, { useState } from 'react'
import { Row, Col, Button, Card } from 'reactstrap'
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import noImage from '../../../../../assets/images/no-image.png';
import specialRequestImg from '../../../../../assets/images/special-request.png';
import { isMobile } from "react-device-detect";
import FinalReviewCard from './FinalReviewCard';
import IconBadge from '../../../../../SharedComponents/IconBadge/IconBadge';
import { FaExclamation } from 'react-icons/fa';
import { colors } from '../../../../../config/constants';


const FinalReviewRow = (props) => {
    const { submit } = props;
    const { cartList, dataDistributor, priceTotalCard, paymentMethod, deliveryDetail } = props.values;
    const text = `This item was added as a special request. It is not guaranteed that the distributor will be able to deliver this item. Please contact your distributor directly to confirm availability`


    const getClassNameRow = (isSpecialItem) => {
        if (isSpecialItem) {
            return 'contentDetails isEspecial'
        } else {
            return 'contentDetails '
        }
    }

    const contentList = cartList.map((cartItem, index) => {
        let newImage = cartItem.imageUrl ? cartItem.imageUrl : (cartItem.brandImageUrl ? cartItem.brandImageUrl : null);

        return (
            cartItem.caseQuantity ?
                <Row key={'keyROrow-' + index}>
                    <Col>
                        <Card style={{ border: cartItem.isSpecialItem ? '1px solid #6ed79a' : '' }} >
                            <ShowIf show={cartItem.isSpecialItem}>
                                <img className='specialImg' src={specialRequestImg}></img>
                            </ShowIf>
                            <Row className={getClassNameRow(cartItem.isSpecialItem)}>
                                <Col xs='2' className='content-align-center' >
                                    <p style={{
                                        'marginLeft': 40,
                                        'color': '#143357',
                                        'fontSize': 13,
                                        'fontWeight': 600
                                    }}>{cartItem.caseQuantity} {cartItem.unitMeasure}</p>
                                </Col>
                                <Col xs='2' className='clickable content-align-center'>
                                    <img src={newImage ? newImage : noImage}></img>
                                </Col>
                                <Col xs='5' style={{ 'marginLeft': 35 }} className='details clickable'>
                                    <Row>
                                        <h3>{cartItem.isSpecialItem ? cartItem.description : cartItem.name}</h3>
                                    </Row>
                                    <ShowIf show={cartItem.isSpecialItem}>
                                        <Row>
                                            <Col>
                                                <IconBadge idPop='RORPop1' text={text} FaIcon={FaExclamation} size={8} color={colors.white} />
                                            </Col>
                                        </Row>
                                    </ShowIf>
                                    <ShowIf show={!cartItem.isSpecialItem}>
                                        <Row>
                                            <p>{cartItem.din}</p>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <p>{cartItem.brand}</p>
                                            </Col>
                                            <Col>
                                                <p>{cartItem.distributor}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <h3>{cartItem.packSize}</h3>
                                        </Row>
                                    </ShowIf>
                                </Col>
                                <div className='details floatRight' style={{ 'top': 65 }}>
                                    <h3>{cartItem.priceTotalRow}</h3>
                                </div>
                                <ShowIf show={cartItem.isPurchased}>
                                    <div className='headerIcon'>
                                        <span className='iconText left'>LAST PURCHASED</span>
                                        <span className='textDate'>{cartItem.lastPurchasedQty} {cartItem.unitMeasure} ON {cartItem.lastPurchasedDate}</span>
                                    </div>
                                </ShowIf>
                            </Row>
                        </Card >
                    </Col>
                </Row> : ''
        )
    });


    return (

        <div>
            <Row>
                <Col xs="8">
                    {contentList}
                </Col>
                <Col>
                    <FinalReviewCard
                        nameDistributor={dataDistributor.nickname}
                        priceTotalCard={priceTotalCard.priceFormat}
                        paymentMethod={paymentMethod}
                        deliveryDetail={deliveryDetail}
                        submit={submit} />
                </Col>
            </Row>

        </div>


    )
}

export default FinalReviewRow;