import React from 'react';
import { Container, Row, Col} from 'reactstrap';
import { isMobile } from 'react-device-detect';
import Header from '../../../../../SharedComponents/Header/Header';
import useOrderInventory from '../OrderInventory/useOrderInventory';



const OrderInventory = (props) => {
    const {orderInventoryMap,  selectedOrder} = useOrderInventory.useApi(props);
    const SubHeaderOrderView = (
        <Row className={'header h-inherit align-items-center py-1 m-0 text-gray'} style={{fontSize:'14px'}}>
            <Col xs={2}>
                Image
            </Col>
            <Col xs={3}>
                Item Name
            </Col>
            <Col xs={1}>
                Par Level
            </Col>
            <Col xs={1}>
                On Hand
            </Col>
            <Col xs={1} className='p-0'>
                On Hand Value
            </Col>
            <Col xs={2}>
                Last Update Date
            </Col>
            <Col xs={2}>
            </Col>
        </Row>
    )

    if(isMobile) {
       return (
            <Container fluid={true}>
            <Header showMenu={false} showLogo={false} showBack={true} title={selectedOrder ? `Inventory Summary ${selectedOrder.id + ' ' + selectedOrder.createTimeDisplay}` : 'Inventory Summary'} showCart={false} />
                {orderInventoryMap}
            </Container>
       )
    }

    return (
        <Container fluid={true}>
            <Header showMenu={false} showLogo={false} showBack={true} title={selectedOrder ? `Inventory Summary ${selectedOrder.id + ' ' + selectedOrder.createTimeDisplay}` : 'Inventory Summary'} showCart={false} >
                {SubHeaderOrderView}
            </Header>
            <Row className='p-2'>
            </Row>
            {orderInventoryMap}
        </Container>
    )
}

export default OrderInventory;