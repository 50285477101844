import React,{useState, useEffect} from 'react';
import useModal from '../useModal';
import { Modal, ModalBody, ModalHeader, Row, Col, Input, Button } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { modalMenuClassName } from '../../../config/constants';
import { isMobile } from 'react-device-detect';
import { insertInventoryList } from '../../../reducers/items/itemsActions';
import ShowIf from '../../ShowIf/ShowIf';
import comparisonViewsApi from '../../../api/comparisonViewsApi';
import InventoryListItem from './InventoryListItem';
import useInput from '../../SharedEffects/useInput';
import * as $ from 'jquery';
import useInventoryListInterface from './useInventoryListInterface';
import { showModal } from '../../../reducers/modals/modalsActions';
import OneInputModal from '../../../SharedComponents/Modals/OneInputModal/OneInputModal';
import AlertModal from '../../../SharedComponents/Modals/AlertModal/AlertModal';

const InventoryListModal = (props) => {
  const dispatch = useDispatch();
  const { id, isMove, group } = props;
  const modalProps = useModal(id);

  const selected = useSelector(({items}) => items.inventory.listSelected);
  const [lists, setLists] = useState([]);
  const [map, setMap] = useState('');
  const { getLists } = useInventoryListInterface.useApi(setLists);

  const baseProps = {isMove, group, getLists, modalProps};
  const [ isShowInput, setIsShowInput ] = useState(false);
  const newListInput = useInput.useText('');

  const createList = () =>{
    if(!isMobile){
      setIsShowInput(true);
    }else{
      const editProps = {
        title: 'CREATE NEW LIST',
        initValue: '',
        menu: isMobile,
        fullMenu: false,
        onSave: saveList
      };
      dispatch(showModal(OneInputModal, editProps));
      modalProps.toggle();
    }
  };

  const onEnterKey = ({key}) => {
    if (key === 'Enter') saveList(newListInput.value);
  };
  const saveList = (name) =>{
    comparisonViewsApi.createList(name).then(({data}) => {
      dispatch(insertInventoryList(data));
      getLists();
      if(!isMobile){
        setIsShowInput(false);
      }else{
        goBack();
      }
    }).catch(err => {
      console.error(err);
      if (err.code === 400) {
        dispatch(showModal(AlertModal, {message: err.data.message, onOk: () => {} }));
      }
    });

  };

  const goBack = () => {
    dispatch(showModal(InventoryListModal));
  };

  const buildMap = () =>{
    const nMap = lists.map(l => <InventoryListItem key={`invlist_${l.id}`} list={l} selected={l.id === selected.id} baseProps={baseProps}/>);
    setMap(nMap);
  };

  useEffect(() => {
    buildMap();
  }, [lists]);

  useEffect(() => {
    getLists();
  }, []);

  useEffect(() => {
    if (isShowInput) {
      $('#new_list_input').focus();
    }
  }, [isShowInput]);

  return (
    <Modal {...modalProps} modalClassName={modalMenuClassName}>
      <ModalHeader toggle={ !isMobile ? modalProps.toggle : null} className='bg-white border-bottom-0'>{isMove ? 'MOVE TO INVENTORY LIST' : 'MANAGE INVENTORY LISTS'}</ModalHeader>
      <ModalBody className='pt-0'>
        <ShowIf show={!isMove}>
          <ShowIf show={!isMobile}>
            <Row className='py-3 clickable m-0 align-items-center'>
              <Col xs='1' className='pr-0 text-right'></Col>
              <Col xs='8' className='pr-0 text-left text-gray' onClick={() => { createList() }}>
                <ShowIf show={!isShowInput}>
                  Create New List
                </ShowIf>
                <ShowIf show={isShowInput}>
                  <Input id='new_list_input' onChange={newListInput.set} value={newListInput.value} onKeyDown={onEnterKey} />
                </ShowIf>
              </Col>
              <Col xs='2' className='pr-0 text-left'>
                <ShowIf show={isShowInput}>
                  <Button onClick={() => saveList(newListInput.value)} color='green'>Create</Button>
                </ShowIf>
              </Col>
            </Row>
          </ShowIf>
          <ShowIf show={isMobile}>
            <Row className='item'>
              <Col className='p-0 text-left text-gray' onClick={() => { createList() }}>Create New List </Col>
            </Row>
          </ShowIf>
        </ShowIf>
        {map}
      </ModalBody>
    </Modal>
  );
};

export default InventoryListModal;
