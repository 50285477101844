import React, {useEffect,useState,Fragment} from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Container, Row, Col, Card, Button } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import { MdCloudDownload, MdCloudUpload } from 'react-icons/md';
import { colors } from "../../../../../config/constants";
import useOrderGuideDetail from './useOrderGuideDetail';
import Header from '../../../../../SharedComponents/Header/Header';
import InfiniteScroll from 'react-infinite-scroller';
import { useParams } from "react-router-dom";
import distributorApi from '../../../../../api/distributorsApi';
import IconBadgeSolid from '../../../../../SharedComponents/IconBadge/IconBadgeSolid';
import { FaInfo } from 'react-icons/fa';
import { color } from 'highcharts';
import { useSelector } from 'react-redux';

const OrderGuideDetail = (props) => {
    const [distributor, setDistributor] = useState([]);
    const merchant = useSelector(({ user }) => user.activeLocation);
    const params = useParams();
    const id = parseInt(params.id);
    const ogId = parseInt(!params.ogId ? null : params.ogId);
    
    const {orderGuideDetailMap, downloadOG, showUploadModal, hasMore, isLoading, getOrderGuideDetail, orderGuideDetailList } = useOrderGuideDetail.useApi({ogId, distributor, merchant});
    const leftButtonUpload = isMobile ? null :  { icon: [ 
      <Button size='md' color={'green'} onClick={showUploadModal} > <MdCloudUpload size={20} color={colors.white} /> &nbsp; Upload New Order Guide </Button>
    ]}
    const rightButtonDownload = isMobile ? null :  { icon: [ 
        <Button size='lg' color={'green'} onClick={downloadOG} > <MdCloudDownload size={20} color={colors.white} /> &nbsp; Download </Button>
    ]}

    const SubHeaderOrderGuideDetail= (
        <Row className='header h-inherit py-3 align-items-center m-0 text-gray'>
            <Col xs={1} className='pl-4'> Item Code </Col>
            <Col xs={3} className='pl-4'> Item Description </Col>
            <Col xs={1} className='px-0'> <span>Unit of Measure</span> 
                <IconBadgeSolid Icon={FaInfo} colorIcon='white' color={colors.lightBlueText} elemId='uom'>
                  <Row className='p-2'> <Col>CS or EA</Col> </Row>
                </IconBadgeSolid>
            </Col>
            <Col xs={2}> Unit of Measure Price </Col>
            <Col xs={1}> Packsize </Col>
            <Col xs={1}> Unit Price </Col>
            <Col xs={1}> Catch Weight </Col>
            <Col xs={2}> Brand </Col>
        </Row>
    )

    useEffect(() => {
      distributorApi.getById(id).then((res) => {
        setDistributor(res.data);
      }).catch(error => {
      });
    }, []);

    return (
        <Container fluid={true} className='pb-3'>
            <Header showMenu={false} showLogo={false} showBack={true} title={distributor ? distributor.nickname : ''} showCart={false} leftButton={leftButtonUpload}  rightButton={rightButtonDownload} >
                { !isMobile ? SubHeaderOrderGuideDetail : null}
            </Header>
                <Container fluid={true} className={isMobile ? 'px-0' : 'mt-2' } >
                    <InfiniteScroll
                        initialLoad = {false}
                        pageStart={1}
                        loadMore={getOrderGuideDetail}
                        hasMore={hasMore && !isLoading}
                        useWindow={true}>
                        {orderGuideDetailMap}
                    </InfiniteScroll>
                </Container>
                { orderGuideDetailList.length === 0 && !isLoading &&
                    <Card className='mt-2'>
                        <Col className='text-center p-3' xs={12}>
                            No Items Found.
                        </Col>
                    </Card>
                }
        </Container>
    )

}

export default OrderGuideDetail;