import React, { useState, useEffect } from 'react';
import { Row, Col, Card } from 'reactstrap';
import numeral from 'numeral';
import moment from 'moment';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import receivedIcon from '../../../../../assets/images/order/check.png';
import cancelledIcon from '../../../../../assets/images/order/cancelled.png';
import onTheWayIcon from '../../../../../assets/images/order/car-blue.png';

import analyticsApi from '../../../../../api/analyticsApi';

const OrdersList = (props) => {
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);

  const orderIconHandler = (order) => {
    const status = order && order.status || '';
    switch (status) {
      case 'received':
        return receivedIcon;
      case 'cancelled':
        return cancelledIcon;
      default:
        return onTheWayIcon;
    }
  };

  const orderStatusHandler = (order) => {
    const status = order && order.status || '';
    switch (status) {
      case 'received':
        return 'Received';
      case 'cancelled':
        return 'Order Cancelled';
      default:
        return 'On The Way Icon';
    }
  };

  const getOrders = (page) => {
    if (!props.distributor || !props.distributor.distributorId) {
      return;
    }

    let filter = {
      filter_type: props.filterType,
      distributor_id: props.distributor && props.distributor.distributorId,
      section: 'order',
      limit: 10,
      page: page
    };
    if (props.filterType == 'custom') {
      filter = {...filter, start_date: moment(props.startDate).format('YYYY-MM-DD'), end_date: moment(props.endDate).format('YYYY-MM-DD')};
    }
    setIsLoading(true);

    analyticsApi.getAnalyticsDetail(filter)
      .then(res => {
        if (page > 1) {
          setOrders(orders.concat(res.data));
        } else {
          setOrders(res.data);
        }
        setPage(page);
        setLoadMore(res._meta.pageCount > res._meta.currentPage);
        setIsLoading(false);
      })
      .catch(error => console.log('analyticsApi getAnalyticsDetail Error:', error));

  };

  const scrollListener = () => {
    const threshold = 600;
    if (window.innerHeight + document.documentElement.scrollTop + threshold >= document.documentElement.offsetHeight
      && loadMore && !isLoading) {
        getOrders(page+1);
    }
  };

  useEffect(() => {
    window.addEventListener('mousewheel', scrollListener);
    window.addEventListener('scroll', scrollListener);
    window.addEventListener('resize', scrollListener);

    return () => {
      window.removeEventListener('mousewheel', scrollListener);
      window.removeEventListener('scroll', scrollListener);
      window.removeEventListener('resize', scrollListener);
    };
  }, [page, loadMore, isLoading]);

  useEffect(() => {
    setOrders([]);
    getOrders(1);
  }, [props.distributor]);

  return (
    <ShowIf show={!!props.distributor}>
      <Card className='mt-3 px-3 pt-3 pb-1'>
        <Row>
          <Col xs={6}>
            <div className='analytics-header'>{props.distributor && props.distributor.distributorName || ''}</div>
          </Col>
        </Row>

        <Row className='border-top'>
          <Col xs={2} className='analytics-grid-header my-3'>Order No</Col>
          <Col xs={2} className='analytics-grid-header my-3'>Order Date</Col>
          <Col xs={2} className='analytics-grid-header my-3'>Delivery Date</Col>
          <Col xs={2} className='analytics-grid-header my-3'>Line Items</Col>
          <Col xs={2} className='analytics-grid-header my-3'>Total Amount</Col>
          <Col xs={2} className='analytics-grid-header my-3'>Status</Col>
        </Row>

        {
          orders.map((order, index) => {
            return (
              <Row key={'order-'+order.order_id} className='border-top'>
                <Col xs={2} className='analytics-grid-detail my-3'>{order.order_id}</Col>
                <Col xs={2} className='analytics-grid-detail my-3'>{moment(order.order_date).format('MM/DD')}</Col>
                <Col xs={2} className='analytics-grid-detail my-3'>{moment(order.delivery_date).format('MM/DD')}</Col>
                <Col xs={2} className='analytics-grid-detail my-3'>{order.line_items}</Col>
                <Col xs={2} className='analytics-grid-detail my-3'>{numeral(order.tot_amount).format('$0,0.00')}</Col>
                <Col xs={2} className='analytics-grid-detail my-3'>
                  <span><img style={{paddingRight: '10px'}} src={orderIconHandler(order)} /> {orderStatusHandler(order)}</span>
                </Col>
              </Row>
            );
          })
        }

      </Card>
    </ShowIf>
  );
};

export default OrdersList;
