import React, { useState } from 'react';
import { Row, Col, Input } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import ShowIf from '../../../../../../SharedComponents/ShowIf/ShowIf';
import useGroupTitleNew from './useGroupTitleNew';

const GroupTitleFirst = (props) => {
  const { id, title, type } = props;
  const {
    nameInputProps, showInput, onEnterKeyExtreme,
    isShowInput, hideInput
  } = useGroupTitleNew.useNewGroup(props, type);

  if (isMobile) {
    return (
      <Row id={id} className='align-items-center m-2' style={{
        border: '2px solid #9bb1c1',
        borderStyle: 'dashed',
        borderRadius: 4
      }}>
        <ShowIf show={!isShowInput}>
          <Col tabIndex={0} id={id + '_title'} className='border-bottom py-3 text-center text-label-lg text-uppercase dragable font-weight-bold' onFocus={showInput}>
            {title}
          </Col>
        </ShowIf>
        <ShowIf show={isShowInput}>
          <Col className='border-bottom py-3 text-center'>
            <Input onKeyDown={onEnterKeyExtreme} id={id + '_input'} {...nameInputProps} placeholder='New Generic Group Name' onBlur={hideInput} />
          </Col>
        </ShowIf>
      </Row>
    );
  }
  return (
    <Row id={id} className='align-items-center mt-2' style={{
      border: '3px solid #9bb1c1',
      borderStyle: 'dashed'
    }}>
      {/* <Col className='border-top' /> */}
      <ShowIf show={!isShowInput}>
        <Col tabIndex={0} id={id + '_title'} className='text-label-lg text-center text-uppercase py-4 clickable text-nowrap font-weight-bold' onFocus={showInput}>
          {title}
        </Col>
      </ShowIf>
      <ShowIf show={isShowInput}>
        <Col className='text-center py-4 text-nowrap'>
          <Input onKeyDown={onEnterKeyExtreme} id={id + '_input'} {...nameInputProps} placeholder='New Generic Group Name' onBlur={hideInput} />
        </Col>
      </ShowIf>
      {/* <Col className='border-top' /> */}
    </Row>
  );
};

export default GroupTitleFirst;