import axios from 'axios';

const get = () => {
  return axios.get('shopping-lists');
};

const getListById = (id) => {
  return axios.get(`shopping-lists/${id}`);
};

const getListItems = (id, page = 1) => {
  const params = {
    page
  };
  return axios.get(`shopping-lists/${id}/items`, {params});
};

const getAvailableItems = (filterOptions, page = 1) => {
  const params = {
    filterOptions: JSON.stringify(filterOptions),
    page
  };
  return axios.get('items', {params});
};

const addList = (list) => {
  return axios.post('shopping-lists', list);
};

const updateList = (id, list) => {
  return axios.patch(`shopping-lists/${id}`, list);
};

const deleteList = (id) => {
  return axios.delete(`shopping-lists/${id}`);
};

const addListItem = (listId, itemDistributorId) => {
  return axios.post(`shopping-lists/${listId}/add-one`, {itemDistributorId});
};

const deleteListItem = (listId, itemDistributorId) => {
  return axios.delete(`shopping-lists/${listId}/${itemDistributorId}`);
};

export default {
  get,
  getListById,
  getListItems,
  getAvailableItems,
  addList,
  updateList,
  deleteList,
  addListItem,
  deleteListItem
};
