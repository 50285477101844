import React from 'react';
import bookmarkImg from '../../assets/images/bookmark-red.png';
import Bookmark from './Bookmark';

const OrderGuideBookmark = ({elemId}) => {
  return (
    <Bookmark img={bookmarkImg} label='Order Guide' elemId={`${elemId}_og`} />
  );
};

export default OrderGuideBookmark