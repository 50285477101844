import React, { useState, useEffect } from "react"
import recipeApi from "../../../../../api/recipeApi";
import { useParams, useHistory } from "react-router-dom";
import useInput from "../../../../../SharedComponents/SharedEffects/useInput";
import { useDispatch, useSelector } from "react-redux";
import { showModal } from "../../../../../reducers/modals/modalsActions";
import AlertModal from "../../../../../SharedComponents/Modals/AlertModal/AlertModal";
import RecipeIngredientsItemRow from "./Subcomponents/RecipeIngredientsItemRow";
import noimage from '../../../../../assets/images/no-image.png';
import subimage from '../../../../../assets/images/subrecipe-image.png';
import { fraction, number } from 'mathjs';
import validate from 'validate.js';
import { isMobile } from "react-device-detect";
import RecipeInfoModal from '../Modals/RecipeInfoModal/RecipeInfoModal';
import AddIngredientModal from "../Modals/AddIngredientModal/AddIngredientModal";
import OptionsModal from "../../../../../SharedComponents/Modals/OptionsModal/OptionsModal";
import AddFoodbamItemModal from "../../../../../SharedComponents/Modals/AddFoodbamItemModal/AddFoodbamItemModal";
import ItemDetailsFoodbam from "../Modals/ItemDetails/ItemDetailsFoodbam/ItemDetailsFoodbam";
import ItemDetailsSubrecipe from "../Modals/ItemDetails/ItemDetailsSubrecipe/ItemDetailsSubrecipe";
import ItemDetailsInventory from "../Modals/ItemDetails/ItemDetailsInventory/ItemDetailsInventory";
import AddSubrecipe from "../../../../../SharedComponents/Modals/AddSubrecipe/AddSubrecipe";
import AddInventoryItem from "../../../../../SharedComponents/Modals/AddInventoryItem/AddInventoryItem";
import { setIngredientsList, setRecipeInfo, setRecipeList } from "../../../../../reducers/recipeCosting/recipeCostingActions";
import numeral from 'numeral';
import moment from 'moment';

const useApi = () => {
  const ingredientsList = useSelector(({recipeCosting}) => recipeCosting.ingredientsList);
  const recipeInfo = useSelector(({recipeCosting}) => recipeCosting.recipeInfo);
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const id = parseInt(params.recipeId);

  const getRecipeData = () => {
    recipeApi.getRecipe(id, 'ingredients,comparisonView,menu').then(({data}) => {
      const recipeData = {
        id: data.id,
        recipeName: data.name,
        menuCategory: {
          label: data.menu.name,
          value: data.menu.id
        },
        menuPrice: data.price,
        isSubrecipe: data.isSubRecipe,
        isSubrecipeUsed: data.isSubRecipeUsed,
        recipeYield: data.subRecipeYield,
        recipeYieldUom: data.subRecipeYieldUom,
        description: data.description,
        procedure: data.procedure,
        totalCost: data.totalCost,
        marginGrossProfit: data.marginGrossProfit,
        foodCostPercent: data.foodCostPercent,
        hasInactiveItems: data.hasInactiveItems
      }
      dispatch(setRecipeInfo(recipeData));
      const ingredients = data.ingredients.map(ing => {
        if (ing.ingredientType !== 'generic') return ing;
        return {
          ...ing,
          item: {
            ...ing.item,
            items: ing.item.items.sort((a, b) =>{ return a.isPunchedOut - b.isPunchedOut || b.isSubRecipe - a.isSubRecipe || a.unitPrice - b.unitPrice || a.price - b.price })
          }
        }
      });
      dispatch(setIngredientsList(ingredients));
    }).catch(err=> {
      if (err.code === 404) {
        dispatch(showModal(AlertModal, {
          message: 'Recipe Not Found',
          onOk: () => history.push('/recipe-costing')
        }));
      }
      console.error('RecipeApi getMenuCategory Error:', err);
    });
  }

  useEffect(() => {
    if (params.recipeId) {
      getRecipeData();
    }
  }, [params.recipeId]);

  return { recipeInfo, ingredientsList, getRecipeData };
};

const useInfoForms = () => {
  const dispatch = useDispatch();
  const [errors] = useState({});
  const ri = useSelector(({recipeCosting}) => recipeCosting.recipeInfo);
  const { isSubRecipeUsed } = ri;

  const set = (key, value) => {
    dispatch(setRecipeInfo({[key]: value}));
  };

  const recipeName = {...useInput.useText('', {value: ri.recipeName, set: (v) => set('recipeName', v), isInvalidOnBlur: {presence: {allowEmpty: false}, length: {minimum: 3}}})};
  const menuPrice = useInput.useText('', {isNumber: true, value: ri.menuPrice, set: (v) => set('menuPrice', v), isInvalidOnBlur: {presence: {allowEmpty: false}, numericality: { greaterThan: 0 }}});
  const isSubrecipe = {
    value: ri.isSubrecipe,
    toggle: () => {dispatch(setRecipeInfo({isSubrecipe: !ri.isSubrecipe}))},
    set: (v) => {dispatch(setRecipeInfo({isSubrecipe: v}))}
  };
  const recipeYield = useInput.useText('', {value: ri.recipeYield, set: (v) => set('recipeYield', v), validate: {fraction: {strict: false}}, isInvalidOnBlur: {presence: {allowEmpty: false}, fraction: {strict: true}}});
  const recipeYieldUom = useInput.useText('', {value: ri.recipeYieldUom, set: (v) => set('recipeYieldUom', v), isInvalidOnBlur: {presence: {allowEmpty: false}, format: { pattern: "[a-z]+", flags: "i" },}});
  const description = useInput.useText('', {value: ri.description, set: (v) => set('description', v)});
  const procedure = useInput.useText('', {value: ri.procedure, set: (v) => set('procedure', v)});

  const setMenuCategory = (m) => {
    dispatch(setRecipeInfo({menuCategory: m}));
  };

  useEffect(() => {
    if (!ri.isSubrecipe && isSubRecipeUsed) {
      isSubrecipe.toggle();
      dispatch(showModal(AlertModal, {message: 'You can not remove the subrecipe option because this recipe is being used as a subrecipe for other recipes.'}));
    }
  }, [ri.isSubrecipe]);

  const isRecipeInfoValid = () => {
    const form = {
      menuPrice: ri.menuPrice,
      recipeName: ri.recipeName,
      recipeYield: ri.recipeYield,
      recipeYieldUom: ri.recipeYieldUom
    };
    const allowEmpty = {
      allowEmpty: false
    };
    const constrains = {
      menuPrice: {
        numericality: !ri.isSubrecipe ? { greaterThan: 0 } : false,
        presence: !ri.isSubrecipe ? allowEmpty : false,
      },
      recipeName: {
        presence: allowEmpty,
        length: {
          minimum: 3,
        },
      },
      recipeYield: {
        presence: ri.isSubrecipe ? allowEmpty : false,
        fraction: ri.isSubrecipe ? { strict: true } : false,
      },
      recipeYieldUom: {
        presence: ri.isSubrecipe ? allowEmpty : false,
      },
    };
    if (ri.isSubrecipe) {
      constrains.recipeYieldUom.format = { pattern: "[a-z]+", flags: "i" };
    }
    const result = validate(form, constrains);
    if (result) {
      recipeName.onBlur();
      if (!ri.isSubrecipe) {
        menuPrice.onBlur();
      } else {
        recipeYield.onBlur();
        recipeYieldUom.onBlur();
      }
    }
    return !result ? true : false;
  };

  return {
    recipeName,
    menuCategory: { value: ri.menuCategory, set: setMenuCategory },
    menuPrice, isSubrecipe, recipeYield, recipeYieldUom,
    description, procedure, isRecipeInfoValid, errors
  };
};

const useEditRecipeInfo = (getRecipeData) => {
  const dispatch = useDispatch();
  const recipeInfo = useSelector(({recipeCosting}) => recipeCosting.recipeInfo);
  const recipeList = useSelector(({recipeCosting}) => recipeCosting.recipeList);

  const saveInfo = (params) => {
    recipeApi.patch(recipeInfo.id, params).then(({data}) => {
      dispatch(setRecipeList(recipeList.map(r => {
        if (r.id !== data.id) return r;
        return {
          ...r,
          ...data,
          description: data.name
        };
      })));
      getRecipeData();
    }).catch(err=> {
      if (err.code === 400) {
        dispatch(showModal(AlertModal, {message: err.data.message, onOk: () => getRecipeData()}));
      } else {
        dispatch(showModal(AlertModal, {message: "Something wen't wrong", onOk: () => getRecipeData()}));
      }
      console.error('RecipeApi patch Error:', err);
    });
  };

  const editInfo = () => {
    const modalProps = {
      menu: isMobile,
      isEdit: true,
      onSave: saveInfo
    };
    dispatch(showModal(RecipeInfoModal, modalProps));
  };

  return {
    editInfo, saveInfo
  };
};

const useIngredientsDetails = (getRecipeData) => {
  const dispatch = useDispatch();

  const openInventoryTypeDetails = (ingredient, isAdd) => {
    const optionProps = {
      isAdd: isAdd,
      ingredient,
      getRecipeData
    };
    dispatch(showModal(ItemDetailsInventory, optionProps));
  };

  const openFoodbamTypeDetails = (ingredient, isAdd) => {
    const optionProps = {
      ingredient,
      getRecipeData,
      isAdd: isAdd
    };
    dispatch(showModal(ItemDetailsFoodbam, optionProps));
  };

  const openSubrecipeTypeDetails = (ingredient) => {
    const optionProps = {
      ingredient,
      getRecipeData
    };
    dispatch(showModal(ItemDetailsSubrecipe, optionProps));
  };

  return {
    openFoodbamTypeDetails, openSubrecipeTypeDetails, openInventoryTypeDetails
  };
};

const useAddIngredients = (recipeInfo, getRecipeData) => {
  const dispatch = useDispatch();
  const details = useIngredientsDetails(getRecipeData);
  const ingredientsList = useSelector(({recipeCosting}) => recipeCosting.ingredientsList);

  const openAddFoodbamType = () => {
    const optionProps = {
      title: 'Add Foodbam Item',
      initItems: ingredientsList.filter(ing => ing.ingredientType === 'foodbam').map(ing => ing.item),
      idLabel: 'itemDistributorId',
      onAdd: (item) => {
        const ingredient = {
          recipe_id: recipeInfo.id,
          ingredientId: item.itemDistributorId,
          recipe_amount_fraction: '',
          recipe_pack_size_uom: 'lb',
          waste_percentage: '',
          conversion_pack_size_fraction: '',
          conversion_pack_size_uom: item.packSizeUom,
          recipe_pack_size_fraction: '',
          item: item
        };
        details.openFoodbamTypeDetails(ingredient, true)
      }
    };
    dispatch(showModal(AddFoodbamItemModal, optionProps));
  };
  const openInventoryType = () => {
    const modalProps = {
      onAdd: (item) => {
        const ing = {
          recipe_id: recipeInfo.id,
          item: item,
          recipe_pack_size_uom: 'lb'
        }
        details.openInventoryTypeDetails(ing, true);
      }
    };
    dispatch(showModal(AddInventoryItem, modalProps));
  }
  const openSubrecipeType = () => {
    const modalProps = {
      recipeId: recipeInfo.id,
      initItems: ingredientsList.filter(ing => ing.ingredientType === 'subrecipe').map(ing => ing.item),
      onAdd: (params) => {
        recipeApi.postIngredient(recipeInfo.id, params).then(({data}) => {
          getRecipeData();
        }).catch(err => console.error(err));
      }
    };
    dispatch(showModal(AddSubrecipe, modalProps));
  };

  const addIngredients = () => {
    const optionProps = {
      openFoodbam: openAddFoodbamType,
      openSubrecipe: openSubrecipeType,
      openInventory: openInventoryType
    };
    dispatch(showModal(AddIngredientModal, optionProps));
  };

  const addIngredientsMobile = () => {
    const optionProps = {
      title: 'Select',
      options: [
        {label: 'Item', description: 'Items I use FoodBAM to order', onClick: openAddFoodbamType},
        {label: 'Inventory Item', description: 'Item groups from my inventory view', onClick: openInventoryType},
        {label: 'Subrecipe', description: 'Items that I prepare OR make in house, for example "Chiplote Aioli" for your burger', onClick: openSubrecipeType}
      ]
    };
    dispatch(showModal(OptionsModal, optionProps));
  }

  return {
    addIngredients, addIngredientsMobile
  };
};

const useIngredients = (ingredientsList, getRecipeData, details) => {
  const update = (recipeId, id, type, ingredientId, amount) => {
    const params = {
      ingredient_id: ingredientId,
      ingredient_type: type,
      recipe_amount: number(fraction(amount)),
      recipe_amount_fraction: fraction(amount).toFraction(),
    }
    recipeApi.patchIngredient(recipeId, id, params).then(({data}) => {
      getRecipeData();
    }).catch(err => {
      console.error('recipeApi PatchIngredient error:', err);
    });
  };
  const ingredientsMap = ingredientsList.map(ing => {
    const name = ing.item.name;
    let image = noimage;
    let brand = ing.item.brand;
    const openDetails = () => {
      if (ing.ingredientType === 'foodbam') {
        details.openFoodbamTypeDetails(ing);
      } else if (ing.ingredientType === 'subrecipe') {
        details.openSubrecipeTypeDetails(ing);
      } else if (ing.ingredientType === 'generic') {
        details.openInventoryTypeDetails(ing);
      }
    };
    const uom = ing.recipe_pack_size_uom;
    const price = ing.ingredientCosting;
    const amount = ing.recipe_amount_fraction ? ing.recipe_amount_fraction : ing.recipe_amount.toString();
    switch (ing.ingredientType) {
      case 'subrecipe':
        image = subimage;
        break;
      case 'generic':
        if (ing.item.items.length > 0) {
          const item = ing.item.items.filter(i => i.imageUrl)[0];
          image = item ? (item.imageUrl ? item.imageUrl : (item.brandImageUrl ? item.brandImageUrl : noimage)) : noimage;
        }
        brand = (!!ing.lastPurchasedDistributor ? `Last purchased from ${ing.lastPurchasedDistributor}`: ``) +
                (!!ing.lastPurchasedDistributorPrice ? ` at ${numeral(ing.lastPurchasedDistributorPrice).format('$0,0.00')}/${ing.lastPurchasedDistributorItem.unit_measure}` : ``) +
                (!!ing.lastPurchasedDistributorDate ? ` on ${moment(ing.lastPurchasedDistributorDate).format('M-DD-YY')}` : ``)
                    ;
        break;
      case 'foodbam':
      default:
        image = ing.item.imageUrl ? ing.item.imageUrl : (ing.item.brandImageUrl ? ing.item.brandImageUrl : noimage)
        break;
    }
    return (
      <RecipeIngredientsItemRow key={ing.id} name={name}
        image={image} brand={brand} uom={uom} price={price}
        amount={amount} updateAmount={update} data={ing}
        openDetails={openDetails}
      />
    );
  });

  return { ingredientsMap };
};

const useUpdateTotals = (init, id) => {
  const dispatch = useDispatch();

  const handleKey = (key) => {
    switch (key) {
      case 'menuPrice': return 'price'; break;
      case 'foodCostPercent': return 'foodCostPercentage'; break;
      default: return key; break;
    }
  };

  const set = (key, value) => {
    const nKey = handleKey(key);
    const param = {[nKey]: value};
    recipeApi.patch(id, param).then(({data}) => {
      dispatch(setRecipeInfo({
        'menuPrice':  (Math.round(data.price * 100) / 100) ,
        'marginGrossProfit': (Math.round(data.marginGrossProfit * 100) / 100),
        'foodCostPercent': (Math.round(data.foodCostPercent * 100) / 100),
      }));
    }).catch(err=> {
      console.error('RecipeApi Update Error:', err);
    });
  };

  const totalCostTotal = useInput.useText('', {isNumber: true, value: init.totalCost, set: (v) => set('totalCost', v), isInvalidOnBlur: {presence: {allowEmpty: false}, numericality: { greaterThan: 0 }}});
  const menuPriceTotal = useInput.useText('', {isNumber: true, value: init.menuPrice, set: (v) => set('menuPrice', v), isInvalidOnBlur: {presence: {allowEmpty: false}, numericality: { greaterThan: 0 }}});
  const marginGrossProfitTotal = useInput.useText('', {isNumber: true, value: init.marginGrossProfit, set: (v) => set('marginGrossProfit', v), isInvalidOnBlur: {presence: {allowEmpty: false}}});
  const foodCostPercentTotal = useInput.useText('', {isNumber: true, value: init.foodCostPercent, set: (v) => set('foodCostPercent', v), isInvalidOnBlur: {presence: {allowEmpty: false}}});

  return {
    totalCostTotal, menuPriceTotal, marginGrossProfitTotal, foodCostPercentTotal
  };
};

export default {
  useApi, useInfoForms, useIngredients,
  useEditRecipeInfo, useAddIngredients, useIngredientsDetails,
  useUpdateTotals
};
