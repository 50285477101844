import React from 'react';
import { Row, Col, Button, Input } from 'reactstrap';
import numeral from 'numeral';
import { FaArrowDown, FaArrowUp, FaArrowRight, FaPlus, FaTrash, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { FiMoreHorizontal } from 'react-icons/fi';
import { colors } from '../../../../../../config/constants';
import { isMobile } from 'react-device-detect';
import ShowIf from '../../../../../../SharedComponents/ShowIf/ShowIf';
import { useDispatch, useSelector } from 'react-redux';
import { editOneInventoryGroup } from '../../../../../../reducers/items/itemsActions';
import usePlaceOrderInventory from '../usePlaceOrderInventory';
import AlertModal from '../../../../../../SharedComponents/Modals/AlertModal/AlertModal';
import { showModal } from '../../../../../../reducers/modals/modalsActions';
import { Fragment } from 'react';

export const GroupTitle = (props) => {
  const {
    title, spend, canScrollRight,
    scrollRight, scrollLeft, scrollPos,
    dragHandleProps, id, expand,
    isDragging, addDown, addTop,
    openGroupOptions, openAdd,
    deleteGroup, inputProps, isShowEdit,
    showEdit, saveEdit, openMoveToInventory, isInventoryView = false
  } = props;

  const canEditComparisonView = useSelector(({ user }) => user.editComparisonView) ? true : false;
  let prevent = false;
  let timer;
  const dispatch = useDispatch();

  const { conectivity } = usePlaceOrderInventory.useStatusConnection();

  const onEnterKey = ({ key }) => {
    if (key === 'Enter') {
      if (!conectivity) {
        dispatch(showModal(AlertModal, { message: 'You need internet connectivity to use this feature', footerClass: 'justify-content-center' }));
        return;
      }
      saveEdit();
    }
  };

  const openEdit = () => {
    prevent = true;
    clearTimeout(timer);
    showEdit();
  };
  const toggle = () => {
    const expandAction = () => {
      dispatch(editOneInventoryGroup(id, {
        expand: !expand
      }));
    };

    if (!isMobile) {
      timer = setTimeout(() => {
        if (!prevent) {
          expandAction();
        }
        prevent = false;
      }, 200);
    } else {
      expandAction();
    }
  };

  if (isMobile) {
    return (
      <Fragment>
        <Row className={'align-items-center m-0 bg-white' + (expand ? '' : ' border-top') + (isDragging ? ' border bg-light-blue' : '')} style={isDragging ? { margin: '-1px 0 -1px -1px' } : null}>
          <ShowIf show={expand}>
            <Col className='w-fc pr-0' onClick={scrollLeft}>
              <FaChevronLeft color={scrollPos > 0 ? colors.dark : colors.lightBlueText} />
            </Col>
          </ShowIf>
          <ShowIf show={!isShowEdit}>
            <Col className='text-center text-uppercase py-3 text-label-lg font-weight-bold' onClick={toggle}>
              {title}
            </Col>
          </ShowIf>
          <ShowIf show={isShowEdit}>
            <Col className='py-3 text-center'>
              <Input {...inputProps} onBlur={saveEdit} onKeyDown={onEnterKey} />
            </Col>
          </ShowIf>
          <ShowIf show={expand}>
            <Col className='p-0 w-fc' onClick={scrollRight}>
              <FaChevronRight color={canScrollRight ? colors.dark : colors.lightBlueText} />
            </Col>
          </ShowIf>
          <ShowIf show={canEditComparisonView}>
            <Col className='w-fc' onClick={() => openGroupOptions(conectivity)} {...dragHandleProps} >
              <FiMoreHorizontal color={colors.lightBlueText} />
            </Col>
          </ShowIf>
        </Row>
        <ShowIf show={!isInventoryView && !expand}>
          <Row className='bg-white  align-items-center justify-content-between pr-4 pl-4 mb-2 pb-2 border-bottom'>
            <span className="w-fc text-nowrap pr-0 text-label-sm text-uppercase text-label-smm">Total group spend:</span>
            <span className="text-label-bold-sm">{numeral(spend).format('$0,0.00')}</span>
          </Row>
        </ShowIf>

      </Fragment>
    );
  }
  return (
    <Row className='align-items-center bg-white mt-2' style={{ border: '2px solid #e7f0f6', borderBottom: 'none' }}>
      <Col>
        <Row className='align-items-center m-0' >
          <ShowIf show={canEditComparisonView}>
            <Col className='w-fc pl-0 px-1'>
              <Button onClick={() => openAdd(conectivity)} color='white' size='md-square'><FaPlus color={colors.lightBlueText} /></Button>
            </Col>
            <Col className='w-fc px-1'>
              <Button onClick={() => addDown(conectivity)} color='white' size='md-square'><FaArrowDown color={colors.lightBlueText} /></Button>
            </Col>
            <Col className='w-fc px-1'>
              <Button onClick={() => addTop(conectivity)} color='white' size='md-square'><FaArrowUp color={colors.lightBlueText} /></Button>
            </Col>
            <Col className='w-fc px-1'>
              <Button onClick={() => deleteGroup(conectivity)} color='white' size='md-square'><FaTrash color={colors.lightBlueText} /></Button>
            </Col>
            <Col className='w-fc pl-1'>
              <Button onClick={() => openMoveToInventory(conectivity)} color='white' size='md-square'><FaArrowRight color={colors.lightBlueText} /></Button>
            </Col>
          </ShowIf>

          {/* <Col className='border-top' /> */}
        </Row>
      </Col>
      <ShowIf show={!isShowEdit}>
        <Col className=
          {`text-center text-uppercase py-4 text-label-lg font-weight-bold`}
          onDoubleClick={openEdit} onClick={toggle} {...dragHandleProps}>
          {title}
        </Col>
      </ShowIf>
      <ShowIf show={isShowEdit}>
        <Col className='text-center py-3' {...dragHandleProps}>
          <Input {...inputProps} onBlur={saveEdit} onKeyDown={onEnterKey} />
        </Col>
      </ShowIf>
      <Col>
        <Row className='align-items-center justify-content-end mr-1'>
          {/* <Col className='border-top' /> */}
          <Col className='w-fc text-nowrap pr-0 text-label-sm text-uppercase '>
            <ShowIf show={!isInventoryView}>
              Total group spend: <span className="text-label-bold-sm">{numeral(spend).format('$0,0.00')}</span>
            </ShowIf>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default GroupTitle;