import React from 'react';
import { Row, Col, Input } from 'reactstrap';
import ShowIf from '../../../../../../../SharedComponents/ShowIf/ShowIf';

const ItemDetailsInputCol = (props) => {
  const {
    label, type, sideText, set, value, disabled, full
  } = props;

  return (
    <Col xs={full ? 12 : 4}>
      <Row>
        <Col className='text-label mb-1'>{label}</Col>
      </Row>
      <Row className='align-items-center'>
        <Col className={full ? '' : 'w-squared-input'}>
          <Input className='bg-white' disabled={disabled} type={type} onChange={set} value={value} />
        </Col>
        <ShowIf show={sideText}><Col className='pl-0'>{sideText}</Col></ShowIf>
      </Row>
    </Col>
  );
};

export default ItemDetailsInputCol;