import { sortOptions } from "../../config/constants";

export default function reducer(state = {
  filters: {
    purchasedOnly: false,
    distributorIds: [],
    shoppingIds: [],
    pastOrderIds: [],
  }
}, action) {
  switch (action.type) {
    case 'SET_SHOPPING_LISTS_FILTERS':
      return {
        ...state,
        filters: {
          purchasedOnly: action.payload.purchasedOnly,
          distributorIds: action.payload.distributorIds,
          shoppingIds: action.payload.shoppingIds,
          pastOrderIds: action.payload.pastOrderIds
        }
      };

    case 'CLEAR_SHOPPING_LISTS_FILTERS':
      return {
        ...state,
        filters: {
          purchasedOnly: false,
          distributorIds: [],
          shoppingIds: [],
          pastOrderIds: [],
        }
      };

    default:
      return state;
  }
};
