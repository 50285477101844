import React, { Fragment } from 'react';
import { Col, Input } from 'reactstrap';

const ChangePasswordFormInput = (props) => {
  const { label, placeholder, type,
    set, value, error } = props;

  return (
    <Fragment>
      <Col className='mt-3'>{label}</Col>
      <Col>
        <Input type={type} placeholder={placeholder} onChange={set} value={value} />
      </Col>
      <Col className='text-danger'>{error}</Col>
    </Fragment>
  );
};

export default ChangePasswordFormInput;