import React, { Fragment } from 'react';
import { Col, Input, CustomInput } from 'reactstrap';
import RecipeInfoInput from './RecipeInfoInput';
import ShowIf from '../../../../../../SharedComponents/ShowIf/ShowIf';

const RecipeInfoSubrecipeInfo = (props) => {
  const {
    recipeYield, recipeYieldUom, isSubrecipe
  } = props;

  return (
    <Fragment>
      <Col className='p-0 d-flex align-items-end mt-3 mb-1'>
        <Col className='text-label font-weight-bold w-fc'>Is this a subrecipe?</Col>
        <CustomInput className='squared' type='checkbox' id='isSubrecipe' checked={isSubrecipe.value} onChange={isSubrecipe.toggle} />
      </Col>
      <ShowIf show={isSubrecipe.value}>
        <Col className='p-0 d-flex align-items-end'>
          <Col className='p-0'>
            <RecipeInfoInput type='text' label='Recipe Yield' className='pr-2' error={recipeYield.error} onBlur={recipeYield.onBlur} onFocus={recipeYield.onFocus}
              set={recipeYield.set} value={recipeYield.value} placeholder='Recipe Yield'
            />
          </Col>
          <Col className='p-0'>
            <RecipeInfoInput type='text' label='Recipe Yield UOM' className='pl-2' error={recipeYieldUom.error} onBlur={recipeYieldUom.onBlur} onFocus={recipeYieldUom.onFocus}
              set={recipeYieldUom.set} value={recipeYieldUom.value} placeholder='Recipe Yield UOM'
            />
          </Col>
        </Col>
      </ShowIf>
    </Fragment>
  );
}

export default RecipeInfoSubrecipeInfo;