
export const setShoppingListsFilters = (purchasedOnly, distributorIds, shoppingIds, pastOrderIds) => {
  return {
    type: 'SET_SHOPPING_LISTS_FILTERS',
    payload: {
      purchasedOnly: purchasedOnly,
      distributorIds: distributorIds,
      shoppingIds: shoppingIds,
      pastOrderIds: pastOrderIds
    }
  }
};
