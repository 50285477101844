import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { localStorageConst } from '../../config/constants';
import GoTo from '../../SharedComponents/GoTo/useGoTo';
import {showModal} from '../../reducers/modals/modalsActions';

import useConnection from '../OfflineMode/useConnection';
import useShoppingCart from './useShoppingCart';
import useShoppingCartOffline from './useShoppingCartOffline';


const useCartItems = () => {

  const { getConnectionState } = useConnection.useMode();
  const { getCartItems } = useShoppingCart.useCartItems();
  const { getCartItemsOffline } = useShoppingCartOffline.useCartItems();

  const getCartItemsInterface = () => {
    if(getConnectionState()){
      getCartItems();
    }else{
      getCartItemsOffline();
    }
  };

  return { getCartItemsInterface }

};

export default {
  useCartItems
};