import React, { Fragment } from 'react';
import { Col } from 'reactstrap';
import Select from 'react-select';
import ShowIf from '../../ShowIf/ShowIf';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from '../../../reducers/modals/modalsActions';
import InventoryListModal from '../../Modals/InventoryListModal/InventoryListModal';

const SubHeaderPlaceOrderInventoryList = (props) => {
  const { view } = props;
  const dispatch = useDispatch();
  const inventoryList = useSelector(({items}) => items.inventory.listSelected);
  const displayOnDropdown = {
    label: inventoryList ? inventoryList.name : null,
    value: inventoryList ? inventoryList.id : null
  };

  const openInventoryList = () => {
    dispatch(showModal(InventoryListModal));
  };

  return (
    <Fragment>
      <ShowIf show={!isMobile && view === 'inventory'}>
        <Col className='pr-0 d-flex align-items-center'>
          <Col className='w-fc text-light-blue text-right pl-0'>Inventory List:</Col>
          <Col className='w-320px p-0 clickable'>
            <Select className='fb-select' value={displayOnDropdown} classNamePrefix='select'
              components={{Menu: () => null}} isSearchable={false}
              onMenuOpen={openInventoryList}
            />
          </Col>
        </Col>
      </ShowIf>
      <ShowIf show={isMobile && view === 'inventory'}>
        <Col>
          <Select className='fb-select' value={displayOnDropdown} classNamePrefix='select'
            components={{Menu: () => null}} isSearchable={false}
            onMenuOpen={openInventoryList}
          />
        </Col>
      </ShowIf>
    </Fragment>
  )
}

export default SubHeaderPlaceOrderInventoryList;
