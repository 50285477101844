import { useState, useEffect, useCallback, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import { processStatus, onHandTypes, inventoryItemTypes } from '../../config/constants';
import { InventoryContext } from '../../Views/MainApp/Views/PlaceOrder/PlaceOrderInventory/PlaceOrderInventoryItem/context/InventoryContext';
import useConversion from '../Conversion/useConversion';
import {findOneGroup, updateOneGroup} from '../../local/ComparisonViewLocal';
import {editOneGroupItem} from '../../reducers/groups/groupsActions';
import { saveInventoryOnHandTotal } from '../../reducers/items/itemsActions';
import { localStorageConst } from '../../config/constants';
import moment from 'moment';

const useDebounce = (callback, delay) => {
  const d = callback;
  const callbackfunc = useCallback(debounce(d, delay), []);
  return [callbackfunc];
};

const useOnHandUpdateOffline = (onHandProps, setOnHandAmount, groupTotalProps) => {
  const dispatch = useDispatch();
  const [updating, setUpdating] = useState(processStatus.start);
  const [responses, setResponses] = useState(null);
  const onHandTotal = useSelector(({items}) => items.inventory.onHandTotal);

  const {comparisonViewId, groupId, onHandType,
    id, itemDistributorId, isCustomItem, genericCustomItemId, isSubRecipe, subRecipeId, endDate, isPriceOutdated,
    unitQuantity, unitSize, purchasedPrice, unitPrice, unitToConvert, isCustomPackSize, packSizeUom} = onHandProps;

  const itemEndDate = moment(endDate);
  const price = isCustomItem === 1 ? onHandProps.price : 
                            ( itemEndDate.isValid() ? 
                                (itemEndDate.isAfter(moment()) ? onHandProps.price : (onHandProps.isFromInvoice == 1 ? (purchasedPrice > 0 ? purchasedPrice : onHandProps.price) : onHandProps.price) ) :
                                (isPriceOutdated ? (onHandProps.isFromInvoice == 1 ? (purchasedPrice > 0 ? purchasedPrice : onHandProps.price) : onHandProps.price) : onHandProps.price)
                            );

  const { totalOnHandAmt,
    setTotalOnHandAmt,
    setTotalGroupSpent,
    setTotalComparisonView,
    setTotalOnHandAmtShowOnlyMissing,
    setTotalComparisonViewShowMissing,
    setTotalOnHandTotalShowMissing,
    showOnlyMissingHv } = groupTotalProps;

  const inventoryContext = useContext(InventoryContext);
  const { setOH, setOHUom, setOHPack,
    setTotalOH, totalOHAmount, setTotalOHAmount,
    setCollectiveOH, setCollectiveOHUom,
    totalCase, setTotalCase,
    totalPack, setTotalPack,
    totalUnit, setTotalUnit } = inventoryContext;
    

  const updateTotals  = () => {

      const totalOHDiff = Math.round( (Math.abs(parseFloat(responses.totalOHAmt) - parseFloat(totalOHAmount))) * 100) / 100;

      const calcOnHandTotal = responses.operation === 'add' ? (Math.round(onHandTotal * 100) / 100) + totalOHDiff : (Math.round(onHandTotal * 100) / 100) - totalOHDiff;
      const newOnHandTotal = (calcOnHandTotal < 0) ? 0 : calcOnHandTotal;

      const calcTotalOnHandAmt = responses.operation === 'add' ? (Math.round(totalOnHandAmt * 100) / 100) + totalOHDiff : (Math.round(totalOnHandAmt * 100) / 100) - totalOHDiff;
      const newTotalOnHandAmt = (calcTotalOnHandAmt < 0) ? 0 : calcTotalOnHandAmt;

      switch (onHandType) {
        case 'cs':
          setOH(responses.newVal);
          break;
        case 'uom':
          setOHUom(responses.newVal);
          break;
        case 'pack':
          setOHPack(responses.newVal);
          break;
        default:
          break;
      }

      setTotalOH(responses.totalOH);
      setTotalOHAmount(responses.totalOHAmt);
      setTotalOnHandAmt(newTotalOnHandAmt);
      dispatch(saveInventoryOnHandTotal(newOnHandTotal));
      localStorage.setItem(localStorageConst.keys.inventoryItemsToSync, true);

  };

  const update = (newOnHandAmount, prevOnHandAmount, OH, OHUom, OHPack, onHandPropsOH) => {

    const { endDate, isPriceOutdated, unitQuantity, unitSize, purchasedPrice, unitToConvert, isCustomPackSize, packSizeUom } = onHandPropsOH;

    const itemEndDate = moment(endDate);
    const price = isCustomItem === 1 ? onHandPropsOH.price : 
                            ( itemEndDate.isValid() ? 
                                (itemEndDate.isAfter(moment()) ? onHandPropsOH.price : (onHandPropsOH.isFromInvoice == 1 ? (purchasedPrice > 0 ? purchasedPrice : onHandPropsOH.price) : onHandPropsOH.price) ) :
                                (isPriceOutdated ? (onHandPropsOH.isFromInvoice == 1 ? (purchasedPrice > 0 ? purchasedPrice : onHandPropsOH.price) : onHandPropsOH.price) : onHandPropsOH.price)
                            );


    setUpdating(processStatus.processing);
    const onHandBase = onHandType===onHandTypes.cs ? newOnHandAmount : OH;
    const onHandUomBase = onHandType===onHandTypes.uom ? newOnHandAmount : OHUom;
    const onHandPackBase = onHandType === onHandTypes.pack ? newOnHandAmount : OHPack;

    let nUnitSize = unitSize;

    if (unitToConvert.label !== 'Original') {
      const { conversion } = useConversion.usePackSizeParts(unitQuantity, unitSize, packSizeUom, unitToConvert.value);
      nUnitSize = conversion === 0 ? unitSize : conversion;
    };

    const totalPackSizeValue = unitQuantity * nUnitSize;
    const unitPriceBase = price / totalPackSizeValue;
    const totalOnHand = ((onHandBase) * totalPackSizeValue) + (onHandUomBase + (onHandPackBase * nUnitSize));
    
    const totalCase = onHandUomBase;
    const totalPack = (onHandPackBase * nUnitSize);
    const totalUnit = ((onHandBase) * totalPackSizeValue);

    const totalOnHandAmount = Math.round((totalOnHand * unitPriceBase) * 100) / 100;
    const totalOnHandUnit = Math.round((totalCase * unitPriceBase) * 100) / 100;
    const totalOnHandPack = Math.round((totalPack * unitPriceBase) * 100) / 100;
    const totalOnHandCase = Math.round((totalUnit * unitPriceBase) * 100) / 100;

    setTotalCase(totalOnHandCase);
    setTotalPack(totalOnHandPack);
    setTotalUnit(totalOnHandUnit);

    const saveParams = {
      id: id,
      comparisonViewId: comparisonViewId,
      groupId: groupId,
      genericCustomItemId: genericCustomItemId ? genericCustomItemId : null,
      subRecipeId: subRecipeId ? subRecipeId : null,
      itemDistributorId: itemDistributorId ? itemDistributorId : null,
      type: (isCustomItem ? inventoryItemTypes.custom : (isSubRecipe ? inventoryItemTypes.subrecipe : inventoryItemTypes.generic)),
      onHand: onHandBase,
      onHandUom: onHandUomBase,
      onHandPack: onHandPackBase,
      totalOnHand: totalOnHand,
      totalOnHandAmount: totalOnHandAmount,
      toUpdate: 1
    };

    const getParamsByHand = () => {
      switch (onHandType) {
        case 'cs': return {
          onHand: newOnHandAmount,
          totalOnHand: totalOnHand,
          totalOnHandAmount: totalOnHandAmount,
        }; break;
        case 'uom': return {
          onHandUom: newOnHandAmount,
          totalOnHand: totalOnHand,
          totalOnHandAmount: totalOnHandAmount,
        }; break;
        case 'pack': return {
          onHandPack: newOnHandAmount,
          totalOnHand: totalOnHand,
          totalOnHandAmount: totalOnHandAmount,
        }; break;
        default:
          break;
      }
    };
    const updateParams = getParamsByHand();

    const operation = newOnHandAmount > prevOnHandAmount ? 'add' : 'sub';
    findOneGroup(groupId).then((result) => {
      if(result){
        if(result[0].items.length>0){

          const nItems = result[0].items.map((item, i) => {
            if (item.id !== id) return item;
            return {
              ...item,
              onHand: onHandBase,
              onHandUom: onHandUomBase,
              onHandPack: onHandPackBase,
              totalOnHand: totalOnHand,
              totalOnHandAmount: totalOnHandAmount,
              toUpdate: 1
            };
          })

          const params = {items : nItems};
          updateOneGroup(params,groupId).then((res) => {
            if (isCustomItem) {
              dispatch(editOneGroupItem(groupId, genericCustomItemId, updateParams, 'genericCustomItemId'));
            } else if (isSubRecipe) {
              dispatch(editOneGroupItem(groupId, subRecipeId, updateParams, 'subRecipeId'));
            }else {
              dispatch(editOneGroupItem(groupId, itemDistributorId, updateParams));
            }

            setResponses({
              totalOH : totalOnHand,
              totalOHAmt : totalOnHandAmount,
              operation: operation,
              newVal: newOnHandAmount,
            });
            setUpdating(processStatus.end);
          }).catch(err => console.error(err));
        }
      }
    }).catch(err => console.error(err));

  };

  const [ callback ] = useDebounce(update);

  const updateOnHandAmountOffline = (newOnHandAmount, prevOnHandAmount, OH, OHUom, OHPack, onHandProps) => {
    const newOH = (newOnHandAmount==='') ? newOnHandAmount : newOnHandAmount;
    setOnHandAmount(newOH);
    if(newOnHandAmount===''){
      return;
    }
    callback(parseFloat(newOnHandAmount), parseFloat(prevOnHandAmount), OH, OHUom, OHPack, onHandProps);
  };

  useEffect(() => {
    if(updating === processStatus.end && responses !== null){
      updateTotals();
    }
  }, [updating,responses]);

  return { updateOnHandAmountOffline };
};

export default {
  useOnHandUpdateOffline
};
