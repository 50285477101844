import React, { useState, useContext, Fragment } from 'react';
import { Col, Row } from 'reactstrap';
import OnHandItem from '../../../../../../../SharedComponents/OnHandItem/OnHandItem';
import { colors, onHandTypes } from '../../../../../../../config/constants';
import ShowIf from '../../../../../../../SharedComponents/ShowIf/ShowIf';
import numeral from 'numeral';
import { useSelector } from 'react-redux';
import { InventoryContext } from "../context/InventoryContext";
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import ShoppingCartItem from '../../../../../../../SharedComponents/ShoppingCartItem/ShoppingCartItem';

const ItemInventory = (props) => {
  const displayInventory = useSelector(({ user }) => user.activeLocation.display_inventory);
  const [showMore, setShowMore] = useState(false);
  const isDisplayShoppingCart = useSelector(({ user }) => user.activeLocation.display_shopping_cart);

  const {
    group, collectiveOnHand, collectiveOnHandUom,
    unitMeasure, packSizeUom, comparisonViewId, unitToConvert, isCustomPackSize, groupTotalProps,
    isSubRecipe, isCustomItem, itemProps
  } = props;
  const finalPackSizeUom = (unitToConvert.label !== 'Original') ? unitToConvert.label : packSizeUom;

  const inventoryContext = useContext(InventoryContext);
  const {
    totalOH,
    totalOHAmount,
    collectiveOH,
    collectiveOHUom,
  } = inventoryContext;

  return (
    <Fragment>
      <ShowIf show={!isSubRecipe && !isCustomItem && isDisplayShoppingCart !== 0}>
        <Row style={{ height: '90px' }} className='align-items-center' >
          <Col className='px-2'>
            <Row className='m-0'>
              <Col className='text-label-sm text-center my-1'>
                Add to Cart
              </Col>
            </Row>
            <Row className='m-0 align-items-center justify-content-center'>
              <ShoppingCartItem item={props} showAll overlayProps groupTotalProps={groupTotalProps} />
            </Row>
          </Col>
        </Row>
      </ShowIf>
      <ShowIf show={displayInventory}>
        <Row className='align-items-center'>
          <Col className='px-2  d-flex justify-content-end flex-column'>
            <Row className='mx-n2 align-items-center justify-content-center'>
              <Col className='text-label-sm text-center my-1'>
                On Hand
                <br />
                {collectiveOnHand > 0 ? `(Collective On Hand ${collectiveOH} ${unitMeasure})` : <span>&nbsp;</span>}
              </Col>
            </Row>
            <Row className='m-0 align-items-center justify-content-center'>
              <OnHandItem onHandType={onHandTypes.cs} item={props} group={group} comparisonViewId={comparisonViewId} groupTotalProps={groupTotalProps} />
            </Row>
          </Col>
        </Row>
        <ShowIf show={!showMore}>
          <Row className='align-items-center border-top py-2 clickable'
            onClick={() => setShowMore(true)}
            style={{ marginTop: isDisplayShoppingCart === 0 ? ((!isSubRecipe && !isCustomItem && isDisplayShoppingCart !== 0) ? 90 : 20) : 10 }}
          >
            <Col className='text-center text-label-sm'>Show More <FaCaretDown color={colors.lightBlueText} /></Col>
          </Row>
        </ShowIf>
        <ShowIf show={showMore}>
          <Row style={{ height: '90px' }} className='align-items-center'>
            <Col className='px-2 d-flex flex-column justify-content-end'>
              <Row className='m-0 align-items-center justify-content-center'>
                <Col className='text-label-sm text-center my-1 p-0'>
                  {collectiveOnHandUom > 0 ? `(Collective On Hand ${collectiveOHUom} ${finalPackSizeUom})` : <span>&nbsp;</span>}
                </Col>
              </Row>
              <Row className='m-0 align-items-center justify-content-center'>
                <OnHandItem onHandType={onHandTypes.uom} item={props} group={group} comparisonViewId={comparisonViewId} groupTotalProps={groupTotalProps} />
              </Row>
              <Row className='m-0 align-items-center justify-content-center'>
                <Col className='text-label-sm text-center mt-2'>
                  Total On Hand
                  </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col className='px-2 font-weight-bold'> {numeral(totalOH).format('0,0')} {finalPackSizeUom}</Col>
            <Col className='px-2 font-weight-bold text-right'>{numeral(totalOHAmount).format('$0,0.00')}</Col>
          </Row>
          <Row className='align-items-center border-top py-2 clickable mt-3' onClick={() => setShowMore(false)}>
            <Col className='text-center text-label-sm'>Show Less <FaCaretUp color={colors.lightBlueText} /></Col>
          </Row>
        </ShowIf>
      </ShowIf>
    </Fragment >

  );
};

export default ItemInventory;
