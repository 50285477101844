import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import RecipeCostingMenu from './RecipeCostingMenu/RecipeCostingMenu';
import RecipeCostingCategory from './RecipeCostingCategory/RecipeCostingCategory';
import useRecipeCostingCategory from './RecipeCostingCategory/useRecipeCostingCategory';

const RecipeCosting = () => {
  const categoryProps = useRecipeCostingCategory.useApi();

  return (
    <Switch>
      <Route exact path='/recipe-costing'>
        <RecipeCostingCategory {...categoryProps} />
      </Route>
      <Route path='/recipe-costing/:menuId'>
        <RecipeCostingMenu />
      </Route>
      <Route>
        <Redirect to='/recipe-costing' />
      </Route>
    </Switch>
  );
};

export default RecipeCosting;