import React from 'react';
import { Row, Col } from 'reactstrap';
import { MdArrowDropUp, MdClose, MdArrowDropDown } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import useSidebar from './useSidebar';
import ShowIf from '../ShowIf/ShowIf';

const SidebarHeader = ({isLocationOpen, noLocations}) => {

  const dispatch = useDispatch();
  const { clickClose } = useSidebar.useActions(dispatch);

  const activeLocation = useSelector(({user}) => user.activeLocation);
  let logoUrl;
  if (activeLocation && activeLocation.company && activeLocation.company.nickname == 'FoodBam') {
    logoUrl = 'https://diningalliance.s3.amazonaws.com/organization/default.png';
  } else {
    logoUrl = activeLocation && activeLocation.company && activeLocation.company.favico;
  }

  return (
    <Row className='sidebar-header m-0'>
      <Col xs={2} className="p-0 overflow-hidden">
        <img src={logoUrl} style={{width: '35px'}} />
      </Col>
      <Col xs={8} className="p-0 active-location">
        <span>{activeLocation  && activeLocation.nickname}</span>
      </Col>
      <Col xs={1}>
        <ShowIf show={isLocationOpen.value && !noLocations}>
          <MdArrowDropUp color="#fff" className="clickable float-right" onClick={isLocationOpen.toggle} />
        </ShowIf>
        <ShowIf show={!isLocationOpen.value && !noLocations}>
          <MdArrowDropDown color="#fff" className="clickable float-right" onClick={isLocationOpen.toggle} />
        </ShowIf>
        <MdClose color="#fff" onClick={clickClose} className="clickable" style={{color: '#ffffff! important', position: 'relative', top: '-26px', left: '16px'}} />
      </Col>
    </Row>
  );
};

export default SidebarHeader;
