import React from 'react';
import ShowIf from '../ShowIf/ShowIf';

const HeaderSubHeader = (props) => {
  const { subHeaders, show } = props;
  return (
    <ShowIf show={show}>
      {subHeaders}
    </ShowIf>
  );
};

export default HeaderSubHeader;