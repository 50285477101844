import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Col, Button, Form } from 'reactstrap';
import ChangePasswordFormInput from './ChangePasswordFormInput';
import { showModal } from '../../reducers/modals/modalsActions';
import AlertModal from '../../SharedComponents/Modals/AlertModal/AlertModal';

const ChangePasswordForm = (props) => {
  const { setPassword, password, setConfirmPassword, confirmPassword, onSubmit, error } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const goToContactUs = () => {
    window.location.href = 'https://foodbam.com/contact-us/';
  };

  const goToSignUp = () => {
    history.push('/sign-up/1');
  };

  return (
    <Form className='reset-form flex-column m-auto' noValidate="novalidate" onSubmit={onSubmit}>
      <Col className='mt-5 text-center mb-5'>
        <h4>Reset Password</h4>
      </Col>
      <ChangePasswordFormInput label='Password' type='password' placeholder='Password' value={password} set={setPassword} error={error.password ? error.password[0] : ''} />
      <ChangePasswordFormInput label='Password' type='password' placeholder='Confirm Password' value={confirmPassword} set={setConfirmPassword} error={error.confirmPassword ? error.confirmPassword[0] : ''} />
      <Col className='text-center mt-5'>
        <Button color='blue' size='lg' className='w-100' type='submit'>Reset Password</Button>
      </Col>
      <Col className='text-danger text-center'>{error.error ? error.error : ''}</Col>
      
      <Col className='mt-5 text-center'>Don{"'"}t have an account yet</Col>
      <Col onClick={goToSignUp} className='text-center text-blue clickable'>SIGN UP</Col>
      <Col className='mt-3 text-center'>Have a question? <span onClick={goToContactUs} className='text-blue clickable'>Contact Us</span></Col>
    </Form>
  );
};

export default ChangePasswordForm;
