export const saveGroups = (groups) => {
  return {
    type: 'SET_GROUPS',
    payload: groups
  }
};

export const saveList = (list) => {
  return {
    type: 'SET_LIST',
    payload: list
  }
};

export const saveListSelected = (listSelected) => {
  return {
    type: 'SET_LIST_SELECTED',
    payload: listSelected
  }
};

export const saveSortBy = (sortBy) => {
  return {
    type: 'SET_SORT_BY',
    payload: sortBy
  }
};

export const saveSearch = (search) => {
  return {
    type: 'SET_SEARCH',
    payload: search
  }
};

export const clearGroupsData = () => {
  return {
    type: 'CLEAR_GROUPS_DATA',
  };
};

export const editOneGroupItem = (groupId, itemId, value, idLabel) => {
  return {
    type: 'EDIT_ONE_GROUP_ITEM',
    payload: {
      groupId: groupId,
      idLabel: idLabel ? idLabel : 'itemDistributorId',
      itemId: itemId,
      value: value
    }
  }
};