import React, {useState, useEffect}  from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col, Button } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import { IoMdCloudUpload } from "react-icons/io";
import {orderImageUpload} from '../../../../../config/constants';
import useModal from '../../../../../SharedComponents/Modals/useModal';
import ordersApi from '../../../../../api/ordersApi';
import InvoiceImage from './InvoiceImage';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import * as $ from 'jquery';


const OrderUploadInvoiceModal = (props) => {
  const { id, menu, fullMenu } = props;
  const modalProps = useModal(id, menu, fullMenu);
  const defaultModalTitle = 'Upload Invoice';
  const [images, setImages] = useState([]);
  
  const [openView, setOpenView] = useState(false);
  const [imageSrc, setImageSrc] = useState('');
  const [modalTitle, setModalTitle] = useState(defaultModalTitle);

  const getImages = () => {
    ordersApi.getInvoiceImages(id)
      .then(res => {
        const images = res.data;
        const functionProps = {getImages, setOpenView, setModalTitle, setImageSrc};
        setImages(images.map((image, index) => <InvoiceImage key={`order_${id}_image_${index+1}`} name={`image ${index+1}`} index={index+1} image={image} functionProps={functionProps}/>));
    }).catch(error => {console.log(error)});
  };

  const closeImage = () =>{
    setOpenView(false);
    setModalTitle(defaultModalTitle);
  };

  const uploadImage = () =>{    
    $('#img-upload').click();    
  };

  const selectFile = () =>{
    const file = $('#img-upload').prop('files')[0];

    const typeAllowed = orderImageUpload.allowTypes.filter(type => type === file.type)[0];
    const sizeAllowed = (file.size <= orderImageUpload.sizeLimit );

    if (!typeAllowed) {
      $('#img-upload').val('');
      return;
    }

    if (!sizeAllowed) {
      $('#img-upload').val('');
      return;
    }

    ordersApi.uploadInvoiceImage(id, file)
      .then(res => {
        $('#img-upload').val('');
        getImages();
    }).catch(error => {console.log(error)});
  };

  useEffect(() => {    
    getImages();
  }, []);

  return (
      <Modal {...modalProps}>
          <ModalHeader toggle={ !openView ? modalProps.toggle : closeImage} className='d-flex align-items-center justify-content-center'>{modalTitle}</ModalHeader>
          <ModalBody className={isMobile ? 'm-0' : ''}>
            <ShowIf show={!openView}>
              <ShowIf show={!isMobile}>
                <Row className=' mb-3 pb-3 border-bottom'>
                  <Col xs={2}></Col>
                  <Col className='d-flex align-items-center justify-content-center py-2'>
                    <Button color='gray' outline onClick={uploadImage} block ><IoMdCloudUpload color='gray'/> Upload New</Button>
                    <input className='d-none' onChange={() => selectFile()} type='file' id='img-upload' accept=".jpg,.jpeg,.png,.pdf"/>
                  </Col>
                  <Col xs={2}></Col> 
                </Row>
              </ShowIf>
              <div>
                {images}
              </div>
            </ShowIf>
            <ShowIf show={openView}>
              <div>
                <img className='w-100' src={imageSrc}/>
              </div>
            </ShowIf>            
          </ModalBody>
      </Modal>
  )

}

export default OrderUploadInvoiceModal;