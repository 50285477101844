export const saveExpandAll = (values) => {
    return {
        type: 'SET_EXPAND_ALL',
        payload: values
    }
};

export const textSearchInventory = (values) => {
    return {
        type: 'SET_TEXT_SEARCH_INVENTORY',
        payload: values
    }
};


export const saveInventoryEditAll = (values) => {
    return {
        type: 'SET_UPDATE_INVENTORY_EDIT_ALL',
        payload: values
    }
};

