import React, { useEffect, useState, Fragment } from 'react';
import { Container, Card, Row, Col, Button, Input } from 'reactstrap';
import useDistributor from '../useDistributor';
import { isMobile } from 'react-device-detect';
import Header from '../../../../../SharedComponents/Header/Header';
import distributorApi from '../../../../../api/distributorsApi';
import { useParams } from "react-router-dom";
import DsrRow from './DsrRow';
import QuestionModal from '../../../../../SharedComponents/Modals/QuestionModal/QuestionModal';
import UserInputsModal from '../../../../../SharedComponents/Modals/UserInputsModal/UserInputsModal';
import { showModal } from '../../../../../reducers/modals/modalsActions';
import { useDispatch } from 'react-redux';
import { RiAccountBoxLine } from 'react-icons/ri';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import { FaQuestionCircle } from 'react-icons/fa';
import IconTooltipSolid from '../../../../../SharedComponents/iconTooltip/IconTooltipSolid';


const DistributorDetail = (props) => {
  const [distributor, setDistributor] = useState([]);
  const [dsrsMap, setDsrsMap] = useState([]);
  const [dsrsMapD, setDsrsMapD] = useState([]);

  const params = useParams();
  const id = parseInt(params.id);
  const { updateDist, sendInvite } = useDistributor.useRow({ id });
  const dispatch = useDispatch();
  const [nameShowISR, setNameShowISR] = useState('Show');

  useEffect(() => {
    getDistributors();
  }, []);

  const getDistributors = () => {
    distributorApi.getByIdExpanded(id).then((res) => {
      setDistributor(res.data);
    }).catch(error => {
    });
  };

  const showModalUserInputs = () => {
    const props = {
      header: `If your username and/or password has changed ${distributor.nickname}`,
      usernameP: distributor.skyloader.username,
      onOk: (username, password) => {
        distributorApi.updateUserPassDistributor(id,
          {
            username,
            password
          }).then((res) => {
            console.log(res)
          }).catch(error => {
            console.log(error)
          });
      }
    };
    dispatch(showModal(UserInputsModal, props));
  }

  const confirmUpdate = () => {
    const qProps = {
      message: `Are you sure you want to update your login information for ${distributor.nickname}?`,
      menu: isMobile ? true : false,
      leftButton: {
        title: 'Cancel',
        color: 'green',
        type: 'outline'
        // onClick: () => goBack()
      },
      rightButton: {
        title: 'Proceed',
        color: 'green',
        onClick: () => showModalUserInputs()
      }

    };
    dispatch(showModal(QuestionModal, qProps));

  }

  const addDist = () => {
    const params = {
      name: "",
      email: "",
      phone: "",
      is_primary: true
    }
    distributorApi.saveDistributor(id, params)
      .then(res => getDistributors())
      .catch(err => console.log(err))
  }

  const deleteDist = (idDis, params) => {
    distributorApi.deleteDistributor(id, idDis, params)
      .then(res => getDistributors())
      .catch(err => console.log(err))
  }

  const showISR = () => setNameShowISR(nameShowISR === 'Show' ? 'Hide' : 'Show');


  useEffect(() => {
    if (!distributor) { return; }
    if (!distributor.dsrs) { return; }
    if (distributor.dsrs.length === 0) { return; }

    const resultDsrs = distributor.dsrs.reduce((acc, dsr) => {
      console.log(distributor.is_dsr_editable)
      dsr.is_deleted ? acc.isDeleted.push(
        <DsrRow key={dsr.id} id={dsr.id} data={dsr} updateDist={updateDist} sendInvite={sendInvite} deleteDist={deleteDist} isEditable = {distributor.is_dsr_editable}/>
      ) : acc.isNotDeleted.push(
        <DsrRow key={dsr.id} id={dsr.id} data={dsr} updateDist={updateDist} sendInvite={sendInvite} deleteDist={deleteDist} isEditable = {distributor.is_dsr_editable}/>
      );
      return acc;
    }, { isDeleted: [], isNotDeleted: [] });

    setDsrsMap(resultDsrs.isNotDeleted);
    setDsrsMapD(resultDsrs.isDeleted);

  }, [distributor]);

  return (
    <Container fluid={true} className='my-3 pb-3' >
      <Header showMenu={false} showLogo={false} showBack={true} title='Edit Distributor' showCart={false} />
      <Card className='py-3 px-3 m-3 content-dist-card'>
        <Row className='px-3 text-gray mb-1'>
          <Col xs={3}>Distributor</Col>
          <Col xs={3}>Distributor Customer Number</Col>
        </Row>
        <Row className='px-3'>
          <Col xs={3}>
            <Input type='text' value={distributor.nickname} disabled='disabled' />
          </Col>
          <Col xs={3}>
            <Input type='text' value={distributor.dcn} />
          </Col>
        </Row>
        {!distributor.skyloader ? '' :
          (!distributor.skyloader.useSkyloader ? '' :
            <Fragment>
              <Row className='px-3 py-2 mb-1 text-gray'>
                <Col xs={3}>Website Username</Col>
                <Col xs={3}>Website Password</Col>
              </Row>
              <Row className='px-3 pb-2' onClick={confirmUpdate}>
                <Col xs={3}>
                  <Input disabled type='text' value={distributor.skyloader.username} />
                </Col>
                <Col xs={3}>
                  <Input disabled type='password' value='testestesteste' />
                </Col>
              </Row>
            </Fragment>)
        }
        <hr></hr>
        <Row className='px-3 d-flex align-items-center'>
          <Col>
            <p className="text-main m-0">
              Distributor Sales Rep Information</p></Col>
          <Col className="d-flex justify-content-end p-0">
            <Button className="buttonW mr-4" outline color="green" onClick={showISR}>{nameShowISR} Inactive Sales Reps</Button>
            <Button className="buttonW" color="green" onClick={addDist}>Add Distributor Sales Rep</Button>
          </Col>
        </Row>
        <hr></hr>
        <Row className='px-3 text-gray'>
          <Col xs={2}>Name</Col>
          <Col xs={3}>Email</Col>
          <Col xs={2}>Phone Number</Col>
          <Col xs={1}>Receive Order</Col>
          <Col xs={3}>Permissions
          <IconTooltipSolid invoiceDate='test' Icon={FaQuestionCircle} colorIcon='dark-blue' color='dark-blue' elemId={`question_${id}_`} >
              <p style={{ textAlign: "initial" }}>
                <i>Unlimited</i> : <p>Provides selected rep unilmited access to
              update order guide pricing.<br></br>
              Commonly used for your Primary Dsitributor sales rep.</p>
                <i>Limited</i> : <p>Provides rep temporary access to update order guide pricing.
                Must select date range.
              Commonly used to provide an alternate<br></br>
              Distributor. Sales Rep access to update<br></br>
              order guide pricing.</p>
                <i>No Access </i>: No Access.
              </p>
            </IconTooltipSolid>
          </Col>
          <Col xs={1}></Col>
        </Row>
        <hr></hr>
        {dsrsMap}
        <ShowIf show={nameShowISR !== 'Show'}>
          <Col>
            <p className="text-main m-0">
              Inactive Sales Reps</p></Col>
          <hr></hr>
          {dsrsMapD}

        </ShowIf>
      </Card>
    </Container >
  )
}

export default DistributorDetail;