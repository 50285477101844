import cartApi from '../../api/cartApi';
import comparisonViewsApi from '../../api/comparisonViewsApi';
import { saveCartItems, saveLastModified, saveUnsyncedItems, saveCartCount, saveCartDistributor, saveOrderTotal, saveSavingOptions } from "../../reducers/cart/cartActions";
import { localStorageConst } from '../../config/constants';
import { useDispatch } from 'react-redux';

const setCartStructure = (nCartItems) => {
    console.log(nCartItems)
    let structuredCart = [];
    let totalQty = 0;
    let totalOrder = 0;
    nCartItems.forEach(cItem => {
        if (!cItem.specialItem && !cItem.item) {
            return;
        };
        const newItem = (cItem.specialItem !== null) ? {
            caseQuantity: parseFloat(cItem.quantity),
            price: 0,
            id: Math.random(),
            distributorId: parseInt(cItem.specialItem.distributorId),
            din: cItem.specialItem.din,
            packSize: cItem.specialItem.packSize,
            // unitMeasure: cItem.specialItem.uom,
            unit: cItem.specialItem.uom,
            description: cItem.specialItem.description,
            searchText: undefined,
            rebate: undefined,
            swapAcceptId: undefined,
            isSpecialItem: true,
            distributor: cItem.specialItem.distributorName,
            shoppingCartId: cItem.id,
            isMarketPrice: 0,
            active: 0,
            caseSavings: undefined,
            belongsToGroup: 0
        } : {
                caseQuantity: parseFloat(cItem.quantity),
                // price: (cItem.item.isMarketPrice === 1 ? 0 : parseFloat(cItem.item.price)),
                price: parseFloat(cItem.item.price),
                itemDistributorId: cItem.itemDistributorId,
                brand: cItem.item.brand,
                brandImageUrl: cItem.item.brandImageUrl,
                imageUrl: cItem.item.imageUrl,
                name: cItem.item.name,
                packSize: cItem.item.packSize,
                status: cItem.item.status,
                type: cItem.item.type,
                unitPrice: cItem.item.unitPrice,
                distributorId: parseInt(cItem.item.distributorId),
                unitMeasure: cItem.item.unitMeasure,
                description: cItem.item.description,
                searchText: cItem.searchText,
                rebate: parseFloat(cItem.item.rebate),
                swapAcceptId: cItem.swapAcceptId,
                isSpecialItem: false,
                distributor: cItem.item.distributor,
                shoppingCartId: cItem.id,
                isPriceOutdated: cItem.item.isPriceOutdated,
                isMarketPrice: cItem.item.isMarketPrice,
                active: cItem.item.active,
                din: cItem.item.din,
                isPunchedOut: cItem.item.isPunchedOut,
                belongsToGroup: cItem.item.belongsToGroup,
                parLevel: cItem.parLevel,
                onHand: cItem.onHand,
                isPartner: cItem.item.isPartner,
                isPurchased: cItem.item.isPurchased,
                lastPurchasedDate: cItem.item.lastPurchasedDate ?
                    cItem.item.lastPurchasedDate.substr(0, 10).split('-').reverse().join('/') : '',
                lastPurchasedQty: cItem.item.lastPurchasedQty,
                savings: (cItem.swapAcceptId !== null && cItem.savings !== null) ? parseFloat(cItem.savings) : null
            };
        structuredCart.push(newItem);
        //sort by shoppingCartId
        structuredCart.sort((a, b) => { return a.shoppingCartId - b.shoppingCartId; });
        totalQty = totalQty + parseInt(newItem.caseQuantity);
        totalOrder = totalOrder + (parseFloat(newItem.caseQuantity) * parseFloat(newItem.price));
    });

    return { structuredCart, totalQty, totalOrder };
};

const useShoppingCart = () => {
    const dispatch = useDispatch();
    const getCartItemsProm = async () => {
        let res = await cartApi.get();
        const { structuredCart, totalQty, totalOrder } = setCartStructure(res.data.shoppingCart);
        dispatch(saveCartItems(structuredCart));
        dispatch(saveCartCount(totalQty));
        dispatch(saveOrderTotal(totalOrder));
        localStorage.setItem(localStorageConst.keys.cartItems, JSON.stringify(structuredCart));
        dispatch(saveLastModified(res.data.lastModified));
        localStorage.setItem(localStorageConst.keys.lastModifiedCart, res.data.lastModified);
        dispatch(saveUnsyncedItems(res.data.unsyncedItems));

        const cartItems = structuredCart;
        // const cartByDistributor = cartItems.reduce((dist, ci) => {
        //     dist[ci.distributorId] = dist[ci.distributorId] || [];
        //     dist[ci.distributorId].push(ci);
        //     return dist;
        // }, {});

        // return Object.entries(cartByDistributor);
        return getCartByDistributor(cartItems);
    };

    const getCartByDistributor = (cartItems) => {
        const cartByDistributor = cartItems.reduce((dist, ci) => {
            dist[ci.distributorId] = dist[ci.distributorId] || [];
            dist[ci.distributorId].push(ci);
            return dist;
        }, {});

        return Object.entries(cartByDistributor);
    };

    const doGetItemDistributor = async (idDistributor) => {
        let res = await comparisonViewsApi.getItemDistributor(idDistributor);
        if (res.data.length > 0) {
            const newRes = res.data.map(_item => {
                _item.groups.map(_itemP => {
                    _itemP.items = _itemP.items
                        .filter(itemI => itemI.isPunchedOut != 1 && itemI.isCustomItem != 1);
                    return _itemP;
                });
                return _item;
            });
            return newRes;
        } else {
            return []
        }
    };

    return { getCartItemsProm, getCartByDistributor, doGetItemDistributor }
}



export default { useShoppingCart }
