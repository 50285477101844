import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import useLogin from "../Login/useLogin";
import locationsApi from "../../api/locationsApi";
import usersApi from "../../api/usersApi";
import validate from "validate.js";

const useAdminLogin = () => {
  const history = useHistory();
  const { doLogin } = useLogin.useLogin();
  const [locations, setLocations] = useState([]);
  const [location, setLocation] = useState(null);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState(null);
  const [locationSearch, setLocationSearch] = useState('');

  useEffect(() => {
    locationsApi.all(locationSearch).then(({data}) => {
      const locations = data.map(l => {
        return {
          label: l.nickname,
          value: l.id
        }
      });
      setLocations(locations);
    }).catch((err) => {
      console.error('LocationApi all error:', err);
    });
  }, [locationSearch]);

  useEffect(() => {
    if (location) {
      locationsApi.users(location.value).then(({data}) => {
        const users = data.map(u => {
          return {
            label: u.name,
            value: u.id
          }
        });
        setUser(null);
        setUsers(users);
      }).catch((err) => {
        console.error('LocationApi users error:', err);
      });
    }
  }, [location]);

  const submitDisabled = (e) => {
    const data = {
      user: user,
      location: location
    };
    const constrains = {
      user: {
        presence: true
      },
      location: {
        presence: true
      }
    }
    const result = validate(data, constrains);
    return result ? true : false;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const params = {
      userId: user.value,
      merchantId: location.value
    };
    usersApi.merchantLogin(params).then(({data}) => {
      doLogin(data);
    }).catch((err) => {
      history.push('/logout');
      console.error('UsersApi merchantLogin error:', err);
    });
  };

  return {
    onSubmit, location, locations, setLocationSearch,
    setLocation, users, setUser, user, submitDisabled
  };
};

export default useAdminLogin;