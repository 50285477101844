import React from "react";
import { Row, Col } from "reactstrap";
import numeral from "numeral";
import OrderGuideBookmark from "../../../../../SharedComponents/Bookmark/OrderGuideBookmark";
import ListGroup from "../../../../../SharedComponents/ListGroup/ListGroup";
import ListGroupItem from "../../../../../SharedComponents/ListGroup/ListGroupItem";
import noImage from "../../../../../assets/images/no-image.png";
import ShowIf from "../../../../../SharedComponents/ShowIf/ShowIf";

const ItemsPurchased = (props) => {
  const { items } = props;

  return (
    <ListGroup title="Items You Have Purchased" className={props.className}>
      {items.map((item) => {
        const itemImage = item.brandImage || item.imageUrl || noImage;

        return (
          <ListGroupItem
            id={`og_${item.id}`}
            key={item.id}
          >
            <ShowIf show={"order_guide" === item.type}>
              <Row className="item-bookmark-float">
                <Col xs="auto">
                  <OrderGuideBookmark elemId={`og_${item.id}`} />
                </Col>
              </Row>
            </ShowIf>
            <Row>
              <Col xs="12">
                <Row>
                  <Col xs={12} sm={4} md={2} className="item-image">
                    <img src={itemImage} alt={item.description || item.name} />
                  </Col>

                  <Col xs={12} sm={8} md={10} className="item-details">
                    <Row className="item-name">
                      <Col xs="12">{item.description || item.name}</Col>
                    </Row>
                    <Row className="item-manufacturer-details">
                      <Col xs="12" md="6" className="item-manufacturer-name">
                        {item.brand && item.brand.trim() ? item.brand.trim() : "-"}
                      </Col>
                      <Col
                        xs="12"
                        md="6"
                        className="item-manufacturer-distributor"
                      >
                        {item.distributor}
                      </Col>
                    </Row>
                    <Row className="item-package-details">
                      <Col xs="12" md="6" className="item-package-price">
                        {numeral(item.purchasedPrice).format("$0,0.00") +
                          " " +
                          item.unitMeasure}
                      </Col>
                      <Col xs="12" md="6" className="item-package-size">
                        {item.packSize}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </ListGroupItem>
        );
      })}
    </ListGroup>
  );
};

export default ItemsPurchased;
