import axios from 'axios';
import moment from 'moment-timezone';


const get = () => {
  return axios.get('/distributors');
};

const getExpanded = () => {
  return axios.get('/distributors?expand=dsrs,lastOrderGuide,skyloader,dcn');
};

const getById = (id) => {
  const timezone = moment.tz.guess();
  const params = { timezone: timezone };
  return axios.get(`/distributors/${id}`, { params });
};

const getByIdExpanded = (id) => {
  const timezone = moment.tz.guess();
  const params = { timezone: timezone };
  return axios.get(`/distributors/${id}?expand=dsrs,lastOrderGuide,skyloader,dcn`, { params });
};

const updateUserPassDistributor = (id, params) => {
  return axios.patch(`/distributors/${id}`, params);
};

const saveDistributor = (id, params) => {
  return axios.post(`/distributors/${id}/dsr`, params);
};
const updateDistributor = (id, params, dsrId) => {
  return axios.put(`/distributors/${id}/dsr/${dsrId}`, params);
};

const sendInvite = (id, params, dsrId) => {
  return axios.patch(`/distributors/${id}/dsr/${dsrId}`, params);
};
const deleteDistributor = (id, dsrId, params) => {
  return axios.put(`/distributors/${id}/dsr/${dsrId}`, params);
};

const list = (params) => {
  return axios.get('distributors/list', {
    params: params
  });
};

export default {
  get, getById, list, getExpanded, getByIdExpanded, saveDistributor, updateDistributor, sendInvite, updateUserPassDistributor, deleteDistributor
};