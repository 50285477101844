import React, { Fragment } from 'react'
import './IconBadge.scss';
import { Tooltip, Col } from 'reactstrap';
import useInput from '../SharedEffects/useInput';

const IconBadgeSolid = (props) => {
  const { Icon, elemId, children, color, colorIcon } = props;
  const { toggle, value } = useInput.useCheckbox();
  
  return (
    <Fragment>
      <div className='circle-sm d-inline-flex mx-1' id={`${elemId}`} style={{backgroundColor: color}}>
        <Icon color={colorIcon} size={8} />
      </div>
      <Tooltip innerClassName='bg-white text-black shadow solid w-320px' className='arrow-white'
        placement='top'
        autohide={false}
        isOpen={value}
        target={elemId}
        toggle={toggle}
      >
        {children}
      </Tooltip>
    </Fragment>
  )
};

export default IconBadgeSolid;
  