import React, { Fragment } from 'react';
import ShowIf from '../../../SharedComponents/ShowIf/ShowIf';
import { Row, Col } from 'reactstrap';
import SignUpAddDistributor from '../Sections/SignUpAddDistributor';
import { MdEdit, MdDelete } from 'react-icons/md';
import { colors } from '../../../config/constants';
import { isMobile } from 'react-device-detect';

const SignUpStepDistributors = (props) => {
  const {
    showDistributor, step, goTo, locationsData,
    distributors, editDistributor, setDistributors
  } = props;

  const openDistibutor = (l) => {
    goTo(step, `?distributor=${l}`);
  };

  const deleteDistributor = (index) => {
    // setDistributors(distributors.filter((d, i) => i !== index));
    setDistributors(index, null, true);


  };

  const distributorsMap = distributors.map((d, i) =>
    <Row key={`dist_${d.distributorName.value}`} className='mb-3'>
      <Col xs={8} >
        <Row className='py-3 border m-0'>
          <Col>
            {d.distributorName.label}
          </Col>
          <Col className='w-fc clickable' onClick={() => openDistibutor(i + 1)}>
            <MdEdit color={colors.lightBlueText} />
          </Col>
          <Col className='w-fc clickable' onClick={() => deleteDistributor(i)}>
            <MdDelete color={colors.lightBlueText} />
          </Col>
        </Row>
      </Col>
    </Row>
  );

  return (
    <Fragment>
      <ShowIf show={!showDistributor}>
        <Row>
          <Col className={isMobile ? 'font-weight-bold mb-3 text-center' : 'font-weight-bold mb-3'}>Distributor Information</Col>
        </Row>
        <Row className='mb-3'>
          <Col xs={12} lg={8} onClick={() => openDistibutor(distributors.length + 1)}>
            <div className='py-3 text-center border-dash text-label clickable'>
              + Add New Distributor
            </div>
          </Col>
        </Row>
        {distributorsMap}
      </ShowIf>
      <ShowIf show={showDistributor}>
        <SignUpAddDistributor number={showDistributor} distributorsAdded={distributors}
          index={showDistributor - 1} locationsData={locationsData}
          editDistributor={editDistributor}
        />
      </ShowIf>
    </Fragment>
  );
};

export default SignUpStepDistributors;