import React,{useState, useEffect} from 'react';
import useModal from '../useModal';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { modalMenuClassName } from '../../../config/constants';
import { isMobile } from 'react-device-detect';
import ShowIf from '../../ShowIf/ShowIf';
import SpecialRequestForm from '../../../Views/MainApp/Views/SpecialRequest/SpecialRequestForm';

const SpecialRequestModal = (props) => {
  const dispatch = useDispatch();
  const { id, menu, fullMenu, item } = props;
  const modalProps = useModal(id, menu, fullMenu);

  return (
    <Modal {...modalProps} modalClassName={modalMenuClassName}>
      <ModalHeader toggle={ !isMobile ? modalProps.toggle : null} className='bg-white border-bottom-0'>Add Special Request</ModalHeader>
      <ModalBody className='px-0 pt-0'>        
        <SpecialRequestForm modalProps={modalProps} item={item}/>
      </ModalBody>
    </Modal>
  );
};

export default SpecialRequestModal;