import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import ShowIf from '../ShowIf/ShowIf';
import { useSelector, useDispatch } from 'react-redux';
import { saveHeaderIndexRevOrder, saveHeaderContent } from '../../reducers/header/headerActions';
import { isMobile } from 'react-device-detect';
import  useGoto from '../GoTo/useGoTo';

const HeaderReviewOrder = (props) => {

    const { show } = props;
    const history = useHistory();
    const headerIndex = useSelector(({ header }) => header.index)
    const [indexStep, setIndexStep] = useState(headerIndex);
    const dispatch = useDispatch();
    const {placeOrder} = useGoto.useActions();

    const changeIndexStep = (step) => {
        if (step.indexStep == 1) {
            dispatch(saveHeaderIndexRevOrder(3));
            step.changeView();
        } else {
            dispatch(saveHeaderIndexRevOrder(step.indexStep));
            setIndexStep(step.indexStep);
        }
    }

    useEffect(() => {
        switch (history.location.pathname) {
            case '/checkout-ordering/savings':
                setIndexStep(2);
                break;
            case '/checkout-ordering/review-order':
                setIndexStep(3);
                break;
            case '/checkout-ordering/shipping-payment':
                setIndexStep(5);
                break;
            case '/checkout-ordering/final-review':
                setIndexStep(6);
                break;
        }
    }, [history.location.pathname]);

    const listSteps = [
        {
            indexStep: 1, name: 'PLACE ORDER', isDisabled: '', changeView: () => {
                placeOrder();
            }
        },
        { indexStep: 2, name: 'SAVINGS', isDisabled: 'disabled' },

        {
            indexStep: 3, name: 'REVIEW ORDER', isDisabled: '', changeView: () => {
                history.push('/checkout-ordering/review-order')
            }
        },

        // { indexStep: 4, name: 'SIGN IN', isDisabled: 'disabled' },

        {
            indexStep: 5, name: 'SHIPPING AND PAYMENT', isDisabled: 'inactive',
            changeView: () => {
                history.push('/checkout-ordering/shipping-payment')
            }
        },

        { indexStep: 6, name: 'FINAL REVIEW', isDisabled: 'inactive' },
    ]

    useEffect(() => {
        console.log(headerIndex)

        switch (headerIndex) {
            case 2:
                // history.push('/checkout-ordering/savings');
                setIndexStep(headerIndex);
                if (isMobile) {
                    const headerProps = {
                        showMenu: false,
                        showLogo: false,
                        showBack: true,
                        showCart: true,
                        showReviewOrder: false,
                        title: 'Review Order',
                        backFunct: () => {
                            placeOrder();
                        }
                    };
                    dispatch(saveHeaderContent(headerProps));
                }
                break;
            case 3:
                setIndexStep(headerIndex);
                break;
            case 5:
                history.push('/checkout-ordering/shipping-payment')
                setIndexStep(headerIndex);
                break;
            case 6:
                history.push('/checkout-ordering/final-review')
                setIndexStep(headerIndex);
                break;
            default:
                break;
        }

    }, [headerIndex])

    return (
        <ShowIf show={show}>
            <Row className='order-view'>
                {listSteps.map(step => {
                    return (
                        <Col key={step.indexStep} className='disabled' onClick={() => { changeIndexStep(step) }}
                            className={indexStep === step.indexStep ? 'active' : step.isDisabled}>
                            <div className='point'></div>
                            <div className='line'></div>
                            <h1>{step.name}</h1>
                        </Col>
                    )
                })}
            </Row>
        </ShowIf>
    )
}

export default HeaderReviewOrder
