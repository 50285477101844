import React, { Fragment, useState, useEffect } from 'react';
import { isMobile } from "react-device-detect";
import { Form, Input, Row, Col, Button, Label } from 'reactstrap';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import useSpecialRequest from './useSpecialRequest';


const SpecialRequestForm = (props) => {
  const lastModified = useSelector(({cart}) => cart.lastModified);
  const distributorsList = useSelector(({distributors}) => distributors.list);
  const {item} = props;  
  const {
    setDistributor,
    setUnit,
    setQuantity, quantity, valid,
    setDescription, description, 
    submitDisabled, onSubmit, 
  } = useSpecialRequest.useFormInfo(props);

  const { distributors, distributorSel, units, unitSel } = useSpecialRequest.useDefaultInfo(distributorsList, lastModified, item, setDistributor, setUnit);
  
  const qtyHandler = (e) =>{
    const val = e.target.value;
    if(!valid(val)){
      return;
    }
    setQuantity(val);
  };

  const distributorHandler = (e) =>{
    distributorSel.set(e);
    setDistributor(e);
  };

  const unitHandler = (e) =>{
    unitSel.set(e);
    setUnit(e);
  };

  useEffect(() => {
    setQuantity(1);
    if(item){
      setDescription(item.description);
    }
  }, []);

  return (
      <Form className='flex-column m-auto' noValidate="novalidate" onSubmit={onSubmit}>
        <Row className='m-0 mt-3'>        
          <Col>
            <label>Distributor</label>
            <Select className='fb-select' classNamePrefix='select'
              placeholder='Select ...'
              options={distributors}
              onChange={distributorHandler}
              value={distributorSel.value}
              isDisabled={false}
            />
          </Col>
        </Row>
        <Row className='m-0 mt-3'>
          <Col>
            <label>Unit of Measure</label>
            <Select className='fb-select' classNamePrefix='select' 
              placeholder='Select ...'
              options={units}
              onChange={unitHandler}
              value={unitSel.value}
              isDisabled={false}
            />
          </Col>
          <Col>
            <Label>Quantity</Label>
            <Input type='text' value={quantity} onChange={qtyHandler} />
          </Col>
        </Row>
        <Row className='m-0 mt-3'>
          <Col>
            <textarea className='w-100' onChange={setDescription} value={description}
              placeholder='Include your preferences for brand, organic. Be as specific as possible.' >
            </textarea>
          </Col>
        </Row>
        <Row className='m-0 mt-5 justify-content-center'>
          <Col className={(isMobile ? `fixed-down py-2 ` : ` `)} xs={isMobile ? 12 : 4}>
            <Button disabled={submitDisabled()} type='submit' color='green' size='lg' block>Add to Cart</Button>
          </Col>
        </Row>
      </Form>    
  );
};

export default SpecialRequestForm;