import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Container, Button } from 'reactstrap';
import { useDispatch } from 'react-redux';
import Header from '../../../../SharedComponents/Header/Header';
import ItemsAndDistributors from './ItemsAndDistributors/ItemsAndDistributors';
import './Accounting.scss';
import { showModal } from '../../../../reducers/modals/modalsActions';
import BulkUploadModal from './BulkUploadModal/BulkUploadModal';
import DownloadQuickbooksModal from '../../../../SharedComponents/Modals/DownloadQuickbooksModal/DownloadQuickbooksModal';
import uploadIcon from '../../../../assets/images/upload-icon.svg';
import downloadIcon from '../../../../assets/images/download-icon.svg';
import uploadFileIcon from '../../../../assets/images/upload-file.svg';
import accountingApi from '../../../../api/accountingApi';

const Accounting = () => {

  const dispatch = useDispatch();

  const [template, setTemplate] = useState(null);

  const getTemplate = () => {
    accountingApi.getTemplate()
      .then(({data}) => {
        setTemplate(data);
      })
      .catch(error => console.log('accountingApi getTemplate Error:', error));
  };

  const openBulkUploadModall = (e) => {
    e.preventDefault();
    dispatch(showModal(BulkUploadModal, {uploadFileIcon, template}));
  };

  const openQuickbookModal = (e) => {
    e.preventDefault();
    dispatch(showModal(DownloadQuickbooksModal, {}));
  };

  useEffect(() => {
    getTemplate();
  }, []);

  const rightButton = {icon: [
    <Button className='btn-bulk-upload' onClick={openBulkUploadModall}><img src={uploadIcon} style={{marginTop: '-2px', marginRight: '7px'}} />Bulk Upload</Button>,
    <Button className='btn-download-quickbooks' onClick={openQuickbookModal}><img src={downloadIcon} style={{marginTop: '-2px', marginRight: '7px'}} />Download</Button>,
  ]};

  return (
    <Switch>
      <Route exact path='/accounting'>
        <Container fluid={true} className='m-0 p-0'>
          <Header showSearchbar={true} showMenu={true} showLogo={true} showBack={false} title='' showCart={false} rightButton={rightButton} />
          <ItemsAndDistributors />
        </Container>
      </Route>
      <Route>
        <Redirect to='/accounting' />
      </Route>
    </Switch>
  );
};

export default Accounting;
