import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import useModal from '../../../../../../../SharedComponents/Modals/useModal';
import { isMobile } from 'react-device-detect';
import ItemDetailsImage from '../SharedComponents/ItemDetailsImage';
import ItemDetailsHowMuch from '../SharedComponents/ItemDetailsHowMuch';
import ItemDetailsUnitConversion from '../SharedComponents/ItemDetailsUnitConversion';
import ItemDetailsFooter from '../SharedComponents/ItemDetailsFooter';
import { fraction, number } from 'mathjs';
import recipeApi from '../../../../../../../api/recipeApi';
import useItemDetails from '../useItemDetails';
import validate from 'validate.js';
import subrecipeImage from '../../../../../../../assets/images/subrecipe-image.png';
import { useHistory } from 'react-router-dom';

const ItemDetailsSubrecipe = (props) => {
  const history = useHistory();
  const {
    id, ingredient, getRecipeData
  } = props;
  const { item } = ingredient;
  const modalProps = useModal(id, isMobile, true);  

  const init = {
    recipeAmount: ingredient.recipe_amount_fraction ? ingredient.recipe_amount_fraction : ingredient.recipe_amount,
    uom: ingredient.recipe_pack_size_uom,
    waste: ingredient.waste_percentage,
    conversion: {
      packSize: ingredient.conversion_pack_size_fraction ? ingredient.conversion_pack_size_fraction : ingredient.conversion_pack_size_value,
      uom: ingredient.conversion_pack_size_uom
    },
    recipe: {
      packSize: ingredient.recipe_pack_size_fraction ? ingredient.recipe_pack_size_fraction : ingredient.recipe_pack_size_value,
    }
  };

  const { recipeAmount, uom, waste, conversion, canConvert } = useItemDetails.useConversion(init);
  const { onDelete } = useItemDetails.useDeleteIngredient(ingredient.recipe_id, ingredient.id, modalProps.toggle, getRecipeData);

  const cantSave = () => {
    const form = {
      recipeAmount: recipeAmount.value,
      waste: waste.value,
      itemPackSize: conversion.item.packSize.value,
      recipePackSize: conversion.recipe.packSize.value
    };
    const constrains = {
      recipeAmount: {fraction:{strict:true}},
      waste: {numericality: waste.value ? true : false},
      itemPackSize: {fraction:{strict:true}},
      recipePackSize: {fraction:{strict:true}},
    };
    const result = validate(form, constrains);
    return result ? true : false;
  };

  const onSave = () => {
    const params = {
      ingredient_type: "subrecipe",
      ingredient_id: ingredient.ingredientId,
      recipe_amount: number(fraction(recipeAmount.value)),
      recipe_amount_fraction: fraction(recipeAmount.value).toFraction(),
      conversion_pack_size_value: number(fraction(conversion.item.packSize.value)),
      conversion_pack_size_fraction: fraction(conversion.item.packSize.value).toFraction(),
      conversion_pack_size_uom: conversion.item.uom,
      recipe_pack_size_value: number(fraction(conversion.recipe.packSize.value)),
      recipe_pack_size_fraction: fraction(conversion.recipe.packSize.value).toFraction(),
      recipe_pack_size_uom: conversion.recipe.uom,
      waste_percentage: waste.value
    };
    isEditSave(params);
    modalProps.toggle();
  };

  const isEditSave = (params) => {
    recipeApi.patchIngredient(ingredient.recipe_id, ingredient.id, params).then(({data}) => {
      getRecipeData();
      modalProps.toggle();
    }).catch(err => {
      console.error('PatchIngredientApi error:', err);
    });
  };

  return (
    <Modal {...modalProps}>
      <ModalHeader toggle={modalProps.toggle}>Subrecipe Details</ModalHeader>
      <ModalBody>
        <ItemDetailsImage image={subrecipeImage} name={ingredient.item.name} />
        <ItemDetailsHowMuch recipeAmount={recipeAmount} uom={uom} waste={waste} />
        <ItemDetailsUnitConversion canConvert={canConvert} {...conversion} />
      </ModalBody>
      <ItemDetailsFooter onDelete={onDelete} onSave={onSave} disabled={cantSave()} label='Save' />
    </Modal>
  );
};

export default ItemDetailsSubrecipe;