import React, { Fragment, useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { localStorageConst } from '../../../../../config/constants';
import PlaceOrderInventoryOnline from './PlaceOrderInventoryOnline';
import PlaceOrderInventoryOffline from './PlaceOrderInventoryOffline';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import AlertModal from '../../../../../SharedComponents/Modals/AlertModal/AlertModal';
import { showModal } from '../../../../../reducers/modals/modalsActions';
import useConnection from '../../../../../SharedComponents/OfflineMode/useConnection';


const PlaceOrderInventory = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { getConnectionState } = useConnection.useMode();
  let inventorySync = JSON.parse(localStorage.getItem(localStorageConst.keys.inventorySync));
  
  const blockNavigation = (location, action) => {
    if(getConnectionState()){
      return;
    }
    const path = location.pathname;
    return (path==='/' || path==='/place-order/inventory')
      ? true
      : "You're in offline mode, please enable online mode before leaving the page or you will lose any changes made to your account. Do you want to leave anyway?";
  };

  window.onbeforeunload = function() {
    const path = history.location.pathname;
    if(path==='/place-order/inventory' && !getConnectionState()){
      return "Data will be lost if you leave the page, are you sure?";
    }
  };
  
  return (
    <Fragment>
      <Prompt message= {blockNavigation} />

      <ShowIf show={getConnectionState()}>
        <PlaceOrderInventoryOnline />
      </ShowIf>

      <ShowIf show={!getConnectionState()}>
        <PlaceOrderInventoryOffline />
      </ShowIf>
    </Fragment>
  );
};

export default PlaceOrderInventory;
