import React from 'react';
import { Row, Col } from 'reactstrap';
import ShowIf from '../../SharedComponents/ShowIf/ShowIf';
import { isMobile } from 'react-device-detect';

const AdminLoginHeader = ({logo}) => {
  return (
    <Row className='m-0 admin-login-header mb-5 align-items-center pl-2 pr-2'>
      <ShowIf show={!isMobile}>
        <Col className='p-2'>
          <img src={logo} alt='Company Logo' className='company-logo' />
        </Col>
      </ShowIf>
      <Col className={'text-center' + (isMobile ? ' py-3' : ' p-0')}>
        <h4>Login As</h4>
      </Col>
      <ShowIf show={!isMobile}>
        <Col className='p-2' />
      </ShowIf>
    </Row>
  )
};

export default AdminLoginHeader;